import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import * as d3_axis from 'd3-axis';
import * as d3_scale from 'd3-scale';
import * as d3_selection from 'd3-selection';

class Comparison extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            class: props.target
        };
    }

    componentDidMount() {
        // get the data
        let className = this.state.class || 'comparison--chart';
        className = '.' + className;
        const data = JSON.parse(this.state.data);

        // setup the chart
        const width = 350;
        const height = 150;
        const barHeight = 16;
        const x = d3_scale.scaleLinear()
            .domain([0, 100])
            .range([0, width - 35]); // -35 for the bar values
        const y = d3_scale.scaleBand()
            .domain(data.map(d => d.name))
            .range([0, barHeight * data.length]);

        // select the chart
        const chart = d3_selection
            .select(className)
            .attr('viewBox','-27 0 ' + width + ' ' + height)
            .attr('preserveAspectRatio','xMinYMin');

        // set svg groups and data
        const bar = chart
            .selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('class', 'bar')
            .attr('transform', (d, i) => `translate(0,${i * 1.2 * barHeight})`);

        // add the bars
        bar
            .append('rect')
            .attr('width', d => x(d.value))
            .attr('height', barHeight)
            // set color based on value
            .attr('class', d => {
              if (d.value >= 80) {
                  d = 's4';
              } else if (d.value >= 60) {
                  d = 's3';
              } else if (d.value >= 40) {
                  d = 's2';
              } else if (d.value >= 20) {
                  d = 's1';
              } else {
                  d = 's0';
              }
              return d;
            });

        // add value to bar
        bar
            .append('text')
            .attr('x', 0)
            .attr('y', barHeight / 2)
            .attr('dy', '.35em')
            .attr('dx', '-5px')
            .attr('class', 'value')
            .attr('fill', 'black')
            .text(d => d.value);

        // ranges
        const ranges = [0, 20, 40, 60, 80];

        // add x axis
        const displayIntervals = ['0', '19', '20', '39', '40', '59', '60', '79', '80', '100'];
        chart
            .append('g')
            .attr('transform', `translate(0, ${(barHeight * data.length) + 4})`)
            .attr('class', 'scale--x')
            .call(d3_axis.axisBottom(x)
                .tickValues(displayIntervals)
            );

        // add legend
        const legend = chart
            .selectAll('g')
            .data(data)
            .append('g')
            .attr('transform', `translate(0, ${(barHeight * data.length) + 40})`)
            .attr('class', 'legend');

        // IE 10 requires translates to be attributes
        chart.selectAll('g.tick:nth-of-type(2n) > text')
                .attr('transform', 'translate(-6,0)');

        chart.selectAll('g.tick:nth-of-type(odd):not(:first-of-type) > text')
                .attr('transform', 'translate(8,0)');

        chart.selectAll('g.tick:not(:last-of-type) > line')
                .attr('transform', 'scale(1, 2) translate(2.5, 2)');

        // add legend square
        legend.append('rect')
            .attr('x', 0)
            .attr('y', -10)
            .attr('width', 10)
            .attr('height', 10)
            .attr('class', d => {
              if (d.value >= 80) {
                  d = 's4';
              } else if (d.value >= 60) {
                  d = 's3';
              } else if (d.value >= 40) {
                  d = 's2';
              } else if (d.value >= 20) {
                  d = 's1';
              } else {
                  d = 's0';
              }
              return d;
          });

        // add legend name
        legend.append('text')
            .data(data)
            .attr('x', 15)
            .attr('y', 0)
            .attr('dy', '-1')
            .text(d => d.name);

        // ranges
        const range = chart.selectAll('rect.range').data(ranges);
        range.enter().append('rect').attr('class', (d, i) => `range s${i}`)
            .attr('width', x(20))
            .attr('height', barHeight / 10)
            .attr('x', d => x(d))
            .attr('transform', 'translate(0, ' + (barHeight * 1.5) + ' )')
            .attr('y', barHeight); // set to bottom of chart
    }

    render() {
        return (
            <div className="comparison">
                <svg className={`comparison--chart ${this.state.class}`}>
                    <title>Comparison Chart</title>
                </svg>
            </div>
        );
    }

}

export default Comparison;
