import React, {Component} from 'react';
import ReactDOM from 'react-dom';

class Error extends Component {
    render() {
        return (
            <div className="errors">
              {this.props.errors.map((err, index) => {
                  return (
                      <div key={index} className="error">{err.message}</div>
                  );
              })}
            </div>
        );
    }
}

export default Error;
