import React from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select/lib/Async';
import { components } from 'react-select';

const styles = {
  container: provided => ({
    ...provided,
    width: '100%'
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: '16px',
    color: '#98a4ae'
  }),
  option: provided => ({
    width: '100%',
    height: '100%',
    lineHeight: '40px',
    padding: '0 20px',
    display: 'inline-block',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '16px',
    color: '#000',
    lineHeight: '22px'
  })
};

const OptionDiv = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: bold;
  ${props => (props.isFocused ? 'background-color: #C7E9F1;' : '')}
`;

const Highlight = styled.span`
  font-weight: normal;
`;

const Option = props => {
  const {
    isFocused,
    isSelected,
    label,
    selectProps: { inputValue }
  } = props;

  const higlightRegEx = new RegExp(`(${inputValue})`, 'gi');
  const parts = label.split(higlightRegEx);
  let hasHighlighted = false;

  return (
    <OptionDiv isSelected={isSelected} isFocused={isFocused}>
      <components.Option {...props}>
        {parts.map((part, i) => {
          if (
            !hasHighlighted &&
            part.toLowerCase() === inputValue.toLowerCase()
          ) {
            hasHighlighted = true;
            return <Highlight key={i}>{part}</Highlight>;
          } else {
            return <span key={i}>{part}</span>;
          }
        })}
      </components.Option>
    </OptionDiv>
  );
};

export default function SectorSelect({
  value,
  name,
  id,
  noOptionsMessage,
  onChange,
  getOptions,
  onFocus,
  onBlur
}) {
  return (
    <ReactSelect
      styles={styles}
      value={value}
      name={name}
      inputId={id}
      classNamePrefix="location-select"
      placeholder="Search by NAICS code or Sector Name"
      noOptionsMessage={() => noOptionsMessage}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      blurInputOnSelect
      loadOptions={getOptions}
      components={{ Option }}
      maxMenuHeight={1200}
    />
  );
}
