import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Checkbox from '../common/checkbox.jsx';
import ReactModal from 'react-modal';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';
import {csrftoken} from '../../utils/csrf.jsx';
import styled from 'styled-components';
import 'formdata-polyfill';
import { ImproveIcon, SuccessIcon } from './icons.jsx';
import {replace, isEmpty} from 'lodash';

const ReactModalStyled = styled(ReactModal)`
    max-width: 600px;

    .ReactModal__Header {
        margin-bottom: 16px;
    }
`;

const FeedbackList = styled.ul`
    margin: 8px 0 24px 0;
`;

const FeedbackListItem = styled.li`
    position: relative;
    text-transform: capitalize;
    line-height: 24px;
`;

const FooterClose = styled.div`
    &:before {
        content: " ";
        border: 1px solid #f4f6f9;
        display: block;
        margin: 0 -34px 24px;
    }
    text-align: right;
`;

const Label = styled.label`
    font-size: 16px !important;
`;

const ImproveIconContainer = styled.div`
    display: inline-block;
    margin-right: 4px;
`; 

class FeedbackCallToAction extends React.Component {
    render () {
        return (
            <div className="margin__none caption--secondary print__hide">
                <button
                    className="rbtn rbtn--white rbtn--md"
                    onClick={this.props.handleOpenModal}
                >
                  <ImproveIconContainer>
                    <ImproveIcon />
                  </ImproveIconContainer>
                  {LOCALIZED_STRS.get().improveProfile}
                </button>
            </div>
        );
    }
}

class FeedbackModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company: props.feedbackheader,
            feedbackheader: LOCALIZED_STRS.get().feedback_provide,
            feedbackurl: props.feedbackurl,
            formSubmitted: false,
            formSuccess: false,
            isLoading: false,
            formData: [],
            checkedItems: new Map()
        };
        this.formRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, isChecked)
        }));
    }

    handleSubmit(event) {
        event.preventDefault();
        const self = this;
        const formElement = this.formRef.current;
        const formData = new FormData(formElement);
        const csrftoken_value = csrftoken();
        formData.append('company_name', this.state.company);
        formData.append('csrfmiddlewaretoken', csrftoken_value);
        this.setState({isLoading: true});
        for (const pair of formData.entries()) {
            // dont dispaly CSRFToken, company name or additional info textareas
            if (pair[0]
                !== 'csrfmiddlewaretoken'
                && !pair[0].includes('info')
                && !pair[0].includes('company_name')
            ){
                this.state.formData.push(pair[0]);
            }
        }
        fetch(this.state.feedbackurl, {
            method: 'POST',
            body: formData
        })
        .then((res) => {
            self.setState({
                isLoading: false
            });
            if (res.status === 200) {
                self.setState({
                    formSubmitted: true,
                    formSuccess: true,
                    feedbackheader: LOCALIZED_STRS.get().feedback_thanks
                });
            } else {
                self.setState({
                    formSubmitted: true,
                    formSuccess: false
                });
            }
        });
    }

    render() {
        const checkboxes = [
          {
            name: 'address',
            label: LOCALIZED_STRS.get().address,
            current: this.props.address,
            text: LOCALIZED_STRS.get().feedback_instruction + LOCALIZED_STRS.get().address + ' ' + LOCALIZED_STRS.get().below
          },
          {
            name: 'sector',
            label: LOCALIZED_STRS.get().sector,
            current: this.props.subheader,
            text: LOCALIZED_STRS.get().feedback_instruction + LOCALIZED_STRS.get().sector + ' ' + LOCALIZED_STRS.get().below
          },
          {
            name: 'year',
            label: LOCALIZED_STRS.get().yearEstablished,
            current: this.props.established,
            text: LOCALIZED_STRS.get().feedback_instruction + LOCALIZED_STRS.get().yearEstablished + ' ' + LOCALIZED_STRS.get().below
          },
          {
            name: 'size',
            label: LOCALIZED_STRS.get().companySize,
            current: this.props.size,
            text: LOCALIZED_STRS.get().feedback_instruction + LOCALIZED_STRS.get().companySize + ' ' + LOCALIZED_STRS.get().below
          },
          {
            name: 'website',
            label: LOCALIZED_STRS.get().website,
            current: this.props.website,
            text:  LOCALIZED_STRS.get().feedback_instruction + LOCALIZED_STRS.get().website + ' ' + LOCALIZED_STRS.get().below
          }
        ];
        return (
        <ReactModalStyled
            isOpen={this.props.showModal}
            contentLabel="onRequestClose Example"
            onRequestClose={this.props.handleCloseModal}
            className="Modal"
            overlayClassName="Overlay">
            <div className="ReactModal__Header">
                <h2>{this.state.feedbackheader}</h2>
                <button
                    onClick={this.props.handleCloseModal}
                    className="rbtn rbtn--transparent"
                >
                <i
                    className="icon icon--close close-menu"
                    aria-hidden="true"
                >
                </i>
                </button>
            </div>
            <React.Fragment>
              { !this.state.formSubmitted &&
                  <form
                    ref={this.formRef}
                    id="healthmarkFeedback"
                    onSubmit={this.handleSubmit}
                    action={this.state.feedbackurl}
                    method="POST"
                  >
                  {this.state.isLoading ? (
                      <div className="please_wait_animation"></div>
                  ) : (
                      checkboxes.map((item, index) => (
                        <div
                            key={index}
                            className="input__group input__group--inline"
                        >
                            <Checkbox
                                name={item.name}
                                checked={this.state.checkedItems.get(item.name)}
                                onChange={this.handleChange}
                            />
                            <Label
                                htmlFor={item.name}
                                className="text__casing--capitalize"
                            >
                                {item.label}
                            </Label>
                            <p
                                className="caption--secondary margin__horizontal--loose--left"
                            >{item.current}</p>
                            {this.state.checkedItems.get(item.name) &&
                                <>
                                    <p className="margin__vertical--tight">
                                        {item.text}
                                    </p>
                                    <textarea
                                        id={item.name + '_info'}
                                        name={item.name + '_info'}
                                        required
                                        rows="5"
                                        cols="33"
                                    ></textarea>
                                </>
                            }
                        </div>
                      ))
                  )}
                    <div className="form--actions">
                        <button
                            type="submit"
                            name="submit_suggest"
                            className="rbtn rbtn--primary"
                            disabled={isEmpty(this.state.checkedItems)}
                        >
                            {LOCALIZED_STRS.get().submit}
                        </button>
                    </div>
                  </form>
              }
              { (this.state.formSubmitted && this.state.formSuccess) &&
                  <div
                    className = "form form--submitted animated animated--fadeInTop"
                  >
                  {this.state.formSuccess &&
                      <>
                      <div className="margin__vertical">
                        <SuccessIcon />
                      </div>
                      <p>{LOCALIZED_STRS.get().feedback_sent}{this.state.company}</p>
                      <FeedbackList
                        className="content__list--circle"
                      >
                      {this.state.formData.map((item, index) => (
                          <FeedbackListItem
                            key={index}
                          >
                            {replace(item, '_', ' ')}
                          </FeedbackListItem>
                      ))
                      }
                      </FeedbackList>
                      <FooterClose className="form--actions">
                          <button
                              onClick={this.props.handleCloseModal}
                              className="rbtn rbtn--primary"
                          >{LOCALIZED_STRS.get().close}</button>
                      </FooterClose>
                      </>
                  }</div>
              }
              { (this.state.formSubmitted && !this.state.formSuccess) &&
                  <div className="form form--centered form--failed animated animated--fadeInTop">
                         <h3 className="margin__vertical">{LOCALIZED_STRS.get().general_error}</h3>
                  </div>
              }
            </React.Fragment>
        </ReactModalStyled>
        );
    }
}

class HealthMarkFeedback extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            hasError: false,
            hasFocus: false,
            showModal: false,
            companyID: props['company-id']
        });

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal () {
      this.setState({ showModal: true });
    }

    handleCloseModal () {
      this.setState({ showModal: false });
    }

    render() {
        return (
            <div>
                <FeedbackCallToAction
                    handleOpenModal={this.handleOpenModal}
                    hasFocus={this.state.hasFocus}
                    isLoading={this.state.isLoading}
                />
                <FeedbackModal
                    feedbackurl={this.props.feedbackurl}
                    feedbackheader={this.props.feedbackheader}
                    handleOpenModal={this.handleOpenModal}
                    handleCloseModal={this.handleCloseModal}
                    showModal={this.state.showModal}
                    {...this.props}
                />
            </div>
        );
    }
}

export default HealthMarkFeedback;
