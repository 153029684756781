import React from 'react';
import HealthMarkFeedback from '../forms/healthmark-feedback/index.jsx';
import WatchList from '../forms/healthmark-watchlist/index.jsx';
import BreadCrumbs from './breadcrumbs.jsx';
import styled from 'styled-components';

const BreadCrumbsWrapper = styled.div`
    margin-bottom: 8px;
`;

const StyledHeader = styled.div`
    justify-content: space-between;
    position: relative;
    margin: ${props => props.margin ? props.margin : '32px auto'}

`;

const StyledRuler = styled.hr`
    margin: 32px auto 0;
`;

const StyledParagraph = styled.p`
    color: #828C94;
    margin-top: 8px;
`;


function Header(props) {
    return (
        <StyledHeader
            margin = {props.margin}
            className="row"
        >
            <div className="col-xs-12 col-sm-7">
                <BreadCrumbsWrapper>
                    <BreadCrumbs
                        data={props.breadcrumbs}
                    >
                    </BreadCrumbs>
                </BreadCrumbsWrapper>
                {props.linkhref &&
                    <React.Fragment>
                        {props.isparentlink !== undefined &&
                            <span className="header__item header__icon">
                                <i className="icon icon--hierarchy"></i>
                                <div className="icon--line--container"></div>
                            </span>
                        }
                        <span className="header__item header__details">
                            <p><a
                                href={props.linkhref}
                                className="link link--sentence"
                                rel="noopener noreferrer"
                            >{props.linktext}</a></p>
                            <h1>{props.mainheader}</h1>
                        </span>
                    </React.Fragment>
                }
                <span className="header__item header__main">
                    {props.linkhref == undefined &&
                        <h1 className="visually__h1">{props.mainheader}</h1>
                    }
                    <StyledParagraph>
                        {props.subheader}
                    </StyledParagraph>
                </span>
            </div>
            <div className="col-xs-12 col-sm-5 print__hide">
            {props.watchlist_url !== undefined &&
                <div className="float-right margin__horizontal--tight--left">
                    <WatchList
                        {...props}
                    />
                </div>
            }
            {props.feedbackurl !== undefined &&
                <div className="float-right margin__horizontal--tight--left">
                    <HealthMarkFeedback
                        {...props}
                    />
                </div>
            }
            {props.editurl !== undefined &&
                <div className="float-right">
                    <a
                      href={props.editurl}
                      className="rbtn rbtn--ghost rbtn--md"
                    >Edit profile
                    </a>
                </div>
            }
            {props.showeditcompanybutton !== undefined &&
                <div
                  className="float-right"
                  slot-id="ui:slot:hm:profile:edit-company-button"
                  data-company-id={props.companyid}
                >
                </div>
            }
            </div>
            <div className="col-xs-12">
                <StyledRuler />
            </div>
        </StyledHeader>
    );
}

export default Header;
