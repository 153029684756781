import React from 'react';
import Indicator from './summaries/indicator.jsx';
import Strengths from './summaries/strengths.jsx';
import Ratio from './summaries/ratio.jsx';
import Trend from './summaries/trend.jsx';
import Fins, {Fins2} from './summaries/fins.jsx';
import OnDemand from './summaries/ondemand.jsx';
import News from './summaries/news.jsx';
import UTILS from '../utils/utils.jsx';

class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header: props.header || '',
            isActive: UTILS.isEmptyOrUndefined(props.active) ? false : props.active,
            hasHeader: !UTILS.isEmptyOrUndefined(props.header)
        };
        this.supported = {
            strengths: {
                icon: 'icon icon--dialog',
                required: ['header', 'strengths', 'concerns'],
                component: Strengths
            },
            ratio: {
                icon: 'icon icon--ratio',
                required: ['header', 'list'],
                component: Ratio
            },
            indicator: {
                icon: 'icon icon--action-indicator',
                required: ['fhr', 'chs', 'financial-date'],
                component: Indicator
            },
            fins: {
                icon: 'icon icon--fins',
                required: ['header', 'list'],
                component: Fins
            },
            trend: {
                icon: 'icon icon--trend',
                required: ['periods'],
                component: Trend
            },
            news: {
                icon: 'icon icon--news',
                required: ['articles'],
                component: News
            },
            ondemand: {
                icon: 'icon icon--reports',
                required: ['reports'],
                component: OnDemand
            }
        };
    }
    isValid (){
        const params = this.supported[this.props.type];
        if(typeof params === 'undefined'){
            return false;
        } else {
            const valid = params.required.map( requirement => !UTILS.isEmptyOrUndefined(this.props[requirement])).indexOf(false) === -1;
            return valid;
        }
    }
    render() {
        const DynamicComponent = this.supported[this.props.type].component;
        return (
            <section>
              { this.isValid() &&
                  <div className={ (this.state.isActive ) ? 'accordion accordion--active' : (this.state.hasHeader) ? 'accordion' : 'accordion accordion--locked'} id={this.props.id} tracking-name={this.props.type}>
                      { this.state.hasHeader &&
                          <button className="accordion__header full-width-inherit">
                              <i className={this.supported[this.props.type].icon} aria-hidden="true"></i>
                              {this.props.header}
                          </button>
                      }
                      <div className="accordion__details">
                          <DynamicComponent { ...this.props } summary="true"/>
                          { this.props.reveal &&
                              <button className="accordion__more" revealer={this.props.reveal} tracking-name={this.props.type} reveal-active={this.props['reveal-active']}>
                                  MORE<span className="sr-only">{` about ${this.props.header}`}</span>
                              </button>
                          }
                      </div>
                  </div>
                }
            </section>
        );
    }
}

class Summary2 extends Summary {
    constructor(props) {
        super(props);
        this.supported.fins.component = Fins2;
    }
}


export { Summary as default, Summary2 };
