import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Input from '../forms/common/input.jsx';
import RadioGroup from '../forms/common/radioGroup.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import CSRFToken from '../utils/csrf.jsx';


class RelationshipManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitUrl: props.url,
        };
    }

    render() {
      return (
          <div className="relationship_manager">
            <form autoComplete="off" action={this.state.submitUrl} method="post">
              <CSRFToken />
              <Input
                  id="id_full_name"
                  value={this.props.full_name}
                  label={LOCALIZED_STRS.get().name}
                  type="text"
                  name="full_name"
                  required="required"
                  onChange={event => this.handleChange(event) }
              />
              <Input
                  id="id_email"
                  value={this.props.email}
                  label={LOCALIZED_STRS.get().email}
                  type="email"
                  name="email"
                  required="required"
                  onChange={event => this.handleChange(event) }
              />
              <div className="form--actions col-xs-12">
                  <button
                      type="submit"
                      name="Save"
                      className="rbtn rbtn--primary float-right"
                  >
                      {LOCALIZED_STRS.get().save}
                  </button>
              </div>
            </form>
        </div>
      );
    }
}

export default RelationshipManager;
