import React, { Component } from 'react';
import CompanyInput from './company-input.jsx';
import LocationSelect from './location-select.jsx';
import SearchForm from './search-form.jsx';
import SubmitButton from './submit-button.jsx';
import CancelIcon from './cancel-icon.jsx';
import {
  LOCATION_QUERY_FOCUS_MESSAGE,
  LOCATION_QUERY_NO_RESULTS_MESSAGE,
  LOCATION_QUERY_TOO_SHORT_MESSAGE,
  LOCATION_QUERY_ERROR_MESSAGE
} from './constants';
import {
  getLocationSuggestions,
  transformSuggestionsToOptions,
  search,
  getSearchTerms
} from './utils';
import debounce from 'debounce-promise';

export default class HealthMarkSearch extends Component {
  constructor(props) {
    super(props);

    const { companySearchQuery, selectedLocation } = getSearchTerms(
      decodeURI(window.location.search)
    );

    this.state = {
      companySearchQuery,
      selectedLocation,
      locationSearchQuery: '',
      noOptionsMessage: LOCATION_QUERY_TOO_SHORT_MESSAGE,
      isCompanyInputFocused: false,
      isLocationInputFocused: false
    };
  }

  getOptions = query => {
    return new Promise(resolve => {
      this.setState({
        locationSearchQuery: query
      });

      if (query.length < 2) {
        resolve([]);
        this.setState({
          noOptionsMessage: LOCATION_QUERY_TOO_SHORT_MESSAGE
        });
      } else {
        getLocationSuggestions(
          `${this.props.locationsuggestionurl}?location-term=${query}`
        )
          .then(suggestions => {
            const options = transformSuggestionsToOptions(suggestions);

            resolve(options);

            if (this.state.locationSearchQuery === query) {
              this.setState({
                noOptionsMessage:
                  options.length === 0
                    ? LOCATION_QUERY_NO_RESULTS_MESSAGE
                    : LOCATION_QUERY_FOCUS_MESSAGE
              });
            }
          })
          .catch(e => {
            resolve([]);

            if (this.state.locationSearchQuery === query) {
              this.setState({
                noOptionsMessage: LOCATION_QUERY_ERROR_MESSAGE
              });
            }
          });
      }
    });
  };

  handleSearchSubmit = e => {
    e.preventDefault();

    const { selectedLocation, companySearchQuery } = this.state;

    if (companySearchQuery.length === 0) {
      return;
    }

    search(this.props.searchurl, encodeURIComponent(companySearchQuery), selectedLocation);
  };

  handleCompanyInputChange = e =>
    this.setState({
      companySearchQuery: e.target.value
    });

  handleSelectedLocation = selectedLocation => {
    this.setState({
      noOptionsMessage: LOCATION_QUERY_FOCUS_MESSAGE,
      selectedLocation
    });
  };

  handleCompanyInputBlur = () =>
    this.setState({
      isCompanyInputFocused: false
    });

  handleCompanyInputFocus = () =>
    this.setState({
      isCompanyInputFocused: true
    });

  handleLocationInputBlur = () =>
    this.setState({
      isLocationInputFocused: false
    });

  handleLocationInputFocus = () =>
    this.setState({
      noOptionsMessage: LOCATION_QUERY_FOCUS_MESSAGE,
      isLocationInputFocused: true,
      selectedLocation: null
    });

  clearCompanyInput = () => this.setState({ companySearchQuery: '' });

  clearSelectedLocation = () =>
    this.setState({
      selectedLocation: null,
      locationSearchQuery: '',
      noOptionsMessage: LOCATION_QUERY_TOO_SHORT_MESSAGE
    });

  render() {
    const {
      noOptionsMessage,
      companySearchQuery,
      locationSearchQuery,
      selectedLocation,
      isCompanyInputFocused,
      isLocationInputFocused
    } = this.state;

    return (
      <SearchForm
        onSubmit={this.handleSearchSubmit}
        isCompanyInputFocused={isCompanyInputFocused}
        isLocationInputFocused={isLocationInputFocused}
      >
            <CompanyInput
              value={companySearchQuery}
              onChange={this.handleCompanyInputChange}
              onFocus={this.handleCompanyInputFocus}
              onBlur={this.handleCompanyInputBlur}
            />
            <CancelIcon
              isHidden={companySearchQuery}
              onClick={this.clearCompanyInput}
            />
            <LocationSelect
              value={selectedLocation}
              getOptions={debounce(this.getOptions, 250)}
              noOptionsMessage={noOptionsMessage}
              onChange={this.handleSelectedLocation}
              onFocus={this.handleLocationInputFocus}
              onBlur={this.handleLocationInputBlur}
            />
            <CancelIcon
              isHidden={selectedLocation === null && locationSearchQuery.length === 0}
              onClick={this.clearSelectedLocation}
            />
            <SubmitButton>SEARCH</SubmitButton>
      </SearchForm>
    );
  }
}
