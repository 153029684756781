import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import BaseInput from '../forms/common/baseinput.jsx';
import {getRequest} from '../utils/fetch.jsx';
import ReactModal from 'react-modal';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import RequestPublicAddition from './request_public_addition.jsx';

class Search extends BaseInput {
    constructor(props) {
        super(props);
        this.charAt = 1;
        this.state = Object.assign({
            placeholder: props.placeholder,
            url: props['data-url'],
            requestPublicAdditionUrl: props['request-public-addition-url'],
            options: JSON.parse(props['search-by'] || '[]'),
            openOptions: false,
            isLoading: false,
            results: [],
            count: 0,
            hasError: false,
            hasFocus: false,
            showModal: false
        }, this.state);

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }
    handleOpenModal () {
      this.setState({ showModal: true });
    }

    handleCloseModal () {
      this.setState({ showModal: false });
    }
    componentWillMount() {
        document.addEventListener('mousedown', this.handleClickOutside.bind(this), false);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside.bind(this), false);
    }
    closeMenu() {
        this.setState({count: 0, hasFocus: false, openOptions: false});
    }
    handleClickOutside(evt) {
        if ( (this.node.contains(evt.target)) || (this.state.showModal) ) {
            return;
        }
        this.closeMenu();
    }
    onSetFocus(evt) {
        this.handleFocus(evt);
        if (this.isValidSituation()) {
            this.setState({hasFocus: true});
        }
    }
    onMouseOver(count) {
        this.setState({count: count});
    }
    redirectTo(href) {
        window.location = href;
    }
    toggleMenu() {
        this.setState({
            openOptions: !this.state.openOptions
        });
    }
    isValidSituation() {
        return this.state.value.trim().length >= this.charAt;
    }
    handleSearch(evt) {
        this.handleChange(evt, this.initSearch);
    }
    initSearch() {
        clearTimeout(this.state.currentTimeout);
        const currentTimeout =  setTimeout(() => {
            if (this.isValidSituation()) {
              this.setState({ results: [], isLoading: true, hasFocus: true, hasError: false }, () => {
                  this.getResults();
              });
            } else {
                this.setState({results: [], isLoading: false, hasFocus: false, hasError: false});
            }
        }, 200);
        this.setState({
            currentTimeout: currentTimeout
        });
    }
    searchBy(option) {
        const opts = this.state.options;
        opts.forEach(opt => {
            opt.isSelected = (opt.key === option.key);
        });
        this.setState({
            options: opts
        }, () => {
            this.initSearch();
        });
    }
    getResults() {
        const url = `${this.state.url}?search=${encodeURIComponent(this.state.value)}&by=${this.state.options.find(option => option.isSelected).key}`;
        this.setState({
            currentSearchUrl: url
        });
        getRequest(url, 0, true).then(data => {
            if(this.state.currentSearchUrl === data.url) {
                this.setState({
                    results: data.results,
                    isLoading: false,
                    hasError: false,
                    count: 0
                });
            }
        }).catch(err => {
            this.setState({isLoading: false, hasError: false});
        });
    }
    highlight(str, key) {
        if(this.state.options.find(option => option.isSelected).key === key){
            return (
                <span dangerouslySetInnerHTML={{
                    __html: str.replace(new RegExp(this.state.value.trim(), 'gi'), '<strong class="search__highlighted">$&</strong>')
                }}></span>
            );
        } else {
            return(
              <span>{str}</span>
            );
        }

    }
    arrowsPressed(evt) {
        this.handleKeyUp(evt);
        if (!this.state.isLoading && this.state.results.length > 0) {
            if (evt.keyCode === 40 || evt.keyCode === 38 || evt.keyCode === 13) {
                evt.preventDefault();
                if (evt.keyCode === 40) {
                    if (this.state.count < this.state.results.length - 1) {
                        this.setState({
                            count: this.state.count += 1
                        });
                    }
                } else if (evt.keyCode === 38) {
                    if (this.state.count > 0) {
                        this.setState({
                            count: this.state.count -= 1
                        });
                    }
                } else if (evt.keyCode === 13) {
                    this.redirectTo(this.state.results[this.state.count].href);
                    this.closeMenu();
                }
            }
        }
    }
    render() {
        return (
            <div className="search" ref={node => this.node = node}>
                <div className="search__input">
                    { this.state.options.length > 1 &&
                        <div className={ this.state.openOptions ? 'rbtn__group rbtn__group--onclick rbtn__group--onclicked' : 'rbtn__group rbtn__group--onclick'} onClick={this.toggleMenu.bind(this)} >
                            <button type="button" className="rbtn">{LOCALIZED_STRS.get().search.by} {this.state.options.find( option => option.isSelected).display}</button>
                            <ul>
                                {this.state.options.map((option, index) => {
                                  return (
                                      <li key={index}>
                                          <a href="#" onClick={this.searchBy.bind(this, option)}>{option.display}</a>
                                      </li>
                                  );
                                })}
                            </ul>
                        </div>
                    }
                    <div className="input__group">
                        <input placeholder={this.props.placeholder} { ...this.state.attributes } { ...this.state.validations } value={this.state.value} onChange={this.handleSearch.bind(this)} onKeyUp={this.arrowsPressed.bind(this)} onBlur={this.handleBlur.bind(this)} onFocus={this.onSetFocus.bind(this)}/>
                    </div>
                </div>
                { (this.state.isLoading && this.state.hasFocus) &&
                    <div className="search__results animated animated--fadeIn">
                        <div className="search__status">
                            {LOCALIZED_STRS.get().search.loading}
                        </div>
                    </div>
                }
                { (!this.state.isLoading && this.state.hasFocus) &&
                  <div className="search__results animated animated--fadeIn">
                      <div className="search__status">
                          { this.state.hasError &&
                              <React.Fragment>
                                  {LOCALIZED_STRS.get().search.failed}
                              </React.Fragment>
                          }
                          { !this.state.hasError &&
                              <React.Fragment>
                                  <div className="search__total">
                                      { this.state.results.length === 0 &&
                                          <React.Fragment>
                                              {LOCALIZED_STRS.get().search.empty}
                                          </React.Fragment>
                                      }
                                      { this.state.results.length > 0 &&
                                          <React.Fragment>
                                              <strong>{ this.state.results.length === 100 ? '100+' : this.state.results.length}</strong> {LOCALIZED_STRS.get().found}
                                          </React.Fragment>
                                      }
                                  </div>
                              </React.Fragment>
                          }
                      </div>
                      <ul>
                          {this.state.results.map((result, index) => {
                            return (
                                <li key={index} onClick={this.closeMenu.bind(this)} onMouseOver={this.onMouseOver.bind(this, index)} className={ this.state.count === index ? 'li--active' : ''}>
                                    <a href={`${result.href}`} target="_self">
                                        <div className="search__company">{this.highlight(result.longName, 'longName')}</div>
                                        <div className="search__details">
                                            {LOCALIZED_STRS.get().ticker}: {this.highlight(result.ticker, 'ticker')} {LOCALIZED_STRS.get().exchange}: <strong>{result.exchange}</strong>
                                        </div>
                                    </a>
                                </li>
                            );
                          })}
                          <li className="search__request">
                            <button className="request" onClick={this.handleOpenModal}>
                                <span className="search__company">
                                  {LOCALIZED_STRS.get().search.question}
                                </span>
                                <span className="search__details">
                                  {LOCALIZED_STRS.get().search.action}
                                </span>
                            </button>
                          </li>
                      </ul>
                      <ReactModal
                        isOpen={this.state.showModal}
                        contentLabel="onRequestClose Example"
                        onRequestClose={this.handleCloseModal}
                        className="Modal"
                        overlayClassName="Overlay"
                      >
                          <div className="ReactModal__Header">
                            <h2>{LOCALIZED_STRS.get().search.action}</h2>
                            <button
                              onClick={this.handleCloseModal}
                              className="rbtn rbtn--transparent"
                            >
                                <i className="icon icon--close close-menu" aria-hidden="true"></i>
                            </button>
                          </div>
                          <RequestPublicAddition url={this.state.requestPublicAdditionUrl} handler={this.handleCloseModal}/>
                      </ReactModal>
                  </div>
                }
            </div>
        );
    }
}

// necessary for screenreaders to focus on modal
ReactModal.setAppElement('body');

export default Search;
