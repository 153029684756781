import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled, {css} from 'styled-components';
import moment from 'moment';
import { isEqual } from 'lodash';
import { PlusIcon, TickIcon, ExclamationIcon  } from './icons.jsx';
import Carousel from 'nuka-carousel';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import {csrftoken} from '../utils/csrf.jsx';
import 'formdata-polyfill';
import ReactModal from 'react-modal';
import Checkbox from '../forms/common/checkbox.jsx';
import { toast, ToastContainer, cssTransition } from 'react-toastify';

const StyledToastContainer = styled(ToastContainer)`
    width: 95vw;
    padding: 0;
    top: 88px;
    left: 0;
    right: 0;
    position: fixed;
    margin: 0 auto;

    .Toastify__toast {
        padding: 24px;

        &-body {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-top: -4px;
            }

            span {
                padding-left: 12px;
            }
        }

        &.toast {
            &--success {
                background-color: #D8EED6;
                color: #41A939;
                .icon {
                    color: #4A4A4A;
                    margin: auto 0;
                    padding-bottom: 6px;
                }
            }

            &--failed {
                background-color: #FDCECE;
                color: #ED4C4A;
                .icon {
                    color: #4A4A4A;
                    margin: auto 0;
                    padding-bottom: 6px;
                }
            }
        }
    }
`;

const SlideWrapper = styled.div`
    background-color: #fff;
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 12px;
    max-height: 126px;
    border: 1px solid  rgba(199, 210, 219, 0.7);
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(199,210,219,.4);
    width: calc(100% - 17px);
    transition: box-shadow 0.2s ease-in;

    &:hover {
        box-shadow: 0 1px 10px 3px rgba(199,210,219,.4);
        transition: box-shadow 0.2s ease-out;
    }

    @media screen and (min-width: 75em) {
        padding: 32px;
        min-height: 142px;
    }
`;

const IconWrapper = styled.div`
    float: right;
    padding: 4px;
    margin: -4px;
`;

const DateContainer = styled.div`
    position: absolute;
    transform: translate(-24px, -55px);

    @media screen and (min-width: 75em) {
        transform: translate(-32px, -55px);
    }
`;

const Controls = styled.div`
    text-align: right;
`;

const StyledButtonBack = styled.button`
    border: none;
    font-size: 12px;
    padding: 0 8px;
    background-color: transparent;
    color: ${props => props.disabled
      ? css`rgba(0, 0, 0, 0.247) !important`
      : css`rgba(0, 0, 0, 0.800) !important`};

    &:after {
        font-family: "RapidRatings";
        content: "\\e932";
        color: "#4a4a4a";
    }
    &:hover {
        cursor: pointer;
    }
`;

const StyledButtonNext = styled.button`
    border: none;
    font-size: 12px;
    padding: 0 8px;
    background-color: transparent;
    color: ${props => props.disabled
      ? css`rgba(0, 0, 0, 0.247) !important`
      : css`rgba(0, 0, 0, 0.800) !important`};

    &:after {
        font-family: "RapidRatings";
        content: "\\e933";
        color: "#4a4a4a";
    }
    &:hover {
        cursor: pointer;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const ToolTipText = styled.span`
    font-size: 16px;
    line-height: 24px;
`;

const RiskDescription = styled.div`
    display: inline-block;
    position: relative;
    font-size: 14px;
    margin-bottom: 8px;
    &:before {
        content: "";
        color: #3b4043;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: block;
        margin-top: 4px;
        margin-right: 8px;
        float: left;
    }

    &.very_low_risk {
        &:before {
            background-color: #6FBFEA;
        }
    }
    &.low_risk {
        &:before {
            background-color: #46A840;
        }
    }
    &.medium_risk {
        &:before {
            background-color: #F7E856;
        }
    }
    &.high_risk {
        &:before {
            background-color: #F5B353;
        }
    }
    &.very_high_risk {
        &:before {
            background-color: #E84C4B;
        }
    }

    &.text{
        margin-left: 32px;
    }
`;

const CarouselContainer = styled.div`
    position: relative;

    .slider-frame {
        &:before {
            content: '';
            background: #f4f5f9;
            width: 100vw;
            height: 100%;
            display: block;
            position: absolute;
            right: 100%;
            top: -5px;
            z-index: 2;
        }
        &:after {
            content: '';
            background: #f4f5f9;
            width: 100vw;
            height: 100%;
            display: block;
            position: absolute;
            left: calc(100% + 52px);
            top: -5px;
        }
    }

    .slider {
        padding-right: 52px !important;
        overflow-x: hidden;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .slider {
          overflow-y: hidden;
      }
    }

    .slider-frame {
        margin-bottom: 32px !important;
        overflow: visible !important;
    }

    .slider-slide {
        transform: none !important;
        margin-bottom: 12px !important;
    }

    .slider-list {
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .slider .slider-slide .__react_component_tooltip {
            position: absolute;
            top: -10px !important;
            left: 0 !important;
            right: 0;
        }
        .slider .slider-slide:last-of-type .__react_component_tooltip {
            position: absolute;
            top: -8px !important;
        }
    }

    .paging-dot {
        width: 9px !important;
        height: 9px !important;
    }
`;

const StyledReactModal = styled(ReactModal)`
    max-width: 500px;
`;

const ModalContent = styled.div`
    margin: 16px auto;
`;

const ModalFooter = styled.div`
    text-align: right;
    :before {
        content: "";
        border: 1px solid #f4f6f9;
        display: block;
        margin: 0 -34px 24px;
    }
`;

const Label = styled.label`
    font-size: 16px !important;
`;

class HealthmarkRecents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: JSON.parse(props.data || '[]'),
            dataUrl: props.data_url,
            slideIndex: 0,
            slidesToShow: 4,
            modalIsOpen: false,
            selectedItemId: '',
            selectedItem: [],
            originalSelectedItem: []
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleBoomkmarkSubmit = this.handleBoomkmarkSubmit.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    componentDidMount() {
        // fetch the data
        fetch(this.state.dataUrl, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': csrftoken()
            },
            credentials: 'same-origin'
        })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            data.length > 0 &&
            this.setState({data: data});
        });
        this.accessibleLinks();
    }

    componentDidUpdate() {
        this.accessibleLinks();
    }

    fetchBookmarkedData(url){
      fetch(url, {
          headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': csrftoken()
          },
          credentials: 'same-origin'
      })
      .then((res) => {
          return res.json();
      })
      .then((data) => {
          this.setState({
              selectedItem: data,
              originalSelectedItem: data
          });
      });
    }

    accessibleLinks() {
        const slideElements = document.querySelectorAll('li > div.slide');
        // loop over each focusable slide
        Array.prototype.forEach.call(slideElements, (item) => {
          // if the slide isn't focusable and has child elements
          if (item.tabIndex === -1 && item.hasChildNodes) {
            // if the child elements are focusable
            const childElements = item.querySelectorAll(
              'button, [href], input, select, textarea, [tabindex]'
            );
            // loop over focusable children and set tabindex to -1
            for (const childItem of childElements) {
              childItem.setAttribute('tabindex', '-1');
            }
          } else if (item.tabIndex === 0 && item.hasChildNodes) {
            // if the child elements are focusable
            const childElements = item.querySelectorAll(
              'button, [href], input, select, textarea, [tabindex]'
            );
            // loop over focusable children and set tabindex to 0
            for (const childItem of childElements) {
              childItem.setAttribute('tabindex', '0');
            }
          }
        });
    }

    handleKeyPress(event, item){
        if (event.key == 'Enter') {
            location.assign('/health-mark/profile/' + item.target_id);
        }
    }

    handleKeyPressBookmark(event, item){
        if (event.key == 'Enter') {
            this.handleBoomkmarkSubmit(item);
        }
    }

    handleBoomkmarkSubmit() {
        const formData = new FormData();
        const csrftoken_value = csrftoken();
        formData.append('ids', JSON.stringify(this.state.selectedItemId));
        formData.append(
            'action', JSON.stringify(this.state.selectedItem)
        );
        formData.append('csrfmiddlewaretoken', csrftoken_value);
        fetch(window.location.href, {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': csrftoken_value
            },
            credentials: 'same-origin',
            body: formData
        })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            const status = data.bookmarked_status;
            this.setState({
              status: status,
              modalIsOpen: false
            }, () => {
              this.props.parentCallback('update');
              this.state.status !== '' &&
              toast(this.state.status === 'success'
                      ? LOCALIZED_STRS.get().listsUpdateSuccess
                      : LOCALIZED_STRS.get().listsUpdateFailed, {
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  className: `toast--${this.state.status}`
              });
            });
        });
    }

    handleCheckbox(e) {
        const editListName = (list, oldListName, name) => {
            // map over list
            return list.map(item => {
                const temp = Object.assign({}, item);
                // if there is a matching value
                if (temp.list == oldListName) {
                    // update the value
                    temp.bookmarked = (name === true ? true : false);
                }
                return temp;
            });
        };

        const updatedList = editListName(this.state.selectedItem, e.target.name, e.target.checked);

        this.setState({
            selectedItem: updatedList
        });
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    render() {
        const CloseButton = ({ closeToast }) => (
            <i
                className="icon icon--close close-menu"
                aria-hidden="true"
                onClick={closeToast}
            ></i>
        );

        const Message = () => (
            <>
                {
                this.state.status === 'success'
                ? <TickIcon />  : <ExclamationIcon />
                }
                <span>{this.state.statusMessage}</span>
            </>
        );

        const customTransition = cssTransition({
            enter: 'bounceInDown',
            exit: 'bounceOutUp',
            duration: 1500,
            appendPosition: false
        });

        const slides = this.state.data.map((item, index) => {
            return (
                <SlideWrapper
                    className="slide"
                    key={index}
                    onClick={() => {
                        location.assign(
                            '/health-mark/profile/' + item.target_id
                        );
                    }}
                    onKeyPress={() => {
                        this.handleKeyPress(event, item);
                    }}
                >
                  <ReactTooltip
                      id={'healthmarkHelper__' + index}
                      effect="solid"
                      place={index === (this.state.data.length - 1)
                          ? 'left'
                          : 'top'
                      }
                  >
                      <ToolTipText>
                          {LOCALIZED_STRS.get().assignList}
                      </ToolTipText>
                  </ReactTooltip>
                  <IconWrapper
                      tabIndex="0"
                      data-tip
                      data-event="mouseenter focus"
                      data-event-off="mouseleave blur"
                      data-for={'healthmarkHelper__' + index}
                      onClick={(event) => {
                        // prevent bubbling up to <Slide>'s onClick
                        event.stopPropagation();
                        window.sendToSpaFromUiLabs(item._id);
                      }}
                  >
                      <PlusIcon />
                  </IconWrapper>
                  <DateContainer
                    className="caption--secondary"
                    title={
                        'Visited on ' +
                        moment(item.date, 'YYYY-MM-DD HH:mm:ss')
                        .format('MMMM Do, YYYY')
                        .toString()
                    }
                  >
                      {
                          moment(item.date, 'YYYY-MM-DD HH:mm:ss')
                          .format('MMMM Do, YYYY')
                          .toString()
                      }
                  </DateContainer>
                  <RiskDescription
                      className={ item.risk_mark_type &&
                          'caption--secondary '
                          + item.risk_mark_type
                            .replace(/\s+/g, '_')
                            .toLowerCase()
                      }
                  >
                      {item.risk_mark_type}
                  </RiskDescription>
                  <h3 className="bold text__ellipses">
                      {item.name}
                  </h3>
                  <p
                      className="text--lightGrey text__ellipses"
                      title={item.address}
                  >
                      {item.address}
                  </p>
                </SlideWrapper>
            );
        });

        const { slideIndex, slidesToShow } = this.state;
        return (
          this.state.data.length > 0 &&
            <>
                <HeaderContainer>
                    <h3 className="bold">{this.props.title}</h3>
                    <Controls>
                        <StyledButtonBack
                          disabled={this.state.slideIndex === 0}
                          title="Back"
                          onClick={() =>
                            this.setState({
                                slideIndex: this.state.slideIndex - 1
                            })
                          }
                        ></StyledButtonBack>
                        <StyledButtonNext
                          disabled={this.state.slideIndex === (
                              this.state.data.length - this.state.slidesToShow
                          )}
                          title="Forward"
                          onClick={() =>
                            this.setState({
                                slideIndex: this.state.slideIndex + 1
                            })
                          }
                        ></StyledButtonNext>
                    </Controls>
                </HeaderContainer>
                <CarouselContainer>
                    <Carousel
                      dragging={true}
                      swiping={true}
                      disableEdgeSwiping={true}
                      slidesToShow={this.state.slidesToShow}
                      renderCenterLeftControls={() => (
                        null
                      )}
                      renderCenterRightControls={() => (
                        null
                      )}
                      slideIndex={this.state.slideIndex}
                      afterSlide={slideIndex => this.setState({ slideIndex })}
                    >
                      {slides.map((slide, index) => {
                        let needsTabIndex;
                        if (slidesToShow > 1) {
                          // create a range from first to last visible slide
                          const firstVisibleSlide = (
                              index >= slideIndex
                          );
                          const lastVisibleSlide = (
                              index < slidesToShow + slideIndex
                          );
                          needsTabIndex = firstVisibleSlide && lastVisibleSlide;
                          /* if the index of the slide is in range
                           * add 'tabindex : 0' the slide
                          */
                          const tabIndex = needsTabIndex
                            ? { tabIndex: 0 }
                            : { tabIndex: -1 };
                          // clone element to add tabIndex
                          return React.cloneElement(slide, {
                            ...slide.props,
                            ...tabIndex
                          });
                        } else {
                          // when slidesToshow is 1
                          needsTabIndex = index !== slideIndex;
                          const tabIndex = needsTabIndex
                          ? {}
                          : { tabIndex: 0 };
                          return React.cloneElement(slide, {
                            ...slide.props,
                            ...tabIndex
                          });
                        }
                      })}
                    </Carousel>
                </CarouselContainer>
                <StyledToastContainer
                    status={this.state.status}
                    position="top-center"
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    transition={customTransition}
                    pauseOnVisibilityChange
                    closeButton={<CloseButton />}
                />
            </>
        );
    }
}


export default HealthmarkRecents;
