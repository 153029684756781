import React from 'react';

export function OrgListIcon() {
    return (
        <svg width="199px" height="199px" viewBox="0 0 199 199" version="1.1">
            <g id="UI-Handover" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon-82x82-Org-List-Copy-2">
                    <circle id="Oval" fill="#F6F7FA" cx="99.5884774" cy="99.4115226" r="98.7654321"></circle>
                    <g id="Group-6" opacity="0.399251302" transform="translate(54.320988, 45.913580)">
                        <g id="list">
                            <path d="M85.7892146,104.334478 L6.10595705,104.334478 C3.73404648,104.334478 1.79339237,102.393824 1.79339237,100.021913 L1.79339237,5.77254751 C1.79339237,3.39991818 3.73404648,1.45998283 6.10595705,1.45998283 L85.7892146,1.45998283 C88.1611251,1.45998283 90.1017793,3.39991818 90.1017793,5.77254751 L90.1017793,100.021913 C90.1017793,102.393824 88.1611251,104.334478 85.7892146,104.334478 Z" id="background" fill="#FFFFFF"></path>
                            <polygon id="Path" fill="#DAE3E8" opacity="0.603097098" transform="translate(45.760779, 75.659160) scale(-1, 1) translate(-45.760779, -75.659160) " points="2.81435939 64.8975291 88.7071978 48.4244656 88.7071978 102.893854 3.10549452 102.68298"></polygon>
                            <path d="M85.7892146,8.52651283e-14 C88.9986728,8.52651283e-14 91.6179153,2.61894139 91.6179153,5.8287007 L91.6172564,81.018 L91.9019751,81.0185185 C92.6024939,81.0185185 93.1781786,81.6381936 93.2434823,82.4305393 L93.2496516,82.5810185 C93.2496516,83.4439634 92.6462763,84.1435185 91.9019751,84.1435185 L91.9019751,84.1435185 L91.6172564,84.143 L91.6179153,100.078067 C91.6179153,103.287316 88.998464,105.906767 85.7892146,105.906767 L6.10595705,105.906767 C2.89670768,105.906767 0.277256355,103.287316 0.277256355,100.078067 L0.277256355,5.8287007 C0.277256355,2.61894139 2.89649881,8.52651283e-14 6.10595705,8.52651283e-14 L85.7892146,8.52651283e-14 Z M3.30952879,83.9823935 L3.30952839,100.078067 C3.30952839,101.612638 4.57138528,102.874495 6.10595705,102.874495 L85.7892146,102.874495 C87.3237863,102.874495 88.5856432,101.612638 88.5856432,100.078067 L88.5852564,84.143 L3.90624921,84.1435185 C3.69198656,84.1435185 3.4894024,84.0855466 3.30952879,83.9823935 Z M88.5852564,62.538 L3.30925636,62.538 L3.30952879,81.1796436 C3.45264703,81.0975687 3.61014276,81.044097 3.77645897,81.0256712 L3.90624921,81.0185185 L88.5852564,81.018 L88.5852564,62.538 Z M88.5852564,40.933 L3.30925636,40.933 L3.30925636,59.413 L88.5852564,59.413 L88.5852564,40.933 Z M88.5852564,17.528 L3.30925636,17.528 L3.30925636,37.808 L88.5852564,37.808 L88.5852564,17.528 Z M85.7892146,3.03227204 L6.10595705,3.03227204 C4.57109839,3.03227204 3.30952839,4.29369703 3.30952839,5.8287007 L3.30925636,14.403 L88.5852564,14.403 L88.5856432,5.8287007 C88.5856432,4.29369703 87.3240732,3.03227204 85.7892146,3.03227204 Z" id="Combined-Shape" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M41.9990735,30.1311728 L78.1990671,30.1311728 C78.9433682,30.1311728 79.5467435,29.4316178 79.5467435,28.5686728 C79.5467435,27.7057279 78.9433682,27.0061728 78.1990671,27.0061728 L41.9990735,27.0061728 C41.2547723,27.0061728 40.651397,27.7057279 40.651397,28.5686728 C40.651397,29.4316178 41.2547723,30.1311728 41.9990735,30.1311728 Z M11.6763531,30.1311728 L19.8408508,30.1311728 C20.585152,30.1311728 21.1885273,29.4316178 21.1885273,28.5686728 C21.1885273,27.7057279 20.585152,27.0061728 19.8408508,27.0061728 L11.6763531,27.0061728 C10.9320519,27.0061728 10.3286766,27.7057279 10.3286766,28.5686728 C10.3286766,29.4316178 10.9320519,30.1311728 11.6763531,30.1311728 Z M26.8377133,30.1311728 L35.002211,30.1311728 C35.7465122,30.1311728 36.3498875,29.4316178 36.3498875,28.5686728 C36.3498875,27.7057279 35.7465122,27.0061728 35.002211,27.0061728 L26.8377133,27.0061728 C26.0934121,27.0061728 25.4900368,27.7057279 25.4900368,28.5686728 C25.4900368,29.4316178 26.0934121,30.1311728 26.8377133,30.1311728 Z" id="Stroke-7-Copy-2" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M41.5969779,51.7361111 L66.9962645,51.7361111 C67.5184944,51.7361111 67.9418454,51.036556 67.9418454,50.1736111 C67.9418454,49.3106662 67.5184944,48.6111111 66.9962645,48.6111111 L41.5969779,48.6111111 C41.074748,48.6111111 40.651397,49.3106662 40.651397,50.1736111 C40.651397,51.036556 41.074748,51.7361111 41.5969779,51.7361111 Z M11.6763531,51.7361111 L19.8408508,51.7361111 C20.585152,51.7361111 21.1885273,51.036556 21.1885273,50.1736111 C21.1885273,49.3106662 20.585152,48.6111111 19.8408508,48.6111111 L11.6763531,48.6111111 C10.9320519,48.6111111 10.3286766,49.3106662 10.3286766,50.1736111 C10.3286766,51.036556 10.9320519,51.7361111 11.6763531,51.7361111 Z M26.8377133,51.7361111 L35.002211,51.7361111 C35.7465122,51.7361111 36.3498875,51.036556 36.3498875,50.1736111 C36.3498875,49.3106662 35.7465122,48.6111111 35.002211,48.6111111 L26.8377133,48.6111111 C26.0934121,48.6111111 25.4900368,49.3106662 25.4900368,50.1736111 C25.4900368,51.036556 26.0934121,51.7361111 26.8377133,51.7361111 Z" id="Stroke-7-Copy-3" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M41.9990735,73.3410494 L78.1990671,73.3410494 C78.9433682,73.3410494 79.5467435,72.6414943 79.5467435,71.7785494 C79.5467435,70.9156045 78.9433682,70.2160494 78.1990671,70.2160494 L41.9990735,70.2160494 C41.2547723,70.2160494 40.651397,70.9156045 40.651397,71.7785494 C40.651397,72.6414943 41.2547723,73.3410494 41.9990735,73.3410494 Z M11.6763531,73.3410494 L19.8408508,73.3410494 C20.585152,73.3410494 21.1885273,72.6414943 21.1885273,71.7785494 C21.1885273,70.9156045 20.585152,70.2160494 19.8408508,70.2160494 L11.6763531,70.2160494 C10.9320519,70.2160494 10.3286766,70.9156045 10.3286766,71.7785494 C10.3286766,72.6414943 10.9320519,73.3410494 11.6763531,73.3410494 Z M26.8377133,73.3410494 L35.002211,73.3410494 C35.7465122,73.3410494 36.3498875,72.6414943 36.3498875,71.7785494 C36.3498875,70.9156045 35.7465122,70.2160494 35.002211,70.2160494 L26.8377133,70.2160494 C26.0934121,70.2160494 25.4900368,70.9156045 25.4900368,71.7785494 C25.4900368,72.6414943 26.0934121,73.3410494 26.8377133,73.3410494 Z" id="Stroke-7-Copy-4" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M41.8071069,94.9459877 L72.8506795,94.9459877 C73.4889605,94.9459877 74.0063894,94.2464326 74.0063894,93.3834877 C74.0063894,92.5205427 73.4889605,91.8209877 72.8506795,91.8209877 L41.8071069,91.8209877 C41.168826,91.8209877 40.651397,92.5205427 40.651397,93.3834877 C40.651397,94.2464326 41.168826,94.9459877 41.8071069,94.9459877 Z M11.6763531,94.9459877 L19.8408508,94.9459877 C20.585152,94.9459877 21.1885273,94.2464326 21.1885273,93.3834877 C21.1885273,92.5205427 20.585152,91.8209877 19.8408508,91.8209877 L11.6763531,91.8209877 C10.9320519,91.8209877 10.3286766,92.5205427 10.3286766,93.3834877 C10.3286766,94.2464326 10.9320519,94.9459877 11.6763531,94.9459877 Z M26.8377133,94.9459877 L35.002211,94.9459877 C35.7465122,94.9459877 36.3498875,94.2464326 36.3498875,93.3834877 C36.3498875,92.5205427 35.7465122,91.8209877 35.002211,91.8209877 L26.8377133,91.8209877 C26.0934121,91.8209877 25.4900368,92.5205427 25.4900368,93.3834877 C25.4900368,94.2464326 26.0934121,94.9459877 26.8377133,94.9459877 Z" id="Stroke-7-Copy-5" fill="#98A4AE" fillRule="nonzero"></path>
                        </g>
                        <g id="circle" transform="translate(41.409465, 46.810700)">
                            <circle id="Oval-Copy-20" fill="#FFFFFF" cx="34.2078189" cy="34.2078189" r="30.6069959"></circle>
                            <polygon id="Rectangle" fill="#E8EEF1" points="26.1445473 43.3515258 41.7695473 46.2757202 41.7695473 49.4007202 26.1445473 49.4007202"></polygon>
                            <path d="M48.0195473,15.6507202 C49.7454372,15.6507202 51.1445473,17.0498303 51.1445473,18.7757202 L51.1445473,46.9007202 C51.1445473,48.62661 49.7454372,50.0257202 48.0195473,50.0257202 L19.8945473,50.0257202 C18.1686575,50.0257202 16.7695473,48.62661 16.7695473,46.9007202 L16.7695473,18.7757202 C16.7695473,17.0498303 18.1686575,15.6507202 19.8945473,15.6507202 L48.0195473,15.6507202 Z M48.0195473,18.7757202 L19.8945473,18.7757202 L19.8945473,46.9007202 L48.0195473,46.9007202 L48.0195473,18.7757202 Z" id="Rectangle" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M15.2070473,50.0257202 L52.7070473,50.0257202 C53.5699922,50.0257202 54.2695473,49.3261651 54.2695473,48.4632202 C54.2695473,47.6002752 53.5699922,46.9007202 52.7070473,46.9007202 L15.2070473,46.9007202 C14.3441024,46.9007202 13.6445473,47.6002752 13.6445473,48.4632202 C13.6445473,49.3261651 14.3441024,50.0257202 15.2070473,50.0257202 Z" id="Line-6" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M48.0195473,18.7757202 L48.0195473,46.9007202 L41.7695473,46.9007202 L41.7695473,39.2432495 L26.1445473,39.2432495 L26.1445473,46.9007202 L19.8945473,46.9007202 L19.8945473,18.7757202 L48.0195473,18.7757202 Z" id="Combined-Shape" fill="#FFEE00"></path>
                            <path d="M26.1445473,26.5882202 L41.7695473,26.5882202 C42.6324922,26.5882202 43.3320473,25.8886651 43.3320473,25.0257202 C43.3320473,24.1627752 42.6324922,23.4632202 41.7695473,23.4632202 L26.1445473,23.4632202 C25.2816024,23.4632202 24.5820473,24.1627752 24.5820473,25.0257202 C24.5820473,25.8886651 25.2816024,26.5882202 26.1445473,26.5882202 Z" id="Line-6" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M26.1445473,32.8382202 L41.7695473,32.8382202 C42.6324922,32.8382202 43.3320473,32.1386651 43.3320473,31.2757202 C43.3320473,30.4127752 42.6324922,29.7132202 41.7695473,29.7132202 L26.1445473,29.7132202 C25.2816024,29.7132202 24.5820473,30.4127752 24.5820473,31.2757202 C24.5820473,32.1386651 25.2816024,32.8382202 26.1445473,32.8382202 Z" id="Line-6-Copy" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M27.7070473,39.7983642 L40.2070473,39.7983642 L40.2070473,47.6108642 C40.2070473,48.4738091 40.9066024,49.1733642 41.7695473,49.1733642 C42.6324922,49.1733642 43.3320473,48.4738091 43.3320473,47.6108642 L43.3320473,38.2358642 C43.3320473,37.3729193 42.6324922,36.6733642 41.7695473,36.6733642 L26.1445473,36.6733642 C25.2816024,36.6733642 24.5820473,37.3729193 24.5820473,38.2358642 L24.5820473,47.6108642 C24.5820473,48.4738091 25.2816024,49.1733642 26.1445473,49.1733642 C27.0074922,49.1733642 27.7070473,48.4738091 27.7070473,47.6108642 L27.7070473,39.7983642 Z" id="Path-2" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M34.2078189,68.4156379 C53.1002756,68.4156379 68.4156379,53.1002756 68.4156379,34.2078189 C68.4156379,15.3153622 53.1002756,0 34.2078189,0 C15.3153622,0 0,15.3153622 0,34.2078189 C0,53.1002756 15.3153622,68.4156379 34.2078189,68.4156379 Z M34.2078189,64.8148148 C17.3040419,64.8148148 3.60082305,51.111596 3.60082305,34.2078189 C3.60082305,17.3040419 17.3040419,3.60082305 34.2078189,3.60082305 C51.111596,3.60082305 64.8148148,17.3040419 64.8148148,34.2078189 C64.8148148,51.111596 51.111596,64.8148148 34.2078189,64.8148148 Z" id="Oval-Copy-20" fill="#98A4AE" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
  }
  

export function PersonalListIcon() {
    return (
        <svg width="199px" height="199px" viewBox="0 0 199 199" version="1.1">
            <g id="UI-Handover" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon-Personal-List-Copy-2">
                    <circle id="Oval" fill="#F6F7FA" cx="99.5884774" cy="99.4115226" r="98.7654321"></circle>
                    <g id="Group-7" opacity="0.385695685" transform="translate(54.320988, 45.913580)">
                        <g id="list">
                            <path d="M85.7892146,104.334478 L6.10595705,104.334478 C3.73404648,104.334478 1.79339237,102.393824 1.79339237,100.021913 L1.79339237,5.77254751 C1.79339237,3.39991818 3.73404648,1.45998283 6.10595705,1.45998283 L85.7892146,1.45998283 C88.1611251,1.45998283 90.1017793,3.39991818 90.1017793,5.77254751 L90.1017793,100.021913 C90.1017793,102.393824 88.1611251,104.334478 85.7892146,104.334478 Z" id="background" fill="#FFFFFF"></path>
                            <polygon id="Path" fill="#DAE3E8" opacity="0.603097098" transform="translate(45.760779, 75.659160) scale(-1, 1) translate(-45.760779, -75.659160) " points="2.81435939 64.8975291 88.7071978 48.4244656 88.7071978 102.893854 3.10549452 102.68298"></polygon>
                            <path d="M85.7892146,8.52651283e-14 C88.9986728,8.52651283e-14 91.6179153,2.61894139 91.6179153,5.8287007 L91.6172564,81.018 L91.9019751,81.0185185 C92.6024939,81.0185185 93.1781786,81.6381936 93.2434823,82.4305393 L93.2496516,82.5810185 C93.2496516,83.4439634 92.6462763,84.1435185 91.9019751,84.1435185 L91.9019751,84.1435185 L91.6172564,84.143 L91.6179153,100.078067 C91.6179153,103.287316 88.998464,105.906767 85.7892146,105.906767 L6.10595705,105.906767 C2.89670768,105.906767 0.277256355,103.287316 0.277256355,100.078067 L0.277256355,5.8287007 C0.277256355,2.61894139 2.89649881,8.52651283e-14 6.10595705,8.52651283e-14 L85.7892146,8.52651283e-14 Z M3.30952879,83.9823935 L3.30952839,100.078067 C3.30952839,101.612638 4.57138528,102.874495 6.10595705,102.874495 L85.7892146,102.874495 C87.3237863,102.874495 88.5856432,101.612638 88.5856432,100.078067 L88.5852564,84.143 L3.90624921,84.1435185 C3.69198656,84.1435185 3.4894024,84.0855466 3.30952879,83.9823935 Z M88.5852564,62.538 L3.30925636,62.538 L3.30952879,81.1796436 C3.45264703,81.0975687 3.61014276,81.044097 3.77645897,81.0256712 L3.90624921,81.0185185 L88.5852564,81.018 L88.5852564,62.538 Z M88.5852564,40.933 L3.30925636,40.933 L3.30925636,59.413 L88.5852564,59.413 L88.5852564,40.933 Z M88.5852564,17.528 L3.30925636,17.528 L3.30925636,37.808 L88.5852564,37.808 L88.5852564,17.528 Z M85.7892146,3.03227204 L6.10595705,3.03227204 C4.57109839,3.03227204 3.30952839,4.29369703 3.30952839,5.8287007 L3.30925636,14.403 L88.5852564,14.403 L88.5856432,5.8287007 C88.5856432,4.29369703 87.3240732,3.03227204 85.7892146,3.03227204 Z" id="Combined-Shape" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M41.9990735,30.1311728 L78.1990671,30.1311728 C78.9433682,30.1311728 79.5467435,29.4316178 79.5467435,28.5686728 C79.5467435,27.7057279 78.9433682,27.0061728 78.1990671,27.0061728 L41.9990735,27.0061728 C41.2547723,27.0061728 40.651397,27.7057279 40.651397,28.5686728 C40.651397,29.4316178 41.2547723,30.1311728 41.9990735,30.1311728 Z M11.6763531,30.1311728 L19.8408508,30.1311728 C20.585152,30.1311728 21.1885273,29.4316178 21.1885273,28.5686728 C21.1885273,27.7057279 20.585152,27.0061728 19.8408508,27.0061728 L11.6763531,27.0061728 C10.9320519,27.0061728 10.3286766,27.7057279 10.3286766,28.5686728 C10.3286766,29.4316178 10.9320519,30.1311728 11.6763531,30.1311728 Z M26.8377133,30.1311728 L35.002211,30.1311728 C35.7465122,30.1311728 36.3498875,29.4316178 36.3498875,28.5686728 C36.3498875,27.7057279 35.7465122,27.0061728 35.002211,27.0061728 L26.8377133,27.0061728 C26.0934121,27.0061728 25.4900368,27.7057279 25.4900368,28.5686728 C25.4900368,29.4316178 26.0934121,30.1311728 26.8377133,30.1311728 Z" id="Stroke-7-Copy-2" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M41.5969779,51.7361111 L66.9962645,51.7361111 C67.5184944,51.7361111 67.9418454,51.036556 67.9418454,50.1736111 C67.9418454,49.3106662 67.5184944,48.6111111 66.9962645,48.6111111 L41.5969779,48.6111111 C41.074748,48.6111111 40.651397,49.3106662 40.651397,50.1736111 C40.651397,51.036556 41.074748,51.7361111 41.5969779,51.7361111 Z M11.6763531,51.7361111 L19.8408508,51.7361111 C20.585152,51.7361111 21.1885273,51.036556 21.1885273,50.1736111 C21.1885273,49.3106662 20.585152,48.6111111 19.8408508,48.6111111 L11.6763531,48.6111111 C10.9320519,48.6111111 10.3286766,49.3106662 10.3286766,50.1736111 C10.3286766,51.036556 10.9320519,51.7361111 11.6763531,51.7361111 Z M26.8377133,51.7361111 L35.002211,51.7361111 C35.7465122,51.7361111 36.3498875,51.036556 36.3498875,50.1736111 C36.3498875,49.3106662 35.7465122,48.6111111 35.002211,48.6111111 L26.8377133,48.6111111 C26.0934121,48.6111111 25.4900368,49.3106662 25.4900368,50.1736111 C25.4900368,51.036556 26.0934121,51.7361111 26.8377133,51.7361111 Z" id="Stroke-7-Copy-3" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M41.9990735,73.3410494 L78.1990671,73.3410494 C78.9433682,73.3410494 79.5467435,72.6414943 79.5467435,71.7785494 C79.5467435,70.9156045 78.9433682,70.2160494 78.1990671,70.2160494 L41.9990735,70.2160494 C41.2547723,70.2160494 40.651397,70.9156045 40.651397,71.7785494 C40.651397,72.6414943 41.2547723,73.3410494 41.9990735,73.3410494 Z M11.6763531,73.3410494 L19.8408508,73.3410494 C20.585152,73.3410494 21.1885273,72.6414943 21.1885273,71.7785494 C21.1885273,70.9156045 20.585152,70.2160494 19.8408508,70.2160494 L11.6763531,70.2160494 C10.9320519,70.2160494 10.3286766,70.9156045 10.3286766,71.7785494 C10.3286766,72.6414943 10.9320519,73.3410494 11.6763531,73.3410494 Z M26.8377133,73.3410494 L35.002211,73.3410494 C35.7465122,73.3410494 36.3498875,72.6414943 36.3498875,71.7785494 C36.3498875,70.9156045 35.7465122,70.2160494 35.002211,70.2160494 L26.8377133,70.2160494 C26.0934121,70.2160494 25.4900368,70.9156045 25.4900368,71.7785494 C25.4900368,72.6414943 26.0934121,73.3410494 26.8377133,73.3410494 Z" id="Stroke-7-Copy-4" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M41.8071069,94.9459877 L72.8506795,94.9459877 C73.4889605,94.9459877 74.0063894,94.2464326 74.0063894,93.3834877 C74.0063894,92.5205427 73.4889605,91.8209877 72.8506795,91.8209877 L41.8071069,91.8209877 C41.168826,91.8209877 40.651397,92.5205427 40.651397,93.3834877 C40.651397,94.2464326 41.168826,94.9459877 41.8071069,94.9459877 Z M11.6763531,94.9459877 L19.8408508,94.9459877 C20.585152,94.9459877 21.1885273,94.2464326 21.1885273,93.3834877 C21.1885273,92.5205427 20.585152,91.8209877 19.8408508,91.8209877 L11.6763531,91.8209877 C10.9320519,91.8209877 10.3286766,92.5205427 10.3286766,93.3834877 C10.3286766,94.2464326 10.9320519,94.9459877 11.6763531,94.9459877 Z M26.8377133,94.9459877 L35.002211,94.9459877 C35.7465122,94.9459877 36.3498875,94.2464326 36.3498875,93.3834877 C36.3498875,92.5205427 35.7465122,91.8209877 35.002211,91.8209877 L26.8377133,91.8209877 C26.0934121,91.8209877 25.4900368,92.5205427 25.4900368,93.3834877 C25.4900368,94.2464326 26.0934121,94.9459877 26.8377133,94.9459877 Z" id="Stroke-7-Copy-5" fill="#98A4AE" fillRule="nonzero"></path>
                        </g>
                        <g id="circle" transform="translate(41.409465, 46.810700)">
                            <circle id="Oval-Copy-20" fill="#FFFFFF" cx="34.2078189" cy="34.2078189" r="30.6069959"></circle>
                            <path d="M34.2078189,68.4156379 C53.1002756,68.4156379 68.4156379,53.1002756 68.4156379,34.2078189 C68.4156379,15.3153622 53.1002756,0 34.2078189,0 C15.3153622,0 0,15.3153622 0,34.2078189 C0,53.1002756 15.3153622,68.4156379 34.2078189,68.4156379 Z M34.2078189,64.8148148 C17.3040419,64.8148148 3.60082305,51.111596 3.60082305,34.2078189 C3.60082305,17.3040419 17.3040419,3.60082305 34.2078189,3.60082305 C51.111596,3.60082305 64.8148148,17.3040419 64.8148148,34.2078189 C64.8148148,51.111596 51.111596,64.8148148 34.2078189,64.8148148 Z" id="Oval-Copy-20" fill="#98A4AE" fillRule="nonzero"></path>
                        </g>
                        <g id="person" transform="translate(55.054012, 60.898920)">
                            <path d="M20.3125,17.1875 C30.6678391,17.1875 39.0625,23.4834957 39.0625,31.25 C39.0625,31.7784574 39.0236338,32.3001066 38.9479283,32.8134273 L1.67707167,32.8134273 C1.60136619,32.3001066 1.5625,31.7784574 1.5625,31.25 C1.5625,23.4834957 9.95716094,17.1875 20.3125,17.1875 Z" id="Combined-Shape" fill="#FFEC00"></path>
                            <path d="M20.3125,15.625 C31.4490891,15.625 40.625,22.5069332 40.625,31.25 C40.625,31.852076 40.5809393,32.4503721 40.4935135,33.0427182 C40.3803803,33.8092424 39.7225868,34.3770764 38.9477588,34.3770764 L1.6772412,34.3770764 C0.902413167,34.3770764 0.244619737,33.8092424 0.13148651,33.0427182 C0.0440606717,32.4503721 0,31.852076 0,31.25 C0,22.5069332 9.17591094,15.625 20.3125,15.625 Z M37.5,31.25 C37.5,24.4600582 29.8865891,18.75 20.3125,18.75 C10.9971701,18.75 3.53796792,24.1555774 3.14157228,30.701922 L3.125,31.25 L37.5,31.25 Z" id="Combined-Shape" fill="#98A4AE" fillRule="nonzero"></path>
                            <path d="M23.4375,15.625 C25.5415015,15.625 27.5067465,15.0309134 29.1746395,14.0013358 C27.9057536,17.6745596 24.4171888,20.3125 20.3125,20.3125 C15.1348305,20.3125 10.9375,16.1151695 10.9375,10.9375 C10.9375,8.9915068 11.5304078,7.18399462 12.54543,5.6857568 C13.0493157,11.2586719 17.7335026,15.625 23.4375,15.625 Z" id="Combined-Shape" fill="#E8EEF1"></path>
                            <path d="M20.3125,0 C26.3531145,0 31.25,4.89688555 31.25,10.9375 C31.25,16.9781145 26.3531145,21.875 20.3125,21.875 C14.2718855,21.875 9.375,16.9781145 9.375,10.9375 C9.375,4.89688555 14.2718855,0 20.3125,0 Z M20.3125,3.125 C15.9977754,3.125 12.5,6.62277539 12.5,10.9375 C12.5,15.2522246 15.9977754,18.75 20.3125,18.75 C24.6272246,18.75 28.125,15.2522246 28.125,10.9375 C28.125,6.62277539 24.6272246,3.125 20.3125,3.125 Z" id="Oval" fill="#98A4AE" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}


export function Arrow() {
    return (
        <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
            <g id="UI-Handover" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="ArrowIcon">
                    <circle id="Oval" fill="#005E8C" cx="20" cy="20" r="20"></circle>
                    <path d="M21.6464466,9.74695167 C21.8417088,9.55168953 22.1582912,9.55168953 22.3535534,9.74695167 L22.3535534,9.74695167 L32.2530483,19.6464466 C32.4483105,19.8417088 32.4483105,20.1582912 32.2530483,20.3535534 L32.2530483,20.3535534 L22.3535534,30.2530483 C22.1582912,30.4483105 21.8417088,30.4483105 21.6464466,30.2530483 C21.4511845,30.0577862 21.4511845,29.7412037 21.6464466,29.5459415 L21.6464466,29.5459415 L30.692,20.4995051 L6.5,20.5 C6.25454011,20.5 6.05039163,20.3231248 6.00805567,20.0898756 L6,20 C6,19.7238576 6.22385763,19.5 6.5,19.5 L6.5,19.5 L30.692,19.4995051 L21.6464466,10.4540585 C21.4728803,10.2804921 21.4535951,10.0110677 21.5885912,9.81619956 Z" id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                </g>
            </g>
        </svg>
    );
}
