import React, {Component} from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import Select from 'react-select';
import moment from 'moment';
import Modal from 'react-modal';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import {csrftoken} from '../utils/csrf.jsx';
import 'formdata-polyfill';

const CheckboxTable = checkboxHOC(ReactTable);

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        padding: 5,
    }),
    menu:(provided, state) => ({
        ...provided,
        zIndex: 3
    }),
    singleValue: (provided, state) => ({
        ...provided,
        paddingTop: 2,
        paddingBottom: 2
    })
};

class CampaignSolicitationsTable extends Component {
    constructor(props) {
        super(props);
        const columns = JSON.parse(this.props.columns);
        Object.keys(columns).map((key) => {
            const column = columns[key];
            if (column.renderer !== undefined) {
                column.Cell = this.cellRenderers[column.renderer];
            }
        });
        this.state = {
            data: [],
            pages: 0,
            columns: columns,
            filtered: [],
            total: 0,
            filter_select_values: {},
            loading: undefined,
            sorted: [],
            searchPlaceholder: props.searchplaceholder,
            searchParam: props.searchparam,
            searchTerm: props.searchterm,
            page: -1,
            id: this.props.id,
            filters: JSON.parse(this.props.filters),
            assignees: JSON.parse(this.props.assignees),
            url: this.props.url,
            pageSize: this.props.pagesize,
            totalRequestsLabel: this.props.total_requests_label,
            totalFilteredRequestsLabel: this.props.total_filtered_requests_label,
            totalNoRequests: this.props.total_no_requests,
            selection: [],
            selectAll: false,
            selectedAssigneeOption: null,
            modalIsOpen: false,
            assignSolicitationsUrl: props.assign_solicitations_url,
            formSubmitted: false,
            formSuccess: false,
            dropdownActive: false,
            soliciationsUpdated: '',
            soliciationsFailed: '',
            soliciationsSkipped: ''
        };
        this.assigneeName = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        // apply debounce only once per component
        this.refreshDebounced = _.debounce(this.refreshDebounced, this.props.searchdebounce);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    static defaultProps = {
        pagesize: 25,
        filters: '[]',
        assignees: '[]',
        id: 'sortable-table',
        columns: '[]',
        selection: [],
        selectAll: false,
        searchplaceholder: 'Search...',
        searchparam: null,
        searchterm: '',
        searchdebounce: 500,
        searchmin: 2,
        total_requests_label: LOCALIZED_STRS.get().requests,
        total_filtered_requests_label: LOCALIZED_STRS.get().filteredRequests,
        total_no_requests: LOCALIZED_STRS.get().noRequests,
        isFetching: false,
        assigneeErrorMessage: false
    };

    openModal() {
        this.setState({modalIsOpen: true});
        this.toggleDropdown();
    }

    closeModal() {
        this.setState({modalIsOpen: false});
        this.resetForm();
    }

    toggleDropdown() {
      this.setState({
        dropdownActive: !this.state.dropdownActive
      });
    }

    handleClickOutside(evt) {
        if ( (this.node.contains(evt.target))) {
            return;
        }
        this.setState({
          dropdownActive: false
        });
    }

    handleChange = (selectedAssigneeOption) => {
        this.setState({ selectedAssigneeOption });
    };

    handleSubmit(event) {
        event.preventDefault();
        const self = this;
        // ensure an assignee has been selected
        if (this.state.selectedAssigneeOption == null) {
            this.setState({assigneeErrorMessage: true});
            return;
        }
        self.setState({isFetching: true});
        const formData = new FormData();
        const csrftoken_value = csrftoken();
        // add soliciations to FormData
        formData.append('solicitations', this.state.selection);
        // add assignee to FormData
        formData.append('assignee_id', this.state.selectedAssigneeOption.id);
        formData.append('assignee_name', this.state.selectedAssigneeOption.value);
        formData.append('csrfmiddlewaretoken', csrftoken_value);
        fetch(this.state.assignSolicitationsUrl, {
            method: 'POST',
            body: formData
        })
        .then((res) => {
            res.json().then((data) => {
                self.setState({
                    isFetching: false,
                    soliciationsUpdated: data.updated,
                    soliciationsSkipped: data.skipped,
                    soliciationsFailed: data.failed
                });
            });
            if (res.status === 200) {
                self.setState({
                    formSubmitted: true,
                    formSuccess: true
                });
                this.fetchData({page:-1});
            } else {
                self.setState({
                    formSubmitted: true,
                    formSuccess: false
                });
            }
        });
    }

    resetForm = () => {
        this.setState({
            formSubmitted: false,
            formSuccess: false,
            assigneeErrorMessage: false
        });

    };

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    toggleSelection = (key, shift, row) => {
      // start off with the existing state
      let selection = [...this.state.selection];
      const keyIndex = selection.indexOf(key);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        selection = [
          ...selection.slice(0, keyIndex),
          ...selection.slice(keyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        selection.push(key);
      }
      // update the state
      this.setState({ selection });
    };

    toggleAll = () => {
      /*
        The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
        ReactTable and then get the internal state and the 'sortedData'.
        That can then be iterrated to get all the currently visible records and set
        the selection state.
      */
      const selectAll = !this.state.selectAll;
      const selection = [];
      if (selectAll) {
        // we need to get at the internals of ReactTable
        const wrappedInstance = this.checkboxTable.getWrappedInstance();
        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance.getResolvedState().sortedData;
        // we just push all the CP onto the selection array
        currentRecords.forEach(item => {
            selection.push(item._original.id);
        });
      }
      this.setState({ selectAll, selection });
    };

    isSelected = key => {
      /*
        Instead of passing our external selection state we provide an 'isSelected'
        callback and detect the selection state ourselves. This allows any implementation
        for selection (either an array, object keys, or even a Javascript Set object).
      */
      return this.state.selection.includes(key);
    };

    cellRenderers = {
        Link: (cellInfo) => {
            return <a href={cellInfo.original.href} className="link link--sentence"> {cellInfo.original[cellInfo.column.id]} </a>;
        },
        CampaignLink: (cellInfo) => {
            return <a href={cellInfo.original.campaign_link} className="link link--sentence"> {cellInfo.original.campaign_name} </a>;
        },
        HealthMarkLink: (cellInfo) => {
            return <a href={`/health-mark/profile/${cellInfo.original.company_id}?search-term=general&country-code=`} className="link link--sentence"> {cellInfo.original.name} </a>;
        },
        Date: (cellInfo) => {
            return <span>{moment(cellInfo.original[cellInfo.column.id], 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY h:mm:ss A').toString()}</span>;
        },
        HealthMarkIndicator: (cellInfo) => {
            return <span className={`riskLevel riskLevel__${cellInfo.original.risk_mark_code}`}><span className="riskLevel__text">{cellInfo.original.risk_mark_type}</span></span>;
        }
    };

    onClearFilters = () => {
        const filter_select_values = this.state.filter_select_values;
        Object.keys(filter_select_values).map((key) => {
            filter_select_values[key] = null;
        });
        this.setState({
            filtered: [],
            filter_select_values: filter_select_values
        });
    };

    onFilteredChangeCustom = (value, accessor) => {
        const filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter['id'] === accessor) {
                    if (value === '' || !value.length) {
                        filtered.splice(i, 1);
                    } else {
                        filter['value'] = value;
                    }

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });

        this.fetchData({page:-1});
    };

    get_URL = (state) => {
        const searchParam = this.props.searchparam;
        const sorted = this.state.sorted.map(k => (k.desc ? '-' : '') + k.id);
        const filtered = this.state.filtered.map(k => k.id + '=' + k.value).join(',');
        const params = {
            page: state.page + 1,
            pageSize: this.state.pageSize,
            sorted: sorted,
            filtered: filtered
        };
        if (!UTILS.isEmptyOrUndefined(this.props.searchparam)) {
            Object.assign(params, {
                [searchParam]: this.state.searchTerm
            });
        }
        const esc = encodeURIComponent;
        const query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
        return this.state.url + '?' + query;
    };

    fetchData = (state, instance) => {
        this.setState({loading: true});
        // fetch the data
        setTimeout(() => {
            fetch(this.get_URL(state), {
                credentials: 'same-origin',
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    this.setState({
                        data: data.rows,
                        pages: data.pages,
                        total: data.total,
                        loading: false
                    });
                });
        }, 1);
    };

    onPageSizeChange = (pageSize, pageIndex) => {
        this.setState({
            pageSize: pageSize,
            pageIndex: pageIndex
        });
    };

    onSortedChange = (newSorted, column, shiftKey) => {
        // sort only by one column
        // override previous sorted state
        this.setState({
            sorted: newSorted
        });
    };

    clearSearch = () => {
        this.setState({
            searchTerm: ''
        });
        this.fetchData({page:-1});
    };

    renderFilter = (filter, i) => {
        const filterName = filter.name;
        const options = filter.options;
        const placeholder = filter.placeholder;
        return <div className="filter--container" key={i}>
                <div className="select--container">
                    <Select
                        className={'table-filter-' + filterName}
                        styles={customStyles}
                        onChange={entry => {
                            const filter_select_values = this.state.filter_select_values;
                            filter_select_values[filterName] = entry;
                            this.setState({ filter_select_values: filter_select_values});
                            this.onFilteredChangeCustom(entry.id, filterName);
                        }}
                        value={this.state.filter_select_values[filterName]}
                        options={options.map(([value, label]) => {
                            return {id: value, value: label, label: label};
                        })}
                        placeholder={placeholder}
                    />
                </div>
            </div>;
    };

    renderTotals = (state, makeTable, instance) => {
        let recordsInfoText = '';

        const { filtered, pageRows, pageSize, sortedData, page } = state;

        if (sortedData && sortedData.length > 0) {
            const isFiltered = filtered.length > 0;

            const total = this.state.total;

            const recordsCountFrom = (page * pageSize) + 1;

            const recordsCountTo = (recordsCountFrom + pageRows.length) - 1;

            if (isFiltered) {
                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${total} ` + this.state.totalFilteredRequestsLabel;
            } else {
                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${total} ` + this.state.totalRequestsLabel;
            }

        } else {
            recordsInfoText = this.state.totalNoRequests;
        }
        return <div className="main-grid">
            <div className="above-table margin__vertical">
                <span className="records-info">{recordsInfoText}</span>
            </div>
            {makeTable()}
        </div>;
    };

    onSearchTermChange = (searchTerm) => {
        this.setState({
            searchTerm: searchTerm
        });
        // the user has deleted the search term
        if (searchTerm.length === 0) {
            this.fetchData({page:-1});
        // the user has not typed enough characters
        } else if (searchTerm.length < this.props.searchmin) {
            return;
        }
        this.refreshDebounced();
    };

    refreshDebounced = () => {
        this.fetchData({page:-1});
    };

    render() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const { data, pages, selectAll, assignees, selection, selectedAssigneeOption} = this.state;

        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: 'checkbox',
            keyField: 'id'
        };

        return (
            <React.Fragment>
                <div className="row">
                    <div className="table_sortable">
                        {this.state.filters.length > 0 &&
                            <div>
                                <span>Filter by: </span>
                                {
                                    this.state.filters.map(
                                        (filter, i) => this.renderFilter(filter, i)
                                    )
                                }
                                <div className="filter--container">
                                      <span>
                                        <a className="link link--sentence" onClick={this.onClearFilters}>
                                          {LOCALIZED_STRS.get().clearFilters}
                                        </a>
                                      </span>
                                </div>
                            </div>
                        }
                        { this.state.searchParam &&
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="filter--container filter--container--search">
                                        <input
                                            autoComplete="off"
                                            placeholder={this.state.searchPlaceholder}
                                            value={this.state.searchTerm}
                                            onChange={e => {
                                                this.onSearchTermChange(e.target.value);
                                            }}
                                        />
                                        { !UTILS.isEmptyOrUndefined(this.state.searchTerm) &&
                                            <i
                                                onClick={this.clearSearch}
                                                className="icon icon--selectable icon--failed-circle"
                                            >
                                            </i>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        <div ref={node => this.node = node} className="dropdown dropdown--container float-right">
                            <div
                                id="actionsDropdownToggle"
                                onClick={() => this.toggleDropdown()}
                                className="rbtn rbtn--dark dropbtn"
                            >Actions
                            </div>
                            <ul className={'dropdown-content dropdown-content--right ' + (this.state.dropdownActive ? 'active' : '')}>
                                <li
                                    id="openBulkAssign"
                                    onClick={this.openModal}
                                    className="rbtn rbtn--block rbtn--transparent margin__none text__align--left">
                                    Assign
                                </li>
                            </ul>
                        </div>
                        <React.Fragment>
                            <Modal
                              isOpen={this.state.modalIsOpen}
                              onAfterOpen={this.afterOpenModal}
                              onRequestClose={this.closeModal}
                              contentLabel="Bulk Assign"
                              className="bulkAssign"
                              overlayClassName="Overlay"
                            >
                                <div className="ReactModal__Header">
                                    { !this.state.formSubmitted &&
                                        <h2>Assign Requests</h2>
                                    }
                                    <button
                                        onClick={this.closeModal}
                                        className="rbtn rbtn--transparent"
                                    >
                                    <i className="icon icon--close close-menu" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="margin__vertical--loose">
                                { /* Form Content */ }
                                { !this.state.formSubmitted &&
                                    <React.Fragment>
                                        <p className="margin__vertical--loose--bottom">Assign <span className="bold">{this.state.selection.length}</span> requests to </p>
                                        <form autoComplete="off" onSubmit={this.handleSubmit} action={this.state.submitUrl} method="POST">
                                            <Select
                                                id="assigneeDropdownToggle"
                                                className="assigneeSelect"
                                                classNamePrefix="assigneeSelect"
                                                ref={this.assigneeName}
                                                inputId="select_assignee"
                                                styles={customStyles}
                                                placeholder="Select assignee..."
                                                value={selectedAssigneeOption}
                                                onChange={this.handleChange}
                                                options={assignees.map(([value, label]) => {
                                                    return {id: value, value: label, label: label};
                                                })}
                                            />
                                            { this.state.assigneeErrorMessage &&
                                            <div className="errors">
                                                <div className="error">{LOCALIZED_STRS.get().please_select}</div>
                                            </div>
                                            }
                                            <div className="margin__vertical--loose--top">
                                                <button
                                                    id="submitBulkAssign"
                                                    className="rbtn rbtn--primary float-right"
                                                    disabled={this.state.isFetching}
                                                >Assign</button>
                                            </div>
                                        </form>
                                    </React.Fragment>
                                }
                                { /* Form Success */ }
                                { (this.state.formSubmitted && this.state.formSuccess) &&
                                    <div className="form form--centered form--submitted animated animated--fadeInTop">
                                        <div className="success_image"></div>
                                        <h2 className="margin__vertical text__align--center"><span className="bold">{this.state.soliciationsUpdated}</span> requests have been successfully assigned to {selectedAssigneeOption.label}</h2>
                                        { this.state.soliciationsFailed > 0 &&
                                            <h2 className="margin__vertical text__align--center"><span className="bold">{this.state.soliciationsFailed}</span> requests failed</h2>
                                        }
                                        { this.state.soliciationsSkipped > 0 &&
                                            <h2 className="margin__vertical text__align--center"><span className="bold">{this.state.soliciationsSkipped}</span> requests in <span className="bold">Data Entry</span> or <span className="bold">Closed</span> were skipped</h2>
                                        }
                                    </div>
                                }
                                { /* Form Error */ }
                                { (this.state.formSubmitted && !this.state.formSuccess) &&
                                    <div className="form form--centered form--failed animated animated--fadeInTop">
                                        <div className="error_image"></div>
                                        <h2 className="margin__vertical error text__align--center">Hmm...something went wrong. We were unable to assign any requests. Give it another try</h2>
                                    </div>
                                }
                                </div>
                            </Modal>
                        </React.Fragment>
                        <CheckboxTable
                            ref={r => (this.checkboxTable = r)}
                            className="ReactTable--checkboxTable"
                            data={data}
                            pages={pages}
                            url={this.state.url}
                            filtered={this.state.filtered}
                            defaultPageSize={this.state.pageSize}
                            onPageSizeChange={this.onPageSizeChange}
                            pageSize={this.state.pageSize}
                            loading={this.state.loading}
                            onSortedChange={this.onSortedChange}
                            minRows={0}
                            pageSizeOptions= {[5, 10, 20, 25, 50, 100]}
                            onFilteredChange={(filtered, column, value) => {
                                this.onFilteredChangeCustom(
                                    value,
                                    column.id || column.accessor
                                );
                            }}
                            sorted = {this.state.sorted}
                            onFetchData={ this.fetchData }
                            manual
                            columns={this.state.columns}
                            {...checkboxProps}
                        >
                            {this.renderTotals}
                        </CheckboxTable>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CampaignSolicitationsTable.propTypes = {
    pagesize: PropTypes.number,
    filters: PropTypes.string,
    id: PropTypes.string,
    columns: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    assignSolicitationsUrl: PropTypes.string.isRequired

};

export default CampaignSolicitationsTable;
