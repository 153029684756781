import React, { useState } from 'react';
import Modal from 'react-modal';
import HealthMarkSearch from './healthmark-location-search/index.jsx';
import HealthMarkSectorSearch from './healthmark-sector-search/index.jsx';
import styled from 'styled-components';
import 'formdata-polyfill';
import {csrftoken} from '../../utils/csrf.jsx';
import {isUndefined, isEqual } from 'lodash';
import { withFormik } from 'formik';
import * as Yup from 'yup';

const MainContainer = styled.div`
  background-color: #fff;
  padding: 24px;
  min-height: 102px;
  border: 1px solid rgba(199,210,219,.7);
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(199,210,219,.4);

  @media screen and (min-width: 75em) {
    padding: 32px;
  }
`;

const StyledInput = styled.input`
  align-items: center;
  padding: 8px;
  font-size: 16px;
  font-family: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  border-color: ${props =>
    (isUndefined(props.isHiglighted) || props.isHiglighted === true)
    ? '#ccc'
    : '#F5B353'
  }
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 155px;
  max-width: ${props => props.maxWidth || '155px' };
  width: 100%;
  height: 40px;
  position: relative;
  margin: 4px 0;
  transition: all .1s;
  box-sizing: border-box;

  &.required {
    ~ .error {
      display: block;
    }
  }

  &.hasPrefix {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    min-width: calc(155px - 40px);
    max-width: calc(155px - 40px);
  }
`;

const StyledTextarea = styled.textarea`
  padding: 8px;
  font-size: 16px;
  font-family: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  border-color: ${props =>
    (isUndefined(props.isHiglighted) || props.isHiglighted === true)
    ? '#ccc'
    : '#F5B353'
  }
  width: 100%;
  max-width: 665px;
  display: block;
  margin: 4px 0;

  &.required {
    ~ .error {
      display: block;
    }
  }
`;

const StyledLegend = styled.legend`
  font-weight: 600;
  line-height: 24px;
  flex: 0 0 100%;
  display: flex;
`;

const InputContainer = styled.div`
  margin-bottom: 24px;

  label {
    display: inline-block;
  }
`;

const DetailContainer = styled.div`
  margin-bottom: 16px;
`;

const StyledParagraph = styled.p`
  color: #F5B353;
  font-weight: 600;
  margin-bottom: 8px;
  flex: 0 0 100%;
  display: ${props => props.isHidden === true ? 'none' : 'inline-flex'};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  legend {
    flex: 0 0 100%;
  }
`;

const HelpText = styled.p`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: 400;
  color: #828c94;
  text-transform: none;
`;

const ErrorMessage = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #d30547;
`;

const CurrencySymbol = styled.span`
  display: inline-block;
  float: left;
  line-height: 38px;
  width: 40px;
  text-align: center;
  background: #F4F6F9;
  border: 1px solid #CCC;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const StyledModal = styled(Modal)`
  &.company_edit_modal {
    max-width: 780px;
    width: 100%;
  }
`;

const ModalHeader = styled.div`
  margin-bottom: 24px;

  h2 {
    display: inline-block;
    width: 90%;
  }

  button {
    font-size: 1.2em;
    color: #51585d;
    min-width: 0;
    float: right;
    border: none;
    line-height: 32px;
    padding: 0 8px;
    margin: 0;
  }
`;

const CompanyDetail = styled.p`
  font-weight: bold;
  text-transform: capitalize;
`;

const ModalFooter = styled.div`
  margin-top: 24px;
  float: right;
`;

const Caption = styled.div`
  font-size: 13px;
  letter-spacing: 1.2px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 16px;
  color: #a3b0bb;
  margin-bottom: 16px;
`;

const Header2 = styled.h2`
  margin-bottom: 16px;
`;

const getObjectDiff = (obj1, obj2) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

const goToHealthMarkProfile = () => {
  const profileURL = window.location.href.replace(/edit-/g,'');
  setTimeout(() => {
    window.location = profileURL;
  }, 1000);
};

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
    address_line_1: Yup.string()
      .required('Address is required'),
    location: Yup.string()
      .required('Location is required')
  }),
  mapPropsToValues: props => ({
    name: props.name,
    address_line_1: props.address_line_1,
    location: props.location,
    sector: props.sector,
    zip: props.zip,
    sales: props.sales,
    employees: props.employees,
    year: props.year,
    website: props.website,
    sectorsuggestionurl: props.sectorsuggestionurl,
    locationsuggestionurl: props.locationsuggestionurl
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(false);
    const payload = {
      ...values
    };
    const formData = new FormData();
    // get the form updates and add to form data
    const formUpdates = getObjectDiff(props, payload);
    formUpdates.map((item, index) => {
      formData.append(item, JSON.stringify(payload[item]).replace(/"/g, ''));
    });
    if (formUpdates.includes('address_line_1')|| formUpdates.includes('location') || formUpdates.includes('zip')) {
        const locationString = payload.location;
        const lastComma = locationString.lastIndexOf(',');
        const newLocation = (
          locationString.substring(0, lastComma)
          + `${payload.zip ? `, ${payload.zip},` : ''}`
          + locationString.substring(lastComma + 1)
        );
        formData.append('address', `${payload.address_line_1}, ${newLocation}`);
    }
    // add CRSF token to form data
    const csrftoken_value = csrftoken();
    formData.append('csrfmiddlewaretoken', csrftoken_value);
    // send the form data
    fetch(window.location.href, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrftoken_value
      },
      credentials: 'same-origin',
      body: formData
    })
    .then((res) => {
      if (res.status === 200) {
        goToHealthMarkProfile();
      } else {
        window.location.reload();
      }
    });
  },
  displayName: 'CompanyEditForm'
});

class LocationSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange('location', value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur('location', true);
  };

  locationCallback = (value) => {
    this.props.onChange('location', value);
    this.props.onBlur('location', true);
    // setLocation(value.§);
  };

  render() {
    return (
      <InputContainer>
        <label htmlFor="location">Town/City, state (US only) and Country</label>
        <HealthMarkSearch
           id="company_edit_location"
           className="location_field"
           name="location"
           location={this.props.location}
           parentCallback={this.locationCallback}
           locationsuggestionurl={this.props.locationsuggestionurl}
         />
        {!!this.props.error && this.props.touched
          ?
            <ErrorMessage>{this.props.error}</ErrorMessage>
          :
            <HelpText>
              Start typing to get a list of possible location matches
            </HelpText>
        }
      </InputContainer>
    );
  }
}

class SectorSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange('sector', value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur('sector', true);
  };

  sectorCallback = (value) => {
    this.props.onChange('sector', value);
    this.props.onBlur('sector', true);
  };

  render() {
    return (
      <InputContainer>
        <label htmlFor="sector">NAICS Code and Sector</label>
        <HealthMarkSectorSearch
          id="company_edit_sector"
          className="location_field"
          name="sector"
          sector={this.props.sector}
          parentCallback={this.sectorCallback}
          sectorsuggestionurl={this.props.sectorsuggestionurl}
        />
        <HelpText>
          Start typing to get a list of possible NAICS Code or Sector Name suggestions
        </HelpText>
      </InputContainer>
    );
  }
}

const CompanyEditForm = props => {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    values,
    initialValues,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    location,
    locationsuggestionurl,
    sector,
    sectorsuggestionurl
  } = props;

  const payload = {
    ...values
  };

  // get the form updates
  const formUpdates = getObjectDiff(initialValues, values);

  const isLocationMessageHidden = () => {
    if (
      !isEqual(initialValues.address_line_1, values.address_line_1) ||
      !isEqual(initialValues.location, values.location) ||
      !isEqual(initialValues.zip, values.zip)
    ) {
      return false;
    }
    return true;
  };

  return (
    <MainContainer>
    <div className="row bordered">
      <div className="details__block col-xs-12 col-sm-4">
        <Caption>
          Existing Details
        </Caption>
        <Header2>{initialValues.name}</Header2>
        <p className="text--grey">{initialValues.location}</p>
        <hr />
        <DetailContainer>
          <CompanyDetail>Sector</CompanyDetail>
          <p>{initialValues.sector}</p>
        </DetailContainer>
        <DetailContainer>
          <CompanyDetail>Comnpany Size (Employees)</CompanyDetail>
          <p>{initialValues.employees}</p>
        </DetailContainer>
        <DetailContainer>
          <CompanyDetail>Year Established</CompanyDetail>
          <p>{initialValues.year}</p>
        </DetailContainer>
        <DetailContainer>
          <CompanyDetail>Website</CompanyDetail>
          <p>{initialValues.website}</p>
        </DetailContainer>
      </div>
      <div className="details__block col-xs-12 col-sm-8">
        <h3>Edit Details</h3>
        <p
          className="margin__vertical--loose--bottom"
        >
          Update only the information that has been confirmed
        </p>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <fieldset>
            <InputContainer>
              <label htmlFor="name" style={{ display: 'block' }}>
                Company Name
              </label>
              <StyledInput
                id="company_edit_name"
                name="name"
                placeholder="i.e Acme Corp"
                type="text"
                maxWidth="496px"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-invalid={errors.name && touched.name ? true : false}
              />
              {errors.name && touched.name
                ?
                  <ErrorMessage>{errors.name}</ErrorMessage>
                :
                  <HelpText>
                    Include full company name i.e Acme Corp
                  </HelpText>
              }
            </InputContainer>
          </fieldset>
          <fieldset>
            <StyledLegend className="margin__vertical--tight--bottom">Location</StyledLegend>
            <StyledParagraph
              isHidden={isLocationMessageHidden()}
            >
            Looks like you have changed the location, you may need to update the Zip, City, State, and Country.
            </StyledParagraph>
            <FlexContainer>
              <LocationSelect
                value={values.location}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.location}
                touched={touched.location}

                id="company_edit_location"
                className="location_field"
                name="location"
                location={props.location}
                locationsuggestionurl={props.locationsuggestionurl}
              />
              <InputContainer>
                <label htmlFor="zip" style={{ display: 'block' }}>
                  Zip/Postal Code
                </label>
                <StyledInput
                  id="zip"
                  name="zip"
                  type="text"
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputContainer>
            </FlexContainer>

            <InputContainer>
              <label htmlFor="address_line_1" style={{ display: 'block' }}>
                Address (Building Number, Street, Suburb)
              </label>
              <StyledTextarea
                id="address_line_1"
                name="address_line_1"
                placeholder="i.e No 5, 10 Floor, 50 High Street"
                value={values.address_line_1}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-invalid={errors.address_line_1 && touched.address_line_1 ? true : false}
              />
              {errors.address_line_1 && touched.address_line_1 && (
                <ErrorMessage>{errors.address_line_1}</ErrorMessage>
              )}
            </InputContainer>
          </fieldset>
          <fieldset>
            <StyledLegend className="margin__vertical--tight--bottom">Company Details</StyledLegend>

            <SectorSelect
              value={values.sector}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.sector}
              touched={touched.sector}

              id="company_edit_sector"
              className="location_field"
              name="sector"
              sector={props.sector}
              sectorsuggestionurl={props.sectorsuggestionurl}
            />

            <InputContainer>
              <label htmlFor="employees" style={{ display: 'block' }}>
                Company Size (Employees)
              </label>
              <StyledInput
                id="employees"
                name="employees"
                placeholder="i.e 1000"
                type="number"
                value={values.employees}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <HelpText>
                Numerical values only
              </HelpText>
            </InputContainer>

            <InputContainer>
              <label htmlFor="sales" style={{ display: 'block' }}>
                Estimated Sales (US Dollars)
              </label>
              <div>
                <CurrencySymbol>
                $
                </CurrencySymbol>
                <StyledInput
                  id="sales"
                  name="sales"
                  type="number"
                  className="hasPrefix"
                  maxWidth="155px"
                  value={values.sales}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <HelpText>
                Numerical values only
              </HelpText>
            </InputContainer>

            <InputContainer>
              <label htmlFor="year" style={{ display: 'block' }}>
                Year Established
              </label>
              <StyledInput
                id="year"
                name="year"
                type="number"
                value={values.year}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <HelpText>
                Numerical values only
              </HelpText>
            </InputContainer>

            <InputContainer>
              <label htmlFor="website" style={{ display: 'block' }}>
                Website
              </label>
              <StyledInput
                id="website"
                name="website"
                type="text"
                maxWidth="496px"
                value={values.website}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputContainer>
          </fieldset>
          <button
            className="rbtn rbtn--md margin__horizontal--tight--right"
            type="button"
            onClick={() => goToHealthMarkProfile()}
          >Cancel</button>
          <button
            id="companyEditUpdate"
            type="button"
            onClick={() => setModalOpen(true)}
            disabled={Object.keys(errors).length > 0 || formUpdates.length === 0}
            className="rbtn rbtn--primary rbtn--md"
          >
            Update
          </button>
          <StyledModal
            isOpen={isModalOpen}
            onRequestClose={() => setModalOpen(false)}
            contentLabel="Confirm Company Edit"
            className="company_edit_modal"
            overlayClassName="Overlay"
          >
            <ModalHeader>
              <h2>Confirm Company Details</h2>
              <button
                onClick={() => setModalOpen(false)}
                className="rbtn rbtn--transparent"
              >
              <i
                className="icon icon--close close-menu"
                aria-hidden="true"
              ></i>
              </button>
            </ModalHeader>
            <p
              className="text--grey"
            >
              You have updated the following details for
            </p>
            <Header2
              className="margin__vertical--tight--bottom"
            >{initialValues.name}</Header2>
            {formUpdates.map((item, index) => {
                return (
                  <DetailContainer key={index}>
                    <CompanyDetail>{item}</CompanyDetail>
                    <p>
                    {JSON.stringify(item), JSON.stringify(payload[item])}
                    </p>
                  </DetailContainer>
                );
            })}
            <ModalFooter>
              <button
                onClick={() => setModalOpen(false)}
                id="companyEditCancel"
                className="rbtn"
              >
              Cancel
              </button>
              <button
                id="companyEditConfirm"
                type="button"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="rbtn rbtn--primary"
              >
                Confirm
              </button>
            </ModalFooter>
          </StyledModal>
        </form>
      </div>
    </div>
    </MainContainer>
  );
};

const ValidatedCompanyEditForm = formikEnhancer(CompanyEditForm);

export default ValidatedCompanyEditForm;
