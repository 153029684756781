import 'formdata-polyfill';
import {csrftoken} from '../../utils/csrf.jsx';

/**
 * Returns a promise that resolves with the location suggestions for the query.
 * @param {string} url The endpoint for the location suggestion.
 * @returns {Promise} The promise that resolves with the suggestions.
 */
export function getLocationSuggestions(url) {
    return fetch(url)
    .then(res => res.json())
    .then(json => Promise.resolve(json.results));
}

/**
 * Returns an array of options based on the provided suggestions..
 * @param {string} suggestions The suggestions array.
 * @returns {Array} The array of options from the suggestions.
 */
export function transformSuggestionsToOptions(suggestions) {
  return suggestions.map(({ id, name, permissioned }, index) => {
    return {
      value: id,
      displayName: name,
      permissioned: permissioned,
      displayID: 'Org ID. ' + id,
      label: `${name} | Org ID. ${id} ${permissioned ? ' | Permissioned' : ''}`
    };
  });
}
