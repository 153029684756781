import React, {Component} from 'react';
import ReactDOM from 'react-dom';

class Loader extends React.Component {
    render() {
        return (
            <div className="loader">
                <div className="loader__animation"></div>
            </div>
        );
    }
}

export default Loader;
