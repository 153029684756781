import React, { useState, useEffect, useContext } from 'react';
import { DynamicBenchmarkContext } from '../index.jsx';
import styled, {css} from 'styled-components';
import { csrftoken } from '../../../utils/csrf.jsx';
import UTILS from '../../../utils/utils.jsx';
import LOCALIZED_STRS from '../../../utils/localize.jsx';

const SectorsContainer = styled.div`
  border: 1px solid #C7D2DB;
  border-radius: 2px;
  margin-top: 24px;
  padding: 24px;
`;

const SectorButton = styled.div`
  position: relative;
  padding-left: 24px;
  display: inline-block;
  cursor: pointer;

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 9999px;
    background-color: ${props => props.isActive ? '#017BC1' : 'transparent'};
    position: absolute;
    top: 4px;
    left: 2px;
  }

  &:after{
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 9999px;
    border: 1px solid #C6CBD4;
    position: absolute;
    top: 2px;
    left: 0;
  }
`;

const HelpText = styled.p`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: 400;
  color: #828c94;
  text-transform: none;
  max-width: initial;
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: ${props => props.flexWrap ? props.flexWrap : 'nowrap'};
  justify-content: space-between;
  margin: 0 ${props => props.spacing ? css`calc(-${props.spacing})` : '-8px'};

  @media screen and (min-width: 75em) {
    flex-wrap: nowrap;
  }

  > div {
    padding: 0 ${props => props.spacing ? props.spacing : '8px'};
    flex: ${props => props.flex ? props.flex : '0 1 auto'};

    &.dropdownContainer {
      flex: 1 1 auto;
    }
  }

  &.bordered {
    > div:not(:last-of-type) {
      position: relative;

      &:after {
        content: '';
        border-right: 1px solid #C7D2DB;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin-top: -24px;
        margin-bottom: -24px;
      }
    }
  }
`;

const SectorDetailsContainer = styled.div`
  min-height: 50px;
`;

const SpinnerContainer = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-bottom: -5px;
`;

const TextContainer = styled.span`
  text-align: ${props => props.textAlign ? props.textAlign: 'right'};
  padding-right: 16px;
  color: ${props => props.textColor};

  p {
    color: ${props => props.textColor};
    line-height: initial;
  }
`;

export default function NaicsSelector (props) {
  // codes
  const [twoDigitSector, setTwoDigitSector] = useState(null);
  const [fourDigitSector, setFourDigitSector] = useState(null);
  const [sixDigitSector, setSixDigitSector] = useState(null);
  // counts
  const [twoDigitSectorCount, setTwoDigitSectorCount] = useState(null);
  const [fourDigitSectorCount, setFourDigitSectorCount] = useState(null);
  const [sixDigitSectorCount, setSixDigitSectorCount] = useState(null);
  // descriptions
  const [twoDigitSectorDescription, setTwoDigitSectorDescription] = useState(null);
  const [fourDigitSectorDescription, setFourDigitSectorDescription] = useState(null);
  const [sixDigitSectorDescription, setSixDigitSectorDescription] = useState(null);

  const sectorDigitContext = useContext(DynamicBenchmarkContext);
  const { sectorDigitSelected, setSectorDigitSelected } = sectorDigitContext;

  useEffect(() => {
    twoDigitSector &&
      fetchCount(`${twoDigitSector.slice(0,2)}`, setTwoDigitSectorCount);
    fourDigitSector &&
      fetchCount(`${fourDigitSector.slice(0,4)}`, setFourDigitSectorCount);
    sixDigitSector &&
      fetchCount(sixDigitSector, setSixDigitSectorCount);

    fetchDescription(twoDigitSector, setTwoDigitSectorDescription);
    fetchDescription(fourDigitSector, setFourDigitSectorDescription);
    fetchDescription(sixDigitSector, setSixDigitSectorDescription);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoDigitSector, fourDigitSector, sixDigitSector ]);

  useEffect(() => {
    if (props.sectorSelected) {
      setTwoDigitSector(props.sectorSelected.value.slice(0,2).concat('0000'));
      setFourDigitSector(props.sectorSelected.value.slice(0,4).concat('00'));
      setSixDigitSector(props.sectorSelected.value.slice(0,6));
    }
  }, [props.sectorSelected]);

  function fetchDescription (sectorDigit, setFunction) {
    if (sectorDigit === null) {
      return;
    }

    const csrftoken_value = csrftoken();
    fetch(
      `${window.location.origin}/dynamic-benchmark/naics?query=${sectorDigit}`,
      {
        method: 'get',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrftoken_value
        },
        credentials: 'same-origin'
      }
    )
    .then(response => {
      return response.json();
    })
    .then((response)=>{
      response.map((item) => {
        setFunction(item);
      });
    });
  }

  function resetCounts () {
    setTwoDigitSectorCount(null);
    setFourDigitSectorCount(null);
    setSixDigitSectorCount(null);
  }

  function resetDescriptions () {
    setTwoDigitSectorDescription(null);
    setFourDigitSectorDescription(null);
    setSixDigitSectorDescription(null);
  }

  function fetchCount (sectorDigit, setFunction) {
    if (sectorDigit === null) {
      return;
    }

    resetCounts();
    resetDescriptions();
    const csrftoken_value = csrftoken();
    fetch(
      `${window.location.origin}/dynamic-benchmark/company-count?naicsCode=${sectorDigit}`,
      {
        method: 'get',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrftoken_value
        },
        credentials: 'same-origin'
      }
    )
    .then(response => {
      return response.json();
    })
    .then((response)=>{
      setFunction(response.count);
    });
  }

  const RenderLoadingText = () => {
    return (
      <SectorDetailsContainer>
        <p className="margin__horizontal--loose--left bold">
          Calculating Matching Peer Companies
        </p>
        <SpinnerContainer
          className="spinner_animation margin__horizontal--loose--left"
        >
        </SpinnerContainer>
      </SectorDetailsContainer>
    );
  };

  return (
    <SectorsContainer>
      <FlexRow
        flex= "1 1 0px"
        spacing={'24px'}
        className="bordered"
      >
        <div>
          <SectorButton
            isActive={sectorDigitSelected === 6}
            onClick={() => setSectorDigitSelected(6)}
          >
            6 Digit NAICS
          </SectorButton>
          {sixDigitSectorCount === null || sixDigitSectorDescription == null
            ? <RenderLoadingText />
            :
            <SectorDetailsContainer>
              <p className="margin__horizontal--loose--left bold">
                <TextContainer
                  textColor = {sixDigitSectorCount < 5 ? '#ED4C4A' : '#4A4A4A' }
                  textAlign = "left"
                >
                {sixDigitSectorCount < 5 ?
                  'Insufficent Peer Companies'
                  : `${sixDigitSectorCount} Matching Peer Companies`
                }
                </TextContainer>
              </p>
              <HelpText className="margin__horizontal--loose--left">
                {sixDigitSectorDescription !== null &&
                  `${sixDigitSectorDescription.value} | ${sixDigitSectorDescription.description}
                `}
              </HelpText>
            </SectorDetailsContainer>
          }
        </div>
        <div>
          <SectorButton
            isActive={sectorDigitSelected === 4}
            onClick={() => setSectorDigitSelected(4)}
          >
            4 Digit NAICS
          </SectorButton>
          {fourDigitSectorCount === null || fourDigitSectorDescription == null
            ? <RenderLoadingText />
            :
            <SectorDetailsContainer>
              <p className="margin__horizontal--loose--left bold">
                <TextContainer
                  textColor = {fourDigitSectorCount < 5 ? '#ED4C4A' : '#4A4A4A' }
                  textAlign = "left"
                >
                {fourDigitSectorCount < 5 ?
                  'Insufficent Peer Companies'
                  : `${fourDigitSectorCount} Matching Peer Companies`
                }
                </TextContainer>
              </p>
              <HelpText className="margin__horizontal--loose--left">
                {fourDigitSectorDescription !== null &&
                  `${fourDigitSectorDescription.value.slice(0,4)} | ${fourDigitSectorDescription.description}`
                }
              </HelpText>
            </SectorDetailsContainer>
          }
        </div>
        <div>
          <SectorButton
            isActive={sectorDigitSelected === 2}
            onClick={() => setSectorDigitSelected(2)}
          >
            2 Digit NAICS
          </SectorButton>
          {twoDigitSectorCount === null || twoDigitSectorDescription == null
            ? <RenderLoadingText />
            :
            <SectorDetailsContainer>
              <p className="margin__horizontal--loose--left bold">
                <TextContainer
                  textColor = {twoDigitSectorCount < 5 ? '#ED4C4A' : '#4A4A4A' }
                  textAlign = "left"
                >
                {twoDigitSectorCount < 5 ?
                  'Insufficent Peer Companies'
                  : `${twoDigitSectorCount} Matching Peer Companies`
                }
                </TextContainer>
              </p>
              <HelpText className="margin__horizontal--loose--left">
                {twoDigitSectorDescription !== null &&
                  `${twoDigitSectorDescription.value.slice(0,2)} | ${twoDigitSectorDescription.description}
                `}
              </HelpText>
            </SectorDetailsContainer>
          }
        </div>
      </FlexRow>
    </SectorsContainer>
  );

}
