import React, { Fragment } from 'react';
import ReactTable from 'react-table';
import styled, { createGlobalStyle } from 'styled-components';
import moment from 'moment';
import LOCALIZED_STRS from '../utils/localize.jsx';
import { NewLinkIcon } from '../healthMark/icons.jsx';

const ReactTableStyle = createGlobalStyle`
    .ReactTable {
        font-size: 16px;
        line-height: 20px;

        & .rt-resizable-header-content {
            text-align: left;
            padding: 0 10px;
        }
    }
`;

const Column = styled.div`
    font-size: 16px;
    color: #3b4043;
`;

const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    img {
        max-width: 48px;
    }
`;

const TextContainer = styled.div`
    margin: 0 0 0 16px;
`;

const CompanyParent = styled.div`
    height: 24px;
    color: #828c94;
    font-size: 16px;
    line-height: 24px;
`;

const CompanyParentProfileLink = styled.a`
    &:visited,
    &:hover,
    &:link {
        color: #828c94;
    }

    path {
        fill: #828c94;
    }
`;

const Truncate = styled.div`
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    vertical-align: middle;
`;

const CompanyProfileLink = styled.a`
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
`;

const LinkSubtText = styled.span`
    font-weight: 400;
`;

const CompanyIcon = styled.div`
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
`;

function renderFinancialHealthLink (company) {
    return(
        <CompanyProfileLink
          className="link link--sentence"
          href={company.link}
          target="_blank"
        >
          {company.name} <LinkSubtText> (<NewLinkIcon /> FHR)</LinkSubtText>
        </CompanyProfileLink>
    );
}

function renderHealthMarkLink (company) {
    return(
        <CompanyProfileLink
          className="link link--sentence"
          href={company.link}
        >
          {company.name}
        </CompanyProfileLink>
    );
}

function renderParentLink (company) {
    return(
        <CompanyParent>
          <span>Child of </span>
          <CompanyParentProfileLink
            href={company.parent_profile_link}
            target={company.parent_profile_link.includes('company-profile') ? '_blank' : '_self'}
          >
            {company.parent_name}
            {company.parent_profile_link.includes('company-profile') &&
                <LinkSubtText>
                    (<NewLinkIcon /> FHR)
                </LinkSubtText>
            }
          </CompanyParentProfileLink>
        </CompanyParent>
    );
}

class SortableTable extends React.Component {
    constructor(props) {
        super(props);

        const columns = JSON.parse(this.props.columns);
        Object.keys(columns).map((key) => {
            const column = columns[key];
            if (column.renderer !== undefined) {
                column.Cell = this.cellRenderers[column.renderer];
            }
        });

        this.state = {
          url: props.url,
          defaultPageSize: 15,
          data: JSON.parse(props.data),
          columns: columns,
          filtered: [],
          showPagination: true,
          showDisplayAllOption: props.showdisplayalloption !== undefined ? true : false,
          filters: JSON.parse(props.filters),
          searchTerm: encodeURIComponent(props.searchterm),
          searchLocation: encodeURIComponent(props.searchlocation),
          entityType: props.entitytype
        };

    }

    static defaultProps = {
        url: '',
        data: '[]',
        columns: '[]',
        filters:'[]',
        id: 'sortable-table',
        defaultPageSize: 15,
        entityType: LOCALIZED_STRS.get().results
    };

    onFilterClick = o => {
        this.setState({ filtered: [o] });
    }

    cellRenderers = {
        Link: (cellInfo) => {
            return <a href={cellInfo.original.href} className="link link--sentence"> {cellInfo.original[cellInfo.column.id]} </a>;
        },
        CampaignLink: (cellInfo) => {
            return <a href={cellInfo.original.campaign_link} className="link link--sentence"> {cellInfo.original.campaign_name} </a>;
        },
        HealthMarkParentCell: (cellInfo) => {
            return <span className="parent--address">{cellInfo.original.address}</span>;
        },
        HealthMarkLink: ({ original: company }) => {
            return (
              <Column>
                <LinkContainer>
                    {company.parent ? (
                        <CompanyIcon className="companyIcon--child"></CompanyIcon>
                    ) : (
                        <CompanyIcon className="companyIcon--parent"></CompanyIcon>
                    )}
                    <TextContainer>
                    { /* render a link to FHR or HM page */ }
                    {company.link.includes('company-profile') ? (
                        renderFinancialHealthLink(company)
                    ) : (
                        renderHealthMarkLink(company)
                    )}
                    { /* render the parent entity type */ }
                    {company.parent_name ? (
                        renderParentLink(company)
                    ) : company.parent_type ? (
                        null /* if parent_type is available then it will be displayed in own column */
                    ) : (
                        <CompanyParent>Ultimate parent</CompanyParent>
                    )}
                    </TextContainer>
                </LinkContainer>
              </Column>
            );
        },
        PrimaryLocation: ({ original: company }) => {
            return (
              <Column>
                  <Truncate>{company.primary_location}</Truncate>
              </Column>
            );
        },
        SectorDescription: ({ original: company }) => {
            return <Column><Truncate>{company.sector_description}</Truncate></Column>;
        },
        Date: (cellInfo) => {
            return <span>{moment(cellInfo.original[cellInfo.column.id], 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY h:mm:ss A').toString()}</span>;
        },
        HealthMarkIndicator: (cellInfo) => {
            return <span className={`riskLevel riskLevel__${cellInfo.original.risk_mark_code}`}><span className="riskLevel__text">{cellInfo.original.risk_mark_type}</span></span>;
        }
    };

    renderTotals = (state, makeTable, instance) => {
        let recordsInfoText = '';

        const { filtered, pageRows, pageSize, sortedData, data, entityType, showDisplayAllOption, page } = state;

        if (sortedData && sortedData.length > 0) {
            const isFiltered = filtered.length > 0;

            const total = this.state.total;

            const recordsCountFrom = (page * pageSize) + 1;

            const recordsCountTo = (recordsCountFrom + pageRows.length) - 1;

            if (isFiltered) {
                recordsInfoText = LOCALIZED_STRS.get().showing + ` ${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${total} ` + this.state.totalFilteredRequestsLabel;
            } else {
                {
                    this.state.entityType ? (
                        recordsInfoText = LOCALIZED_STRS.get().showing + ` ${recordsCountFrom}-${recordsCountTo} ${this.state.entityType}`
                    ) : (
                        recordsInfoText = LOCALIZED_STRS.get().showing + ` ${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of_all_results
                    );
                }
            }

        } else {
            recordsInfoText = this.state.totalNoRequests;
        }
        return <div className="main-grid">
            <div className="above-table margin__vertical--bottom">
            {this.state.showPagination ? (
                <strong>
                    <span className="records-info">{recordsInfoText}</span>
                    {this.state.showDisplayAllOption &&
                        <button
                            className="rbtn rbtn--ghost rbtn--md margin__horizontal--tight--left"
                            onClick={this.showAll}
                        >
                          {LOCALIZED_STRS.get().displayAll}
                        </button>
                    }
                </strong>
            ) : (
                <strong>{LOCALIZED_STRS.get().showingAll} {this.state.entityType}</strong>
            )}
            </div>
            {makeTable()}
        </div>;
    };

    showAll = () => {
        this.setState({
            defaultPageSize: this.state.data.length,
            pageSize: this.state.data.length,
            showPagination: false
        });
    }

    componentWillMount() {
        if (this.state.data.length > this.state.defaultPageSize) {
            this.setState({
                defaultPageSize: 15
            });
        } else {
            this.setState({
                defaultPageSize: this.state.data.length,
                showPagination: false,
                showDisplayAllOption: false
            });
        }
    }

    render() {
        const { data, columns, pageSize, showPagination, defaultPageSize, url, filtered, filters } = this.state;

        return (
            <Fragment>
                <ReactTable
                    className="ReactTable--lineHeight--md"
                    data={data}
                    columns={columns}
                    url={url}
                    showPagination={showPagination}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    pageSize={pageSize}
                    defaultPageSize={defaultPageSize}
                >
                {this.renderTotals}
                </ReactTable>
                <ReactTableStyle />
            </Fragment>
        );
    }
}

export default SortableTable;
