import React, { Component } from 'react';
import SectorSelect from './sector-select.jsx';
import SearchContainer from './search-container.jsx';
import CancelIcon from './cancel-icon.jsx';
import {
  QUERY_FOCUS_MESSAGE,
  QUERY_NO_RESULTS_MESSAGE,
  QUERY_TOO_SHORT_MESSAGE,
  QUERY_ERROR_MESSAGE
} from './constants';
import {
  getLocationSuggestions,
  transformSuggestionsToOptions,
  search
} from './utils';

export default class HealthMarkSectorSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLocation: {value: props.sector, label: props.sector} || '',
      locationSearchQuery: '',
      noOptionsMessage: QUERY_TOO_SHORT_MESSAGE,
      isLocationInputFocused: false
    };
  }

  getOptions = query => {
    return new Promise(resolve => {
      this.setState({
        locationSearchQuery: query
      });

      if (query.length < 2) {
        resolve([]);
        this.setState({
          noOptionsMessage: QUERY_TOO_SHORT_MESSAGE
        });
      } else {
        getLocationSuggestions(
          `${this.props.sectorsuggestionurl}?sector-term=${query}`
        )
          .then(suggestions => {
            const options = transformSuggestionsToOptions(suggestions);

            resolve(options);

            if (this.state.locationSearchQuery === query) {
              this.setState({
                noOptionsMessage:
                  options.length === 0
                    ? QUERY_NO_RESULTS_MESSAGE
                    : QUERY_FOCUS_MESSAGE
              });
            }
          })
          .catch(e => {
            resolve([]);

            if (this.state.locationSearchQuery === query) {
              this.setState({
                noOptionsMessage: QUERY_ERROR_MESSAGE
              });
            }
          });
      }
    });
  };

  handleSelectedLocation = selectedLocation => {
    this.props.parentCallback(selectedLocation.value);
    this.setState({
      noOptionsMessage: QUERY_FOCUS_MESSAGE,
      selectedLocation
    });
  };


  handleLocationInputBlur = () =>
    this.setState({
      isLocationInputFocused: false
    });

  handleLocationInputFocus = () =>
    this.setState({
      noOptionsMessage: QUERY_FOCUS_MESSAGE,
      isLocationInputFocused: true
    });

  clearSelectedLocation = () =>
    this.setState({
      selectedLocation: null,
      locationSearchQuery: '',
      noOptionsMessage: QUERY_TOO_SHORT_MESSAGE
    });

  render() {
    const {
      noOptionsMessage,
      locationSearchQuery,
      selectedLocation,
      isLocationInputFocused
    } = this.state;

    return (
      <SearchContainer>
        <SectorSelect
          value={selectedLocation}
          name={this.props.name}
          id={this.props.id}
          getOptions={this.getOptions}
          noOptionsMessage={noOptionsMessage}
          onChange={this.handleSelectedLocation}
          onFocus={this.handleLocationInputFocus}
          onBlur={this.handleLocationInputBlur}
        />
        <CancelIcon
          isHidden={selectedLocation === null && locationSearchQuery.length === 0}
          onClick={this.clearSelectedLocation}
        />
      </SearchContainer>
    );
  }
}
