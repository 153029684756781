
const ReduxStore = (ReduxStore) => class extends ReduxStore {
    constructor(props) {
        super(props);
        if (props.store) {
            this.store = props.store;    
            if (props.store_model) {
                this.store_model = props.store_model;
                this.update_value_from_store();
            }
            if (props.store_change_action) {
                this.store_change_action = props.store_change_action;
            }
        }
    }

    update_value_from_store() {
        const new_value = this.store.getState()[this.store_model];
        if (new_value !== undefined) {
            this.setState({value: new_value});
        }
    }

    update_store(value) {
        if (this.store_change_action) {
            const payload = {};
            payload[this.store_model] = value;
            this.store.dispatch({'type': this.store_change_action, 'payload': payload});
        }
    }
};

export default ReduxStore;
