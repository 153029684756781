import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import BaseInput from '../forms/common/baseinput.jsx';
import {getRequest} from '../utils/fetch.jsx';
import ReactModal from 'react-modal';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import RequestPublicAddition from './request_public_addition.jsx';
import { Search2, SearchInput, SearchResult, SearchResults, SearchStatus, RequestPublicAdditionCallToAction, RequestPublicAdditionModal } from './search2.jsx';


class Search3 extends BaseInput {
    constructor(props) {
        super(props);
        this.charAt = 1;
        this.state = Object.assign({
            placeholder: props.placeholder,
            url: props['data-url'],
            requestPublicAdditionUrl: props['request-public-addition-url'],
            options: JSON.parse(props['search-by'] || '[]'),
            isLoading: false,
            count: 0,
            results: [],
            hasError: false,
            hasFocus: false,
            showModal: false,
            maxResults: props['max-results'] || 100
        }, this.state);
        this.closeMenu = this.closeMenu.bind(this);
        this.searchBy = this.searchBy.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.arrowsPressed = this.arrowsPressed.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onSetFocus = this.onSetFocus.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    handleOpenModal () {
      this.setState({ showModal: true });
    }

    handleCloseModal () {
      this.setState({ showModal: false });
    }

    closeMenu() {
        this.setState({hasFocus: false, openOptions: false});
    }

    handleClickOutside(evt) {
        if ( (this.node.contains(evt.target)) || (this.state.showModal) ) {
            return;
        }
        this.closeMenu();
    }

    onSetFocus(evt) {
        this.handleFocus(evt);
        if (this.isValidSituation()) {
            this.setState({hasFocus: true});
        }
    }

    onMouseOver(count) {
        this.setState({
            count: count
        });
    }

    redirectTo(href) {
        window.location = href;
    }

    isValidSituation() {
        return this.state.value.trim().length >= this.charAt;
    }

    handleSearch(evt) {
        this.handleChange(evt, this.initSearch);
    }

    initSearch() {
        clearTimeout(this.state.currentTimeout);
        const currentTimeout =  setTimeout(() => {
            if (this.isValidSituation()) {
              this.setState({ results: [], isLoading: true, hasFocus: true, hasError: false }, () => {
                  this.getResults();
              });
            } else {
                this.setState({results: [], isLoading: false, hasFocus: false, hasError: false});
            }
        }, 500);
        this.setState({
            currentTimeout: currentTimeout
        });
    }

    searchBy(option) {
        const opts = this.state.options;
        opts.forEach(opt => {
            opt.isSelected = (opt.key === option.key);
        });
        this.setState({
            options: opts
        }, () => {
            this.initSearch();
        });
    }

    getResults() {
        const url = `${this.state.url}?search=${encodeURIComponent(this.state.value)}&by=${this.state.options.find(option => option.isSelected).key}`;
        this.setState({
            currentSearchUrl: url
        });
        getRequest(url, 0, true).then(data => {
            if(this.state.currentSearchUrl === data.url) {
                this.setState({
                    results: data.results,
                    isLoading: false,
                    hasError: false,
                    count: 0
                });
            }
        }).catch(err => {
            this.setState({isLoading: false, hasError: false});
        });
    }

     arrowsPressed(evt) {
        this.handleKeyUp(evt);
        if (!this.state.isLoading && this.state.results.length > 0) {
            if (evt.keyCode === 40 || evt.keyCode === 38 || evt.keyCode === 13) {
                evt.preventDefault();
                if (evt.keyCode === 40) {
                    if (this.state.count < this.state.results.length - 1) {
                        this.setState({
                            count: this.state.count += 1
                        });
                    }
                } else if (evt.keyCode === 38) {
                    if (this.state.count > 0) {
                        this.setState({
                            count: this.state.count -= 1
                        });
                    }
                } else if (evt.keyCode === 13) {
                    this.redirectTo(this.state.results[this.state.count].href);
                    this.closeMenu();
                }
            }
        }
    }

    render() {
        let newLink;

        if (this.state.hasFocus && !this.state.isLoading) {
            newLink = <div className="search__request search__request__v3">
                        <div>{LOCALIZED_STRS.get().search.comp_question}</div>
                        <a className="link link--sentence" href={`health-mark/?search-term=${this.state.value}`}>{LOCALIZED_STRS.get().search.comp_action}</a>
                      </div>;
        }
        return (
            <div className="search" ref={node => this.node = node}>
                <label className="sr-only" htmlFor={this.props.id}>{this.state.label}</label>
                <SearchInput
                    id={this.state.id}
                    value={this.state.value}
                    placeholder={this.state.placeholder}
                    options={this.state.options}
                    openOptions={this.state.openOptions}
                    attributes={this.state.attributes}
                    onChange={this.handleSearch}
                    onKeyUp={this.arrowsPressed}
                    onBlur={this.handleBlur}
                    onFocus={this.onSetFocus}
                    searchBy={this.searchBy}
                />
                <div className="search__results__v2 animated animated--fadeIn">
                    <SearchResults count={this.state.count} searchterm={this.state.value}
                                   options={this.state.options} onclick={this.closeMenu}
                                   results={this.state.results} hasError={this.state.hasError}
                                   hasFocus={this.state.hasFocus} isLoading={this.state.isLoading}
                                   onMouseOver={this.onMouseOver} maxResults={this.state.maxResults}
                    />
                    {newLink}
                </div>
                <RequestPublicAdditionModal
                    requestPublicAdditionURL={this.state.requestPublicAdditionUrl}
                    handleOpenModal={this.handleOpenModal}
                    handleCloseModal={this.handleCloseModal}
                    showModal={this.state.showModal}
                />
            </div>
        );
    }
}

// necessary for screenreaders to focus on modal
ReactModal.setAppElement('body');

export {
    Search3 as default,
    Search3,
    SearchInput,
    SearchResult,
    SearchResults,
    SearchStatus,
    RequestPublicAdditionCallToAction,
    RequestPublicAdditionModal
};
