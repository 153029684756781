import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from './localize.jsx';

class BasicFileInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            preview_image_id: props.preview_image_id,
            id: props.id,
            name: props.name
        };

    }

    handleChange(event, preview_image_id) {
        const target = event.target;

        if (target.files && target.files[0]) {
            const reader = new FileReader();

            reader.onload = function(e) {
                document.getElementById(preview_image_id).setAttribute('src', e.target.result);
            };

            reader.readAsDataURL(target.files[0]);
        }

    }

    render() {
      if (this.state.preview_image_id) {
            return (
                <React.Fragment>
                    <label htmlFor={this.state.id} className="file__input--label">{LOCALIZED_STRS.get().browse}</label>
                    <input type="file" id={this.state.id} className="file__input file__input--logo" name={this.state.name} onChange={
                        (event) => {
                            this.handleChange(event, this.state.preview_image_id);
                        }
                    } />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <label htmlFor={this.state.id} className="rbtn rbtn--primary">{LOCALIZED_STRS.get().browse}</label>
                    <input type="file" id={this.state.id} className="file-upload" name={this.state.name} />
                </React.Fragment>
            );
        }
    }
}

export default BasicFileInput;
