export const MOBILE_BREAKPOINT = '850px';

// Color theme
export const WHITE = '#FFF';
export const BLACK = '#231F20';
export const ABBEY = '#51585D';
export const TURBO = '#FFE900';
export const GREY_PRIMARY = '#3B4043';
export const GREY_TERTIARY = '#A3B0BB';
export const BRAND_TURBO = '#FFE900';
