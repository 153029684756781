import React from 'react';

export function RemoveBookmarkIcon() {
  return (
      <svg width="9px" height="12px" viewBox="0 0 9 12" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-636.000000, -67.000000)" fill="#3B4043">
                  <path d="M645,78.9889064 L642.611548,77.2343596 L640.300404,75.5366021 L636,78.9002358 L636,68 C636,67.4477153 636.447715,67 637,67 L644,67 C644.552285,67 645,67.4477153 645,68 L645,78.9889064 Z" id="Combined-Shape-Copy-2"></path>
              </g>
          </g>
      </svg>
  );
}

export function AddBookmarkIcon() {
  return (
      <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-664.000000, -67.000000)" fill="#3B4043" fillRule="nonzero">
                  <g id="Group-3-Copy-9" transform="translate(664.000000, 67.000000)">
                      <path d="M9,7 L8,7 L8,8 L9,8 L9,11.9889064 L6.61154839,10.2343596 L4.30040359,8.53660205 L-3.04201109e-14,11.9002358 L-3.04201109e-14,1 C-3.04201109e-14,0.44771525 0.44771525,1.82964754e-13 1,1.82964754e-13 L8,1.82964754e-13 C8.55228475,1.82964754e-13 9,0.44771525 9,1 L8,1 L8,2 L9,2 L9,4 L11.032959,4 L11.032959,5 L9,5 L9,7 Z M8,4 L8,1 L1,1 L1,9.84850778 L4.28145384,7.28186289 L8,10.0134917 L8,5 L6,5 L6,4 L8,4 Z" id="Combined-Shape"></path>
                  </g>
              </g>
          </g>
      </svg>
  );
}

export function PlusIcon() {
    return(
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
            <title>Add to lists</title>
            <g id="UI-Handover" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Landing-Page-Two-lists-1024px" transform="translate(-260.000000, -323.000000)" fill="#3B4043">
                    <g id="Card" transform="translate(8.000000, 296.000000)">
                        <g id="Group-20" transform="translate(252.000000, 27.000000)">
                            <g id="Group-19">
                                <rect id="Rectangle-Copy-22" x="0" y="5" width="11" height="1"></rect>
                                <rect id="Rectangle-Copy-22" transform="translate(5.500000, 5.500000) rotate(-90.000000) translate(-5.500000, -5.500000) " x="0" y="5" width="11" height="1"></rect>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
} 