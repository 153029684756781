import React from 'react';

export default function TopAnchorIcon() {
  return (
      <React.Fragment>
          <svg width="56px" height="56px" viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <title>Back to top</title>
              <defs>
                  <circle id="path-1" cx="24" cy="24" r="24"></circle>
                  <filter x="-14.6%" y="-10.4%" width="129.2%" height="129.2%" filterUnits="objectBoundingBox" id="filter-2">
                      <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                      <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                      <feColorMatrix values="0 0 0 0 0.780392157   0 0 0 0 0.823529412   0 0 0 0 0.858823529  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                  </filter>
              </defs>
              <g id="fINAL-ui-handover-header-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="company-structure-2" transform="translate(-1297.000000, -1440.000000)">
                      <g id="Group-2" transform="translate(1301.000000, 1442.000000)">
                          <g id="Oval-Copy-2">
                              <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                              <use fill="#000000" fillRule="evenodd" xlinkHref="#path-1"></use>
                          </g>
                          <path d="M22.9350758,14.5593939 L15.7924621,21.7020076 C15.3824116,22.1120581 14.7175884,22.1120581 14.3075379,21.7020076 C13.8974874,21.2919571 13.8974874,20.6271338 14.3075379,20.2170833 L23.2170833,11.3075379 C23.6271338,10.8974874 24.2919571,10.8974874 24.7020076,11.3075379 L33.611553,20.2170833 C34.0216035,20.6271338 34.0216035,21.2919571 33.611553,21.7020076 C33.2015025,22.1120581 32.5366793,22.1120581 32.1266288,21.7020076 L24.9350758,14.5104546 L24.9350758,34.9650758 C24.9350758,35.5173605 24.4873605,35.9650758 23.9350758,35.9650758 C23.382791,35.9650758 22.9350758,35.5173605 22.9350758,34.9650758 L22.9350758,14.5593939 Z" id="Combined-Shape" fill="#FFE900" fillRule="nonzero"></path>
                      </g>
                  </g>
              </g>
          </svg>
      </React.Fragment>
  );
}
