import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import ReduxStore from '../forms/common/redux_store.jsx';

class NspStripeModal extends ReduxStore(React.Component) {
    constructor(props) {
        super(props);
        this.state = {
            stripeKey: props.stripekey,
            stripeURL: props.stripeurl,
            currency: props.currency,
            cost: props.cost,
            isenabled: this.store.getState()[props.store_enabled_model],
        };
        this.store_enabled_model = props.store_enabled_model;
        this.store.subscribe(() => {
            this.update_value_from_store();
        });
    }

    update_value_from_store() {
        const new_value = this.store.getState()[this.store_model];
        this.setState({cost: new_value, isenabled: this.store.getState()[this.store_enabled_model]});
        this.enablePayment(this.store, this.store_enabled_model);
    }


    enablePayment(store, store_enabled_model) {
        if (window.uploadComponent) {
            const filesSubmitted = window.uploadComponent.uploader.methods._netUploadedOrQueued;
            // stipe payment element
            const paymentElement = document.getElementById('stripeButton');
            if (filesSubmitted >= 1 && store.getState()[store_enabled_model]) {
                paymentElement.removeAttribute('disabled');
                paymentElement.innerHTML = LOCALIZED_STRS.get().supplierPay.complete;
            } else {
                paymentElement.setAttribute('disabled', 'disabled');
            }
        }
    }

    componentDidMount() {
        // StripeCheckout is loaded from Stripe's CDN for security purposes, so disable no-undef
        // eslint-disable-next-line no-undef
        const handler = StripeCheckout.configure({
            key: document.getElementById('stripeKey').value,
            image: 'https://cdn.rapidratings.com/assets/v2.1.0/imgs/rapidratings_icon_140.png',
            locale: 'auto',
            token: function(token) {
                // Get the token ID to your server-side code for use.
                document.getElementById('stripeToken').value = token.id;

                perform_post(
                    document.getElementById('stripeUrl').value,
                    {'payment_token': token.id}
                );
            },
            closed: function() {
                // check if Stripe token exists
                const stripePaymentToken = document.getElementById('stripeToken').value;
                if (stripePaymentToken) {
                    // start loading animation "Hang tight..."
                    document.getElementById('modal-loading').checked = true;
                } else {
                    // stipe payment element
                    const paymentElement = document.getElementById('stripeButton');
                    // re-enable the payment button
                    paymentElement.removeAttribute('disabled');
                    // set button text to "Pay with card and complete"
                    paymentElement.innerHTML = LOCALIZED_STRS.get().supplierPay.complete;
                }
            }
        });

        document.getElementById('nsp--submission').addEventListener('submit', (e) => {
            e.preventDefault();
            // Open Checkout with further options:
            handler.open({
                name: LOCALIZED_STRS.get().supplierPay.name,
                amount: this.state.cost,
                currency: this.state.currency,
                zipCode: false,
                email: '',
                billingAddress: false,
                allowRememberMe: false
            });
        });

        // Close Checkout on page navigation:
        window.addEventListener('popstate', () => {
            handler.close();
        });

        // stripe token's hidden input field
        const stripeInput = document.getElementById('stripeToken');
        // form submission button
        const submissionElement = document.getElementById('button_submit_submission');

        const the_store = this.store;
        const the_store_enabled_model = this.store_enabled_model;
        const odd_enablePayment = this.enablePayment;

        window.onload = function() {
            window.uploadComponent.uploader.on('onStatusChange', (id, oldStatus, newStatus) => {
                if ( (newStatus == 'submitted') || (newStatus == 'canceled') ) {
                    odd_enablePayment(the_store, the_store_enabled_model);
                } else if (newStatus == 'upload failed') {
                    document.getElementById('modal-loading').checked = false;
                }
            });
        };

        function submitFiles () {
            const stripePaymentToken = document.getElementById('stripeToken').value;
            if (stripePaymentToken) {
                // begin uploading files
                window.uploadComponent.uploader.methods.uploadStoredFiles();
            }
        }

        function perform_post(url, data, onsuccess, onerror) {
            const headers = new Headers();
            headers.append('X-CSRFToken', window.uploadComponent.csrftoken());
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: headers
            }).then(async (results) => {
                if(results.ok) {
                    // add listener for file uploads
                    window.uploadComponent.uploader.on('onStatusChange', (id, oldStatus, newStatus) => {
                        if (newStatus == 'upload successful') {
                            const totalFiles = window.uploadComponent.uploader.methods._netUploadedOrQueued;
                            const successfulUploads = window.uploadComponent.uploader.methods._netUploaded;
                            // check the number of queued and successfully uploaded files are equal
                            if (totalFiles === successfulUploads) {
                                // submit form
                                document.getElementById('nsp--submission').submit();
                            }
                        }
                    });
                    // begin uploading files
                    submitFiles();
                } else if (results.status / 100 == 5) {
                    if (onerror) {
                        onerror();
                    }
                } else {
                    const result = await results.json();
                    if (onerror) {
                        onerror(result);
                    }
                }
            }).catch((err) => {
                if (onerror) {
                    onerror();
                }
            });

        }
    }

    render() {
        return (
            <React.Fragment>
                <input id="stripeToken" type="hidden" name="stripe_token" value="" />
                <input id="stripeKey" type="hidden" name="stripe_key" value={this.state.stripeKey} />
                <input id="stripeUrl" type="hidden" name="stripe_url" value={this.state.stripeURL} />
            </React.Fragment>
        );
    }
}

export default NspStripeModal;
