import React, {Component} from 'react';
import ReactDOM from 'react-dom';


class Pill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text,
            color: this.props.color,
            removable: this.props.removable || false
        };
    }

    render() {
        return (
            <span className="pill" style={{ background: this.props.color }}>
                <span className="pill--value">{this.state.text}</span>
                { this.state.removable &&
                    <button type="button" className="pill--delete">
                        <i className="icon icon--close" aria-hidden="true"></i>
                    </button>
                }
            </span>
        );
    }
}


export default Pill;
