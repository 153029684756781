import React from 'react';
import Card from './card.jsx';
import styled from 'styled-components';
import moment from 'moment';
import {csrftoken} from '../../utils/csrf.jsx';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';

const TextExtraLarge = styled.h2`
    font-size: 48px !important;
    font-weight: bold !important;
    line-height: 56px;
    margin-bottom: 8px;
`;

const TextEmpty = styled.h2`
    font-size: 26px;
    line-height: 32px;
    max-width: 318px;
    margin-bottom: 8px;
`;

const Title = styled.p`
    font-weight: 600;
    margin-bottom: 8px;
`;

const StyledParagraph = styled.p`
    max-width: 368px;
    color: ${props => props.color ? props.color : '#828C94'};
    font-size: 16px;
    ${props => props.isCapitalized ?  'text-transform: capitalize' : ''};
    line-height: 24px;
    margin-bottom: 8px;
    position: relative;
    z-index: 1;
`;

const StyledDate = styled.p`
    font-weight: 400;
    color: #828C94;
    font-size: 14px;
    line-height: 16px;
    margin-top: 24px;
`;

const StyledLink = styled.a`
    color: #fff;
    background-color: #00afd7;
    border: 1px solid #00afd7;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-family: Source Sans Pro,sans-serif;
    font-size: 12px;
    display: inline-block;
    min-width: 95px;
    cursor: pointer;
    transition: background-color .2s linear;
    outline: none;
    margin: 16px 4px 0 0;

    :hover {
        background-color: #0085a4;
        transition: background-color .2s linear;
    }
`;

function NumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function ListSummary(props) {

    const count = props.info_data.companies;
    const updated = props.info_data.last_update;

    return (
        <Card
            url={props.url}
            isOrgList={props.isOrgList !== undefined ? true : false }
            disabled = {count > 0 ? false : true}
        >
            <Title>
                {props.title}
            </Title>
            {
              count > 0
                ? <TextExtraLarge>{NumberWithCommas(count)}</TextExtraLarge>
                : <TextEmpty>{LOCALIZED_STRS.get().noCompaniesList}</TextEmpty>
            }
            {count > 0 &&
                <StyledParagraph
                    color="#4a4a4a"
                    isCapitalized
                >
                    {count == 1
                        ? LOCALIZED_STRS.get().company
                        : LOCALIZED_STRS.get().companies
                    }
                </StyledParagraph>
            }
            <StyledParagraph>
                {props.description}
            </StyledParagraph>
            {count > 0
                ?
                <StyledDate>
                    <span>{LOCALIZED_STRS.get().lastUpdated} </span>
                    {
                        moment(updated, 'YYYY-MM-DD HH:mm:ss')
                            .format('MMMM Do YYYY')
                            .toString()
                    }
                </StyledDate>
                :
                props.isorglist !== undefined
                    ? ( <StyledLink
                            href="https://help.rapidratings.com/article/cig98li2rg-my-health-marks-list"
                            target="_blank"
                            rel="noopener"
                        >
                        {LOCALIZED_STRS.get().learnMore}
                        </StyledLink>
                    )
                    : ( <StyledParagraph
                            color="#4a4a4a"
                        >
                        {LOCALIZED_STRS.get().noCompaniesGetStarted}
                        </StyledParagraph>
                    )
            }
        </Card>
    );
  }

export default ListSummary;
