const LOCALIZED_STRS = {
    get: key => LOCALIZED_STRS[LOCALIZED_STRS.lang()] || {},
    lang: () => document.documentElement.lang || 'en',
    settings: {
        currency: {
            defaults: ['USD', 'GBP', 'EUR']
        }
    },
    en: {
        ticker: 'Ticker',
        name: 'Name',
        company_name: 'Company Name',
        company_country: 'Company Country',
        reference_id: 'Reference ID (optional)',
        reference_id_caption: 'A reference ID is a unique identifier assigned to any counterparty',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        phone: 'Phone number',
        logout: 'Logout',
        cancel: 'Cancel',
        submit: 'Submit',
        add: 'Add',
        delete: 'Delete',
        back: 'Back',
        close: 'Close',
        skip: 'Skip to main content',
        welcome: 'Welcome',
        start: 'Start',
        searchText: 'Search',
        website: 'website',
        address: 'address',
        companySize: 'company size',
        yearEstablished: 'year established',
        results: 'results',
        below: 'below',
        improveProfile: 'improve this profile',
        showing: 'Showing',
        all: 'all',
        displayAll: 'Display all',
        showingAll: 'Showing all',
        noCompanies: 'No companies found',
        questions: 'Questions?',
        findOutMore: 'Find out more',
        critical: 'Critical',
        criticality: 'Criticality',
        non_critical: 'Non-Critical',
        or: 'or',
        contactUs: 'Contact us',
        aboutUs: 'About Us',
        request: 'Request',
        save: 'Save',
        expected_rating_update : 'Approximate Update Expected: ',
        suggest_rating_update: 'Suggest Rating Update',
        submit_rating_update: 'Are new financial statements available for this company? If yes, please provide a link:',
        submit_rating_update_info: 'Our system requires full financial statements to update a rating. Preliminary earnings announcements are not sufficient. A public company can take up to 2 to 3 months to release full financial statements of their latest reporting period.',
        provided_url : 'Provided Link',
        faq: 'Frequently Asked Questions',
        register: 'Register',
        confirm_company: 'Confirm Company',
        thank_you: 'Thank you, your request has been successfully submitted.',
        feedback: 'Feedback',
        feedback_provide: 'What can we change to improve this profile?',
        feedback_instruction: 'Please enter your feedback about the ',
        feedback_thanks: 'Thank you for your feedback',
        feedback_sent: 'You have sent the following feedback for ',
        agree: 'I agree to the',
        tcs: 'Terms & Conditions',
        general_error: 'We apologize, but it looks like an unexpected error has occurred. If the issue persists, contact Client Services at support@rapidratings.com or +1 (646) 233-4563.',
        date_error: 'Please enter in MM/DD/YYYY date format or select a date from the calendar.',
        please_select: 'Please select an option from the dropdown.',
        exchange: 'Exchange',
        menu: 'Menu',
        browse: 'Browse',
        browse_for_files: 'Browse for files',
        fhr: 'FHR',
        full_fhr: 'Full FHR',
        chs: 'CHS',
        sim: 'Simulated FHR',
        average_fhr: 'Avg.FHR',
        average_chs: 'Avg.CHS',
        of: 'of',
        of_all_results: 'of all results',
        add_request: 'Add request',
        all_fields_required: 'All fields are required unless otherwise stated',
        requests: 'requests',
        new: 'New',
        refresh: 'Refresh',
        if_refresh: 'If refresh',
        report_type: 'Report type',
        dynamic_report_header: 'Dynamic Peer Benchmarking',
        dynamic_report_subheader: 'Benchmark your company against others in the full FHR Benchmarking Database.',
        dynamic_report_create:'Create benchmark report for',
        dynamic_report_description_1: '1. Select the Sector and NAICS you want to benchmark',
        dynamic_report_description_2: '2. Define the additional criteria you want to benchmark against',
        dynamic_report_minimum: 'A minimum of 5 companies are needed to continue',
        dynamic_report_run: 'Run Peer Benchmark Report',
        dynamic_report_sector_helper: 'Enter a business keyword or NAICS code to select a sector classification',
        dynamic_benchmark_header: 'Your Peer Benchmark Report is being created.',
        dynamic_benchmark_helper: 'Once this has been completed, a detailed PDF of the report will download automatically.',
        sectorOrNaics: 'Sector or NAICS',
        companySizeSales: 'Company Size (Sales USD)',
        location: 'Location',
        companyType: 'Company Type',
        benchmarking: 'Benchmarking',
        reportGenerated: 'Your previous report has been generated. To generate a new report',
        against: 'against',
        company: 'company',
        companies: 'companies',
        filteredRequests: 'filtered requests',
        filteredCompanies: 'filtered companies',
        permissions_assigned: 'Permissions assigned',
        permissions_removed: 'Permissions removed',
        permissions_error: 'Permissions could not be created',
        permission_add: 'Add permission',
        permission_delete: 'Delete permission',
        permission_added: 'Permission has been successfully added',
        permission_deleted: 'Permission has been successfully deleted',
        permission_assign_header: 'Assign new HealthMark permission',
        permissions_confirmation: 'Are you sure you want to delete HealthMark permissions for',
        permissions_existing_header: 'Existing HealthMark Permissions',
        permissions_existing_subheader: 'View and manage assigned permissions',
        permissions_none: 'No existing permissions assigned',
        downloadHealtMarkChildren: 'Download list of Child Companies',
        downloadFullList: 'Download Full List',
        noCompaniesFound: 'No companies found for your search',
        noMatchingResults: 'We were unable to find any matching results',
        noPermissions: 'There are no existing permissions',
        noPermissionsAssigned: 'No existing permissions assigned',
        downloadInProgress: 'Download in progress',
        bookmarkAdd: 'Bookmark to my HealthMark List',
        bookmarkRemove: 'Remove from my HealtMark List',
        removeSelected: 'Remove Selected',
        learnMore: 'Learn More',
        noCompaniesList: 'There are currently no companies in this list.',
        noCompaniesGetStarted: 'To get started, you can add any company where you see the ‘+’ button.',
        updateLists: 'Update Lists',
        assignList: 'Assign to list',
        bulkProcessing: 'Your Supplier list is being processed.',
        bulkLoadingHelper: 'This could take a few minutes so please do not close your browser window. Once matching has been completed an updated list will download automatically.',
        bulkInputSubheader: 'Assign Org to display matched companies (Optional)',
        bulkInputHelper: 'Start typing to get a list of Org matches. All matched companies will automatically be added to the Org\'s HealthMark List.',
        listsUpdateSuccess: 'Your lists have been updated',
        listsUpdateFailed: 'We could not update your lists',
        lastUpdated: 'Last Updated',
        booleans: {
            no: 'No',
            yes: 'Yes',
            true: 'True',
            false: 'False',
            if_yes: 'If yes',
            if_no: 'If no'
        },
        unspecified: 'Unspecified',
        noRequests: 'No requests',
        clearFilters: 'Clear filters',
        company_info: 'Company Information',
        supplier_details: 'Supplier Details',
        relationship_manager_details: 'Relationship Manager Details',
        supplierPay: {
            name: 'Risk Management Program',
            complete: 'Pay with card and complete'
        },
        simulatedFhr: {
            better: 'With abnormal items excluded, the company’s FHR is marginally better.',
            worse: 'With abnormal items excluded, the company’s FHR is marginally worse.',
            nochange: 'No abnormal items, so no change to the Simulated FHR score'
        },
        preposition: 'and',
        found: 'Rated companies found',
        search: {
            question: 'Can\'t find a public company?',
            action: 'Request Public Addition',
            comp_question: 'Are you looking for a company that has not been designated an FHR?',
            comp_action: 'Try our company HealthMark search',
            public_comp_question: 'Looking for a Public Company?',
            public_comp_action: 'REQUEST A PUBLIC FINANCIAL HEALTH REPORT',
            failed: 'There seems to be a problem, please try again.',
            loading: 'Loading, please wait.',
            by: 'By',
            empty: 'Sorry, Nothing matches your company FHR search. Please try an alternative search term.',
        },
        news: {
            empty: 'Our provider ThomsonReuters® only retains news for the last 30 days approx.',
            heading: 'Latest News',
            subHeading: 'News is only available for the last 30 days',
            othersEmpty: 'have no news in the last 30 days.',
            singularEmpty: 'has no news in the last 30 days.',
            news: 'General News',
            disasters: 'Disasters',
            mergers: 'Mergers & Acquisitions',
            bankruptcy: 'Bankruptcy',
            read: 'Read Full Article',
            readall: 'Read all news',
            readmore: 'Read More'
        },
        strengths: 'Strengths',
        concerns: 'Concerns',
        emptyStrengths: 'There are no obvious strengths',
        emptyConcerns: 'There are no obvious concerns',
        trend: 'Trend',
        monthsPassed: 'months ago',
        ratioHeader: 'Key Metrics',
        changes: 'Changes',
        financials: 'Financials',
        more: 'More',
        inputPlaceholder: 'Please select',
        risk: {
            veryhigh: 'Very High Default Risk',
            high: 'High Default Risk',
            medium: 'Medium Default Risk',
            low: 'Low Default Risk',
            verylow: 'Very Low Default Risk'
        },
        fiscal: 'fiscal',
        epd: 'Estimated Probability of Default',
        password: {
            label: 'Password',
            show: 'Show Password',
            hide: 'Hide Password',
            forgot: 'Forgot Password?'
        },
        fhrRegistration: {
            name: 'Your name',
            title: 'Job title',
            email: 'Work Email',
            phone_number: 'Phone Number',
            business_name: 'Business Name',
            website: 'Website',
            business_address: 'Business Address',
            authorized: 'I am authorized to provide Financial data',
            not_authorized: 'I am not authorized to provide Financial data',
            forward_invitation: 'Forward Invitation',
            forward_name: 'Colleague\'s Name',
            forward_email: 'Colleague\'s Email',
            provide_details: 'Provide Details',
            outdated_financials : 'My Financials are older than 12 months',
            reminder: 'Choose your preferred time to be reminded',
            steps: {
                setup: 'Setup',
                details: 'Your Details',
                confirm: 'Confirm Company',
                financials: 'Provide Financial Details'

            }
        },
        formErrors: {
            'email': 'Please enter a valid email address.',
            'capslock': 'Caps Lock is on.',
            'min-length': '',
            'max-length': 'You\'ve exceeded the maximum required characters.',
            'required': 'Please provide '
        },
        currency: {
            change: 'Change currency',
            header: 'Company reports originally in',
            exchange: 'exchange rate from',
            commongroup: 'Commonly Used',
            othergroup: 'Others'
        },
        loading: 'Please wait',
        months: {
            0: 'Jan',
            1: 'Feb',
            2: 'Mar',
            3: 'Apr',
            4: 'May',
            5: 'Jun',
            6: 'Jul',
            7: 'Aug',
            8: 'Sep',
            9: 'Oct',
            10: 'Nov',
            11: 'Dec'
        },
        indicators: {
            question: 'Action Indicator',
            a: 'These companies are the strongest, with a low short-term default risk.',
            b: 'These companies are typically marginally profitable or unprofitable but have strong balance sheets which mitigate short-term risk.',
            c: 'These companies are the weakest, and typically exhibit poor profits and weak balance sheets, resulting in high short-term default risk and lack of sustainability over the medium term.',
            d: 'These companies are typically profitable with potentially strong margins but may carry aggressive debt loads with poor liquidity, making them vulnerable to any operational shocks such as loss of a key client.',
            veryhigh: {
                veryhigh: {
                    label: 'c',
                    response: '',
                    action: 'unsustainable',
                    answer: 'Take urgent steps to mitigate.'
                },
                high: {
                    label: 'c',
                    response: '',
                    action: 'troubling',
                    answer: 'We see significant concerns. Begin risk mitigation.'
                },
                medium: {
                    label: 'b',
                    response: '',
                    action: 'tolerable',
                    answer: 'Acceptable level of risk but room to improve.'
                },
                mediumupper: {
                    label: 'b',
                    response: '',
                    action: 'acceptable',
                    answer: 'Unlikely to cause any problems.'
                },
                low: {
                    label: 'b',
                    response: '',
                    action: 'acceptable',
                    answer: 'Unlikely to cause any problems.'
                },
                verylow: {
                    label: 'b',
                    response: '',
                    action: 'acceptable',
                    answer: 'Unlikely to cause any problems.'
                }
            },
            high: {
                veryhigh: {
                    label: 'c',
                    response: '',
                    action: 'unsustainable',
                    answer: 'Take urgent steps to mitigate.'
                },
                high: {
                    label: 'c',
                    response: '',
                    action: 'troubling',
                    answer: 'We see significant concerns. Begin risk mitigation.'
                },
                medium: {
                    label: 'b',
                    response: '',
                    action: 'tolerable',
                    answer: 'Acceptable level of risk but room to improve.'
                },
                mediumupper: {
                    label: 'b',
                    response: '',
                    action: 'acceptable',
                    answer: 'Unlikely to cause any problems.'
                },
                low: {
                    label: 'b',
                    response: '',
                    action: 'acceptable',
                    answer: 'Unlikely to cause any problems.'
                },
                verylow: {
                    label: 'b',
                    response: '',
                    action: 'acceptable',
                    answer: 'Unlikely to cause any problems.'
                }
            },
            medium: {
                veryhigh: {
                    label: 'd',
                    response: '',
                    action: 'unsustainable',
                    answer: 'Take urgent steps to mitigate.'
                },
                high: {
                    label: 'd',
                    response: '',
                    action: 'troubling',
                    answer: 'We see significant concerns. Begin risk mitigation.'
                },
                medium: {
                    label: 'a',
                    response: '',
                    action: 'tolerable',
                    answer: 'Acceptable level of risk but room to improve.'
                },
                mediumupper: {
                    label: 'a',
                    response: '',
                    action: 'acceptable',
                    answer: 'Unlikely to cause any problems.'
                },
                low: {
                    label: 'a',
                    response: '',
                    action: 'strong',
                    answer: 'This is a strong company.'
                },
                verylow: {
                    label: 'a',
                    response: '',
                    action: 'strong',
                    answer: 'This is a strong company.'
                }
            },
            low: {
                veryhigh: {
                    label: 'd',
                    response: '',
                    action: 'unsustainable',
                    answer: 'Take urgent steps to mitigate.'
                },
                high: {
                    label: 'd',
                    response: '',
                    action: 'troubling',
                    answer: 'We see significant concerns. Begin risk mitigation.'
                },
                medium: {
                    label: 'a',
                    response: '',
                    action: 'tolerable',
                    answer: 'Acceptable level of risk but room to improve.'
                },
                mediumupper: {
                    label: 'a',
                    response: '',
                    action: 'acceptable',
                    answer: 'Unlikely to cause any problems.'
                },
                low: {
                    label: 'a',
                    response: '',
                    action: 'strong',
                    answer: 'This is a strong company.'
                },
                verylow: {
                    label: 'a',
                    response: '',
                    action: 'strong',
                    answer: 'This is a strong company.'
                }
            },
            verylow: {
                veryhigh: {
                    label: 'd',
                    response: '',
                    action: 'unsustainable',
                    answer: 'Take urgent steps to mitigate.'
                },
                high: {
                    label: 'd',
                    response: '',
                    action: 'troubling',
                    answer: 'We see significant concerns. Begin risk mitigation.'
                },
                medium: {
                    label: 'a',
                    response: '',
                    action: 'tolerable',
                    answer: 'Acceptable level of risk but room to improve.'
                },
                mediumupper: {
                    label: 'b',
                    response: '',
                    action: 'acceptable',
                    answer: 'Unlikely to cause any problems.'
                },
                low: {
                    label: 'a',
                    response: '',
                    action: 'strong',
                    answer: 'This is a strong company.'
                },
                verylow: {
                    label: 'a',
                    response: '',
                    action: 'very impressive',
                    answer: 'A very impressive level of health.'
                }
            }
        },
        sector: 'sector',
        reports: {
            bank_indicator: {
                name: 'Indicator',
                sort: 3
            },
            creditlimit: {
                name: 'Credit Limit',
                sort: 2
            },
            fhr: {
                name: 'FHR Report',
                sort: 1
            },
            financial_dialogue: {
                name: 'Financial Dialogue',
                sort: 0
            },
            fixed_income: {
                name: 'Fixed Income',
                sort: 2
            },
            harley_davidson: {
                name: 'Custom',
                sort: 6
            },
            liberty: {
                name: 'Custom',
                sort: 7
            },
            morgan_stanley: {
                name: 'Custom',
                sort: 8
            },
            matrix: {
                name: 'Matrix',
                sort: 9
            },
            peer_benchmark: {
                name: 'Peer Benchmark',
                sort: 3
            },
            prr: {
                name: 'PPR',
                sort: 11
            },
            fin_services: {
                name: 'Indicator',
                sort: 3
            },
            financial_statement: {
                name: 'Financial Statement',
                sort: 12
            },
            unknown: {
                name: 'Custom',
                sort: 13
            }
        },
        solicitationRequest: {
            supplier_first_name: 'Supplier first name',
            supplier_last_name: 'Supplier last name',
            supplier_email: 'Supplier email address',
            supplier_phone: 'Supplier phone number',
            relationship_manager_name: 'Relationship manager name',
            relationship_manager_email: 'Relationship manager email address',
            pending: 'Your request has been created as pending!',
            message: 'While in pending status, you will have the ability to edit or cancel the request.'
        },
        solicitationDetails: {
            disabledButton: 'Send to data entry not available when "Report type" is unspecified.',
            ndaErrorMessage: 'Please specifiy an NDA type from the dropdown.',
            companyIDErrorMessage: 'Please search for a company name.'
        },
        'date-formats': {
          'default-date-short-month': { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' },
          'default-datetime': { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC' },
          'default-date': { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }
        }
    },
    zh: {
        ticker: '断续器',
        logout: 'Logout',
        exchange: '交换',
        menu: '菜单',
        risk: {
            veryhigh: '非常高风险',
            high: '高风险',
            medium: '中等风险',
            low: '低风险',
            verylow: '非常低的风险'
        },
        fiscal: '财',
        password: {
            label: 'Password',
            show: 'Show Password',
            hide: 'Hide Password',
            forgot: 'Forgot Password?'
        },
        formErrors: {
            'email': 'Please enter a valid email address.',
            'capslock': 'Caps Lock is on.',
            'min-length': '',
            'max-length': 'You\'ve exceeded the maximum required characters.',
            'required': 'Please provide '
        },
        loading: 'Loading',
        months: {
            1: '一月',
            2: '二月',
            3: '游行',
            4: '四月',
            5: '可能',
            6: '游行',
            7: '游行',
            8: 'Aug',
            9: '游行',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec'
        }
    }
};

export default LOCALIZED_STRS;
