import React, { Component } from 'react';
import SearchGeneric from '../search-generic/index.jsx';
import {csrftoken} from '../../utils/csrf.jsx';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';
import { isEmpty } from 'lodash';

const StyledReactModal = styled(ReactModal)`
    max-width: 500px;
`;

const ModalContent = styled.div`
    margin: 16px auto;
`;

const StyledParagraph = styled.p`
    margin: 4px auto;
`;

export default class HealthMarkBulk extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formurl: props.formurl,
            orgsuggestionurl: props.orgsuggestionurl,
            job_id: '',
            errorMessages: [],
            isLoading: false,
            clearValue: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.pollForResult = this.pollForResult.bind(this);
    }

    openModal() {
        this.setState({isLoading: true});
    }

    closeModal() {
        this.setState({isLoading: false});
    }

    pollForResult (job_id) {
        const self = this;
        const csrftoken_value = csrftoken();

        fetch(`${this.state.formurl}-job?job_id=${job_id}`, {
            method: 'GET',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': csrftoken_value
            },
            credentials: 'same-origin'
        })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            if (data.status === 'success') {
                self.setState({
                    isLoading: false,
                    clearValue: true
                });
                document.location = data.result_file;
                document.getElementById('file_bulkupload').value = '';

            } else if (data.status === 'started' || data.status === 'queued') {
                setTimeout(() => {
                    self.pollForResult(job_id);
                }, 2000);
            } else {
                self.setState({
                    isLoading: false,
                    errorMessages: {
                      status: data.status,
                      errors: data.errors
                    }
                });
            }
        });
    }

    handleSubmit () {
        const self = this;
        this.setState({
            clearValue: false,
            errorMessages: [],
            isLoading: true
        });
        const formData = new FormData(this.formRef.current);
        const csrftoken_value = csrftoken();
        const xhr = new XMLHttpRequest();

        xhr.open('POST', `${this.state.formurl}`, true);
        xhr.setRequestHeader('X-CSRFToken', csrftoken_value);
        xhr.onload = function (e) {
            if (xhr.status === 200) {
                const result = JSON.parse(this.responseText);
                if (result.job_id) {
                    setTimeout(self.pollForResult(result.job_id), 1000);
                }
            } else {
                const errros = JSON.parse(this.responseText);
                self.setState({
                    isLoading: false,
                    errorMessages: errros
                });
            }
        };
        xhr.send(formData);
    }

    render() {
        return (
            <>
                <form
                    autoComplete="off"
                    ref={this.formRef}
                    onSubmit={this.handleSubmit}
                    id="org_search_form"
                    encType="multipart/form-data"
                    acceptCharset="utf-8"
                >
                    <div className="margin__vertical">
                        <input
                            id="file_bulkupload"
                            required
                            type="file"
                            name="upload_file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                    </div>
                    <StyledParagraph>
                        {LOCALIZED_STRS.get().bulkInputSubheader}
                    </StyledParagraph>
                    <SearchGeneric
                        name="org_id"
                        isformelement
                        clearValue={this.state.clearValue}
                        placeholder="Assign Org to display matched companies (Optional)"
                        searchurl={this.state.orgsuggestionurl}
                        searchobject="Org"
                        searchobjectplural="orgs"
                    >
                    </SearchGeneric>
                    <StyledParagraph className="caption--secondary">
                        {LOCALIZED_STRS.get().bulkInputHelper}
                    </StyledParagraph>
                    <ul
                        className="errors margin__vertical"
                    >
                        {
                            !isEmpty(this.state.errorMessages) &&
                            this.state.errorMessages.errors.map((errorMessage, index) => {
                                return (
                                    <li
                                        className="error"
                                        key={index}
                                    >
                                        {errorMessage}
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <hr className="margin__vertical" />
                    <div className="margin__vertical--top">
                        <button
                            type="button"
                            className="rbtn rbtn--primary rbtn--md"
                            disabled={this.state.isLoading}
                            onClick={this.handleSubmit}
                        >
                            Upload and Match
                        </button>
                    </div>
                </form>
                <StyledReactModal
                    isOpen={this.state.isLoading}
                    contentLabel="HealthMark Bulk Upload Modal"
                    className="Modal"
                    overlayClassName="Overlay"
                    shouldCloseOnOverlayClick={false}
                >
                    <div className="ReactModal__Header">
                        <h2>
                            {LOCALIZED_STRS.get().bulkProcessing}
                        </h2>
                    </div>
                    <StyledParagraph>
                        {LOCALIZED_STRS.get().bulkLoadingHelper}
                    </StyledParagraph>
                    <ModalContent>
                        <div className="please_wait_animation"></div>
                    </ModalContent>
                </StyledReactModal>
            </>
        );
    }
}
