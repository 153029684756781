import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Input from '../forms/common/input.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import CSRFToken from '../utils/csrf.jsx';
import 'formdata-polyfill';

class AddSolicitationRequestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitUrl: props.url,
            formSubmitted: false,
            formSuccess: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.form = React.createRef();
    }

    static defaultProps = {
        onSubmit: () => {},
        onBack: () => {}
    };

    resetForm = () => {
        this.setState({
            formSubmitted: false,
            formSuccess: false
        });

    };

    handleBack() {
        this.resetForm();
        this.props.onBack();
    }

    handleSubmit(event) {
        event.preventDefault();
        const self = this;
        const formData = new FormData(this.form.current);
        fetch(this.state.submitUrl, {
            method: 'POST',
            body: formData
        })
        .then((res) => {
            if (res.status == 200) {
                self.setState({
                    formSubmitted: true,
                    formSuccess: true
                });
            } else {
                self.setState({
                    formSubmitted: true,
                    formSuccess: false
                });
            }
            self.props.onSubmit();
        });
    }

    renderError() {
        return (
            <div className="solicitation_request form form--centered form--failed animated animated--fadeInTop">
                <i className="icon icon--failed-circle" aria-hidden="true"></i>
                <h3 className="margin__vertical error">{LOCALIZED_STRS.get().general_error}</h3>
                <button
                    onClick={this.handleBack}
                    className="rbtn rbtn--primary float-right"
                >
                    {LOCALIZED_STRS.get().back}
                </button>
            </div>
        );
    }

    renderSuccess() {
        return (
            <div className="solicitation_request form form--centered form--submitted animated animated--fadeInTop">
                {this.state.formSuccess &&
                    <React.Fragment>
                        <div className="margin__vertical pendingRequest"></div>
                        <h2 className="margin__vertical text__align--center">{LOCALIZED_STRS.get().solicitationRequest.pending}</h2>
                        <h3 className="margin__vertical text__align--center">{LOCALIZED_STRS.get().solicitationRequest.message}</h3>
                        <button
                            className="rbtn rbtn--primary float-right"
                            onClick={() => {
                                location.reload();
                            }}
                        >{LOCALIZED_STRS.get().close}
                        </button>
                    </React.Fragment>
                }
            </div>
        );
    }

    renderForm() {
        return (
            <form autoComplete="off" ref={this.form} onSubmit={this.handleSubmit} action={this.state.submitUrl} method="POST">
                <CSRFToken />
                <div className="row margin__vertical--top">
                    <div className="col-xs-12">
                        <div className="subheader">{LOCALIZED_STRS.get().company_info}</div>
                    </div>
                </div>
                <div className="row margin__vertical">
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="company_name"
                            value=""
                            label={LOCALIZED_STRS.get().company_name}
                            type="text"
                            name="company_name"
                            required="required"
                        />
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="company_country"
                            value=""
                            label={LOCALIZED_STRS.get().company_country}
                            type="text"
                            name="company_country"
                            required="required"
                        />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                        <Input
                            id="reference_id"
                            value=""
                            label={LOCALIZED_STRS.get().reference_id}
                            type="text"
                            name="reference_id"
                        />
                    </div>
                  </div>
                  <div className="row">
                      <div className="col-xs-12">

                        <div className="subheader">{LOCALIZED_STRS.get().supplier_details}</div>
                    </div>
                </div>
                <div className="row margin__vertical">
                    <div className="col-xs-12 col-md-4">
                        <Input
                            id="contact_first_name"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.supplier_first_name}
                            type="text"
                            name="contact_first_name"
                            required="required"
                        />
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <Input
                            id="contact_last_name"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.supplier_last_name}
                            type="text"
                            name="contact_last_name"
                            required="required"
                        />
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <Input
                            id="contact_email"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.supplier_email}
                            type="email"
                            name="contact_email"
                            required="required"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="subheader">{LOCALIZED_STRS.get().relationship_manager_details}</div>
                    </div>
                </div>
                <div className="row margin__vertical">
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="relation_manager_name"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.relationship_manager_name}
                            type="text"
                            name="relation_manager_name"
                            required="required"
                        />
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="relation_manager_email"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.relationship_manager_email}
                            type="email"
                            name="relation_manager_email"
                            required="required"
                        />
                    </div>
                </div>
                <div className="form--actions float-right">
                    <button
                        type="submit"
                        name="submit_request"
                        className="rbtn rbtn--primary"
                    >
                        {LOCALIZED_STRS.get().add}
                    </button>
                </div>
            </form>
        );
    }

    render() {
      return (
          <div className="request_public_addition">
            { !this.state.formSubmitted && this.renderForm()}
            { (this.state.formSubmitted && this.state.formSuccess) && this.renderSuccess()}
            { (this.state.formSubmitted && !this.state.formSuccess) && this.renderError()}
        </div>
      );
    }
}

class AddSolicitationRequestForm2 extends AddSolicitationRequestForm {
    renderForm() {
        return (
            <form autoComplete="off" ref={this.form} onSubmit={this.handleSubmit} action={this.state.submitUrl} method="POST">
                <CSRFToken />
                <div className="row margin__vertical--top">
                    <div className="col-xs-12">
                        <div className="subheader">{LOCALIZED_STRS.get().company_info}</div>
                    </div>
                </div>
                <div className="row margin__vertical">
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="company_name"
                            value=""
                            label={LOCALIZED_STRS.get().company_name}
                            type="text"
                            name="company_name"
                            required="required"
                        />
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="company_country"
                            value=""
                            label={LOCALIZED_STRS.get().company_country}
                            type="text"
                            name="company_country"
                            required="required"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Input
                            id="reference_id"
                            value=""
                            label={LOCALIZED_STRS.get().reference_id}
                            type="text"
                            name="reference_id"
                            helpText={LOCALIZED_STRS.get().reference_id_caption}
                        />
                    </div>
                  </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="subheader">{LOCALIZED_STRS.get().supplier_details}</div>
                    </div>
                </div>
                <div className="row margin__vertical">
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="contact_first_name"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.supplier_first_name}
                            type="text"
                            name="contact_first_name"
                            required="required"
                        />
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="contact_last_name"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.supplier_last_name}
                            type="text"
                            name="contact_last_name"
                            required="required"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="contact_email"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.supplier_email}
                            type="email"
                            name="contact_email"
                            required="required"
                        />
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="contact_phone"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.supplier_phone}
                            type="text"
                            name="contact_phone"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="subheader">{LOCALIZED_STRS.get().relationship_manager_details}</div>
                    </div>
                </div>
                <div className="row margin__vertical">
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="relation_manager_name"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.relationship_manager_name}
                            type="text"
                            name="relation_manager_name"
                            required="required"
                        />
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Input
                            id="relation_manager_email"
                            value=""
                            label={LOCALIZED_STRS.get().solicitationRequest.relationship_manager_email}
                            type="email"
                            name="relation_manager_email"
                            required="required"
                        />
                    </div>
                </div>
                <div className="form--actions float-right">
                    <button
                        type="submit"
                        name="submit_request"
                        className="rbtn rbtn--primary"
                    >
                        {LOCALIZED_STRS.get().add}
                    </button>
                </div>
            </form>
        );
    }
}
class AddSolicitationRequestModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false
        };
        this.form = React.createRef();
    }

    static defaultProps = {
        modal_id: 'modal-request'
    };

    onSubmit = () => {
        this.setState({
            formSubmitted: true
        });
    };

    onClose = () => {
        this.setState({
            formSubmitted: false
        });
        this.form.current.resetForm();
    };

    onBack = () => {
        this.setState({
            formSubmitted: false
        });
    };

    getForm() {
        return (
            <AddSolicitationRequestForm
                ref={this.form}
                url={this.props.url}
                onSubmit={this.onSubmit}
                onBack={this.onBack}
            />
        );
    }

    render() {
        return (<div>
            <input className="modal-state" id={this.props.modal_id} type="checkbox"/>
            <div className="rrModal animated animated--fadeIn">
                <label className="modal__bg" htmlFor={this.props.modal_id} onClick={this.onClose}></label>
                <div className="modal__inner">

                    <div className="modal__header margin__vertical--bottom">
                        <React.Fragment>
                            {!this.state.formSubmitted &&
                                <React.Fragment>
                                    <h1>{LOCALIZED_STRS.get().add_request}</h1>
                                </React.Fragment>
                            }
                            <label className="modal__close" htmlFor={this.props.modal_id} onClick={this.onClose}></label>
                        </React.Fragment>
                    </div>
                    <div className="modal__content">
                        {this.getForm()}
                    </div>
                </div>
            </div>
        </div>);
    }
}

class AddSolicitationRequestModal2 extends AddSolicitationRequestModal {
    getForm() {
        return (
            <AddSolicitationRequestForm2
                ref={this.form}
                url={this.props.url}
                onSubmit={this.onSubmit}
                onBack={this.onBack}
            />
        );
    }
}

export default AddSolicitationRequestModal;
export {AddSolicitationRequestForm, AddSolicitationRequestForm2, AddSolicitationRequestModal2};
