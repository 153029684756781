import React from 'react';
import styled from 'styled-components';
import { PersonalListIcon, OrgListIcon, Arrow } from './icons.jsx';

const Card = styled.div`
    background-color: ${props => props.disabled === true ? '#ECEEF2' : '#FFF'};
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 12px;
    min-height: 256px;
    border: 1px solid  rgba(199, 210, 219, 0.7);
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(199,210,219,.4);
    transition: box-shadow 0.2s ease-in;
    position: relative;
    width: 100%;
    overflow: hidden;

    .arrow {
      opacity: 0;
      transition: opacity 0.2s ease-in;
    }

    &:hover {
        box-shadow: ${props => props.disabled === true
          ? '0 1px 3px 0 rgba(199,210,219,.4)'
          : '0 1px 10px 3px rgba(199,210,219,.4)'
        };
        cursor: ${props => props.disabled === true ? 'default' : 'pointer'};

        .arrow {
          opacity: ${props => props.disabled === true ? '0' : '1'};
        }
    }

    @media screen and (min-width: 75em) {
        padding: 32px;
        min-height: 272px;
    }
`;

const ArrowContainer = styled.div`
    position: absolute;
    bottom: 32px;
    right: 32px;
`;

const IconContainer = styled.div`
    position: absolute;
    top: -52px;
    right: -44px;
    width: 200px;
    height: 200px;

    @media screen and (min-width: 75em) {
      top: -90px;
      right: -60px;
      width: 300px;
      height: 300px;
    }

    svg {
      width: 100%;
      height: 100%;
    }

`;

function handleKeyPress(event, url) {
  if (event.key == 'Enter') {
    location.assign(url);
  }
}

export default function ListCard({
  children,
  ...props
}) {
  return (
    <Card
      id={`healthmark:list:${props.isOrgList === true ? 'master' : 'user'}`}
      disabled={props.disabled}
      tabIndex="0"
      onClick={() => {
        props.disabled === false && location.assign(
            props.url
        );
      }}
      onKeyPress={() => {
        props.disabled === false && handleKeyPress(event, props.url);
      }}
    >
      <div className="pointer-events-none">
        <IconContainer>
          {props.isOrgList === true
            ? <OrgListIcon />
            : <PersonalListIcon />
          }
        </IconContainer>
        <ArrowContainer
          className="arrow"
        >
          <Arrow />
        </ArrowContainer>
        {children}
      </div>
    </Card>
  );
}
