import React from 'react';

export function MainIcon() {
    return (
      <svg width="280" height="280" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <circle fill="#F4F6F9" cx="140" cy="140" r="140" />
            <g opacity=".408">
              <path
                d="M198.58 213.12H82.403c-3.458 0-6.287-2.83-6.287-6.288V69.416c0-3.459 2.83-6.287 6.287-6.287h116.179c3.458 0 6.287 2.828 6.287 6.287v137.416c0 3.458-2.83 6.288-6.287 6.288z"
                fill="#FFF"
              />
              <path
                fill="#DAE3E8"
                opacity=".603"
                d="M202.835 155.62L77.603 131.604v79.416l124.808-.307z"
              />
              <path
                d="M78.325 179.15c.191-.097.395-.15.607-.15h123.726v-26.125H78.325v26.276zm0 5.7v22.064c0 2.237 1.84 4.077 4.077 4.077h116.179c2.237 0 4.077-1.84 4.077-4.077V185H78.932a1.32 1.32 0 01-.607-.15zm0-37.225h124.333v-26.25H78.325v26.25zm0-31.5h124.333V87.25H78.325v28.875zm0-34.125h124.333V69.498a4.095 4.095 0 00-4.077-4.077H82.402a4.095 4.095 0 00-4.077 4.077V82zm120.256 133.412H82.402c-4.679 0-8.498-3.82-8.498-8.498V69.498c0-4.68 3.82-8.498 8.498-8.498h116.179c4.68 0 8.498 3.818 8.498 8.498v137.416c0 4.679-3.82 8.498-8.498 8.498z"
                fill="#98A4AE"
                fillRule="nonzero"
              />
              <path
                d="M134.735 105.625h52.78c1.084 0 1.964-1.175 1.964-2.625s-.88-2.625-1.965-2.625h-52.78c-1.085 0-1.964 1.175-1.964 2.625s.88 2.625 1.965 2.625zm-44.21 0h11.903c1.085 0 1.965-1.175 1.965-2.625s-.88-2.625-1.965-2.625H90.524c-1.085 0-1.965 1.175-1.965 2.625s.88 2.625 1.965 2.625zm22.104 0h11.904c1.085 0 1.965-1.175 1.965-2.625s-.88-2.625-1.965-2.625H112.63c-1.085 0-1.965 1.175-1.965 2.625s.88 2.625 1.965 2.625zM134.148 137.125h37.033c.761 0 1.378-1.175 1.378-2.625s-.617-2.625-1.378-2.625h-37.033c-.761 0-1.378 1.175-1.378 2.625s.617 2.625 1.378 2.625zm-43.624 0h11.904c1.085 0 1.965-1.175 1.965-2.625s-.88-2.625-1.965-2.625H90.524c-1.085 0-1.965 1.175-1.965 2.625s.88 2.625 1.965 2.625zm22.105 0h11.904c1.085 0 1.965-1.175 1.965-2.625s-.88-2.625-1.965-2.625H112.63c-1.085 0-1.965 1.175-1.965 2.625s.88 2.625 1.965 2.625zM134.735 168.625h52.78c1.084 0 1.964-1.175 1.964-2.625s-.88-2.625-1.965-2.625h-52.78c-1.085 0-1.964 1.175-1.964 2.625s.88 2.625 1.965 2.625zm-44.21 0h11.903c1.085 0 1.965-1.175 1.965-2.625s-.88-2.625-1.965-2.625H90.524c-1.085 0-1.965 1.175-1.965 2.625s.88 2.625 1.965 2.625zm22.104 0h11.904c1.085 0 1.965-1.175 1.965-2.625s-.88-2.625-1.965-2.625H112.63c-1.085 0-1.965 1.175-1.965 2.625s.88 2.625 1.965 2.625zM134.455 200.125h45.261c.93 0 1.685-1.175 1.685-2.625s-.754-2.625-1.685-2.625h-45.261c-.93 0-1.685 1.175-1.685 2.625s.754 2.625 1.685 2.625zm-43.93 0h11.903c1.085 0 1.965-1.175 1.965-2.625s-.88-2.625-1.965-2.625H90.524c-1.085 0-1.965 1.175-1.965 2.625s.88 2.625 1.965 2.625zm22.104 0h11.904c1.085 0 1.965-1.175 1.965-2.625s-.88-2.625-1.965-2.625H112.63c-1.085 0-1.965 1.175-1.965 2.625s.88 2.625 1.965 2.625z"
                fill="#98A4AE"
                fillRule="nonzero"
              />
            </g>
            <g transform="translate(127 129)">
              <path
                d="M43.171 48.161l35.696 35.696a2.757 2.757 0 003.899-3.899L47.07 44.262a2.757 2.757 0 00-3.899 3.9z"
                fill="#D9DEE3"
                fillRule="nonzero"
              />
              <rect
                fill="#FFF57D"
                transform="rotate(45 76.561 79.318)"
                x="59.561"
                y="70.318"
                width="34"
                height="18"
                rx="5.514"
              />
              <path
                d="M73.637 60.798L95.08 82.242a5.514 5.514 0 010 7.798l-7.798 7.798a5.514 5.514 0 01-7.798 0L58.041 76.394a5.514 5.514 0 010-7.798l7.798-7.798a5.514 5.514 0 017.798 0zm-3.899 3.9l-7.798 7.797 21.444 21.444 7.798-7.798-21.444-21.444z"
                fill="#D9DEE3"
                fillRule="nonzero"
              />
              <circle fill="#FFF" cx="35.04" cy="35.04" r="33.083" />
              <circle fill="#EDF1F5" cx="35.04" cy="35.04" r="22.055" />
              <path
                d="M35.04 69.502C16.007 69.502.578 54.072.578 35.04.578 16.007 16.008.578 35.04.578c19.033 0 34.462 15.43 34.462 34.462 0 19.033-15.43 34.462-34.462 34.462zm0-4.762c16.403 0 29.7-13.297 29.7-29.7s-13.297-29.7-29.7-29.7-29.7 13.297-29.7 29.7 13.297 29.7 29.7 29.7z"
                fill="#D9DEE3"
                fillRule="nonzero"
              />
            </g>
          </g>
      </svg>
    );
  }
