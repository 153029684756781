import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import PropTypes from 'prop-types';
import ReportType from './reportType.jsx';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

class ReportType2 extends ReportType {
  setInitial(reportType, ndaRequired, ndaType) {
        // check if the provided defaultNdaType is within the reportOptions
        this.state.reportOptions.some((e) => {
            if (e.value === ndaType) {
                this.setState({
                    selectedOption: e
                });
            }
        });
    }
  
  handleChange = (selectedOption) => {
      this.setState({ selectedOption });
  }

    render() {
        const { reportOptions, selectedOption, readonly } = this.state;
        const customStyles = {
            container: (provided, state) => ({
              ...provided,
              display: 'inline-block',
              minWidth: 250,
              verticalAlign: 'top'
          }),
          singleValue: (provided, state) => ({
              ...provided,
              paddingTop: 2,
              paddingBottom: 2
          })
        };
        return (
            <React.Fragment>
                <fieldset
                    className="radio-group radio-group__horizontal"
                    disabled={readonly}
                >
                    <span>NDA:</span>
                    <label htmlFor="true">
                        <input
                            id="true"
                            type="radio"
                            name="nda_required"
                            value="true"
                            checked={this.state.openNdaOptions}
                            onChange={this.openNdaOptions.bind(this, true)}
                        />
                        <span>{LOCALIZED_STRS.get().booleans.yes}</span>
                    </label>
                    <label htmlFor="false">
                        <input
                            id="false"
                            type="radio"
                            name="nda_required"
                            value="false"
                            checked={this.state.openNdaOptions === false}
                            onChange={this.openNdaOptions.bind(this, false)}
                        />
                        <span>{LOCALIZED_STRS.get().booleans.no}</span>
                    </label>
                    { this.state.openNdaOptions &&
                        <div className="selectDropdown selectDropdown--inline">
                            <div className="selectDropdown__prefix">{LOCALIZED_STRS.get().booleans.if_yes}:</div>
                            <Select
                                inputId="select_nda_type"
                                name="nda_type"
                                styles={customStyles}
                                value={selectedOption}
                                onChange={this.handleChange}
                                options={reportOptions}
                                isDisabled={readonly}
                             />
                             <div className="selectDropdown__error">
                                 <div className="errors">
                                     <p className="error">{LOCALIZED_STRS.get().solicitationDetails.ndaErrorMessage}</p>
                                 </div>
                             </div>
                        </div>
                    }
                </fieldset>
            </React.Fragment>
        );
    }
}

ReportType2.propTypes = {
    reportOptions: PropTypes.object,
    defaultNdaRequired: PropTypes.string,
    defaultNdaType: PropTypes.string
};

export default ReportType2;
