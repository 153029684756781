import FastClick from './fastclick.jsx';

let activeReveal = null;

class Reveal {
    constructor(ele) {
        this.ele = ele;
        this.locateRevealAndHide();
    }
    locateRevealAndHide() {
        this.revealEle = [...document.querySelectorAll(`[reveal="${this.ele.getAttribute('revealer')}"]`)][0];
        if (typeof this.revealEle !== 'undefined') {
            this.revealEle.classList.add('revealer');
            this.revealEle.classList.add('animated');
            this.globalToggle = this.ele.getAttribute('revealer-global-toggle') || 'on';
            this.targetToggle = this.ele.getAttribute('revealer-toggle') || null;
            this.onClickHandler();
        }
    }
    toggleClasses() {
        this.revealEle.classList.toggle('revealer--show');
        this.revealEle.classList.toggle('animated--fadeInLeft');
        if (document.querySelector('#content') !== null) {
            const parentAccordion = this.ele.closest('snapshot, summary, li');
            parentAccordion.classList.toggle('revealer--active');
        }
        this.ele.classList.toggle('revealer--active');
        this.revealElements = [...document.querySelectorAll(`[revealer="${this.ele.getAttribute('revealer')}"]`)][0];
        this.revealElements.classList.toggle('revealer--active');
        try {
            [...document.querySelectorAll(`[revealer-marker="${this.ele.getAttribute('revealer')}"]`)][0].classList.toggle('active');
        } catch (e) {
            console.log('');
        }
    }
    onClick(track) {
        const add_tracking = track || false;
        if (add_tracking) {
            const focusable=document.getElementsByClassName('fullinfo');
            focusable[0].setAttribute('tabindex','0');
            focusable[0].focus();
        }
        if (this.globalToggle === 'off') {
            this.toggleClasses();
        } else {
            if (activeReveal !== null) {
                activeReveal.toggleClasses();
            }
            this.toggleClasses();
            activeReveal = this;
        }
    }
    onClickHandler() {
        if (this.ele.getAttribute('reveal-active')) {
            this.onClick(false);
        }
        FastClick.apply(this.ele, () => {
            this.onClick(true);
        });
    }
}

{
    document.revealer = new Map();
    document.addEventListener('DOMContentLoaded', () => {
        [...document.querySelectorAll('[revealer]')].forEach(ele => {
          document.revealer.set(ele.getAttribute('revealer'), new Reveal(ele));
        }, this);
    });
}

export default Reveal;
