import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import PropTypes from 'prop-types';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

class ReportType extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            reportOptions: JSON.parse(props.reportoptions),
            openNdaOptions: props.defaultndarequired.toLowerCase() === 'true',
            selectedOption: null,
            defaultReportType: props.defaultreporttype,
            defaultNdaType: props.defaultndatype,
            readonly: props.readonly !== undefined ? true : false
        };
        this.openNdaOptions = this.openNdaOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setInitial = this.setInitial.bind(this);
        this.formValidation = this.formValidation.bind(this);
    }

    componentDidMount() {
        this.setInitial(this.state.defaultReportType, this.state.defaultNdaRequired, this.state.defaultNdaType);
        // add validation for NDA type and Company
        document.querySelector('#save_changes').addEventListener('click', (event) => {
            event.preventDefault();
            // create array of required fields
            const requiredFields = [];
            const ndaType = document.querySelector('[name=\'nda_type\']');
            const companyID = document.querySelector('[name=\'rated_company_id\']');
            if (ndaType) {
                requiredFields.push(ndaType);
            }
            if (companyID) {
                requiredFields.push(companyID);
            }
            // if the form has a required field
            if (requiredFields.length) {
                // validate the form
                this.formValidation(requiredFields);
            } else {
                // validation not required
                document.getElementById('solicitation_details_form').submit();
            }
        });
    }

    formValidation(requiredFields) {
        // validate all required fields
        for (let i = 0; i < requiredFields.length; i++) {
            if (UTILS.isEmptyOrUndefined(requiredFields[i].value)) {
                // show this field's error message
                requiredFields[i].closest('.selectDropdown').classList.add('selectDropdown--showErrors');
            } else {
                // remove this field's error message
                requiredFields[i].closest('.selectDropdown').classList.remove('selectDropdown--showErrors');
            }
        }
        const errorMessages = document.querySelector('.selectDropdown--showErrors');
        // if the form has no errors
        if (UTILS.isEmptyOrUndefined(errorMessages)) {
            // submit the form
            document.getElementById('solicitation_details_form').submit();
        }
    }

    setInitial(reportType, ndaRequired, ndaType) {
        if (reportType != null) {
            // set the radio already selected
            document.getElementById(reportType).checked = true;

            // disable data entry when report is unspecified
            if (reportType == 'unspecified') {
                const dataEntryButton = document.getElementById('action_send_to_data_entry');
                if (dataEntryButton) {
                    dataEntryButton.setAttribute('disabled', true);
                    dataEntryButton.setAttribute('title', LOCALIZED_STRS.get().solicitationDetails.disabledButton);
                }
            }
        }
        // check if the provided defaultNdaType is within the reportOptions
        this.state.reportOptions.some((e) => {
            if (e.value === ndaType) {
                this.setState({
                    selectedOption: e
                });
            }
        });
    }

    openNdaOptions(value) {
        this.setState({
            openNdaOptions: value
        });
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        if (selectedOption.label === 'RR confidential' || selectedOption.label === 'Third-party confidential') {
            document.getElementById('nfr').checked = true;
        }
    }

    render() {
        const { reportOptions, selectedOption, readonly } = this.state;
        const customStyles = {
            container: (provided, state) => ({
              ...provided,
              display: 'inline-block',
              minWidth: 250,
              verticalAlign: 'top'
          }),
          singleValue: (provided, state) => ({
              ...provided,
              paddingTop: 2,
              paddingBottom: 2
          })
        };
        return (
            <React.Fragment>
                <fieldset
                    className="radio-group radio-group__horizontal"
                    disabled={readonly}
                >
                    <span>{LOCALIZED_STRS.get().report_type}:</span>
                    <label htmlFor="full_fhr">
                        <input
                            id="full_fhr"
                            type="radio"
                            name="report_type"
                            value="full_fhr"
                        />
                        <span>{LOCALIZED_STRS.get().full_fhr}</span>
                    </label>
                    <label htmlFor="nfr">
                        <input
                            id="nfr"
                            type="radio"
                            name="report_type"
                            value="nfr"
                        />
                        <span>NFR</span>
                    </label>
                    <label htmlFor="unspecified">
                        <input
                            id="unspecified"
                            type="radio"
                            name="report_type"
                            value="unspecified"
                            defaultChecked
                        />
                        <span>{LOCALIZED_STRS.get().unspecified}</span>
                    </label>
                </fieldset>
                <fieldset
                    className="radio-group radio-group__horizontal"
                    disabled={readonly}
                >
                    <span>NDA:</span>
                    <label htmlFor="true">
                        <input
                            id="true"
                            type="radio"
                            name="nda_required"
                            value="true"
                            checked={this.state.openNdaOptions}
                            onChange={this.openNdaOptions.bind(this, true)}
                        />
                        <span>{LOCALIZED_STRS.get().booleans.yes}</span>
                    </label>
                    <label htmlFor="false">
                        <input
                            id="false"
                            type="radio"
                            name="nda_required"
                            value="false"
                            checked={this.state.openNdaOptions === false}
                            onChange={this.openNdaOptions.bind(this, false)}
                        />
                        <span>{LOCALIZED_STRS.get().booleans.no}</span>
                    </label>
                    { this.state.openNdaOptions &&
                        <div className="selectDropdown selectDropdown--inline">
                            <div className="selectDropdown__prefix">{LOCALIZED_STRS.get().booleans.if_yes}:</div>
                            <Select
                                inputId="select_nda_type"
                                name="nda_type"
                                styles={customStyles}
                                value={selectedOption}
                                onChange={this.handleChange}
                                options={reportOptions}
                                isDisabled={readonly}
                             />
                             <div className="selectDropdown__error">
                                 <div className="errors">
                                     <p className="error">{LOCALIZED_STRS.get().solicitationDetails.ndaErrorMessage}</p>
                                 </div>
                             </div>
                        </div>
                    }
                </fieldset>
            </React.Fragment>
        );
    }
}

ReportType.propTypes = {
    reportOptions: PropTypes.object,
    defaultReportType: PropTypes.string,
    defaultNdaRequired: PropTypes.string,
    defaultNdaType: PropTypes.string
};

export default ReportType;
