import React from 'react';
import { isEmpty } from 'lodash';

export default function SourceFinancials({ companyid, isfinancial, ...props }) {
  // check whether Financial data is able to be rendered
  const willFinancialDataRender = () => {
    const isCompanyNonFinancial = isfinancial === 'False';
    const isContentValid = !isEmpty(props.header) && !isEmpty(props.list);
    return isCompanyNonFinancial && isContentValid;
  };

  // if financial data tab will render, we don't want source financials to show outside of it
  if (!willFinancialDataRender()) {
    return (
      <div
        slot-id="ui:slot:download-source-financials"
        data-company-id={companyid}
        data-no-fins-tab={true}
      ></div>
    );
  }
  return null;
}
