import React from 'react';
import styled from 'styled-components';
import { MainIcon } from './icons.jsx';

const Card = styled.div`
    background-color: ${props => props.disabled === true ? '#ECEEF2' : '#FFF'};
    padding: 12px 24px 24px;
    margin-top: 12px;
    margin-bottom: 12px;
    min-height: 168px;
    border: 1px solid  rgba(199, 210, 219, 0.7);
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(199,210,219,.4);
    transition: box-shadow 0.2s ease-in;
    position: relative;
    width: 100%;
    overflow: hidden;

    @media screen and (min-width: 1200px) {
        padding: 12px 32px 32px;
    }
`;

const StyledRow = styled.div`
  display: flex;
  margin-top: 24px;
  margin-left: -14px;
  margin-right: -14px;

  @media screen and (min-width: 1200px) {
    margin-top: 32px;
  }
`;

const IconContainer = styled.div`
    position: absolute;
    left: -110px;
    transform: translateY(-35%);

    @media screen and (min-width: 1200px) {
      left: -90px;
      transform: translateY(-40%);
    }

    svg {
      width: 100%;
      height: 100%;
    }

`;

const IconCol = styled.div`
  padding: 0 14px;
  flex-basis: 190px;
`;

const QuestionCol = styled.div`
  padding: 0 14px;
  flex-basis: 230px;

  @media screen and (min-width: 1200px) {
    flex-basis: 280px;
  }
`;

const AnswersCol = styled.div`
  padding: 0 14px;
`;

export default function HealthMarkHelperCard() {
  return (
    <Card>
      <StyledRow>
        <IconCol>
          <IconContainer>
            <MainIcon />
          </IconContainer>
        </IconCol>
        <QuestionCol>
          <p className="visually__h3">Search Tips</p>
          <p className="visually__h2">Are you having trouble finding a company?</p>
        </QuestionCol>
        <AnswersCol>
          <ul className="content__list--circle">
            <li>Try the legal name of the company as this can vary from a more recognised name</li>
            <li>Use our location filter to narrow down the number of search results by city, state or country</li>
            <li>Check that the address is current</li>
          </ul>
        </AnswersCol>
      </StyledRow>
    </Card>
  );
}
