const FastClick = {
    apply: (ele, func) => {
        ele.removeEventListener('touchstart', func);
        ele.removeEventListener('click', func);
        ele.addEventListener('touchstart', func);
        ele.addEventListener('click', func);
    }
};

export default FastClick;
