import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0 15px;
  width: 32px;
  height: 32px;
  cursor: pointer;

  visibility: ${props => props.isHidden ? 'hidden' : 'visible'};

  & .icon {
    outline: none;
    line-height: 18px;

    &:hover {
      background-color: #98a4ae;
    }
  }

  .icon--backgroundGrey {
      margin: 0 auto;
  }

`;

export default function CancelIcon({ onClick, isHidden = false }) {
  return (
    <Div isHidden={isHidden}>
      <i
        className="icon icon--backgroundGrey icon--close"
        role="button"
        tabIndex="-1"
        onClick={onClick}
        onKeyUp={onClick}
      />
    </Div>
  );
}
