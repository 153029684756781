import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import Select from 'react-select';
import moment from 'moment';
import styled from 'styled-components';
import classNames from 'classnames';
import { debounce } from 'lodash';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import { csrftoken } from '../utils/csrf.jsx';
import { NewLinkIcon } from './icons.jsx';
import HealthMarkHelperCard from './cardHelper/index.jsx';
import URLSearchParams from '@ungap/url-search-params';

const CompanyProfileLink = styled.a`
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
`;

const CompanyParentProfileLink = styled.a`
    &:visited,
    &:hover,
    &:link {
        color: #828c94;
    }
    text-decoration: underline !important;

    path {
        fill: #828c94;
    }
`;

const LinkSubtText = styled.span`
    font-weight: 400;
`;

const Column = styled.div`
    font-size: 16px;
    color: #3b4043;
`;

const TextContainer = styled.div`
    margin: 0 0 0 16px;
`;

const CompanyParent = styled.div`
    height: 24px;
    color: #828c94;
    font-size: 16px;
    line-height: 24px;
`;

const CompanyIcon = styled.div`
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
`;

const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    img {
        max-width: 48px;
    }
`;

function getFuzzySearchURL(url) {
  const params = new URLSearchParams(url);

  if (!params.has('contains')) {
    params.append('contains', true);
  }

  const fuzzySearchURL = `${window.location.origin}${
    window.location.pathname
  }?${params.toString()}`;

  return {
    fuzzySearchURL
  };
}

function getSearchTerms(queryString) {
  const params = new URLSearchParams(queryString);

  const fuzzySearchQuery = params.get('contains');

  return {
    fuzzySearchQuery
  };
}

function getLocationParams(queryString) {
  const params = new URLSearchParams(queryString);

  const label = params.get('label');
  const city = params.get('city');
  const state = params.get('state');
  const country = params.get('country');

  const location =
    label === null || label === ''
      ? null
      : {
          label,
          value: { city: city, state: state, country: country}
        };

  return {
    location
  };
}

function renderFinancialHealthLink (company) {
    return(
        <CompanyProfileLink
          className="link link--sentence"
          href={company.link}
          target="_blank"
        >
          {company.name} <LinkSubtText> (<NewLinkIcon /> FHR)</LinkSubtText>
        </CompanyProfileLink>
    );
}

function renderHealthMarkLink (company) {
    return(
        <CompanyProfileLink
          className="link link--sentence"
          href={company.link}
        >
          {company.name}
        </CompanyProfileLink>
    );
}

function renderParentLink (company) {
    return(
        <CompanyParent>
          <span>Child of </span>
          <CompanyParentProfileLink
            href={company.parent_profile_link}
            target={company.parent_profile_link.includes('company-profile') ? '_blank' : '_self'}
          >
            {company.parent_name}
            {company.parent_profile_link.includes('company-profile') &&
                <LinkSubtText>
                    (<NewLinkIcon /> FHR)
                </LinkSubtText>
            }
          </CompanyParentProfileLink>
        </CompanyParent>
    );
}

class TableHealthMarkSearchResults extends Component {
  constructor(props) {
    super(props);

    const columns = JSON.parse(this.props.columns);
    Object.keys(columns).map(key => {
      const column = columns[key];
      if (column.renderer !== undefined) {
        column.Cell = this.cellRenderers[column.renderer];
      }
    });

    this.state = {
      pages: 0,
      page: -1,
      total: 0,
      sorted: [],
      pageSize: 10,
      loading: true,
      columns: columns,
      id: this.props.id,
      url: this.props.url,
      resultType: 'exact',
      relatedResultsClickThrough: false
    };
  }

  componentDidMount () {
    // disable the page jumping in footer
    const pageJump = document.querySelector('.-pageJump input');
    pageJump && pageJump.setAttribute('disabled', '');
  }

  static defaultProps = {
    columns: '[]',
    id: 'sortable-table',
    data: JSON.parse('[]')
  };

  cellRenderers = {
    Link: cellInfo => {
      return (
        <a href={cellInfo.original.href} className="link link--sentence">
          {' '}
          {cellInfo.original[cellInfo.column.id]}{' '}
        </a>
      );
    },
    CampaignLink: cellInfo => {
      return (
        <a
          href={cellInfo.original.campaign_link}
          className="link link--sentence"
        >
          {' '}
          {cellInfo.original.campaign_name}{' '}
        </a>
      );
    },
    HealthMarkLink: ({ original: company }) => {
        return (
          <Column>
            <LinkContainer>
                {company.parent_name ? (
                    <CompanyIcon className="companyIcon--child"></CompanyIcon>
                ) : (
                    <CompanyIcon className="companyIcon--parent"></CompanyIcon>
                )}
                <TextContainer>
                { /* render a link to FHR or HM page */ }
                {company.link.includes('company-profile') ? (
                    renderFinancialHealthLink(company)
                ) : (
                    renderHealthMarkLink(company)
                )}
                { /* render the parent entity type */ }
                {company.parent_name ? (
                    renderParentLink(company)
                ) : company.parent_type ? (
                    null /* if parent_type is available then it will be displayed in own column */
                ) : (
                    <CompanyParent>Ultimate parent</CompanyParent>
                )}
                </TextContainer>
            </LinkContainer>
          </Column>
        );
    },
    Date: cellInfo => {
      return (
        <span>
          {moment(cellInfo.original[cellInfo.column.id], 'YYYY-MM-DD HH:mm:ss')
            .format('MMM Do, YYYY')
            .toString()}
        </span>
      );
    },
    HealthMarkIndicator: cellInfo => {
      return (
        <span
          className={`riskLevel riskLevel__${cellInfo.original.risk_mark_code}`}
        >
          <span className="riskLevel__text">
            {cellInfo.original.risk_mark_type}
          </span>
        </span>
      );
    }
  };

  get_URL = state => {
    const sorted = this.state.sorted.map(
      k => 'SORT_BY_' + k.id.toUpperCase() + (k.desc ? '_DESC' : '_ASC')
    );

    const { location } = getLocationParams(
      decodeURI(window.location.search)
    );

    const params = {
      page: state.page + 1,
      pageSize: this.state.pageSize,
      sortedBy: sorted,
      searchTerm: this.props.searchterm,
      contains: this.state.resultType === 'exact' ? false : true,
      ...(location && location.label && {label: location.label}),
      ...(location && location.value.city && {city: location.value.city}),
      ...(location && location.value.state && {state: location.value.state}),
      ...(location && location.value.country && {country: location.value.country})
    };

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
    return this.state.url + '?' + query;
  };

  fetchData = (state, instance) => {
    this.setState({ loading: true });

    // fetch the data
    setTimeout(() => {
      fetch(this.get_URL(state), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrftoken()
        },
        credentials: 'same-origin'
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          this.setState({
            data: data.companies,
            pages: data.pages,
            loading: false,
            resultType: data.result_type
          });
        });
    }, 1);
  };

  debouncedFetchData = debounce(this.fetchData, 200);

  onPageSizeChange = (pageSize, pageIndex) => {
    this.setState({
      pageSize: pageSize,
      pageIndex: pageIndex
    });
  };

  onSortedChange = (newSorted, column, shiftKey) => {
    this.setState({
      sorted: newSorted
    });
  };

  customNoDataComponent = () => {
    const { fuzzySearchURL } = getFuzzySearchURL(
      decodeURI(window.location.search)
    );

    return (
      <>
        <div className="rt-noData rt-noData--searchResults">
          <div className="rt-noData--image"></div>
          <div className="rt-noData--textLower">
            {LOCALIZED_STRS.get().noMatchingResults}
            {this.props.showaddcompanybutton !== undefined &&
              <div
                style={{pointerEvents: 'all'}}
                className="margin__vertical--top"
                slot-id="ui:slot:hm:search:no-results:add-company-button"
              >
              </div>
            }
            <p className="margin__vertical--top">
              {this.state.resultType === 'exact' && (
                <a
                  href={fuzzySearchURL}
                  className="link link--sentence padding__horizontal--tight--left"
                >
                  Include related results
                </a>
              )}
            </p>
          </div>
        </div>
      </>
    );
  };

  handleExactSearchRequest = (state, instance) => {
    this.setState({
      resultType: 'exact'
    }, () => {
      this.fetchData(state);
    });
  };

  handleRelatedSearchRequest = (state, instance) => {
    this.setState({
      resultType: '',
      relatedResultsClickThrough: true,
    }, () => {
      this.fetchData(state);
    });
  };

  renderInnerTable = (state, makeTable, instance) => {
    return <div className="main-grid">
      <div
        style={{alignItems: 'center'}}
        className="row above-table margin__vertical--loose--bottom"
      >
        <div className="col-xs-12">
          <hr
            style={{height: '1px'}}
            className="margin__vertical--loose"
          />
        </div>
        <div className="col-xs-8">
          {this.state.resultType === 'exact' ? (
              <p className="bold">
                Showing exact matching results.
                <a
                  onClick={() => this.handleRelatedSearchRequest(state, instance)}
                  className="link link--sentence padding__horizontal--tight--left"
                >
                  Click here to widen the search results.
                </a>
              </p>
          ) : (
            this.state.relatedResultsClickThrough && (
              <p className="bold">
                Showing all results.
                <a
                  onClick={() => this.handleExactSearchRequest(state, instance)}
                  className="link link--sentence padding__horizontal--tight--left"
                >
                  Click here to see exact matches.
                </a>
              </p>
            )
          )}
        </div>
        <div className="col-xs-4">
          {this.props.showaddcompanybutton !== undefined &&
          <div className="flex end-sm">
            <div
              style={{pointerEvents: 'all'}}
              slot-id="ui:slot:hm:search:merge-companies-button"
            >
            </div>
            <div
              style={{pointerEvents: 'all'}}
              className="margin__horizontal--tight--left"
              slot-id="ui:slot:hm:search:add-branch-button"
            >
            </div>
            <div
              style={{pointerEvents: 'all'}}
              className="margin__horizontal--tight--left"
              slot-id="ui:slot:hm:search:add-company-button"
            >
            </div>
            </div>
           }
        </div>
      </div>
      {makeTable()}
      {this.state.resultType === 'match_phrase' && (
        <>
          <hr className="margin__vertical--loose" />
          <HealthMarkHelperCard />
        </>
      )}
    </div>;
  };

  render() {
    const {
      url,
      data,
      pages,
      sorted,
      columns,
      loading,
      pageSize,
      selectAll
    } = this.state;

    const { fuzzySearchQuery } = getSearchTerms(
      decodeURI(window.location.search)
    );

    const tableClass = classNames({
      'ReactTable--lineHeight--md': true,
      'margin__vertical--tight--bottom': true
    });

    return (
      <div className="row">
          <div className="table_sortable margin__vertical--tight--bottom">
          <ReactTable
            manual
            url={url}
            data={data}
            pages={pages}
            sorted={sorted}
            columns={columns}
            loading={loading}
            pageSize={pageSize}
            className={tableClass}
            onSortedChange={this.onSortedChange}
            onFetchData={this.debouncedFetchData}
            onPageSizeChange={this.onPageSizeChange}
            NoDataComponent={this.customNoDataComponent}
            pageSizeOptions={[5, 10, 15, 20, 25]}
          >
            {this.renderInnerTable}
          </ReactTable>
        </div>
      </div>
    );
  }
}

TableHealthMarkSearchResults.propTypes = {
  id: PropTypes.string,
  pagesize: PropTypes.number,
  columns: PropTypes.string.isRequired
};

export default TableHealthMarkSearchResults;
