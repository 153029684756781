import smoothscroll from 'smoothscroll-polyfill';
// add polyfill for older browsers
smoothscroll.polyfill();

// add smooth scrolling to anchors with .rbtn--anchor
const anchors = [...document.querySelectorAll('.rbtn--anchor')];
anchors.map(anchor => {
    anchor.addEventListener('click', function(e) {
        e.preventDefault();

        // update active class
        const currentAnchors = [...document.querySelectorAll('.rbtn--anchor')];
        currentAnchors.map(anchor => {
            anchor.classList.remove('rbtn--anchor--active');
        });
        anchor.classList.add('rbtn--anchor--active');

        // the target element
        if (this.getAttribute('href') != '#') {
            const target = document.querySelector(this.getAttribute('href'));
            const yCoordinate = target.getBoundingClientRect().top + window.pageYOffset;
            // scroll to target
            if (target.hasAttribute('data-requiresOffset')) {
                const yOffset = -175; // 175 is the height of nav with taskbar
                window.scrollTo({
                    top: yCoordinate + yOffset,
                    behavior: 'smooth'
                });
            } else {
                target.scrollIntoView({
                    top: yCoordinate,
                    behavior: 'smooth'
                });
            }
            // add tabindex for elements not focusable
            target.setAttribute('tabindex', '-1');
            // set focus
            setTimeout(target.focus({preventScroll: true}), 500);
        }

    });
});
