import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export default class DateTimePicker extends React.Component {
    constructor(props) {
        super(props);
        const hiddenTimeFormat = props.hiddentimeformat ? props.hiddentimeformat : 'YYYY-MM-DD HH:mm:ss';
        const initialDate = props.initialdate ? new Date(props.initialdate) : null;
        const initialDateHidden = initialDate ? moment(initialDate).format(hiddenTimeFormat) : null;
        const timeIntervals = props.timeintervals ? parseInt(props.timeintervals) : 15;
        const placeholderText = props.placeholdertext ? props.placeholdertext : 'Click here to select a date';
        const dateFormat = props.dateformat ? props.dateformat : 'MMMM d, yyyy h:mm aa';
        const timeFormat = props.timeformat ? props.timeformat : 'hh:mm aa';
        this.state = {
            startDate: initialDate,
            startDateHidden: initialDateHidden,
            id: props.id,
            name: props.name,
            dateFormat: dateFormat,
            timeFormat: timeFormat,
            timeIntervals: timeIntervals,
            hiddenTimeFormat: hiddenTimeFormat,
            placeholderText: placeholderText
        };
    }


    handleChange = date => {
      this.setState({
        startDate: date,
        startDateHidden: moment(date).format(this.state.hiddenTimeFormat)
      });
    };

    render() {
      return (
        <div>
          <input
            type="text"
            hidden={true}
            id={this.state.id}
            name={this.state.name}
            value={this.state.startDateHidden ? this.state.startDateHidden : ''}
            readOnly
          ></input>
          <DatePicker
            selected={this.state.startDate}
            onChange={this.handleChange}
            showTimeSelect
            dateFormat={this.state.dateFormat}
            timeFormat={this.state.timeFormat}
            timeIntervals={this.state.timeIntervals}
            placeholderText={this.state.placeholderText}
          />
        </div>
      );
    }
  }
