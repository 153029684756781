import React from 'react';
import BaseInput from './baseinput.jsx';
import Error from './error.jsx';
import ReduxStore from './redux_store.jsx';

class Input extends ReduxStore(BaseInput) {

    handleKeyUp(e) {
        super.handleKeyUp(e);
        this.update_store(e.target.value);
    }

    render() {
        return (
            <div className={this.props.required ? 'input__group required' : 'input__group'}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <input autoComplete="off" placeholder={this.props.placeholder} className={ this.props.className} { ...this.state.attributes } { ...this.state.validations } value={this.state.value} onChange={this.handleChange.bind(this)} onKeyUp={this.handleKeyUp.bind(this)} onBlur={this.handleBlur.bind(this)} onFocus={this.handleFocus.bind(this)} />
                { 
                    this.props.helpText && !this.props.errors && <small className="caption--secondary">{this.props.helpText}</small>
                }
                <Error errors={this.state.errors}/>
            </div>
        );
    }
}

export default Input;
