import 'formdata-polyfill';
import {csrftoken} from '../../utils/csrf.jsx';

/**
 * Returns a promise that resolves with the suggestions for the query.
 * @param {string} url The endpoint for the suggestion.
 * @returns {Promise} The promise that resolves with the suggestions.
 */
export function getSuggestions(url) {
    return fetch(url)
    .then(res => res.json())
    .then(json => Promise.resolve(json.results));
}

export function getNoResultsMessage(item) {
    return (`Sorry, there are no suggestions for that ${item}.`);
}

export function getQueryErrorMessage(item) {
    return (`Sorry, could not provide suggestions for that ${item}.`);
}

/**
 * Returns an array of options based on the provided suggestions..
 * @param {string} suggestions The suggestions array.
 * @param {string} searchObject The human readable name of the search.
 * @returns {Array} The array of options from the suggestions.
 */
export function transformSuggestionsToOptions(suggestions, searchObject) {
  return suggestions.map(({ id, name, type }, index) => {
    const itemType = type ? `| Type ${type}` : '';
    return {
      value: id,
      displayName: name,
      displayID: `${searchObject} ID. ${id}`,
      label: `${name} | ${searchObject} ID. ${id} ${itemType}`
    };
  });
}
