import React from 'react';

export function ImproveIcon() {
  return (
      <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1">
          <g id="fINAL-ui-handover-header-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="HM-Header-1024-CTAs" transform="translate(-750.000000, -77.000000)" fill="#3B4043">
                  <path d="M760,82.1052374 L760,87 L750,87 L750,77.2104748 L755,77.2104748 L755,78.1894273 L751,78.1894273 L751,86.0210475 L759,86.0210475 L759,82.1052374 L760,82.1052374 Z M753.5,82.256975 L757.511,78.3225649 L758.288,79.3215859 L754.2295,83.5736662 L753.5,83.5736662 L753.5,82.256975 Z M752.5,84.5526187 L754.658,84.5526187 L759.6115,79.3225649 L757.538,77 L752.5,81.8438571 L752.5,84.5526187 Z" id="edit_cover-[#1481]"></path>
              </g>
          </g>
      </svg>
  );
}

export function SuccessIcon() {
  return (
      <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
          <title>Feedback Submitted</title>
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="large-low-icon" transform="translate(-7.000000, -8.000000)">
                  <g id="low" transform="translate(7.000000, 8.000000)">
                      <circle id="Oval-Copy-10" fill="#46A840" opacity="0.799920945" cx="32" cy="32" r="32"></circle>
                      <polyline id="Path-4-Copy" stroke="#FFFFFF" strokeWidth="4.10540823" strokeLinecap="round" strokeLinejoin="round" points="21 33.8393732 29.4563792 42 45 27"></polyline>
                  </g>
              </g>
          </g>
      </svg>
  );
}
