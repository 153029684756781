import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { get } from 'lodash';
import {
  MOBILE_BREAKPOINT,
  WHITE,
  BLACK,
  ABBEY,
  GREY_PRIMARY
} from './constants';
import MenuGroup from './menuGroup.jsx';
import styled from 'styled-components';
import MenuToggleIcon from './menuToggleIcon.jsx';
import MenuCloseIcon from './menuCloseIcon.jsx';
import Category from './category.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

const Logo = styled.a`
    position: relative;
    float: left;
    top: 7px;
    left: -8px;
    z-index: 1;

    img {
        width: 229px;
        height: auto;
        margin: auto;
        vertical-align: baseline;
    }
`;
const CloseLink = styled.a`
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    text-align: right;
    padding: 0 8px 0 0;
    line-height: 43px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    @media only screen and (min-width : ${MOBILE_BREAKPOINT}) {
        display: none;
    }

    svg {
        vertical-align: sub;
        margin: 0 8px;
    }
`;
const MenuBackground = styled.div`
    background-color: ${BLACK};
    opacity: 0.6;
    display: ${props => (props.active === true ? 'block' : 'none')};
    position: absolute;
    z-index: 4;
    content: " ";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 400ms ease;

    @media only screen and (min-width : ${MOBILE_BREAKPOINT}) {
        background-color: initial;
        display: none;
    }

`;
MenuBackground.displayName = 'MenuBackground';
const MenuBar = styled.div`
    background-color: ${BLACK};
    text-align: right;
    padding: 0 8px;
    line-height: 58px;
    height: 60px;
    transform: translateX(0px);
    transition: transform 400ms ease;
    z-index: 5;
    position: fixed;
    left: 0;
    right: 0;
    top: 24px;
`;
const MenuList = styled.div`
    background-color: ${GREY_PRIMARY};
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 220px;
    text-align: left;
    transform: ${props => (props.active === true ? 'translate(0%)' : 'translate(100%)')};
    overflow-y: scroll;
    transition: transform 400ms ease;
    z-index: 4;

    @media only screen and (min-width : ${MOBILE_BREAKPOINT}) {
        background-color: ${BLACK};
        display: flex;
        justify-content: flex-end;
        position: relative;
        overflow-y: inherit;
        height: inherit;
        width: inherit;
        transform: inherit;
    }
`;
const MenuToggle = styled.a`
    display: inline-block;
    color: ${WHITE};
    text-decoration: none;
    padding: 0 8px;
    cursor: pointer;

    span {
        display: inline-block;
        padding: 0 8px 0 4px;
        font-size: 14px;
        font-weight: 600;
    }

    @media only screen and (min-width:${MOBILE_BREAKPOINT}) {
        display: none;
    }
`;
MenuToggle.displayName = 'MenuToggle';
const MenuToggleIconContainer = styled.span`
    svg {
        width: 24px;
        vertical-align: middle;
    }
`;
// End styles

class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            expandedMenu: null,
            orderedMenuItems: props.menu
        };
        this.keyFunction = this.keyFunction.bind(this);
        this.closeSubMenus = this.closeSubMenus.bind(this);
        this.handleEventOutside = this.handleEventOutside.bind(this);
        this.focusChecks = this.focusChecks.bind(this);
    }

    keyFunction(event){
        if (event.keyCode === 27) {
            // when esc is pressed
            this.closeSubMenus();
            this.setState({
                isActive: false
            });
        }
        if ( !this.node.contains(event.target)) {
            this.setState({
                expandedMenu: null,
                isActive: false
            });
        }
    }

    focusChecks(event) {
        if (this.state.expandedMenu !== null) {
            this.closeSubMenus();
        }
    }

    closeSubMenus() {
        this.setState({
            expandedMenu: null
        });
    }

    handleEventOutside(event) {
        if ( this.node.contains(event.target)) {
            return;
        }
        this.setState({
            expandedMenu: null,
            isActive: false
        });
    }

    toggleMenu() {
        this.setState({
            isActive: !this.state.isActive
        });
        // add .navigation__noscroll to <body>
        document.body.classList.toggle(
            'navigation__noscroll'
        );
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleEventOutside, false);
        document.addEventListener('keydown', this.keyFunction, false);
        document.addEventListener('shift', this.focusChecks, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleEventOutside, false);
        document.removeEventListener('keydown', this.toggleGroup, false);
    }

    toggleGroup(name) {
        this.setState({expandedMenu: name});
    }

    render() {
        return (
            <nav
                ref={node => this.node = node}
                id="mainmenu"
            >
                <MenuBackground
                    onClick={this.toggleMenu.bind(this)}
                    active={(this.state.isActive) ? true : false}
                />
                <MenuBar
                    role="menubar"
                    active={(this.state.isActive) ? true : false}
                >
                    <Logo
                        role="menuitem"
                        aria-haspopup="false"
                        href="/"
                        title="Visit the main page"
                    >
                        <img src={ this.props.logourl } alt="RapidRatings FHR Exchange"/>
                    </Logo>
                    <MenuToggle
                        href="#"
                        role="menuitem"
                        aria-haspopup="true"
                        onClick={this.toggleMenu.bind(this)}
                    >
                        <MenuToggleIconContainer>
                            <MenuToggleIcon/>
                        </MenuToggleIconContainer>
                        <span className="open-menu">{LOCALIZED_STRS.get().menu}</span>
                    </MenuToggle>
                    <MenuList
                        active={(this.state.isActive) ? true : false}
                    >
                        {this.state.orderedMenuItems.map((category, a) => {
                            return (
                                <Category
                                    key={a}
                                    category={category}
                                />
                            );
                        })}
                        
                        <CloseLink
                            role="menuitem"
                            onClick={this.toggleMenu.bind(this)}
                        >
                            {LOCALIZED_STRS.get().close}
                            <MenuCloseIcon />
                        </CloseLink>
                    </MenuList>
                </MenuBar>
            </nav>
        );
    }
}

export default MainMenu;
