import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import * as d3_axis from 'd3-axis';
import * as d3_format from 'd3-format';
import * as d3_scale from 'd3-scale';
import * as d3_selection from 'd3-selection';

class Comparison extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            class: props.target,
            ranges: props.ranges
        };
    }

    renderChart = (isPrintVersion) => {
        let className = this.state.class || 'comparison--chart';
        className = '.' + className;
        // clear svg contents for drawing
        d3_selection.select(className).selectAll('*').remove();
        // get the data
        const data = JSON.parse(this.state.data);

        // setup the chart
        const bounds = d3_selection.select(className).node().getBoundingClientRect(),
              margin = { top: 0, right: 0, bottom: 0, left: 0 },
              height = bounds.height - margin.top - margin.bottom;
        let width = bounds.width - margin.left - margin.right;

        if (isPrintVersion === 'printVersion') {
            // general cross browser width of A4 page with normal margins
            width = 630;
        } else {
            width = bounds.width - margin.left - margin.right;
        }

        const barHeight = 16;
        const x = d3_scale.scaleLinear()
            .domain([0, 5])
            .range([0, width - 36]);
        const y = d3_scale.scaleBand()
            .domain(data.map(d => d.name))
            .range([0, barHeight * data.length]);

        // select the chart
        const chart = d3_selection
            .select(className);

        // set svg groups and data
        const bar = chart
            .selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('class', 'bar')
            .attr('transform', (d, i) => `translate(36,${i * 1.2 * barHeight})`);

        // add the bars
        bar
            .append('rect')
            .attr('width', d => x(d.value))
            .attr('height', barHeight)
            // set color based on value
            .attr('class', d => {
              if (d.value > 4) {
                  d = 's4';
              } else if (d.value > 3) {
                  d = 's3';
              } else if (d.value > 2) {
                  d = 's2';
              } else if (d.value > 1) {
                  d = 's1';
              } else {
                  d = 's0';
              }
              return d;
            });

        // add value to bar
        bar
            .append('text')
            .attr('x', 0)
            .attr('y', barHeight / 2)
            .attr('dy', '.35em')
            .attr('dx', '-5px')
            .attr('class', 'value')
            .attr('fill', 'black')
            .text(d => d.value);

        // ranges
        const ranges = [0, 1, 2, 3, 4];

        // add x axis
        const displayIntervals = ['0', '1', '2', '3', '4', '5'];
        chart
            .append('g')
            .attr('transform', `translate(36, ${(barHeight * data.length) + 4})`)
            .attr('class', 'scale--x')
            .call(d3_axis.axisBottom(x)
                .tickValues(displayIntervals)
                .tickFormat(d3_format.format('.0f'))
            );

        // add legend
        const legend = chart
            .selectAll('g')
            .data(data)
            .append('g')
            .attr('transform', `translate(0, ${(barHeight * data.length) + 60})`)
            .attr('class', 'legend');

        // IE 10 requires translates to be attributes
        chart.selectAll('g.tick > line')
            .attr('transform', 'scale(1, 0.5) translate(-0.5, 10)');

        chart.selectAll('g.tick:last-of-type > text')
            .attr('dx', '-5px');

        // ranges
        const range = chart.selectAll('rect.range').data(ranges);
        range.enter()
            .append('rect')
            .attr('class', (d, i) => `range s${i}`)
            .attr('width', ((width - 36) / 5))
            .attr('height', barHeight / 10)
            .attr('x', d => x(d))
            .attr('transform', 'translate(36, ' + ( (barHeight * data.length) / 1.35 ) + ' )')
            .attr('y', barHeight); // set to bottom of chart
    }

    componentDidMount() {
        this.renderChart();
        // if the window resizes, redraw the chart
        window.addEventListener('resize', this.renderChart);
        // resize the chart before printing
        window.addEventListener('beforeprint', (event) => {
            this.renderChart('printVersion');
        });
        // restore size of chart after printing
        window.addEventListener('afterprint', (event) => {
            this.renderChart();
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.renderChart);
        window.removeEventListener('beforeprint', this.renderChart);
        window.removeEventListener('afterprint', this.renderChart);
    }

    renderLegendColor = (score) => {
        if (score > 4) {
          score = 'rgb(111, 191, 234)';
        } else if (score > 3) {
          score = 'rgb(70, 168, 64)';
        } else if (score > 2) {
          score = 'rgb(247, 232, 86)';
        } else if (score > 1) {
          score = 'rgb(245, 179, 83)';
        } else {
          score = 'rgb(232, 76, 75)';
        }
        return score;
    }

    render() {
        const legendData = JSON.parse(this.props.data);
        return (
            <div className="comparison comparison--2">
                <svg className={`comparison--chart comparison--chart--2 ${this.state.class}`}>
                    <title>Comparison Chart</title>
                </svg>
                <div className="legend">
                {legendData.map((legendItem, index) => {
                    return (
                        <div className="legend--item" key={index}>
                            <p className="score">
                            <svg
                                width="16"
                                height="16"
                            >
                                <rect
                                    width="16"
                                    height="16"
                                    style={{fill: this.renderLegendColor(legendItem.value)}}
                                />
                            </svg>
                                {legendItem.name}
                            </p>
                        </div>
                    );
                })}
                </div>
            </div>
        );
    }

}

export default Comparison;
