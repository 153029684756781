import React from 'react';
import LOCALIZED_STRS from './localize.jsx';

const DEFAULT_DATE_FORMAT = 'default-date';

const DEFAULT_SHORT_DATE_FORMAT = 'default-date-short-month';


class DateSpan extends React.Component {
    constructor(props) {
        super(props);
        let date_format = props['date-format'] || DEFAULT_DATE_FORMAT || DEFAULT_SHORT_DATE_FORMAT;
        if (!(date_format in LOCALIZED_STRS.get()['date-formats'])) {
            date_format = DEFAULT_DATE_FORMAT || DEFAULT_SHORT_DATE_FORMAT;
        }
        let date_object = props['data-datetime'];
        if (date_object  && (typeof date_object === 'string' || date_object instanceof String)) {
            date_object = new Date(date_object);
        }
        this.state = {
            date_object: date_object,
            date_format: date_format,
            label: props.label,
            tail: props.tail
        };
    }

    render() {
        if (!this.state.date_object) {
            return null;
        }
        const title = this.state.label ? <span className="bold">{this.state.label}: </span> : null;
        const tail = this.state.tail ? <span className="fiscal_date">{this.state.tail}</span> : null;
        const date = this.state.date_object.toLocaleDateString(
            LOCALIZED_STRS.lang(), LOCALIZED_STRS.get()['date-formats'][this.state.date_format]);
        return <span className="datetime" data-datetime={this.state.date_object.toISOString()}>
            {title}{date}{tail}
            </span>;
    }
}

export { DateSpan as default, DEFAULT_DATE_FORMAT, DEFAULT_SHORT_DATE_FORMAT };
