import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import PropTypes from 'prop-types';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

class RequestType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: props.url,
            openCompanies: false,
            selectedOption: { label: props.defaultname, value: props.defaultvalue },
            options: [],
            isLoading: false,
            readonly: props.readonly !== undefined ? true : false,
            defaultRequestRadio: props.defaultrequestradio
        };
        this.openCompanies = this.openCompanies.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setInitialRadio = this.setInitialRadio.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
    }

    async copyOptionsForAsync(url, newValue) {
        this.setState({
            isLoading: true
        });
        if (newValue == null) {
            newValue = '';
        }
        const resultOptions = [];
        const searchUrl = `${url}&search=` + newValue + '&by=longName';
        const response = await fetch(searchUrl);
        const data = await response.json();

        if (data.results.length) {
          data.results.forEach(element => {
              const dropDownEle = { label: element['longName'], value: element['id'] };
              resultOptions.push(dropDownEle);
          });
        }
        this.setState({
            options: resultOptions,
            isLoading: false
        });
    }

    componentDidMount() {
        if (UTILS.isEmptyOrUndefined(this.props.defaultvalue)) {
            this.setState({
                selectedOption: ''
            });
        }
        this.setInitialRadio(this.state.defaultRequestRadio);
    }

    setInitialRadio(radioID) {
        if (radioID != null) {
          // set the radio already selected
          document.getElementById(radioID).checked = true;
          if (radioID === 'refresh') {
              this.openCompanies(true);
          }
        }
    }

    handleOnchange = (newValue) => {
        if (newValue != '') {
            this.copyOptionsForAsync(this.state.url, newValue);
        }
    };

    handleMenuClose() {
        this.setState({
            options: []
        });
    }

    openCompanies(value) {
        this.setState({
            openCompanies: value
        });
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
    }

    render() {
        const customStyles = {
            container: (provided, state) => ({
                ...provided,
                display: 'inline-block',
                minWidth: 250,
                verticalAlign: 'top'
            }),
            singleValue: (provided, state) => ({
                ...provided,
                paddingTop: 2,
                paddingBottom: 2
            })
        };
        const { selectedOption, readonly } = this.state;
        return (
            <fieldset
                className="radio-group radio-group__horizontal"
                disabled={readonly}
            >
                <span>Request Type:</span>
                <label htmlFor="new">
                    <input
                        id="new"
                        type="radio"
                        name="request_type"
                        value="new"
                        onClick={this.openCompanies.bind(this, false)}
                    />
                    <span>{LOCALIZED_STRS.get().new}</span>
                </label>
                <label htmlFor="refresh">
                    <input
                        id="refresh"
                        type="radio"
                        name="request_type"
                        value="refresh"
                        onClick={this.openCompanies.bind(this, true)}
                    />
                    <span>{LOCALIZED_STRS.get().refresh}</span>
                </label>
                { this.state.openCompanies &&
                    <div className="selectDropdown">
                        <div className="selectDropdown__prefix">{LOCALIZED_STRS.get().if_refresh}:</div>
                        <Select
                            name="rated_company_id"
                            styles={customStyles}
                            options={this.state.options}
                            value={selectedOption}
                            onChange={this.handleChange}
                            onInputChange={this.handleOnchange}
                            onMenuClose={this.handleMenuClose}
                            isLoading={this.state.isLoading}
                            isDisabled={readonly}
                            isClearable={true}
                            openMenuOnFocus={false}
                            openMenuOnClick={false}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                            }}
                        />
                        <div className="selectDropdown__error">
                            <div className="errors">
                                <p className="error">{LOCALIZED_STRS.get().solicitationDetails.companyIDErrorMessage}</p>
                            </div>
                        </div>
                    </div>
                }
            </fieldset>
        );
    }
}

RequestType.defaultProps = {
    defaultRequestRadio: null
};

RequestType.propTypes = {
    defaultname: PropTypes.string,
    defaultvalue: PropTypes.string
};

export default RequestType;
