import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {
  WHITE,
  BLACK,
  ABBEY,
  TURBO
} from './constants';
import track from 'react-tracking';


const MenuItemList = styled.li`
    line-height: 34px;

    &:last-of-type {
        border-bottom: 1px solid ${ABBEY};
    }
`;
const MenuAnchor = styled.a`
   text-decoration: none;
   color: ${WHITE};
   cursor: pointer;
   font-size: 14px;
   font-weight: 400;
   text-align: left;
   text-transform: capitalize;
   line-height: inherit;
   display: inline-block;
   width: 100%;
   text-decoration: none;
   padding: 0 8px;

   &:link, &:visited, &:active {
       color: ${WHITE};
       text-decoration: none;
   }

   &:hover {
       background-color: ${TURBO};
       transition: background-color .3s cubic-bezier(.54,.09,.3,.82);
       color: ${BLACK};
   }
`;


class MenuGroup extends React.Component {
    constructor(props) {
        super(props);
    }
    @track((props, state, args) => {
        const event = args[0];
        return {
            event: 'linked clicked',
            label: event.currentTarget.title || event.currentTarget.textContent
        };
    })
    handleClick(event) {
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }
    render() {
        return (
          <>
            {this.props.subcategoryList.map((item, index) => {
                return (
                    <MenuItemList key={index}>
                        <MenuAnchor
                            onClick={this.handleClick.bind(this)}
                            href={item.action}
                            role="menuitem"
                        >{item.label}</MenuAnchor>
                    </MenuItemList>
                );
            })}
         </>
        );
    }
}
export default MenuGroup;
