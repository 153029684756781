import {csrftoken} from '../../../utils/csrf.jsx';

/**
 * Returns a promise that resolves with the suggestions for the query.
 * @param {string} url The endpoint for the suggestion.
 * @returns {Promise} The promise that resolves with the suggestions.
 */
export function getSectorSuggestions(url) {
    return fetch(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrftoken()
        },
        credentials: 'same-origin'
    })
    .then(res => res.json())
    .then(json => Promise.resolve(json));
}

/**
 * Returns an array of options based on the provided suggestions..
 * @param {string} suggestions The suggestions array.
 * @returns {Array} The array of options from the suggestions.
 */
export function transformSuggestionsToOptions(suggestions) {
  return suggestions.map(({ value, label }, index) => {
    return {
      value: value,
      label: label
    };
  });
}
