import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  max-width: 665px;
  width: 100%;
  background: #fff;
  border: 1px solid #c7d2db;
  border-radius: 3px;
  height: 40px;
  margin: 4px 16px 4px 0;

  & .location-select__control {
    height: calc(100% - 16px);
    border: none;
    border-radius: 0;
    box-shadow: none;

    &:hover {
      box-shadow: none !important;
    }
  }

  & .location-select__indicators {
    display: none;
  }

  & .location-select__menu {
    width: calc(100% + 49px);
    margin-top: 1px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #c7d2db;
    border-top: none;
    padding: 0;
  }

  & .location-select__menu-notice {
    text-align: left;
  }
`;

export default function SearchContainer({
  children,
  isLocationInputFocused = false
}) {
  return (
    <Container
      isLocationInputFocused={isLocationInputFocused}
    >
      {children}
    </Container>
  );
}
