import React, {Component} from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import Select from 'react-select';
import moment from 'moment';
import { intersection } from 'lodash';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import 'formdata-polyfill';
import {csrftoken} from '../utils/csrf.jsx';
import styled from 'styled-components';
import { RemoveBookmarkIcon, AddBookmarkIcon } from './icons.jsx';

const DeleteButton = styled.button`
    min-width: auto;
    width: 32px;
    height: 32px;

    .iconWrapper {
        margin-left: -2px;
        vertical-align: middle;
    }

    @media screen and (min-width: 1200px) {
        min-width: 95px;
        width: auto;
        height: auto;

        .iconWrapper {
            display: none;
        }
    }
`;

const DeleteText = styled.span`
    @media screen and (min-width: 1200px) {
        &:after {
            font-family: inherit;
            content: 'Remove';
            min-width: 95px;
            font-size: inherit;
            margin-left: inherit;
        }
    }
`;

const SearchInputWrapper = styled.div`
    margin: 0 auto;
    float: left;

    &:before {
        content: '\\e915';
        font-size: 18px;
        color: #51585d;
        font-family: RapidRatings;
        padding-top: 8px;
        padding-left: 16px;
        z-index: 1;
        position: absolute;
    }
    input {
        padding: 8px 8px 8px 40px;
        min-width: 235px;

        &:hover {
            cursor: text;
        }
    }
`;

const Filter = styled.div`
    margin: 0 auto;
    float: left;
`;

const ClearFiltersLink = styled.a`
    line-height: 40px;
`;

const CheckboxTable = checkboxHOC(ReactTable);

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        padding: 5,
    }),
    menu:(provided, state) => ({
        ...provided,
        zIndex: 3
    }),
    singleValue: (provided, state) => ({
        ...provided,
        height: '100%'
    }),
    container: (provided, state) => ({
        ...provided,
        height: '40px',
        minWidth: '235px'
    }),
    control: (provided, state) => ({
        ...provided,
        height: '40px',

        '&:hover': {
            cursor: 'pointer'
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0
    }),
    input: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0,
        color: '#3B4043'
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#3B4043'
    })
};

class TableHealthMarkFollow extends Component {
    constructor(props) {
        super(props);
        const columns = JSON.parse(this.props.columns);
        Object.keys(columns).map((key) => {
            const column = columns[key];
            if (column.renderer !== undefined) {
                column.Cell = this.cellRenderers[column.renderer];
            }
        });
        this.state = {
            data: JSON.parse(props.data) || [],
            filters: JSON.parse(this.props.filters),
            filter_select_values: {},
            filtered: [],
            pages: 0,
            columns: columns,
            total: 0,
            sorted: [],
            searchPlaceholder: props.searchplaceholder,
            searchParam: props.searchparam,
            searchTerm: props.searchterm,
            id: this.props.id,
            pageSize: this.props.pagesize,
            selection: [],
            selectAll: false,
            search: '',
            toBeDeleted: [],
        };
    }

    static defaultProps = {
        pagesize: 25,
        filters: '[]',
        assignees: '[]',
        id: 'sortable-table',
        columns: '[]',
        selection: [],
        selectAll: false,
        searchplaceholder: 'Search...',
        searchparam: null,
        searchterm: '',
        searchdebounce: 500,
        searchmin: 2,
        total_requests_label: LOCALIZED_STRS.get().requests,
        total_filtered_requests_label: LOCALIZED_STRS.get().filteredRequests,
        total_no_requests: LOCALIZED_STRS.get().noRequests
    };

    cellRenderers = {
        Link: (cellInfo) => {
            return <a href={cellInfo.original.href} className="link link--sentence"> {cellInfo.original[cellInfo.column.id]} </a>;
        },
        CampaignLink: (cellInfo) => {
            return <a href={cellInfo.original.campaign_link} className="link link--sentence"> {cellInfo.original.campaign_name} </a>;
        },
        HealthMarkLink: (cellInfo) => {
            return <a href={`/health-mark/profile/${cellInfo.original.id}?search-term=&country-code=`} className="link link--sentence"> {cellInfo.original.name} </a>;
        },
        Date: (cellInfo) => {
            return <span>{moment(cellInfo.original[cellInfo.column.id], 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY').toString()}</span>;
        },
        Delete: (cellInfo) => {
            return (
                <DeleteButton
                    onClick={(e) => this.handleIndividualDelete(cellInfo.original.id, e)}
                    className="rbtn rbtn--ghost rbtn--responsive"
                    title="Remove"
                >
                    <span className="iconWrapper">
                        <RemoveBookmarkIcon />
                    </span>
                    <DeleteText />
                </DeleteButton>
            );
        },
        HealthMarkIndicator: (cellInfo) => {
            return <span className={`riskLevel riskLevel__${cellInfo.original.risk_mark_code}`}><span className="riskLevel__text">{cellInfo.original.risk_mark_type}</span></span>;
        }
    };

    toggleSelection = (key, shift, row) => {
      // start off with the existing state
      let selection = [...this.state.selection];
      const keyIndex = selection.indexOf(key);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        selection = [
          ...selection.slice(0, keyIndex),
          ...selection.slice(keyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        selection.push(key);
      }
      // update the state
      this.setState({ selection });
    };

    toggleAll = () => {
      /*
        The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
        ReactTable and then get the internal state and the 'sortedData'.
        That can then be iterrated to get all the currently visible records and set
        the selection state.
      */
      const selectAll = !this.state.selectAll;
      const selection = [];
      if (selectAll) {
        // we need to get at the internals of ReactTable
        const wrappedInstance = this.checkboxTable.getWrappedInstance();
        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance.getResolvedState().sortedData;
        // we just push all the CP onto the selection array
        currentRecords.forEach(item => {
            selection.push(item._original.id);
        });
      }
      this.setState({ selectAll, selection });
    };

    isSelected = key => {
      /*
        Instead of passing our external selection state we provide an 'isSelected'
        callback and detect the selection state ourselves. This allows any implementation
        for selection (either an array, object keys, or even a Javascript Set object).
      */
      return this.state.selection.includes(key);
    };

    renderFilter = (filter, i) => {
        const filterName = filter.name;
        const options = filter.options;
        const placeholder = filter.placeholder;
        return <Filter className="filter--container" key={i}>
                <div className="select--container">
                    <Select
                        className={'table-filter-' + filterName}
                        styles={customStyles}
                        onChange={entry => {
                            const filter_select_values = this.state.filter_select_values;
                            filter_select_values[filterName] = entry;
                            this.setState({ filter_select_values: filter_select_values});
                            this.onFilteredChangeCustom(entry.id, filterName);
                        }}
                        value={this.state.filter_select_values[filterName]}
                        options={options.map(([value, label]) => {
                            return {id: value, value: label, label: label};
                        })}
                        placeholder={placeholder}
                    />
                </div>
            </Filter>;
    };

    onFilteredChangeCustom = (value, accessor) => {
        const filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter['id'] === accessor) {
                    if (value === '' || !value.length) {
                        filtered.splice(i, 1);
                    } else {
                        filter['value'] = value;
                    }

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });

    };

    onClearFilters = () => {
        const filter_select_values = this.state.filter_select_values;
        Object.keys(filter_select_values).map((key) => {
            filter_select_values[key] = null;
        });
        this.setState({
            filtered: [],
            filter_select_values: filter_select_values,
            search: ''
        });
    };

    deleteWatched = () => {
        const currentlySelected = this.state.selection;
        // we need to get at the internals of ReactTable
        const wrappedInstance = this.checkboxTable.getWrappedInstance();
        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance.getResolvedState().sortedData;
        const visibleRecords = [];
        currentRecords.map((item, index) => {
            visibleRecords.push(item._original.id);
        });
        const companyIntersection = intersection(currentlySelected, visibleRecords);
        this.setState({
            toBeDeleted: companyIntersection
        }, () => {
            this.handleSubmit();
        });
    }

    handleSubmit = () => {
        const self = this;

        const formData = new FormData();
        const csrftoken_value = csrftoken();
        // add action to FormData
        formData.append('action', 'delete');
        // add ID's to FormData
        formData.append('ids', JSON.stringify(this.state.toBeDeleted));
        formData.append('csrfmiddlewaretoken', csrftoken_value);
        fetch(window.location.href, {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': csrftoken_value
            },
            credentials: 'same-origin',
            body: formData
        }).then((res) => {
            if (res.status === 200) {
                location.reload();
            }
        });
    }

    handleIndividualDelete = (id) => {
        const self = this;
        const formData = new FormData();
        const csrftoken_value = csrftoken();
        const companyToUnfollow = [id];
        // add action to FormData
        formData.append('action', 'delete');
        // add ID's to FormData
        formData.append('ids', JSON.stringify(companyToUnfollow));
        formData.append('csrfmiddlewaretoken', csrftoken_value);
        fetch(window.location.href, {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': csrftoken_value
            },
            credentials: 'same-origin',
            body: formData
        }).then((res) => {
            if (res.status === 200) {
                location.reload();
            }
        });
    }

    customNoDataComponent = () => {
      return (
          <>
            <div className="rt-noData">
                <div className="rt-noData--text">No companies found for your search</div>
                <div className="rt-noData--image"></div>
            </div>
          </>
      );
    }


    renderTotalFilters = (state, makeTable, instance) => {
        let recordsInfoText = '';

        const { filtered, pageRows, pageSize, sortedData, page } = state;

        if (sortedData && sortedData.length > 0) {
            const isFiltered = filtered.length > 0;

            const total = sortedData.length;

            const recordsCountFrom = (page * pageSize) + 1;

            const recordsCountTo = (recordsCountFrom + pageRows.length) - 1;

            if (isFiltered) {
                recordsInfoText = LOCALIZED_STRS.get().showing + ` ${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${total} ` + LOCALIZED_STRS.get().filteredCompanies;
            } else {
                recordsInfoText = LOCALIZED_STRS.get().showing + ` ${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${total} ` + LOCALIZED_STRS.get().companies;
            }

        } else {
            recordsInfoText = LOCALIZED_STRS.get().noCompanies;
        }

        return (
            <div className="main-grid">
                <div className="above-table margin__vertical">
                    <span className="records-info bold">{recordsInfoText}</span>
                </div>
                <div className="row margin__vertical--top margin__vertical--loose--bottom">
                    <div className="col-xs-12">
                        <Filter className="filter--container borderRight">
                            <button
                                className="rbtn rbtn--ghost rbtn--md"
                                onClick={this.deleteWatched}
                                disabled={this.state.selection.length == 0}
                            >Remove Selected</button>
                        </Filter>
                        <SearchInputWrapper className="filter--container">
                            <input
                                autoComplete="off"
                                value={this.state.search}
                                placeholder="Search Company"
                                onChange={e => this.setState({search: e.target.value})}
                            />
                        </SearchInputWrapper>
                        {this.state.filters.length > 0 &&
                            <>
                                {
                                    this.state.filters.map(
                                        (filter, i) => this.renderFilter(filter, i)
                                    )
                                }
                                <Filter className="filter--container">
                                      <span>
                                        <ClearFiltersLink className="link link--sentence" onClick={this.onClearFilters}>
                                          {LOCALIZED_STRS.get().clearFilters}
                                        </ClearFiltersLink>
                                      </span>
                                </Filter>
                            </>
                        }
                    </div>
                </div>
                {makeTable()}
            </div>
        );
    };

    render() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const {pages, selectAll, selection} = this.state;

        let data = this.state.data;
        if (this.state.search) {
            data = matchSorter(data, this.state.search, {
                keys: [
                    'name',
                    'address',
                    'sector_description',
                    'date_added',
                ], threshold: matchSorter.rankings.WORD_STARTS_WITH
            });
        }

        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: 'checkbox',
            keyField: 'id'
        };

        return (
            <React.Fragment>
                <div className="row">
                    <div className="table_sortable">
                        <CheckboxTable
                            ref={r => (this.checkboxTable = r)}
                            className="ReactTable--checkboxTable ReactTable--lineHeight--md"
                            data={data}
                            defaultPageSize={data.length <= 25 ? data.length : this.state.pageSize}
                            filtered={this.state.filtered}
                            onFilteredChange={(filtered, column, value) => {
                              this.onFilteredChangeCustom(value, column.id || column.accessor);
                            }}
                            pageSizeOptions= {[5, 10, 20, 25, 50, 100]}
                            columns={this.state.columns}
                            NoDataComponent={this.customNoDataComponent}
                            {...checkboxProps}
                        >
                            {this.renderTotalFilters}
                        </CheckboxTable>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

TableHealthMarkFollow.propTypes = {
    pagesize: PropTypes.number,
    filters: PropTypes.string,
    id: PropTypes.string,
    columns: PropTypes.string.isRequired
};

export default TableHealthMarkFollow;
