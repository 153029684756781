import React from 'react';
import Statement, {Statement2} from './financials/statement.jsx';
import {getRequest} from '../utils/fetch.jsx';
import UTILS from '../utils/utils.jsx';

class Financials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sheets: JSON.parse(props.sheets || '[]'),
            keyNameMapping: JSON.parse(props['key-names'] || '{}'),
            url: props['data-url'] || '',
            isLoading: true,
            isEditable: JSON.parse(props.editable || 'false')
        };
    }
    componentDidMount() {
        if (!UTILS.isEmptyOrUndefined(this.state.url)) {
            this.getFINData();
        } else {
            if (this.state.sheets.length > 0 && Object.keys(this.state.keyNameMapping).length > 0) {
                this.setState({isLoading: false});
            }
        }
    }
    getFINData() {
        getRequest(this.state.url).then(results => {
            this.setState({sheets: results.sheets, keyNameMapping: results.keyNames, isLoading: false});
        }).catch(err => {});
    }
    render() {
        return (
            <div>
                {!this.state.isLoading &&
                    this.state.sheets.map((sheet, index) => {
                        return (
                            <div key={index}>
                                {this.getStatement(sheet)}
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    getStatement(sheet) {
        return <Statement name={sheet.name} periods={sheet.periods} isEditable={this.state.isEditable}
                          keyNameMapping={this.state.keyNameMapping[sheet.name]}/>;
    }
}

class Financials2 extends Financials {
    getStatement(sheet) {
        return <Statement2 name={sheet.name} periods={sheet.periods} isEditable={this.state.isEditable}
                          keyNameMapping={this.state.keyNameMapping[sheet.name]}/>;
    }

}

export { Financials as default, Financials2};
