import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Autosuggest from 'react-autosuggest';

const renderSuggestion = suggestion => (
  <div>
      {suggestion.display}
  </div>
);

class AutoSuggest extends React.Component {
  constructor(props) {
    super(props);

    const options = JSON.parse(props.options || '[]');
    const key = props['option-key'] || 'display';
    const arr = options.filter(option => option[key].toLowerCase() === (props.value || '').toLowerCase());
    const { display, code } = (arr.length !== 0) ? arr[0] : { display: '', code: ''};

    this.state = {
      value: display,
      suggestions: [],
      options: options,
      placeholder: props.placeholder || '',
      hiddenValue: code
    };
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : this.state.options.filter(option => option.display.toLowerCase().slice(0, inputLength) === inputValue);
  };

  getSuggestionValue = suggestion => suggestion.display;

  onChange = (event, {newValue}) => {
    this.setState({value: newValue});
  };

  onBlur = (event) => {
    if(this.state.options.filter(option => option.display.toLowerCase() === this.state.value.toLowerCase()).length === 0){
      this.setState({value: '', hiddenValue: ''});
    } else {
      this.onSuggestionSelected(event, { suggestion: this.getSuggestions(this.state.value)[0] });
    }
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
      const key = this.props['option-key'] || 'display';
      this.setState({hiddenValue: suggestion[key]});
      this.onSuggestionsClearRequested();
  };

  onSuggestionsFetchRequested = ({value}) => {
    this.setState({suggestions: this.getSuggestions(value)});
  };

  onSuggestionsClearRequested = () => {
    this.setState({suggestions: []});
  };

  handleClear = () => {
    this.setState({
        value: '',
        hiddenValue: ''
    });
  }

  render() {
    const {value, suggestions, hiddenValue } = this.state;
    const inputProps = {
      id: this.props.id,
      placeholder: this.props.placeholder,
      value: value,
      onChange: this.onChange,
      onBlur: this.onBlur
    };
    return (
      <div className="autosuggest">
        <input autoComplete="off" name={this.props['input-name']} id={this.props['input-name']} type="hidden" value={hiddenValue} />
        <Autosuggest
            suggestions={suggestions}
            highlightFirstSuggestion={true}
            onSuggestionSelected={this.onSuggestionSelected.bind(this)}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
            getSuggestionValue={this.getSuggestionValue.bind(this)}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
      {this.state.value &&
        <i
            className="icon icon--close icon--backgroundGrey icon--selectable"
            onClick={this.handleClear.bind(this)}>
        </i>
      }
      </div>
    );
  }
}

export default AutoSuggest;
