class RRRequest {
    constructor(props) {
        this.url = props.url;
        this.attempts = props.attempts;
        this.resolve = props.resolve;
        this.reject = props.reject;
        this.includeMeta = props.meta || false;
        this.timer = null;
        this.get();
    }
    tryAgain() {
        this.attempts--;
        clearInterval(this.timer);
        this.timer = setTimeout(() => {
            this.get();
        }, 2000);
    }
    get() {
        fetch(this.url, {
          credentials: 'include',
          redirect: 'follow'
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('No connect');
        }).then(data => {
            if(this.includeMeta){
                data.url = this.url;
                this.resolve(data);
            } 
            if (data.results){
                this.resolve(data.results);
            } else {
                this.resolve(data);
            }
        }).catch(err => {
            if (this.attempts !== 0) {
                this.tryAgain();
            } else {
                this.reject(err);
            }
        });
    }
}
const getRequest = (url, attempts = 0, meta = false) => {
    return new Promise((resolve, reject) => {
        return new RRRequest({url: url, attempts: attempts, resolve: resolve, reject: reject, meta: meta});
    });
};
export {getRequest};
