import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  width: 50px;
  border-left: none;
  margin-left: -150px;
  z-index: 1;

  &:hover {
    transition: background-color 0.3s ease;
    .icon {
        background-color: #98a4ae;
        transition: background-color 0.3s ease;
    }
  }

  & .icon {
    outline: none;
    padding: 0 !important;
    line-height: 18px !important;
    visibility: ${props => props.isHidden ? 'hidden' : 'visible'};
  }
`;

const Icon = styled.i`
    margin: 0 auto;
`;

export default function CancelIcon({ onClick, isHidden = false }) {
  return (
    <Div isHidden={isHidden}>
      <Icon
        className="icon icon--backgroundGrey icon--close"
        role="button"
        tabIndex="-1"
        onClick={onClick}
        onKeyUp={onClick}
      />
    </Div>
  );
}
