import React from 'react';
import styled, {css} from 'styled-components';
import ReactSelect from 'react-select/lib/Async';
import { components } from 'react-select';

const styles = {
  container: provided => ({
    ...provided,
    width: css`calc(100% - 50px)`,
    border: '1px solid #c7d2db',
    borderRight: 'none'
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: '18px',
    color: '#98a4ae'
  }),
  option: provided => ({
    width: '100%',
    height: '100%',
    lineHeight: '40px',
    padding: '0 20px',
    display: 'inline-block',
    cursor: 'pointer'
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '18px',
    color: '#000',
    lineHeight: '22px',
    padding: '2px 0'
  })
};

const OptionDiv = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: bold;

  ${props => (props.isFocused ? 'background-color: #ffe900;' : '')}
`;

const Highlight = styled.span`
  font-weight: normal;
`;

const Option = props => {
  const {
    isFocused,
    isSelected,
    label,
    selectProps: { inputValue }
  } = props;

  const higlightRegEx = new RegExp(`(${inputValue})`, 'gi');
  const parts = label.split(higlightRegEx);
  let hasHighlighted = false;

  return (
    <OptionDiv isSelected={isSelected} isFocused={isFocused}>
      <components.Option {...props}>
        {parts.map((part, i) => {
          if (
            !hasHighlighted &&
            part.toLowerCase() === inputValue.toLowerCase()
          ) {
            hasHighlighted = true;
            return <Highlight key={i}>{part}</Highlight>;
          } else {
            return <span className="text__casing--capitalize" key={i}>{part}</span>;
          }
        })}
      </components.Option>
    </OptionDiv>
  );
};

export default function Select({
  value,
  name,
  noOptionsMessage,
  onChange,
  getOptions,
  onFocus,
  onBlur,
  placeHolder
}) {
  return (
    <ReactSelect
      styles={styles}
      name={name}
      value={value}
      classNamePrefix="select"
      placeholder={placeHolder || 'Search'}
      noOptionsMessage={() => noOptionsMessage}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      blurInputOnSelect
      loadOptions={getOptions}
      components={{ Option }}
      maxMenuHeight={1200}
      placeHolder={placeHolder}
    />
  );
}
