import React, {Component} from 'react';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import DateSpan, {DEFAULT_SHORT_DATE_FORMAT} from '../utils/dates.jsx';
import LongMonthSpan, {DEFAULT_LONG_MONTH_FORMAT} from '../utils/longMonth.jsx';

class Snapshot extends React.Component {
    constructor(props) {
        super(props);

        const fhr = JSON.parse(props.fhr || '{}');
        const company = JSON.parse(props.company || '{}');

        this.state = {
            fhr: fhr.score,
            financialDate: fhr['financial-date'],
            fhrDate: `${fhr['date']} ${fhr['equity-year']}` ,
            yearlyDelta: Math.abs(fhr['yearly-delta']),
            isIncrease: (fhr['yearly-delta'] > 0),
            hasNoDelta: typeof fhr['yearly-delta'] === 'undefined' || fhr['yearly-delta'] === null,
            hasEPD: !UTILS.isEmptyOrUndefined(fhr.pd),
            EPD: fhr.pd || 0,
            name: company.name,
            RRID: company.rrid,
            legalName: (!company.legalName) ? '': company.legalName + ', ',
            risk: UTILS.getRiskName(fhr.score)
        };
    }
    render() {
        return (
          <div>
              <div className="fhr animated animated--fadeIn" id={this.props.id}>
                  <div
                      className={this.state.isIncrease ? 'fhr__score fhr__score--' + this.state.risk + ' fhr__score--increase' : 'fhr__score fhr__score--' + this.state.risk + ' fhr__score--decrease'}>
                      <div className="fhr__details">
                          <div className="fhr__score">
                              <div>
                                  <h2 className="fhr__score__details">
                                      {this.state.fhr}
                                      {!this.state.hasNoDelta &&
                                      <sup>
                                          {this.state.yearlyDelta}
                                          {(this.state.isIncrease && this.state.yearlyDelta > 0) &&
                                          <i className="icon icon--increase" aria-hidden="true"></i>
                                          }
                                          {(!this.state.isIncrease && this.state.yearlyDelta > 0) &&
                                          <i className="icon icon--decrease" aria-hidden="true"></i>
                                          }
                                          {(!this.state.isIncrease && this.state.yearlyDelta === 0) &&
                                          <i className="icon icon--minimize" aria-hidden="true"></i>
                                          }
                                      </sup>
                                      }
                                  </h2>
                              </div>
                              <div className="badge__footer">
                                  {this.renderDate()}
                              </div>
                          </div>
                      </div>
                  </div>
                  <div aria-hidden="true" className="fhr__levels">
                      <ul>
                          <li className="fhr__level fhr__level--verylow"></li>
                          <li className="fhr__level fhr__level--low"></li>
                          <li className="fhr__level fhr__level--medium"></li>
                          <li className="fhr__level fhr__level--high"></li>
                          <li className="fhr__level fhr__level--veryhigh"></li>
                      </ul>
                  </div>
                  {this.renderCompanyDetails()}
              </div>
              <p className="sr-only">
                The FHR is {this.state.fhr} for the financial period of {' '}
                <LongMonthSpan date-format={DEFAULT_LONG_MONTH_FORMAT} data-datetime={this.state.financialDate} />
                {' '} representing fiscal {this.state.fhrDate}.
                {!this.state.hasNoDelta &&
                  <span> It has a Delta of {this.state.yearlyDelta} </span>
                }
              </p>
          </div>
        );
    }
    renderExtraCompanyDetails() {
        return '';
    }
    renderCompanyDetails() {
        return <div className="company__details">
            <h1 className="rr-header-1 !leading-none">{this.state.name}</h1>
            {this.renderExtraCompanyDetails()}
            <p className={'risk__copy risk__copy--' + this.state.risk}>{LOCALIZED_STRS.get().risk[this.state.risk]}</p>
            {(this.state.hasEPD) &&
            <p className="company__summary company__summary--uppercase company__summary--epd">
                <strong>{LOCALIZED_STRS.get().epd}:</strong> {this.state.EPD}%
            </p>
            }
            {this.props.reveal &&
            <button className="accordion__more" revealer={this.props.reveal} tracking-name="snapshot"
                 reveal-active={this.props['reveal-active']}>
                MORE<span className="sr-only"> about Risk Analysis</span>
            </button>
            }
        </div>;
    }
    renderDate() {
        return <p className="fhr__dates">{UTILS.getDisplayDate(this.state.financialDate)}
            <span>({LOCALIZED_STRS.get().fiscal} {this.state.fhrDate})</span>
        </p>;
    }
}

class Snapshot2 extends Snapshot {
    renderDate() {
        const tail = '(' + LOCALIZED_STRS.get().fiscal + ' ' + this.state.fhrDate + ')';
        return <p className="fhr__dates"><DateSpan date-format={DEFAULT_SHORT_DATE_FORMAT} data-datetime={this.state.financialDate} tail={tail}/></p>;
    }

}

class Snapshot3 extends Snapshot2 {
    renderExtraCompanyDetails(){
        if (this.state.RRID){
            return <p className="company__summary company__summary--rrid">
                <strong>RRID:</strong> {this.state.RRID}
            </p>;
        }
        return '';

    }

}

export { Snapshot as default, Snapshot2, Snapshot3 };
