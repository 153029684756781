import React from 'react';
import track from 'react-tracking';
import Loader from '../../utils/loader.jsx';
import CurrencyPicker from './currencyPicker.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';
import SummaryBase from './summary_base.jsx';


class OnDemandSummary extends React.Component {
    constructor(props) {
        super(props);
        const reports = JSON.parse(props.reports || '[]').map(params => {
            return {
                name: (LOCALIZED_STRS.get().reports[params.report] ||  LOCALIZED_STRS.get().reports.unknown).name,
                key: params.report,
                isLoading: false,
                company_id: params.company_id,
                hasCurrencyChange: (params.currencyConvertible) ? params.currencyConvertible : false,
                hasOnDemand: params.formats.indexOf('html') !== -1,
                jobId: null,
                sort: LOCALIZED_STRS.get().reports[params.report].sort
            };
        }).sort((a, b) => a.sort - b.sort);
        this.state = {
            reports: reports,
            iframeLink: '',
            pollTime: props['poll-milliseconds'],
            currencies: JSON.parse(props.currencies),
            baseCurrency: props.base,
            selectedCurrency: {
                code: props.base,
                value: 1
            }
        };
    }

    generateUrl(report) {
        let url = `company_id=${report.company_id}&report_type=${report.key}`;
        if (report.jobId !== null) {
            url += `&jobId=${report.jobId}`;
        }
        if (report.hasCurrencyChange && this.state.selectedCurrency.code !== this.state.baseCurrency) {
            url += `&code=${this.state.selectedCurrency.code}&value=${this.state.selectedCurrency.value}`;
        }
        if (report.hasOnDemand) {
            url += '&on_demand=1';
        }
        return url;
    }

    @track((props, state, args) => {
        const report = args[0];
        return {
            OnDemand: report.hasOnDemand,
            report: report.name,
            hasCurrencyChange: report.hasCurrencyChange,
            event: 'request-report-' + report.name
        };
    })
    requestReport(report) {
        // tracking with appcues if available
        if (window.Appcues !== undefined) {
          window.Appcues.track('Clicked report ' + report.name, {
            OnDemand: report.hasOnDemand,
            report: report.name,
            hasCurrencyChange: report.hasCurrencyChange,
          });
        }
        if (report.hasOnDemand) {
            this.requestOnDemandReport(report);
        } else {
            this.setState({iframeLink: null});
            this.requestDownloadReport(report);
        }
    }
    @track((props, state, args) => {
        const currency = args[0];
        return {
            event: 'download-report-currency-changed',
                currency: currency.code
        };
    })
    onCurrencyChange(currency) {
        this.setState({
            selectedCurrency: currency
        });
    }
    requestOnDemandReport(report) {
        const urlparams = this.generateUrl(report);
        window.open('/reportsapi/html/loading?'+urlparams, '_blank', `directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=1,width=1000,height=${screen.height}`);
    }
    requestDownloadReport(report) {
        if (!report.isLoading) {
            const reports = [...this.state.reports];
            const rep = reports.find(rep => rep.name === report.name);
            rep.isLoading = true;
            rep.hasFailed = false;
            this.setState({reports: reports});
            const urlparams = this.generateUrl(report);
            const url = `/reportsapi/status/${rep.company_id}/${rep.key}?`+ urlparams;

            fetch(url, {
                credentials: 'include',
                redirect: 'follow'
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('No connect');
            }).then(res => {
                if (res.status === 'failed' || res.status === 'error') {
                    rep.hasFailed = true;
                    rep.isLoading = false;
                    rep.jobId = null;
                    this.setState({reports: reports});
                } else if (res.status === 'success') {
                    rep.isLoading = false;
                    rep.jobId = null;
                    this.setState({iframeLink: res.link, reports: reports});
                } else if (res.status === 'queued' || res.status === 'started') {
                    setTimeout(() => {
                        rep.jobId = (res.jobId) ? res.jobId : null;
                        rep.isLoading = false;

                        this.requestDownloadReport(rep);
                    }, this.state.pollTime);
                }
            }).catch(err => {});
        }
    }
    render() {
        return (
            <SummaryBase { ...this.props } >
            <React.Fragment>
                { this.state.currencies.length > 0 &&
                  <CurrencyPicker show="false" onChange={this.onCurrencyChange.bind(this)} base={this.state.selectedCurrency} currencies={this.state.currencies}/>
                }
                {this.state.reports.map((report, index) => {
                      return (
                          <button
                                  key={index}
                                  onClick={this.requestReport.bind(this, report)}
                                  className={`rbtn rbtn--onFocus rbtn--textStandard rbtn--${report.hasFailed ? 'danger' : 'primary' }`}
                                  disabled={this.state.baseCurrency !== this.state.selectedCurrency.code && !report.hasCurrencyChange ? 'disabled' : ''}
                                  name={report.key}>
                              {report.isLoading && <Loader/>}
                              {report.name}
                              { (this.state.baseCurrency !== this.state.selectedCurrency.code && report.hasCurrencyChange) &&
                                  <sup>{this.state.selectedCurrency.code}</sup>
                              }
                          </button>
                      );
                  })}
                <iframe aria-label="Change currency" style={{'visibility': 'hidden'}, {'height': '0px'}} src={this.state.iframeLink} />
            </React.Fragment>
            </SummaryBase>
        );
    }
}

OnDemandSummary.defaultProps = Object.assign({}, SummaryBase.defaultProps, {
    currencies: '[]',
    'poll-milliseconds': 2000,
    type: 'ondemand'
});

export default OnDemandSummary;
