import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { uniqueId } from 'lodash';

class ModalContent extends React.Component {
    constructor(props) {
        super(props);
        const id = uniqueId('modal-');
        this.state = {
            id: id,
            isModalOpen: false,
            isInnerModalOpen: false,
            header: props.header,
            content: JSON.parse(props.content || [])
        };
        // bind functions
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);

    }

    // close modal
    closeModal() {
        this.setState({isModalOpen: false});
    }

    // open modal
    openModal() {
        const id = this.state.id;
        this.setState({isModalOpen: true});
        const elem = document.getElementById(id);
        document.body.appendChild(elem);
        document.body.setAttribute('aria-hidden','true');
    }

    render() {
        const id = this.state.id;
        return (
            <React.Fragment>
                <div className="link link--sentence" onClick={this.openModal}>{this.state.header}</div>
                <div id={id} className="modal__outter" style={{
                        display: this.state.isModalOpen
                            ? 'block'
                            : 'none'
                    }}>
                    <div className="modal__overlay" onClick={this.closeModal}></div>
                    <div className="modal__container" >
                        <div className="modal__header">
                          <h2>{this.state.header}</h2>
                          <button
                            onClick={this.closeModal}
                            className="rbtn rbtn--transparent"
                          >
                              <i className="icon icon--close close-menu" aria-hidden="true"></i>
                          </button>
                        </div>
                        <div className="modal__content">
                            {this.state.content.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <p>
                                            {item.text}
                                        </p>
                                        <img src={item.imgURL} />
                                        <a href={item.link.url} className="link" target="_blank" rel="noopener noreferrer">{item.link.display}</a>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="modal__footer">
                            <button className="rbtn rbtn--default" onClick={this.closeModal}>Close</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default ModalContent;
