import React, { Component, useState, useContext } from 'react';
import SectorSelect from './sector-select.jsx';
import SearchContainer from './search-container.jsx';
import {
  QUERY_FOCUS_MESSAGE,
  QUERY_NO_RESULTS_MESSAGE,
  QUERY_TOO_SHORT_MESSAGE,
  QUERY_ERROR_MESSAGE
} from './constants';
import {
  getSectorSuggestions,
  transformSuggestionsToOptions,
  search
} from './utils';
import {csrftoken} from '../../../utils/csrf.jsx';
import { DynamicBenchmarkContext } from '../index.jsx';
import debounce from 'debounce-promise';

export default function SectorSearch(props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [noOptionsMessage, setNoOptionsMessage] = useState(QUERY_TOO_SHORT_MESSAGE);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [options, SetOptions] = useState(null);
  const sectorContext = useContext(DynamicBenchmarkContext);
  const { sectorSelected, setSectorSelected} = sectorContext;

  function getOptions (query) {
    setSearchQuery(query);
      return new Promise(resolve => {
        setSearchQuery(query);

        if (query.length < 2) {
          resolve([]);
          setNoOptionsMessage(QUERY_TOO_SHORT_MESSAGE);
        } else {
          getSectorSuggestions(
            `${props.sectorsuggestionurl}?query=${query}&digits=6&linked_to_peer_companies=true`
          )
            .then(suggestions => {
              const options = transformSuggestionsToOptions(suggestions);

              resolve(options);

              if (this.state.searchQuery === query) {
                setNoOptionsMessage(
                  options.length === 0
                    ? QUERY_NO_RESULTS_MESSAGE
                    : QUERY_FOCUS_MESSAGE
                );
              }
            })
            .catch(e => {
              resolve([]);
              if (searchQuery === query) {
                setNoOptionsMessage(QUERY_ERROR_MESSAGE);
              }
            });
        }
      });
  }

  function handleSelectedSector (selectedSector) {
    setNoOptionsMessage(QUERY_FOCUS_MESSAGE);
    setSectorSelected(selectedSector);
  }

  function handleInputFocus() {
    setNoOptionsMessage(QUERY_FOCUS_MESSAGE);
    setIsInputFocused(true);
  }

  return (
    <SearchContainer>
      <SectorSelect
        value={sectorSelected}
        name={props.name}
        id={props.id}
        getOptions={debounce((query) => getOptions(query), 250)}
        noOptionsMessage={noOptionsMessage}
        onChange={(value) => handleSelectedSector(value)}
        onFocus={() => handleInputFocus}
        isClearable
      />
    </SearchContainer>
  );
}
