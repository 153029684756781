import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';


class SingleDatepicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || 'reminder_date_id',
            placeholder: props.placeholder,
            date: props.date !== undefined ? moment(props.date, props.dateFormat, true) : null,
            dateFormat: props.dateFormat || null,
            displayInvalidMessage: false,
            // if prop 'required' is absent, set required to false
            required:  props.required !== undefined ? true : false,
            no_border: true,
            readOnly: props.readOnly !== undefined ? true : false,
            showDefaultInputIcon: props.showDefaultInputIcon || true,
            minDate: moment(props.mindate, 'MM/DD/YYYY').toDate() || null,
            maxDate: moment(props.maxdate, 'MM/DD/YYYY').toDate() || null
        };
    }

    onClosed = (date) => {
        // check for valid date using moment's strict validation
        const isValidDate = moment(date.date, 'MM/DD/YYYY', true).isValid();
        if(isValidDate) {
            this.setState({
                date: date.date,
                displayInvalidMessage: false
            });
        } else {
            this.setState({
                displayInvalidMessage: true
            });
            // react-dates has bug that does not allow setState of date (even to null) when date is an incorrect format
            document.querySelector(`input[id='${this.state.id}']`).value = null;
        }
    }

    render() {
        return (
            <React.Fragment>
                <SingleDatePicker
                  placeholder={this.state.placeholder}
                  showDefaultInputIcon={this.state.showDefaultInputIcon}
                  noBorder={this.state.no_border}
                  readOnly={this.state.readOnly}
                  date={this.state.date} // momentPropTypes.momentObj or null
                  required={this.state.required}
                  onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                  onClose={({ date }) =>this.onClosed({ date })}
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  id={this.state.id} // PropTypes.string.isRequired,
                  isOutsideRange={date => date.isBefore(this.state.minDate) || date.isAfter(this.state.maxDate)}
                />
                {this.state.displayInvalidMessage &&
                    <div className="errors">
                        <div id="dateError" className="error">{LOCALIZED_STRS.get().date_error}</div>
                    </div>
                 }
            </React.Fragment>
      );
    }
}

export default SingleDatepicker;
