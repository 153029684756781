import React from 'react';
import DateSpan from '../../utils/dates.jsx';

class Fins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: JSON.parse(props.list || '[]'),
      financialDate: props.financialdate
    };
  }
  render() {
    return (
      <div>
        <table className="table table--summary mb-4">
          <thead>
            <tr>
              <th scope="col">
                {this.props.currency} {this.props['data-scale']}
              </th>
              <th scope="col" className="numeric">
                {this.renderDate()}
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.list.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{item.name}</th>
                  <td className="numeric">{item.current}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {this.props.iscompanypublic === 'False' && 
          <div
          slot-id="ui:slot:download-source-financials"
          data-company-id={this.props.companyid}
        ></div>
        }
      </div>
    );
  }
  renderDate() {
    return this.state.financialDate;
  }
}

class Fins2 extends Fins {
  renderDate() {
    return <DateSpan data-datetime={this.state.financialDate} />;
  }
}

export { Fins as default, Fins2 };
