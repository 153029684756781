import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Input from '../forms/common/input.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import CSRFToken from '../utils/csrf.jsx';
import 'formdata-polyfill';


class RequestPublicAddition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitUrl: props.url,
            formSubmitted: false,
            formSuccess: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    handleChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
    }

    handleSubmit(event) {
        event.preventDefault();
        const self = this;
        const formElement = document.querySelector('form');
        const formData = new FormData(formElement);
        const request = new XMLHttpRequest();
        request.open('POST', this.state.submitUrl);

        request.setRequestHeader(
            'X-CSRFToken',
            formData.get('csrfmiddlewaretoken'));
        request.onload = function() {
            if (request.status == 201){
                self.setState({
                    formSubmitted: true,
                    formSuccess: true
                });
            } else {
                self.setState({
                    formSubmitted: true,
                    formSuccess: false
                });
            }
        };
        request.send(formData);
    }

    render() {
      return (
          <div className="request_public_addition">
            { !this.state.formSubmitted &&
                <form autoComplete="off" onSubmit={this.handleSubmit} action={this.state.submitUrl} method="POST">
                  <CSRFToken />
                  <Input
                      id="id_company_name"
                      value=""
                      label={LOCALIZED_STRS.get().company_name}
                      type="text"
                      name="company_name"
                      required="required"
                      onChange={event => this.handleChange(event) }
                  />
                  <Input
                      id="id_ticker"
                      value=""
                      label={LOCALIZED_STRS.get().ticker}
                      type="text"
                      name="ticker"
                      required="required"
                      onChange={event => this.handleChange(event) }
                  />
                  <div className="form--actions">
                      <button
                        onClick={this.props.handler}
                        className="rbtn rbtn--default"
                      >
                          {LOCALIZED_STRS.get().cancel}
                      </button>
                      <button
                          type="submit"
                          name="submit_request"
                          className="rbtn rbtn--primary"
                      >
                          {LOCALIZED_STRS.get().request}
                      </button>
                  </div>
                </form>
            }
            { (this.state.formSubmitted && this.state.formSuccess) &&
                <div className="request_public_addition form form--centered form--submitted animated animated--fadeInTop">
                    <i className="icon icon--submitted-circle" aria-hidden="true"></i>
                    {this.state.formSuccess &&
                        <h3>{LOCALIZED_STRS.get().thank_you}</h3>
                    }
                </div>
            }
            { (this.state.formSubmitted && !this.state.formSuccess) &&
                <div className="request_public_addition form form--centered form--failed animated animated--fadeInTop">
                    <i className="icon icon--failed-circle" aria-hidden="true"></i>
                        <h3>{LOCALIZED_STRS.get().general_error}</h3>
                </div>
            }
        </div>
      );
    }
}

export default RequestPublicAddition;
