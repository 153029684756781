import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  font-size: 18px;
  background: none;
  border: none;
  width: calc(60% - 140px);
  height: 100%;
  line-height: 52px;
  display: block;
  padding: 0 8px;
  letter-spacing: 0;
  font-family: Source Sans Pro, sans-serif;
  color: #000;
  outline: none;
  box-shadow: none;
  box-sizing: border-box;

  &::-ms-clear {
      display: none;
  }

  &::placeholder {
    color: #98a4ae;
  }
`;

export default function CompanyInput({ value = '', onChange, onFocus, onBlur, className }) {
  return (
    <Input
      type="text"
      placeholder="Search for a Company HealthMark"
      required="required"
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
    />
  );
}
