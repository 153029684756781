import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';
import styled from 'styled-components';
import { DownloadIcon, LoadingIcon } from './icons.jsx';
import isIe11 from '../../utils/isIe11.jsx';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';

const IconWrapper = styled.span`
    padding-right: 4px;
    display: inline-block;
`;

class HealthMarkChildrenDownload extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            isLoading: false,
            url: props.url,
            fileName: props.filename
        });

        this.handleDownload = this.handleDownload.bind(this);
    }

    handleDownload (event) {
      if (!isIe11()) {
        event.preventDefault();
        this.setState({isLoading: true});
        fetch(this.props.url, {
            method: 'GET',
            headers: {
              'X-Requested-With': 'XMLHttpRequest'
            },
            credentials: 'same-origin'
        }).then((response) => {
            if(response.ok) {
                return response.blob();
            }
        }).then((myBlob) => {
            // create a link
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            // store the blob in link URL
            const objectURL = URL.createObjectURL(myBlob);
            a.href = objectURL;
            a.download = this.state.fileName;
            a.click();
        }).then(() => {
            this.setState({isLoading: false});
        });
      }
    }

    render() {
        const linkClass = classNames({
          'link': true,
          'link--paragraph': true,
          'link--disabled': this.state.isLoading
        });
        return (
            <a
                href={this.state.url}
                className={linkClass}
                onClick={(event) => this.handleDownload(event)}
                disabled={this.state.isLoading}
            >
            <IconWrapper>
            {this.state.isLoading ? (
                <LoadingIcon />
              ) : (
                <DownloadIcon />
             )}
            </IconWrapper>
            {this.state.isLoading ? (
                LOCALIZED_STRS.get().downloadInProgress
              ) : (
                LOCALIZED_STRS.get().downloadHealtMarkChildren
             )}
             </a>
        );
    }
}

export default HealthMarkChildrenDownload;
