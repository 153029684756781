import React from 'react';

class GetInitials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            string: this.props.string
        };
    }

    componentDidMount() {
        const stringWords = this.state.string.split(' ');
        let initials = stringWords[0].substring(0, 1).toUpperCase();
        this.setState({
            formattedString: initials
        });

        if (stringWords.length > 1) {
            initials += stringWords[stringWords.length - 1].substring(0, 1).toUpperCase();
            this.setState({
                formattedString: initials
            });
        }
    }

    render() {
        return (
            <span className="initials">{this.state.formattedString}</span>
        );
    }

}

export default GetInitials;
