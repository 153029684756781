import UTILS from './utils.jsx';
import {csrftoken} from './csrf.jsx';

class AjaxForm{
    constructor(ele, redux_store, props) {
        this.ele = ele;
        this.redux_store_model = props.redux_store_model;
        this.redux_action = props.redux_action;
        this.ajax_submit = props.ajax_submit;
        this.store = redux_store;
        this.ele.addEventListener('submit', (e) => {
            e.preventDefault();
            e.stopPropagation();

            const headers = new Headers();
            headers.append('X-CSRFToken', csrftoken());
            headers.append('Accept', 'application/json');
            headers.append('Content-Type', 'application/json');
            const formData = new FormData(this.ele);
            const ajaxData = {};
            for (const key of formData.keys()) {
                ajaxData[key] = formData.get(key);
            }

            fetch(this.ajax_submit, {
                method: 'POST',
                headers: headers,
                credentials: 'include',
                body: JSON.stringify(ajaxData)
            })
            .then(async (res) => {
                if (res.status == 200 || res.status == 201) {
                    if (this.redux_store_model) {
                        const result_data = await res.json();
                        this.store.dispatch({'type': this.redux_action, 'payload': result_data});
                    }
                } else {
                    console.log('status ' + res.status);
                }
            });


        });
    }

}

const attach_ajax_forms = (redux_store) => {
    document.ajax_forms = new Map();
    document.addEventListener('DOMContentLoaded', () => {
        [...document.querySelectorAll('[ajax_submit]')].forEach(ele => {
          const attrs = UTILS.getAttrs(ele);
          document.ajax_forms.set(ele.getAttribute('ajax_submit'), new AjaxForm(ele, redux_store, attrs));
        }, this);
    });
};

export {attach_ajax_forms};
