import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import Select from 'react-select';
import moment from 'moment';
import { intersection, isEmpty } from 'lodash';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import 'formdata-polyfill';
import isIe11 from '../utils/isIe11.jsx';
import {csrftoken} from '../utils/csrf.jsx';
import styled from 'styled-components';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import { toast, ToastContainer, cssTransition } from 'react-toastify';
import { TickIcon, ExclamationIcon, LoadingIcon, DownloadIcon, ErrorIcon } from './icons.jsx';

const StyledToastContainer = styled(ToastContainer)`
    width: 95vw;
    padding: 0;
    top: 88px;
    left: 0;
    right: 0;
    position: fixed;
    margin: 0 auto;

    .Toastify__toast {
        padding: 24px;

        &-body {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-top: -4px;
            }

            span {
                padding-left: 12px;
            }
        }

        &.toast {
            &--success {
                background-color: #e6faf3;
                color: #006647;
                .icon {
                    color: #4A4A4A;
                    margin: auto 0;
                    padding-bottom: 6px;
                }
            }

            &--failed {
                background-color: #FDCECE;
                color: #ED4C4A;
                .icon {
                    color: #4A4A4A;
                    margin: auto 0;
                    padding-bottom: 6px;
                }
            }
        }
    }
`;

const StyledReactModal = styled(ReactModal)`
    max-width: 500px;
`;

const ModalContent = styled.div`
    margin: 16px auto;
    line-height: normal;
`;

const ModalFooter = styled.div`
    text-align: right;
    :before {
        content: "";
        border: 1px solid #f4f6f9;
        display: block;
        margin: 0 -34px 24px;
    }
`;

const DeleteButton = styled.button`
    min-width: 95px;
    width: auto;
    height: auto;
`;

const DeleteText = styled.span`
    &:after {
        font-family: inherit;
        content: 'Remove';
        min-width: 95px;
        font-size: inherit;
        margin-left: inherit;
    }
`;

const SearchInputWrapper = styled.div`
    margin: 0 auto;
    float: left;

    &:before {
        content: '\\e915';
        font-size: 18px;
        color: #51585d;
        font-family: RapidRatings;
        padding-top: 8px;
        padding-left: 16px;
        z-index: 1;
        position: absolute;
    }
    input {
        padding: 8px 8px 8px 40px;
        min-width: 235px;

        &:hover {
            cursor: text;
        }
    }
`;

const Filter = styled.div`
    margin: 0 auto;
    float: left;
`;

const ClearFiltersLink = styled.a`
    line-height: 40px;
`;

const FlexRow = styled.div`
    justify-content: space-between;
    align-items: center;
    display: flex;
`;

const StyledHR = styled.hr`
    margin: 16px auto;
`;

const ClearButton = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;

    i {
        color: #ccc;
        font-size: 12px;
        padding-top: 2px;
        transition: color 0.2s ease-out;
    }

    &:hover {
        cursor: pointer;

        i {
            color: #4a4a4a;
            transition: color 0.2s ease-in;
        }
    }
`;

const IconWrapper = styled.span`
    padding-right: 4px;
    display: inline-block;
`;

const CheckboxTable = checkboxHOC(ReactTable);

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        padding: 5,
    }),
    menu:(provided, state) => ({
        ...provided,
        zIndex: 3
    }),
    singleValue: (provided, state) => ({
        ...provided,
        height: '100%'
    }),
    container: (provided, state) => ({
        ...provided,
        height: '40px',
        minWidth: '235px'
    }),
    control: (provided, state) => ({
        ...provided,
        height: '40px',

        '&:hover': {
            cursor: 'pointer'
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        marginRight: '24px'
    }),
    input: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0,
        color: '#3B4043'
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#3B4043'
    })
};

class TableHealthMarkFollow extends Component {
    constructor(props) {
        super(props);
        const columns = JSON.parse(this.props.columns);
        Object.keys(columns).map((key) => {
            const column = columns[key];
            if (column.renderer !== undefined) {
                column.Cell = this.cellRenderers[column.renderer];
            }
        });
        this.state = {
            pages: 0,
            columns: columns,
            loading: undefined,
            filters: JSON.parse(this.props.filters),
            filter_select_values: {},
            filtered: [],
            page: -1,
            url: this.props.url,
            hasEditPermissions: props.haseditpermissions !== undefined ? true : false,
            isMasterList: props.ismasterlist !== undefined ? true : false,
            total: 0,
            list_total: null,
            sorted: [],
            searchPlaceholder: props.searchplaceholder,
            searchParam: props.searchparam,
            searchTerm: props.searchterm,
            id: this.props.id,
            pageSize: 10,
            selection: [],
            selectAll: false,
            search: '',
            toBeDeleted: [],
            status: '',
            statusMessage: '',
            customToastID: 'listTableToast',
            isDownloading: false,
            isDeleteModalOpen: false,
            isBulkRemoveAction: false,
            individualIdToBeDeleted: null
        };
    }

    static defaultProps = {
        data:  JSON.parse('[]'),
        filters: '[]',
        assignees: '[]',
        id: 'sortable-table',
        columns: '[]',
        selection: [],
        selectAll: false,
        searchplaceholder: 'Search...',
        searchparam: null,
        searchterm: '',
        searchdebounce: 500,
        searchmin: 2,
        total_requests_label: LOCALIZED_STRS.get().requests,
        total_filtered_requests_label: LOCALIZED_STRS.get().filteredRequests,
        total_no_requests: LOCALIZED_STRS.get().noRequests
    };

    componentDidMount () {
        // remove the delete column if no edit permissions
        this.state.hasEditPermissions === false ? this.state.columns.splice(-1,1) : '';
        // disable the page jumping in footer
        const pageJump = document.querySelector('.-pageJump input');
        pageJump && pageJump.setAttribute('disabled', '');
    }

    cellRenderers = {
        Link: (cellInfo) => {
            return <a href={cellInfo.original.href} className="link link--sentence"> {cellInfo.original[cellInfo.column.id]} </a>;
        },
        CampaignLink: (cellInfo) => {
            return <a href={cellInfo.original.campaign_link} className="link link--sentence"> {cellInfo.original.campaign_name} </a>;
        },
        HealthMarkLink: (cellInfo) => {
            return <a id={`healthmark:link:${cellInfo.original.id}`} href={cellInfo.original.link} className="link link--sentence"> {cellInfo.original.name} </a>;
        },
        Date: (cellInfo) => {
            return <span>{moment(cellInfo.original[cellInfo.column.id], 'YYYY-MM-DD HH:mm:ss').format('MMM Do, YYYY').toString()}</span>;
        },
        Delete: (cellInfo) => {
            return (
                <DeleteButton
                    onClick={(e) => this.openModal({isBulkRemove: false, id: cellInfo.original._id })}
                    id={`healthmark:remove:${cellInfo.original._id}`}
                    className="rbtn rbtn--ghost rbtn--md"
                    title="Remove"
                >
                    <DeleteText />
                </DeleteButton>
            );
        },
        HealthMarkIndicator: (cellInfo) => {
            return <span className={`riskLevel riskLevel__${cellInfo.original.risk_mark_code}`}><span className="riskLevel__text">{cellInfo.original.healthmark_type}</span></span>;
        }
    };

    toggleSelection = (key, shift, row) => {
      // start off with the existing state
      let selection = [...this.state.selection];
      const keyIndex = selection.indexOf(key);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        selection = [
          ...selection.slice(0, keyIndex),
          ...selection.slice(keyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        selection.push(key);
      }
      // update the state
      this.setState({ selection });
    };

    toggleAll = () => {
      /*
        The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
        ReactTable and then get the internal state and the 'sortedData'.
        That can then be iterrated to get all the currently visible records and set
        the selection state.
      */
      const selectAll = !this.state.selectAll;
      const selection = [];
      if (selectAll) {
        // we need to get at the internals of ReactTable
        const wrappedInstance = this.checkboxTable.getWrappedInstance();
        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance.getResolvedState().sortedData;
        // we just push all the CP onto the selection array
        currentRecords.forEach(item => {
            selection.push(item._original._id);
        });
      }
      this.setState({ selectAll, selection });
    };

    isSelected = key => {
      /*
        Instead of passing our external selection state we provide an 'isSelected'
        callback and detect the selection state ourselves. This allows any implementation
        for selection (either an array, object keys, or even a Javascript Set object).
      */
      return this.state.selection.includes(key);
    };

    renderFilter = (filter, i) => {
        const filterName = filter.name;
        const options = filter.options;
        const placeholder = filter.placeholder;
        const filter_select_values = this.state.filter_select_values;
        return <Filter className="filter--container" key={i}>
                <div className="select--container">
                    <Select
                        id={`healthmark:filter:${filterName}`}
                        className={'table-filter-' + filterName}
                        styles={customStyles}
                        onChange={entry => {
                            filter_select_values[filterName] = entry;
                            this.setState({ filter_select_values: filter_select_values});
                            this.onFilteredChangeCustom(entry.id, filterName);
                        }}
                        value={this.state.filter_select_values[filterName]}
                        options={options.map(([value, label]) => {
                            return {id: value, value: label, label: label};
                        })}
                        placeholder={placeholder}
                    />
                    {this.state.filter_select_values[filterName] != null &&
                        <ClearButton
                            onClick={() => (
                                this.onFilteredChangeCustom('', filterName),
                                Object.keys(filter_select_values).map((key) => {
                                    filter_select_values[filterName] = null;
                                })
                            )}
                        >
                            <i
                                className="icon icon--close close-menu"
                                aria-hidden="true"
                            ></i>
                        </ClearButton>
                    }
                </div>
            </Filter>;
    };

    onFilteredChangeCustom = (value, accessor) => {
        const filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter['id'] === accessor) {
                    if (value === '' || !value.length) {
                        filtered.splice(i, 1);
                    } else {
                        filter['value'] = value;
                    }

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });

        this.fetchData({page:-1, doNotRedirect: true});

    };

    onClearFilters = () => {
        const filter_select_values = this.state.filter_select_values;
        Object.keys(filter_select_values).map((key) => {
            filter_select_values[key] = null;
        });
        this.setState({
            filtered: [],
            filter_select_values: filter_select_values,
            searchTerm: ''
        });
    };

    closeModal = () => {
      this.setState({
        isDeleteModalOpen: false,
        isBulkRemoveAction: false
      });
    }

    openModal = ({ isBulkRemove, id }) => {
      if (isBulkRemove) {
        const currentlySelected = this.state.selection;
        // we need to get at the internals of ReactTable
        const wrappedInstance = this.checkboxTable.getWrappedInstance();
        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance.getResolvedState().sortedData;
        const visibleRecords = [];
        currentRecords.map((item, index) => {
            visibleRecords.push(item._original._id);
        });
        const companyIntersection = intersection(currentlySelected, visibleRecords);
        // this.deleteWatched();
        this.setState({
          isDeleteModalOpen: true,
          isBulkRemoveAction: isBulkRemove ? true : false,
          individualIdToBeDeleted: id ? id : null,
          toBeDeleted: companyIntersection
        });
      }
      this.setState({
        isDeleteModalOpen: true,
        isBulkRemoveAction: isBulkRemove ? true : false,
        individualIdToBeDeleted: id ? id : null
      });
    }

    deleteWatched = () => {
        const currentlySelected = this.state.selection;
        // we need to get at the internals of ReactTable
        const wrappedInstance = this.checkboxTable.getWrappedInstance();
        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance.getResolvedState().sortedData;
        const visibleRecords = [];
        currentRecords.map((item, index) => {
            visibleRecords.push(item._original._id);
        });
        const companyIntersection = intersection(currentlySelected, visibleRecords);
        this.setState({
            toBeDeleted: companyIntersection
        });
    }

    handleSubmit = () => {
        const formData = new FormData();
        const csrftoken_value = csrftoken();

        const Message = () => (
            <>
                {
                this.state.status === 'success'
                ? <TickIcon />  : <ExclamationIcon />
                }
                <span>{this.state.statusMessage}</span>
            </>
        );

        // add action to FormData
        formData.append('action', JSON.stringify([{
            'list': this.state.isMasterList ? 'master' : 'user',
            'bookmarked': false
        }]));
        // add ID's to FormData
        formData.append('ids', JSON.stringify(this.state.toBeDeleted));
        formData.append('csrfmiddlewaretoken', csrftoken_value);
        fetch(window.location.href, {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': csrftoken_value
            },
            credentials: 'same-origin',
            body: formData
        }).then((res) => {
            return res.json();
        })
        .then((data) => {
            const status = data.bookmarked_status;
            this.setState({
                status,
                selectAll: false,
                statusMessage: status === 'success'
                    ? LOCALIZED_STRS.get().listsUpdateSuccess
                    : LOCALIZED_STRS.get().listsUpdateFailed
            });
            this.closeModal();
            toast(Message, {
                toastId: this.state.customToastID,
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                className: `toast--${this.state.status}`
            });
            this.fetchData({page:-1});
        });
    }

    handleIndividualDelete = () => {
        const formData = new FormData();
        const csrftoken_value = csrftoken();
        const companyToUnfollow = [this.state.individualIdToBeDeleted];

        const Message = () => (
            <>
                {
                this.state.status === 'success'
                ? <TickIcon />  : <ExclamationIcon />
                }
                <span>{this.state.statusMessage}</span>
            </>
        );

        // add action to FormData
        formData.append('action', JSON.stringify([{
            'list': this.state.isMasterList ? 'master' : 'user',
            'bookmarked': false
        }]));
        // add ID's to FormData
        formData.append('ids', JSON.stringify(companyToUnfollow));
        formData.append('csrfmiddlewaretoken', csrftoken_value);
        fetch(window.location.href, {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': csrftoken_value
            },
            credentials: 'same-origin',
            body: formData
        })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            const status = data.bookmarked_status;
            this.setState({
                status,
                statusMessage: status === 'success'
                    ? LOCALIZED_STRS.get().listsUpdateSuccess
                    : LOCALIZED_STRS.get().listsUpdateFailed
            });
            this.closeModal();
            toast(Message, {
                toastId: this.state.customToastID,
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                className: `toast--${this.state.status}`
            });
            this.fetchData({page:-1});
        });
    }

    customNoDataComponent = () => {
      return (
          <>
            <div className="rt-noData">
                <div className="rt-noData--text">{LOCALIZED_STRS.get().noCompaniesFound}</div>
                <div className="rt-noData--image"></div>
            </div>
          </>
      );
    }


    renderTotalFilters = (state, makeTable, instance) => {
        let recordsInfoText = '';

        const { filtered, pageRows, pageSize, sortedData, page } = state;

        const linkClass = classNames({
          'rbtn rbtn--ghost rbtn--md': true,
          'link--paragraph': true,
          'link--disabled': this.state.isDownloading
        });

        if (sortedData && sortedData.length > 0) {
            const isFiltered = filtered.length > 0;

            const recordsCountFrom = (page * pageSize) + 1;

            const recordsCountTo = (recordsCountFrom + pageRows.length) - 1;

            if (isFiltered) {
                recordsInfoText = LOCALIZED_STRS.get().showing + ` ${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${this.state.total} ` + LOCALIZED_STRS.get().filteredCompanies;
            } else {
                recordsInfoText = LOCALIZED_STRS.get().showing + ` ${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${this.state.total} ` + LOCALIZED_STRS.get().companies;
            }

        } else {
            recordsInfoText = LOCALIZED_STRS.get().noCompanies;
        }

        return (
            <div className="main-grid">
                <div className="row">
                    <FlexRow className="col-xs-12">
                        <span className="records-info bold">{recordsInfoText}</span>
                        <a
                            href={this.props.download}
                            className={linkClass}
                            onClick={(event) => this.handleListDownload(event)}
                            disabled={this.state.isDownloading}
                        >
                        <IconWrapper>
                        {this.state.isDownloading ? (
                            <LoadingIcon />
                          ) : (
                            <DownloadIcon />
                        )}
                        </IconWrapper>
                        {this.state.isDownloading ? (
                            LOCALIZED_STRS.get().downloadInProgress
                          ) : (
                            LOCALIZED_STRS.get().downloadFullList
                         )}
                         </a>
                    </FlexRow>
                </div>
                <StyledHR />
                <div className="row margin__vertical--top margin__vertical--loose--bottom">
                    <div className="col-xs-12">
                        {this.state.hasEditPermissions &&
                            <Filter className="filter--container borderRight">
                                <button
                                    id="healthmark:remove:selected"
                                    className="rbtn rbtn--ghost rbtn--md"
                                    onClick={() => this.openModal({isBulkRemove: true})}
                                    disabled={this.state.selection.length == 0}
                                >{LOCALIZED_STRS.get().removeSelected}</button>
                            </Filter>
                        }
                        <SearchInputWrapper id="filterSearch" className="filter--container">
                            <input
                                autoComplete="off"
                                id={'healthmark:filter:search'}
                                value={this.state.searchTerm}
                                placeholder="Search Company"
                                onChange={e => {
                                    this.onSearchTermChange(e.target.value);
                                }}
                            />
                        </SearchInputWrapper>
                        {this.state.filters.length > 0 &&
                            <>
                                {
                                    this.state.filters.map(
                                        (filter, i) => this.renderFilter(filter, i)
                                    )
                                }
                                <Filter className="filter--container">
                                      <span>
                                        <ClearFiltersLink
                                          id="healthmark:filter:clear"
                                          className="link link--sentence"
                                          onClick={this.onClearFilters}
                                         >
                                          {LOCALIZED_STRS.get().clearFilters}
                                        </ClearFiltersLink>
                                      </span>
                                </Filter>
                            </>
                        }
                    </div>
                </div>
                {makeTable()}
            </div>
        );
    };

    get_URL = (state) => {
        const searchParam = this.props.searchparam;
        const sorted = this.state.sorted.map(
            k => 'SORT_BY_'
            +  k.id.toUpperCase()
            + (k.desc ? '_DESC' : '_ASC')
        );
        const filtered = this.state.filtered.map(k => k.id + '=' + k.value).join(',');
        const params = {
            page: state.page + 1,
            pageSize: this.state.pageSize,
            sortedBy: sorted,
            filterBy: filtered
        };
        if (!UTILS.isEmptyOrUndefined(this.props.searchparam)) {
            Object.assign(params, {
                [searchParam]: this.state.searchTerm
            });
        }
        const esc = encodeURIComponent;
        const query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
        return this.state.url + '?' + query;
    };

    fetchData = (state, instance) => {
        this.setState({loading: true});
        // fetch the data
        setTimeout(() => {
            fetch(this.get_URL(state), {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': csrftoken()
                },
                credentials: 'same-origin',
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    this.setState({
                      data: data.companies,
                      pages: data.pages,
                      total: data.total,
                      list_total: data.list_total,
                      loading: false
                    });
                })
                .then(() => {
                  if (!state.doNotRedirect && this.state.list_total === 0){
                    window.location.href = '/health-mark';
                  }
                });
        }, 1);
    };

    onPageSizeChange = (pageSize, pageIndex) => {
        this.setState({
            pageSize: pageSize,
            pageIndex: pageIndex
        });
    };

    onSortedChange = (newSorted, column, shiftKey) => {
        this.setState({
            sorted: newSorted
        });
    };

    clearSearch = () => {
        this.setState({
            searchTerm: ''
        });
        this.fetchData({page:-1});
    };

    refreshDebounced = () => {
        this.fetchData({page:-1, doNotRedirect: true});
    };

    onSearchTermChange = (searchTerm) => {
        this.setState({
            searchTerm: searchTerm
        });
        // the user has deleted the search term
        if (searchTerm.length === 0) {
            this.fetchData({page:-1});
        // the user has not typed enough characters
        } else if (searchTerm.length < this.props.searchmin) {
            return;
        }
        this.refreshDebounced();
    };

    handleListDownload (event) {
      if (!isIe11()) {
        event.preventDefault();
        this.setState({isDownloading: true});
        fetch(this.props.download, {
            method: 'GET',
            headers: {
              'X-Requested-With': 'XMLHttpRequest'
            },
            credentials: 'same-origin'
        }).then((response) => {
            if(response.ok) {
                return response.blob();
            }
        }).then((dataBlob) => {
            // create a link
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            // store the blob in link URL
            const objectURL = URL.createObjectURL(dataBlob);
            a.href = objectURL;
            a.download = this.props.filename;
            a.click();
        }).then(() => {
            this.setState({isDownloading: false});
        });
      }
    }

    render() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const {pages, selectAll, selection} = this.state;

        let data = this.state.data;
        if (this.state.search) {
            data = matchSorter(data, this.state.search, {
                keys: [
                    'name',
                    'address',
                    'sector_description',
                    'date_added',
                ], threshold: matchSorter.rankings.WORD_STARTS_WITH
            });
        }

        const CloseButton = ({ closeToast }) => (
            <i
                className="icon icon--close close-menu"
                aria-hidden="true"
                onClick={closeToast}
            ></i>
        );

        const customTransition = cssTransition({
            enter: 'bounceInDown',
            exit: 'bounceOutUp',
            duration: 1500,
            appendPosition: false
        });

        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: 'checkbox',
            keyField: '_id'
        };

        const tableClass = classNames({
            'ReactTable--lineHeight--md': true,
            'ReactTable--checkboxTable': this.state.hasEditPermissions
        });

        const tableComponents = {
            true: CheckboxTable,
            false: ReactTable
        };

        const RenderedTable = tableComponents[this.state.hasEditPermissions];

        return (
            <React.Fragment>
                <StyledToastContainer
                    status={this.state.status}
                    position="top-center"
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    transition={customTransition}
                    pauseOnVisibilityChange
                    closeButton={<CloseButton />}
                />
                <StyledReactModal
                    isOpen={this.state.isDeleteModalOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="HealthMark List Modal"
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div className="ReactModal__Header">
                        <h2>Remove Company</h2>
                        <button
                            onClick={this.closeModal}
                            className="rbtn rbtn--transparent"
                        >
                            <i
                                className="icon icon--close close-menu"
                                aria-hidden="true"
                            >
                            </i>
                        </button>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: 8, marginTop: 14 }}>
                        <ErrorIcon />
                      </div>
                      <ModalContent>
                        This will remove the selected company (or companies) from this list. Are you sure you want to proceed? You will have to manually add the company back to this list once removed.
                      </ModalContent>
                    </div>

                    <ModalFooter>
                        <button
                            id="hm:list:remove"
                            name="submit_bookmarks"
                            className="rbtn rbtn--primary rbtn--md"
                            onClick={this.state.isBulkRemoveAction ? this.handleSubmit : this.handleIndividualDelete}
                        >
                          REMOVE
                        </button>
                    </ModalFooter>
                </StyledReactModal>
                <div className="row">
                    <div className="table_sortable">
                        <RenderedTable
                            ref={r => (this.checkboxTable = r)}
                            className={tableClass}
                            data={data}
                            pages={pages}
                            url={this.state.url}
                            filtered={this.state.filtered}
                            onPageSizeChange={this.onPageSizeChange}
                            pageSize={this.state.pageSize}
                            loading={this.state.loading}
                            onSortedChange={this.onSortedChange}
                            onFilteredChange={(filtered, column, value) => {
                              this.onFilteredChangeCustom(value, column.id || column.accessor);
                            }}
                            pageSizeOptions= {[ 5, 10, 15, 20, 25, 50, 100]}
                            sorted = {this.state.sorted}
                            onFetchData={this.fetchData}
                            manual
                            columns={this.state.columns}
                            NoDataComponent={this.customNoDataComponent}
                            {...(this.state.hasEditPermissions && {...checkboxProps})}
                        >
                            {this.renderTotalFilters}
                        </RenderedTable>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

TableHealthMarkFollow.propTypes = {
    pagesize: PropTypes.number,
    filters: PropTypes.string,
    id: PropTypes.string,
    columns: PropTypes.string.isRequired
};

export default TableHealthMarkFollow;
