import React from 'react';
import { get } from 'lodash';
import {
  MOBILE_BREAKPOINT,
  WHITE,
  BLACK,
  ABBEY,
  GREY_PRIMARY,
  BRAND_TURBO
} from './constants';
import styled from 'styled-components';
import MenuGroup from './menuGroup.jsx';
import DownChevron from './downChevron.jsx';

// Begin styles
const CategoryContainer = styled.div`
    display: ${props => (props.active === true ? 'block' : 'none')};
    left: ${props => (props.active === true ? 'auto' : 'initial')};
    right: 0;
    top: 58px;
    min-width: 215px;
    background-color: ${GREY_PRIMARY};

    @media only screen and (min-width : ${MOBILE_BREAKPOINT}) {
        position: absolute;
        right: 0;
        width: 215px;
        box-shadow: -1px 2px 4px rgb(0 0 0 / 25%);
    }
`;
CategoryContainer.displayName = 'CategoryContainer';
const SubCategoryHeader = styled.span`
    font-size: 14px;
    font-weight: 700;
    background-color: ${ABBEY};
    display: block;
    padding: 0 0 0 4px;
    line-height: 42px;
    display: none;
    transition: color 0.3s ease;

    &:after {
        content: '';
        display: inline-block;
    }

    &:empty {
        display: none;
    }

    @media only screen and (min-width : ${MOBILE_BREAKPOINT}) {
        display: block;
        line-height: 48px;
        background-color: ${ABBEY};

        &:hover {
            color: $turbo;
            transition: color 0.3s ease;
        }

        &.active {
            &:after {
                content: '';
                border-bottom: 1px solid $turbo;
                display: block;
                margin-top: -14px;
            }
        }
    }

        padding: 0 0 0 8px;
        color: ${WHITE};
        text-align: left;
        font-size: 12px;
        font-weight: 600;
        background-color: ${ABBEY};
        line-height: 42px;

        &:hover {
            cursor: default;
        }

`;

const SubCategoryList = styled.ul`
    margin: 0;
    padding: 0 0 0 24px;
    @media screen and (min-width: ${MOBILE_BREAKPOINT}) {
        padding: 0;
    }
`;

const RruText = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color:${BLACK};
  margin-bottom: 16px;
  font-family: 'Source Sans Pro',sans-serif;
    a{
        text-decoration: none;
    }
`;

const MenuItem = styled.div`
    &:first-of-type {
        margin-top: 40px;
    }
    @media only screen and (min-width : ${MOBILE_BREAKPOINT}) {
        &:first-of-type {
            margin-top: inherit;
        }
        display: flex;
        justify-content: flex-end;
        margin: 2px;
    }
`;

const MenuContainer = styled.div`
    @media only screen and (min-width : ${MOBILE_BREAKPOINT}) {
        position: relative;
        float: left;

        &:hover {
            cursor: pointer;
        }
    }
`;
const ChevronContainer = styled.span`
    margin-right: 4px;
    float: left;
    transform: scale(0.7);

    @media screen and (min-width: ${MOBILE_BREAKPOINT}) {
        margin-left: 4px;
        float: right;
    }
`;
const MenuButton = styled.button`
    line-height: 58px;
    background-color: ${ABBEY};
    text-align: left;
    padding-left: 8px;
    padding-right: 24px;
    width: 100%;
    color: ${WHITE};
    border: none;
    position: relative;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    font-family: Source Sans Pro,sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 16px;
    margin: 2px auto;

    @media only screen and (min-width: ${MOBILE_BREAKPOINT}) {
        margin: -2px auto;
        background-color: ${BLACK};
    }
`;
MenuButton.displayName = 'MenuButton';


// End styles


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            expandedMenu: null
        };

        this.keyFunction = this.keyFunction.bind(this);
        this.closeSubMenus = this.closeSubMenus.bind(this);
        this.handleEventOutside = this.handleEventOutside.bind(this);
    }

    focusOnLink = (reference, link) => {
        reference.querySelector(link).focus();
    }

    handleNavigateVertical = (offsetParent) => {
        this.focusOnLink(offsetParent, 'button');
    }

    handleNavigateHorizontal = (offsetParentSibling) => {
        if (offsetParentSibling && offsetParentSibling.querySelector('button')) {
            offsetParentSibling.querySelector('button').focus();
        }
    }

    handlePrevious = (previousParentCategory) => {
        if (previousParentCategory) {
            const links = previousParentCategory.getElementsByTagName('a');
            links[(links.length - 1)].focus();
        } else { // beginning of the overall category has been reached
            const offsetParent = event.srcElement.closest('div[class^="MenuItem"]');
            if (offsetParent !== null) {
                this.handleNavigateVertical(offsetParent, 'button');
            }
            // close menu
            this.toggleGroup(null);
        }
    }

    handleNext = (offsetParent) => {
        const offsetParentSibling = offsetParent.closest('div[class^="MenuItem"]').nextSibling;
        if (offsetParentSibling.querySelector('button')) {
            offsetParentSibling.querySelector('button').focus();
        } else { // last link reached
            // close menu
            this.toggleGroup(null);
            // skip to main content
            document.getElementById('content').scrollIntoView();
        }
    }

    handleNextCategory = (nextParentCategory) => {
        if (nextParentCategory) {
            this.focusOnLink(nextParentCategory, 'a');
        } else { // end of the overall category has been reached
            const offsetParent = event.srcElement.offsetParent;
            this.handleNext(offsetParent);
        }
    }

    keyFunction(event){
        if (this.node.contains(event.target)) {
            const focusedElement = document.activeElement;
            if (event.keyCode === 27) { // esc is pressed
                this.closeSubMenus();
                const offsetParent = event.srcElement.closest('div[class^="MenuItem"]');
                this.handleNavigateVertical(offsetParent);
                // close menu
                this.toggleGroup(null);
            }

            if (event.keyCode === 38) { // up is pressed
                event.preventDefault();
                if (event.srcElement.parentNode.previousSibling !== null){ // focusedElement has link sibling
                    this.focusOnLink(event.srcElement.parentNode.previousSibling, 'a');
                } else { // focusedElement has an no immediate link sibling, go to previous category
                    const previousParentCategory = event.srcElement.closest('div').previousSibling;
                    this.handlePrevious(previousParentCategory);
                }
            }

            if (event.keyCode === 40) { // down is pressed
                event.preventDefault();

                if (event.srcElement.attributes['aria-haspopup']) { // focusedElement is a top level category
                    // open the category
                    this.toggleGroup(event.srcElement.attributes['name'].nodeValue);
                    // focus on first link
                    this.focusOnLink(event.srcElement.parentNode, 'a');
                    return;
                }

                if (event.srcElement.parentNode.nextSibling) { // focusedElement has an immediate link sibling
                    // focus on immediate link sibling
                    this.focusOnLink(event.srcElement.parentNode.nextSibling, 'a');
                } else { // focusedElement has an no immediate link sibling, go to next category
                    const nextParentCategory = event.srcElement.closest('div').nextSibling;
                    this.handleNextCategory(nextParentCategory);
                }
            }
        }
    }

    closeSubMenus() {
        this.setState({
            expandedMenu: null
        });
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleEventOutside, false);
        document.addEventListener('keydown', this.keyFunction, false);
        document.addEventListener('focus', this.handleEventOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleEventOutside, false);
        document.removeEventListener('keydown', this.keyFunction, false);
        document.removeEventListener('focus', this.handleEventOutside, true);
    }

    handleEventOutside(event) {
        if ( this.node.contains(event.target)) {
            return;
        }
        this.setState({
            expandedMenu: null
        });
    }

    focusChecks(event) {
        if (this.state.expandedMenu !== null) {
            this.closeSubMenus();
        }
    }

    toggleGroup(name) {
        if (name === this.state.expandedMenu){
            this.closeSubMenus();
        } else{
        this.setState({
            expandedMenu: name
        });
    }
    }

    render() {
        const category = this.props.category || this.props.label;
        if (category === undefined) {
          return null;
        }
        const categoryName = get(category, 'label', '');
        const actionLinkHost = get(category, 'groups[0].list[0].action', '/');
        const clientID = get(category, 'groups[0].list[0].context.client_id','');

        return (
            <MenuItem
                ref={node => this.node = node}
                data-name={categoryName}
                active={(categoryName === this.state.expandedMenu) ? true : false}
                data-heap-id={`navbar:menu-item:${categoryName
                    .toLowerCase()
                    .split(' ')
                    .join('-')}`}
            >
                {categoryName !== 'Access RR University' && 
                    <MenuButton
                        role="menuitem"
                        as="button"
                        aria-haspopup="true"
                        name={categoryName}
                        onClick={this.toggleGroup.bind(this, categoryName)}
                    >
                        {categoryName}
                        <ChevronContainer>
                            <DownChevron arrowColor={WHITE} />
                        </ChevronContainer>
                    </MenuButton>
                }
                <MenuContainer
                    role="presentation"
                >
                    <CategoryContainer
                        active={(categoryName === this.state.expandedMenu) ? true : false}
                        role="presentation"
                    >
                      
                        {categoryName !== 'Access RR University' && 
                            <>
                            {category.groups.map((group, index) => {
                                const subcategoryList = group.list;
                                return (
                                    <div
                                        key={index}
                                        data-name={group.label}
                                    >
                                        <SubCategoryHeader
                                            role="presentation"
                                        >{group.label}
                                        </SubCategoryHeader>
                                        <SubCategoryList role="presentation" > 
                                            <MenuGroup subcategoryList={subcategoryList} />
                                        </SubCategoryList>
                                    
                                    </div>
                                );
                            })}
                            </>
                        }
                        {categoryName === 'Access RR University' && null}
                   </CategoryContainer>
                </MenuContainer>
            </MenuItem>

        );
    }
}
export default Category;
