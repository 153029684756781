import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  font-size: 14px;
  height: 100%;
  max-height: 100%;
  width: 155px;
  min-width: 155px !important;
  margin: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

export default function SubmitButon({ onClick, children }) {
  return (
    <Button type="submit" className="rbtn rbtn--primary" onClick={onClick}>
      {children}
    </Button>
  );
}
