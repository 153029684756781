import React from 'react';
import styled from 'styled-components';

const Form = styled.form`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: #fff;
  border-radius: 3px;

  & .org-select__control {
    height: calc(100% - 16px);
    border: none;
    border-radius: 0;
    padding: 8px 0 8px 16px;
    height: 52px;
    box-shadow: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 180px);

    &::before {
      content: '\\e915';
      font-size: 22px;
      color: ${props => (props.isCompanyInputFocused ? '#00afd7' : '#51585d')};
      margin-top: -8px;
      z-index: 1;
      font-family: RapidRatings;
    }
  }

  & .org-select__indicators {
    display: none;
  }

  .org-select__option{
    width: 100%;
    height: 100%;
    line-height: 40px;
    padding: 0 20px;
    display: inline-block;
    cursor: pointer;
    opacity: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

      span.matched-text {
        margin-right: 0px;
        display: contents;
      }

    }
    
  }

  & .org-select__menu {
    width: calc(100% + 49px);
    margin-top: 1px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #c7d2db;
    border-top: none;
    padding: 0;
  }

  & .org-select__menu-notice {
    text-align: left;
  }
`;

export default function SearchForm({
  children,
  onSubmit,
  isOrgInputFocused = false
}) {
  return (
    <Form
      className="preventLoadingState"
      onSubmit={onSubmit}
      isOrgInputFocused={isOrgInputFocused}
    >
      {children}
    </Form>
  );
}
