import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import TopAnchorIcon from './topAnchorIcon.jsx';
import {debounce} from 'lodash';
import styled from 'styled-components';

const TopButton = styled.button`
    position: fixed;
    bottom: 60px;
    right: 16px;
    background: none;
    border: none;
    padding: 0;
    display: ${props => props.isVisible ? 'block' : 'none'};
    z-index: 1;

    &:hover {
        cursor: pointer;
    }
`;

class TopAnchor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPastElement: props.scrollpastelement || '',
            isVisible: false
        };
        this.handleScroll = debounce(this.handleScroll.bind(this), 250, { 'maxWait': 500 });
        this.scrollBack = this.scrollBack.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    scrollBack () {
        const elementTarget = document.getElementById(this.state.scrollPastElement);
        const yCoordinate = elementTarget.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -175; // 175 is the height of nav with taskbar
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        // add tabindex for elements not focusable
        const pageTitle = document.querySelector('h1');
        pageTitle.setAttribute('tabindex', '-1');
        // set focus
        setTimeout(setFocus, 500);
        function setFocus() {
            pageTitle.focus();
            // set first anchor to active
            const firstAnchor = document.querySelector('#anchors a[href^="#"]');
            firstAnchor.classList.add('rbtn--anchor--active');
        }

        const currentAnchors = [...document.querySelectorAll('a[href^="#"]')];
        currentAnchors.map(anchor => {
            anchor.classList.remove('rbtn--anchor--active');
        });
    }

    handleScroll() {
        const elementTarget = document.querySelector('#'+this.state.scrollPastElement+'');

        function isElementInViewport (el) {
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function resetAnchors() {
            if (isElementInViewport(elementTarget) ) {
                const currentAnchors = [...document.querySelectorAll('a[href^="#"]')];
                currentAnchors.map(anchor => {
                    anchor.classList.remove('rbtn--anchor--active');
                });
                const firstAnchor = document.querySelector('#anchors a[href^="#"]');
                if (firstAnchor){
                    firstAnchor.classList.add('rbtn--anchor--active');
                }
            }
        }

        if (window.pageYOffset > ( (elementTarget.offsetTop - 175) + elementTarget.offsetHeight)) {
            this.setState({isVisible: true});
        } else {
            this.setState({isVisible: false});
            setTimeout(resetAnchors, 350);
        }
    }

    render() {
        return (
            <TopButton
                onClick={this.scrollBack}
                isVisible={this.state.isVisible}
                className="print__hide"
            >
                <TopAnchorIcon/>
            </TopButton>
        );
    }
}


export default TopAnchor;
