import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import PropTypes from 'prop-types';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

class Criticality extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          criticality: props.criticality,
          readonly: props.readonly !== undefined ? true : false
        };
        this.setInitial = this.setInitial.bind(this);
    }

    componentDidMount() {
        this.setInitial(this.state.criticality);
    }

    setInitial(option) {
        if (option != null) {
            document.getElementById(option).checked = true;
        }
    }

    render() {
        const { defaultCriticality, readonly } = this.state;
        return (
            <React.Fragment>
                <fieldset
                    className="radio-group radio-group__horizontal"
                    disabled={readonly}
                >
                    <span>{LOCALIZED_STRS.get().criticality}:</span>
                    <label htmlFor="non-critical">
                        <input
                            id="non-critical"
                            type="radio"
                            name="criticality"
                            value="non-critical"
                            defaultChecked
                        />
                        <span>{LOCALIZED_STRS.get().non_critical}</span>
                    </label>
                    <label htmlFor="critical">
                        <input
                            id="critical"
                            type="radio"
                            name="criticality"
                            value="critical"
                        />
                        <span>{LOCALIZED_STRS.get().critical}</span>
                    </label>
                 </fieldset>
            </React.Fragment>
        );
    }
}

export default Criticality;
