import React from 'react';
import Select from 'react-select';
import ReduxStore from './redux_store.jsx';

class SelectDropdown extends ReduxStore(React.Component) {
    constructor(props){
        super(props);
        this.state = {
            options: JSON.parse(props.options),
            selectedOption: null,
            defaultValue: JSON.parse(props.defaultvalue),
            placeholder: props.placeholder,
            store_subscribe: props.store_subscribe,
            store_enabled_model: props.store_enabled_model,
            isdisabled: props.isdisabled
        };
        if (props.store_subscribe)  {
            this.store.subscribe(() => {
                if (this.store.getState()[this.state.store_enabled_model]) {
                    this.setState({'isdisabled': false});
                } else {
                    this.setState({'isdisabled': true});
                }
            });
        }
    }

    componentDidMount() {
        if (this.state.defaultValue != null) {
            this.setState({
                selectedOption: this.state.defaultValue
            });
        }
    }

    handleChange = (selectedOption) => {
        this.update_store(selectedOption.value);
        this.setState({ selectedOption });
    }

    render() {
        const { selectedOption } = this.state;
        const customStyles = {
            container: (provided, state) => ({
                ...provided,
                display: 'inline-block',
                minWidth: 250,
                width: '100%',
                verticalAlign: 'top'
            }),
            singleValue: (provided, state) => ({
                ...provided,
                paddingTop: 2,
                paddingBottom: 2
            })
        };
        return (
            <Select
                name={this.props.name}
                placeholder={this.props.placeholder}
                isDisabled={this.state.isdisabled}
                value={selectedOption}
                styles={customStyles}
                onChange={this.handleChange}
                options={this.state.options}
            />
        );
    }
}

SelectDropdown.defaultProps = {
    options: '[]',
    defaultvalue: '[]',
    placeholder: null
};

export default SelectDropdown;
