import React from 'react';
import Input from '../forms/common/input.jsx';
import ReactModal from 'react-modal';
import moment from 'moment';
import LOCALIZED_STRS from '../utils/localize.jsx';
import {csrftoken} from '../utils/csrf.jsx';
import 'formdata-polyfill';

class SuggestUpdateCallToAction extends React.Component {
    render () {
        return (
            <div className="margin__none caption--secondary">
                <p className="neutral-70">{LOCALIZED_STRS.get().expected_rating_update}
                {this.props.expectedUpdate ? moment(this.props.expectedUpdate, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY ').toString() : 'Unavailable'}</p>
                <button className="link link--sentence rbtn--transparent padding__none"
                        onClick={this.props.handleOpenModal}>
                    <span className="search__details padding__horizontal--tight--left">
                      {LOCALIZED_STRS.get().suggest_rating_update}
                    </span>
                </button>
            </div>
        );
    }
}

class SuggestUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyID: props.companyID,
            suggestUpdateUrl: props.suggestUpdateUrl,
            formSubmitted: false,
            formSuccess: false,
            isLoading: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        const self = this;
        const formElement = document.querySelector('form');
        const formData = new FormData(formElement);
        const csrftoken_value = csrftoken();
        // add soliciations to FormData
        formData.append('companyID', this.state.companyID);
        formData.append('csrfmiddlewaretoken', csrftoken_value);
        this.setState({isLoading: true});
        fetch(this.state.suggestUpdateUrl, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': csrftoken()
            },
            credentials: 'same-origin',
            method: 'POST',
            body: formData
        })
        .then((res) => {
            self.setState({
                isLoading: false
            });
            if (res.status === 200) {
                self.setState({
                    formSubmitted: true,
                    formSuccess: true
                });
            } else {
                self.setState({
                    formSubmitted: true,
                    formSuccess: false
                });
            }
        });
    }

    render() {
        return (
        <ReactModal
            isOpen={this.props.showModal}
            contentLabel="onRequestClose Example"
            onRequestClose={this.props.handleCloseModal}
            className="Modal"
            overlayClassName="Overlay">
            <div className="ReactModal__Header">
                <h2>Suggest Update</h2>
                <button
                    onClick={this.props.handleCloseModal}
                    className="rbtn rbtn--transparent"
                >
                <i className="icon icon--close close-menu" aria-hidden="true"></i>
                </button>
            </div>
            <React.Fragment>
              { !this.state.formSubmitted &&
                  <form autoComplete="off" onSubmit={this.handleSubmit} action={this.state.suggestUpdateUrl} method="POST">
                  {this.state.isLoading ? (
                      <div className="please_wait_animation"></div>
                  ) : (
                        <div className="margin__vertical--bottom">
                            <p>{LOCALIZED_STRS.get().submit_rating_update}</p>
                                <br />
                                <Input
                                    id="provided_url"
                                    value=""
                                    label={LOCALIZED_STRS.get().provided_url}
                                    type="text"
                                    name="provided_url"
                                    autocomplete="off"
                                />
                             <p>{LOCALIZED_STRS.get().submit_rating_update_info}</p>
                        </div>
                  )}
                    <div className="form--actions">
                        <button
                            type="submit"
                            name="submit_suggest"
                            className="rbtn rbtn--primary"
                            disabled={this.state.isLoading}
                        >
                            {LOCALIZED_STRS.get().submit}
                        </button>
                    </div>
                  </form>
              }
              { (this.state.formSubmitted && this.state.formSuccess) &&
                  <div className="form form--centered form--submitted animated animated--fadeInTop">
                      {this.state.formSuccess &&
                          <h3 className="margin__vertical">{LOCALIZED_STRS.get().thank_you}</h3>
                      }
                  </div>
              }
              { (this.state.formSubmitted && !this.state.formSuccess) &&
                  <div className="form form--centered form--failed animated animated--fadeInTop">
                         <h3 className="margin__vertical">{LOCALIZED_STRS.get().general_error}</h3>
                  </div>
              }
          </React.Fragment>
        </ReactModal>
        );
    }
}

class SuggestUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            hasError: false,
            hasFocus: false,
            showModal: false,
            companyID: props['company-id'],
            suggestUpdateUrl: props['submit-url'],
            expectedUpdate: props['expected-rating-update']
        });

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal () {
      this.setState({ showModal: true });
    }

    handleCloseModal () {
      this.setState({ showModal: false });
    }

    render() {
        return (
            <div>
                <SuggestUpdateCallToAction
                    handleOpenModal={this.handleOpenModal}
                    hasFocus={this.state.hasFocus}
                    isLoading={this.state.isLoading}
                    expectedUpdate={this.state.expectedUpdate}
                />
                <SuggestUpdateModal
                    suggestUpdateUrl={this.state.suggestUpdateUrl}
                    companyID={this.state.companyID}
                    handleOpenModal={this.handleOpenModal}
                    handleCloseModal={this.handleCloseModal}
                    showModal={this.state.showModal}
                />
            </div>
        );
    }
}

export default SuggestUpdate;
