import React, {useEffect} from 'react';
import { toast, ToastContainer, cssTransition } from 'react-toastify';
import styled from 'styled-components';

const StyledToastContainer = styled(ToastContainer)`
  width: 100vw;
  padding: 0;
  top: 0;
  right: 0;
  position: absolute;
  margin: 0 auto;

  .Toastify__toast {
    padding: 24px;

    &-container {
        position: absolute !important;
        top: 0 !important;
    }

    &-body {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
      }
    }

    &.toast {
      &--success {
        background-color: #e6faf3;
        color: #006647;
        .icon {
          color: #4a4a4a;
          margin: auto 0;
          padding-bottom: 6px;
        }
      }

      &--failed {
        background-color: #fff0ed;
        color: #B50500;
        .icon {
          color: #4a4a4a;
          margin: auto 0;
          padding-bottom: 6px;
        }
      }
    }
  }
`;

export default function Toast({ToastMessage = '', toastId = 'toast-id', className= 'toast--success', status='success', duration = 5000} ) {

    useEffect(()=>{
      // only call displayToast if a message is passed
      if (ToastMessage) {
        displayToast();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const customTransition = cssTransition({
        enter: 'bounceInDown',
        exit: 'bounceOutUp',
        duration: 1500,
        appendPosition: false
    });
    
    const displayToast = () => {
        toast(ToastMessage, {
            toastId,
            autoClose: duration,
            hideProgressBar: true,
            closeOnClick: true,
            className,
            closeButton: false
        });
    };
  return <StyledToastContainer
            status={status}
            position="top-center"
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            transition={customTransition}
            pauseOnVisibilityChange
/>;
}
