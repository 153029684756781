import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import List from './list.jsx';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';

class Indicator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...LOCALIZED_STRS.get().indicators[UTILS.getRiskName(props.chs)][UTILS.getRiskLevel(props.fhr)],
            ...props
        };
    }
    render() {
        return (
            <div className={`quad quad--${this.state.label}`}>
                <div className="quad__indicator">
                    <div className="quad__letter">{this.state.label}</div>
                    <svg className="quad__grids" viewBox="0 0 293 168" version="1.1">
                        <title>Quad Grids</title>
                        <defs></defs>
                        <g>
                            <g transform="translate(-1.000000, 0.000000)">
                                <polygon className="quad__grid quad__grid--c" points="0.920403042 81.8144596 72.6445709 42.1223249 145.289142 83.5078113 71.8900173 124.006771"></polygon>
                                <polygon className="quad__grid quad__grid--a" points="74 125.402332 146.323882 85 217.407039 126.507811 146.323882 168.015623"></polygon>
                                <polygon className="quad__grid quad__grid--d" points="74 40.2800076 146.644571 5.68434189e-14 219.289142 41.3854864 146.323882 82.8932977"></polygon>
                                <polygon className="quad__grid quad__grid--b" points="148 83.4023325 220.323882 43 293.289142 84.5078113 220.323882 126.015623"></polygon>
                            </g>
                        </g>
                    </svg>
                    <div className="quad__cta">{this.state.action}</div>
                </div>
                <div className="quad__details">
                    <h2 className="quad__header">
                        <strong>{this.state.answer}</strong>
                    </h2>
                    <p className="quad__summary">
                        {LOCALIZED_STRS.get().indicators[this.state.label]}
                    </p>
                </div>
            </div>
        );
    }
}

export default Indicator;
