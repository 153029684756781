import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Input from '../forms/common/input.jsx';
import RadioGroup from '../forms/common/radioGroup.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import CSRFToken from '../utils/csrf.jsx';

class SupplierPayContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitUrl: props.url,
            can_resend_intro: props.can_resend_intro,
        };
    }

    renderEditDetails() {
        return (
            <React.Fragment>
                <Input
                    id="id_first_name"
                    value={this.props.first_name}
                    label={LOCALIZED_STRS.get().first_name}
                    type="text"
                    name="first_name"
                    required="required"
                    onChange={event => this.handleChange(event) }
                />
                <Input
                    id="id_last_name"
                    value={this.props.last_name}
                    label={LOCALIZED_STRS.get().last_name}
                    type="text"
                    name="last_name"
                    required="required"
                    onChange={event => this.handleChange(event) }
                />
                <Input
                    id="id_email"
                    value={this.props.email}
                    label={LOCALIZED_STRS.get().email}
                    type="email"
                    name="email"
                    required="required"
                    onChange={event => this.handleChange(event) }
                />
            </React.Fragment>
        );
    }

    renderResendIntro() {
        return (
            <React.Fragment>
                <fieldset className="col-xs-12 fieldset__radio">
                    <legend>Re-send introduction email?</legend>
                    <div className="fieldset__options">
                        <input type="radio" id="id_true" value="true" name="resend_intro_email" required=""/>
                        <label htmlFor="id_true">Yes</label>
                        <input type="radio" id="id_false" value="false" name="resend_intro_email" defaultChecked/>
                        <label htmlFor="id_false">No</label>
                    </div>
                </fieldset>
            </React.Fragment>
        );
    }

    renderInfo() {
        return(
            <React.Fragment>
                <div >
                    <span className="caption2 text-sm">
                        <i className="icon icon--exclamation"/> 
                        To resend your introduction mail, please use the <b>Actions</b> dropdown
                    </span>
                </div>
            </React.Fragment>
        );
    }

    render() {
      return (
        <div className="supplier_pay_contact">
            <form action={this.state.submitUrl} method="post">
                <CSRFToken />
                {this.renderEditDetails()}
                {this.state.can_resend_intro === 'true' && this.renderResendIntro()}
                <div className="form--actions col-xs-12">
                    <button type="submit" name="Save" className="rbtn rbtn--primary float-right">
                        {LOCALIZED_STRS.get().save}
                    </button>
                </div>
                {!this.props.can_resend_intro ? this.renderInfo() : null}
            </form>
        </div>
      );
    }
}

class SupplierPayContact2 extends SupplierPayContact{
    renderEditDetails() {
        return (
            <React.Fragment>
                <Input
                    id="id_first_name"
                    value={this.props.first_name}
                    label={LOCALIZED_STRS.get().first_name}
                    type="text"
                    name="first_name"
                    required="required"
                    onChange={event => this.handleChange(event) }
                />
                <Input
                    id="id_last_name"
                    value={this.props.last_name}
                    label={LOCALIZED_STRS.get().last_name}
                    type="text"
                    name="last_name"
                    required="required"
                    onChange={event => this.handleChange(event) }
                />
                <Input
                    id="id_email"
                    value={this.props.email}
                    label={LOCALIZED_STRS.get().email}
                    type="email"
                    name="email"
                    required="required"
                    onChange={event => this.handleChange(event) }
                />
                <Input
                    id="id_phone"
                    value={this.props.phone}
                    label={LOCALIZED_STRS.get().phone}
                    type="text"
                    name="phone"
                    onChange={event => this.handleChange(event) }
                />
            </React.Fragment>
        );
    }
}

export default SupplierPayContact;
export {SupplierPayContact2};
