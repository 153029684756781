import React, { Component } from 'react';
import OrgSelect from './org-select.jsx';
import SearchForm from './search-form.jsx';
import SubmitButton from './submit-button.jsx';
import CancelIcon from './cancel-icon.jsx';
import {
  ORG_QUERY_FOCUS_MESSAGE,
  ORG_QUERY_NO_RESULTS_MESSAGE,
  ORG_QUERY_TOO_SHORT_MESSAGE,
  ORG_QUERY_ERROR_MESSAGE
} from './constants';
import {
  AssignedIcon,
  DeletedIcon,
  ExistingIcon,
  TickIcon,
  ExclamationIcon
} from './icons.jsx';
import {
  getLocationSuggestions,
  transformSuggestionsToOptions
} from './utils';
import {csrftoken} from '../../utils/csrf.jsx';
import styled from 'styled-components';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import classNames from 'classnames';
import {
  toast,
  ToastContainer,
  cssTransition
} from 'react-toastify';
import matchSorter from 'match-sorter';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';
import debounce from 'debounce-promise';

const ModalFooter = styled.div`
  width: 100%;
  text-align: right;

  &:before {
    content: " ";
    border: 1px solid #f4f6f9;
    display: block;
    margin: 0 -34px 24px;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  width: 60vw;
  padding: 0;
  top: 88px;
  left: 0;
  right: 0;
  position: fixed;
  margin: 0 auto;

  .Toastify__toast {
    padding: 24px;

    &-body {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-top: -4px;
      }

      span {
        padding-left: 12px;
      }
    }

    &.toast {
      &--success {
        background-color: #D8EED6;
        color: #41A939;
        .icon {
          color: #4A4A4A;
          margin: auto 0;
          padding-bottom: 6px;
        }
      }

      &--failed {
        background-color: #FDCECE;
        color: #ED4C4A;
        .icon {
          color: #4A4A4A;
          margin: auto 0;
          padding-bottom: 6px;
        }
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items:center;
  flex: 0 0 100%;
  height: 100%;
`;

const CellButton = styled.button`
  min-width: 95px;
  width: auto;
  height: auto;
  align-self: center;
`;

const DeleteText = styled.span`
  &:after {
    font-family: inherit;
    content: 'Delete';
    min-width: 95px;
    font-size: inherit;
    margin-left: inherit;
  }
`;

const FlexRow = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const StyledHR = styled.hr`
  margin-bottom: 24px;
`;

const StyledModal = styled(Modal)`
  &.healthmark--permissionsmodal {
    max-width: 500px;
  }
`;

const LargeIcon = styled.div`
  svg {
    width: 64px;
    height: 64px;
  }
`;

const SearchInputWrapper = styled.div`
  &:before {
    content: '\\e915';
    font-size: 18px;
    color: #51585d;
    font-family: RapidRatings;
    padding-top: 8px;
    padding-left: 16px;
    z-index: 1;
    position: absolute;
  }
  input {
    padding: 8px 8px 8px 40px;
    min-width: 235px;

    &:hover {
        cursor: text;
    }
  }
`;

const SwitchLabel = styled.label`
	position: relative;
	display: inline-flex;
  align-items: center;
	width: 45px;
	height: 24px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: 1px solid;
    border-color: ${props =>
      props.active === 'hasPermissions'
        ? '#5EA60A'
        : '#4F5356'};
		border-radius: 34px;
    background-color: ${props =>
      props.active === 'hasPermissions'
        ? '#5EA60A'
        : '#4F5356'};
		-webkit-transition: 0.4s;
		transition: 0.4s;

		&:before {
			position: absolute;
			content: "";
			height: 18px;
			width: 18px;
			left: 2px;
			bottom: 2px;
			border: 1px solid;
      border-color: ${props =>
        props.active === 'hasPermissions'
          ? '#5EA60A'
          : '#4F5356'};
			border-radius: 50%;
			background-color: #fff;
			background-size: 20px;
			background-position: center center;
			background-repeat: no-repeat;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}
	}

  .slider_text{
    margin-left: 48px;
    color: ${props =>
      props.active === 'hasPermissions'
        ? '#5EA60A'
        : '#4F5356'};
  }

	input:focus + .slider {
		outline: 1px dotted #212121;
		outline: 5px auto -webkit-focus-ring-color;
	}

	input:checked + .slider:before {
		transform: translateX(22px);
	}
`;


export default class HealthMarkPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrg: '',
      searchQuery: '',
      modalIsOpen: false,
      isFetching: false,
      action: '',
      message: '',
      noOptionsMessage: ORG_QUERY_TOO_SHORT_MESSAGE,
      isLocationInputFocused: false,
      columns: JSON.parse(this.props.columns),
      loading: undefined,
      page: -1,
      url: this.props.url,
      total: 0,
      id: this.props.id,
      pageSize: 10,
      status: '',
      statusMessage: '',
      orgToDelete: null,
      customToastID: 'listTableToast',
      entityName: this.props.entityname || '',
      search: '',
      isButtonDisabled: true
    };
  }

  static defaultProps = {
    data:  JSON.parse('[]'),
    id: 'sortable-table',
    columns: '[]'
  };

  openModal = (org) => {
    this.setState({
      orgToDelete: org,
      modalIsOpen: true
    });
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
    this.clearSelectedOrg();
  }

  handleIndividualDelete = (id) => {
    const formData = new FormData();
    const csrftoken_value = csrftoken();

    const Message = () => (
      <>
        {
          this.state.status === 'deleted'
          ? <TickIcon />  : <ExclamationIcon />
        }
        <span>{this.state.statusMessage}</span>
      </>
    );
    // add ID's to FormData
    formData.append('org_id', JSON.stringify(id));
    formData.append('csrfmiddlewaretoken', csrftoken_value);
    fetch(this.props.deletepermissionsurl, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrftoken_value
      },
      credentials: 'same-origin',
      body: formData
    })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      const status = data.results.action;
      this.setState({
        status,
        statusMessage: status === 'deleted'
          ? LOCALIZED_STRS.get().permission_deleted
          : LOCALIZED_STRS.get().permission_added
      });
      toast(Message, {
        toastId: this.state.customToastID,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        className:
          this.state.status === 'error'
            ? 'toast--failed'
            : 'toast--success'
      });
      this.fetchData({page:-1});
    });
  }

  handleMasterListChange = (id, status) => {
    this.setState({loading: true});
    const formData = new FormData();
    const csrftoken_value = csrftoken();

    // add ID's to FormData
    formData.append('org_id', JSON.stringify(id));
    formData.append('action', JSON.stringify(status));
    formData.append('csrfmiddlewaretoken', csrftoken_value);
    fetch(
      status === 'enable'
        ? this.props.addmasterpermissionsurl
        : this.props.deletemasterpermissionsurl, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrftoken_value
      },
      credentials: 'same-origin',
      body: formData
    })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      this.fetchData({page:-1});
    });
  }

  onPageSizeChange = (pageSize, pageIndex) => {
    this.setState({
      pageSize: pageSize,
      pageIndex: pageIndex
    });
  };

  renderTable = (state, makeTable, instance) => {
    let recordsInfoText = '';

    const { pageRows, pageSize, sortedData, page } = state;

    if (sortedData && sortedData.length > 0) {
      const recordsCountFrom = (page * pageSize) + 1;
      const recordsCountTo = (recordsCountFrom + pageRows.length) - 1;
      recordsInfoText = (
        LOCALIZED_STRS.get().showing
        + ` ${recordsCountFrom}-${recordsCountTo} `
        + LOCALIZED_STRS.get().of
        + ` ${this.state.data.length} `
        );
    } else {
      recordsInfoText = LOCALIZED_STRS.get().noPermissionsAssigned;
    }

    return (
      <div className="main-grid">
        <StyledHR />
        <div className="row">
          <FlexRow className="col-xs-12">
            <p className="bold">
              {`Existing ${this.state.entityName} Permissions`}
            </p>
          </FlexRow>
        </div>
        <div className="row">
          <FlexRow className="col-xs-12">
            <p
              className="text--grey"
            >{LOCALIZED_STRS.get().permissions_existing_subheader}</p>
          </FlexRow>
        </div>
        <div className="row">
          <SearchInputWrapper
            id="filterSearch"
            className="col-xs-12 filter--container"
          >
              <input
                  value={this.state.searchTerm}
                  placeholder="Search"
                  onChange={e => this.setState({search: e.target.value})}
              />
          </SearchInputWrapper>
        </div>
        <div
          className="row margin__vertical margin__vertical--top"
        >
          <FlexRow className="col-xs-12">
            <span className="records-info bold">
              {recordsInfoText}
            </span>
          </FlexRow>
        </div>
        {makeTable()}
      </div>
    );
  };

  fetchData = (state, instance) => {
      this.setState({loading: true});
      // fetch the data
      setTimeout(() => {
        fetch(this.props.permissionedorgsurl, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': csrftoken()
          },
          credentials: 'same-origin',
        })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            this.setState({
              data: data.results.orgs,
              loading: false
            });
        });
      }, 1);
  };

  getOptions = query => {
    return new Promise(resolve => {
      this.setState({
        searchQuery: query
      });

      if (query.length < 2) {
        resolve([]);
        this.setState({
          noOptionsMessage: ORG_QUERY_TOO_SHORT_MESSAGE
        });
      } else {
        getLocationSuggestions(
          `${this.props.orgsuggestionurl}?search-term=${query}`
        )
          .then(suggestions => {
            const options = transformSuggestionsToOptions(suggestions);

            resolve(options);

            if (this.state.searchQuery === query) {
              this.setState({
                noOptionsMessage:
                  options.length === 0
                    ? ORG_QUERY_NO_RESULTS_MESSAGE
                    : ORG_QUERY_FOCUS_MESSAGE
              });
            }
          })
          .catch(e => {
            resolve([]);

            if (this.state.searchQuery === query) {
              this.setState({
                noOptionsMessage: ORG_QUERY_ERROR_MESSAGE
              });
            }
          });
      }
    });
  };

  handleSearchSubmit = e => {
    e.preventDefault();

    const { selectedOrg } = this.state;

    this.addOrgPermission(this.props.permissionsurl, selectedOrg);
  };

  handleSelectedOrg = selectedOrg => {
    this.setState({
      noOptionsMessage: ORG_QUERY_FOCUS_MESSAGE,
      isButtonDisabled: selectedOrg !== null ? false : true,
      selectedOrg
    });
  };

  handleLocationInputBlur = () => {
    this.setState({
      isLocationInputFocused: false
    });
  }

  handleLocationInputFocus = () => {
    this.setState({
      noOptionsMessage: ORG_QUERY_FOCUS_MESSAGE,
      isLocationInputFocused: true,
      selectedOrg: ''
    });
  }

  clearSelectedOrg = () => {
    this.setState({
      selectedOrg: '',
      searchQuery: '',
      action: null,
      orgToDelete: null,
      isButtonDisabled: true,
      noOptionsMessage: ORG_QUERY_TOO_SHORT_MESSAGE
    });
  }

  addOrgPermission = (permissionsurl, selectedOrg) => {
    this.setState({
      modalIsOpen: true,
      isFetching: true
    });
    const csrftoken_value = csrftoken();
    const formData = new FormData();
    formData.append('org_id', selectedOrg.value);
    formData.append('csrfmiddlewaretoken', csrftoken_value);
    fetch(`${this.props.addpermissionsurl}`, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrftoken_value
      },
      credentials: 'same-origin',
      body: formData
    })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      let dataMessage;
      data.results.message
        ? dataMessage = data.results.message
        : data.results.action === 'added'
          ? dataMessage = LOCALIZED_STRS.get().permission_added
          : dataMessage = LOCALIZED_STRS.get().permission_deleted;

      this.setState({
        isFetching: false,
        modalIsOpen: false,
        action: data.results.action,
        statusMessage: dataMessage
      });
      const Message = () => (
        <>
          {data.results.action === 'added'
            ? <TickIcon /> : <ExclamationIcon />
          }
          <span>
            {this.state.statusMessage}
          </span>
        </>
      );
      toast(Message, {
        toastId: this.state.customToastID,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        className:
          this.state.action === 'error'
            ? 'toast--failed'
            : 'toast--success'
      });
    })
    .then(() => {
      this.fetchData({page:-1});
      this.clearSelectedOrg();
    });
  }

  customNoDataComponent = () => {
    return (
        <>
          <div className="rt-noData">
              <div className="rt-noData--text visually__h3">
                <span className="visually__h3 text--grey text__weight--normal">
                  {LOCALIZED_STRS.get().noPermissions}
                </span>
              </div>
              <div className="rt-noData--keyError"></div>
          </div>
        </>
    );
  }

  renderIcon() {
    return (
      <>
      {this.state.action == 'added' &&
        <AssignedIcon />
      }
      {this.state.action == 'deleted' &&
        <DeletedIcon />
      }
      {this.state.action == 'error' &&
        <ExistingIcon />
      }
      {this.state.action == 'delete' &&
        <LargeIcon>
          <ExclamationIcon />
        </LargeIcon>
      }
      </>
    );
  }

  renderHeading() {
    return (
      <>
      {this.state.action == 'added' &&
        <h2>{LOCALIZED_STRS.get().permissions_assigned}</h2>
      }
      {this.state.action == 'deleted' &&
        <h2>{LOCALIZED_STRS.get().permissions_removed}</h2>
      }
      {this.state.action == 'error' &&
        <h2>{LOCALIZED_STRS.get().permissions_error}</h2>
      }
      {this.state.action == 'delete' &&
        <h2>{LOCALIZED_STRS.get().permission_delete}</h2>
      }
      </>
    );
  }

  renderMessage () {
    return(
      <>
        {this.state.message != '' && this.state.message}
        {this.state.action === 'delete' &&
          <div
            className="margin__vertical--tight--bottom text--grey"
          >
            {LOCALIZED_STRS.get().permissions_confirmation}
          </div>
        }
      </>
    );
  }

  renderOrgDetails() {
    return (
      <>
      {this.state.selectedOrg &&
        <>
          <h1>
            {this.state.selectedOrg.displayName}
          </h1>
          <h1>
            {this.state.selectedOrg.displayID}
          </h1>
        </>
      }
      {this.state.orgToDelete &&
          <>
            <h1>
              {this.state.orgToDelete.name}
            </h1>
            <h1>
              {`Org ID. ${this.state.orgToDelete.id}`}
            </h1>
          </>
      }
      </>
    );
  }

  render() {
    const {
      noOptionsMessage,
      searchQuery,
      selectedOrg,
      isLocationInputFocused,
      isDanger,
      pages,
      selection,
      toggleSelection,
      toggleAll,
      isSelected,
      entityName
    } = this.state;

    let data = this.state.data;
    if (this.state.search) {
        data = matchSorter(data, this.state.search, {
            keys: [
                'name',
                'id',
            ], threshold: matchSorter.rankings.WORD_STARTS_WITH
        });
    }

    let isButtonDisabled = true;
    isButtonDisabled= this.state.isButtonDisabled;

    const CloseButton = ({ closeToast }) => (
      <i
        className="icon icon--close close-menu"
        aria-hidden="true"
        onClick={closeToast}
      ></i>
    );

    const customTransition = cssTransition({
      enter: 'bounceInDown',
      exit: 'bounceOutUp',
      duration: 1500,
      appendPosition: false
    });

    const columns = [
      {
        Header: 'Org ID',
        maxWidth: 110,
        accessor: 'id'
      },
      {
        Header: 'Company name',
        accessor: 'name'
      },
      {
        Header: 'MasterList Permissions',
        sortable: false,
        Cell: row => (
          <ButtonContainer>
            <SwitchLabel
              active={
                row.original.has_master_list_permission
                  ? 'hasPermissions' : ''
              }
            >
              <input
                type="checkbox"
                checked={row.original.has_master_list_permission}
                disabled={this.state.loading}
                onChange={() =>
                  this.handleMasterListChange(
                    row.original.id,
                    row.original.has_master_list_permission
                      ? 'disable'
                      : 'enable'
                )}
              />
              <span className="slider"></span>
              <span className="slider_text">
                {row.original.has_master_list_permission
                  ? 'MasterList Enabled'
                  : 'MasterList Disabled'
                }
              </span>
            </SwitchLabel>
          </ButtonContainer>
      )},
      {
        Header: '',
        sortable: false,
        maxWidth: 140,
        Cell: row => (
          <ButtonContainer>
            <CellButton
              onClick={(e) => (
                this.setState({action: 'delete'}),
                this.openModal(row.original)
              )}
              className="rbtn rbtn--ghost rbtn--md"
              title="Delete"
            >
              <DeleteText />
            </CellButton>
          </ButtonContainer>
      )},
    ];

    return (
      <>
        <StyledModal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Assign HealthMark permissions"
          className="healthmark--permissionsmodal"
          overlayClassName="Overlay"
          shouldCloseOnOverlayClick={this.state.orgToDelete? true: false}
        >
            <div className="ReactModal__Header">
                {this.renderHeading()}
                { this.state.orgToDelete &&
                <button
                  onClick={this.closeModal}
                  className="rbtn rbtn--transparent"
                >
                <i
                  className="icon icon--close close-menu"
                  aria-hidden="true"
                />
                </button>}
            </div>
            {this.state.isFetching ? (
                <div className="please_wait_animation"></div>
            ) : (
                <div className="margin__vertical--loose">
                    <div className="margin__vertical--tight">
                        {this.renderIcon()}
                    </div>
                    <div>
                        {this.renderMessage()}
                    </div>
                    {this.renderOrgDetails()}
                </div>
            )}
            <ModalFooter>
                { this.state.orgToDelete &&
                <>
                   <button
                   onClick={this.closeModal}
                   className="rbtn rbtn--md rbtn--ghost"
                 >
                   {LOCALIZED_STRS.get().cancel}
                 </button>
                  <button
                    onClick={() =>  (
                      this.handleIndividualDelete(this.state.orgToDelete.id),
                      this.clearSelectedOrg(),
                      this.closeModal()
                    )}
                    className="rbtn rbtn--md rbtn--primary"
                  >
                    {LOCALIZED_STRS.get().permission_delete}
                  </button>
                  </>
                }
            </ModalFooter>
        </StyledModal>
        <div className="accordion accordion--card accordion--locked">
          <div
            className="bold margin__vertical--bottom"
          >
            {`Assign new ${entityName} permission`}
          </div>
          <SearchForm
              onSubmit={this.handleSearchSubmit}
              isLocationInputFocused={isLocationInputFocused}
          >
            <OrgSelect
              value={selectedOrg}
              getOptions={this.getOptions}
              noOptionsMessage={noOptionsMessage}
              onChange={debounce(this.handleSelectedOrg,500)}
              onFocus={this.handleLocationInputFocus}
              onBlur={this.handleLocationInputBlur}
            />
            <CancelIcon
              isHidden={searchQuery.length === 0}
              onClick={this.clearSelectedOrg}
            />
            <SubmitButton
              disabled={isButtonDisabled}
            >
              {LOCALIZED_STRS.get().permission_add}
            </SubmitButton>
        </SearchForm>
        </div>
        <StyledToastContainer
            status={this.state.status}
            position="top-center"
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            transition={customTransition}
            pauseOnVisibilityChange
            closeButton={<CloseButton />}
        />
        <div className="row margin__vertical--loose--top">
          <div className="col-xs-12 table_sortable">
            <ReactTable
              ref={r => (this.checkboxTable = r)}
              className="ReactTable--lineHeight--md"
              data={data}
              pages={pages}
              url={this.props.permissionedorgsurl}
              loading={this.state.loading}
              pageSize={this.state.pageSize}
              pageSizeOptions= {[5, 10, 15, 20, 25, 50, 100]}
              onPageSizeChange={this.onPageSizeChange}
              onFetchData={this.fetchData}
              columns={columns}
              NoDataComponent={this.customNoDataComponent}
            >
              {this.renderTable}
            </ReactTable>
          </div>
        </div>
      </>
    );
  }
}

HealthMarkPermissions.propTypes = {
    pagesize: PropTypes.number,
    filters: PropTypes.string,
    id: PropTypes.string
};
