import React, { useState, useEffect, useContext }  from 'react';
import styled from 'styled-components';
import ReactSelect, { createFilter, components } from 'react-select';
import { DynamicBenchmarkContext } from '../index.jsx';

const styles = {
  container: provided => ({
    ...provided,
    width: '100%',
    borderRight: 'none'
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: '16px',
    color: '#333333'
  }),
  option: provided => ({
    width: '100%',
    height: '100%',
    lineHeight: '40px',
    padding: '0 20px',
    display: 'inline-block',
    cursor: 'pointer'
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '16px',
    color: '#000',
    lineHeight: '22px',
    padding: '2px 0'
  }),
  control: provided => ({
    ...provided,
    minHeight: '40px',
    height: '100%'
  })
};

const StyledReactSelect = styled(ReactSelect)`
  .country-select-option-custom {
    &:hover {
      background-color: #E4F7FB;
    }
  }
`;

const OptionDiv = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: bold;

  ${props => (props.isFocused ? 'background-color: #E4F7FB;' : '')}
`;

const Highlight = styled.span`
  font-weight: normal;
`;

const Option = ({ children, ...props }) => {

  const {
    isFocused,
    isSelected,
    label,
    id,
    name,
    defaultValue,
    selectProps: { inputValue }
  } = props;

  const {
    onMouseMove,
    onMouseOver,
    ...rest
  } = props.innerProps;

  const newProps = Object.assign(props, { innerProps: rest });
  const higlightRegEx = new RegExp(`(${inputValue})`, 'gi');
  const parts = label !== null ? label.split(higlightRegEx) : null;
  let hasHighlighted = false;

  if (parts === null) {
    return null;
  }

  return (
    <OptionDiv
      isSelected={isSelected}
      isFocused={isFocused}
      className="country-select-option-custom"
    >
      <components.Option
        {...newProps}
      >
        {parts.map((part, i) => {
          if (
           !hasHighlighted &&
            part.toLowerCase() === inputValue.toLowerCase()
           ) {
             hasHighlighted = true;
            return <Highlight key={i}>{part}</Highlight>;
           } else {
             return <span key={i}>{part}</span>;
           }
        })}
      </components.Option>
    </OptionDiv>
  );
};

export default function CountrySelect({
  options,
  value,
  id,
  name,
  noOptionsMessage,
  onChange,
  getOptions,
  onFocus,
  onBlur
}) {

  const countryContext = useContext(DynamicBenchmarkContext);
  const { countrySelected, setCountrySelected } = countryContext;

  function handleChange (selectedOption) {
    setCountrySelected(selectedOption);
  }

  return (
    <StyledReactSelect
      options={options}
      styles={styles}
      value={countrySelected}
      name={name}
      id={id}
      classNamePrefix="country-select"
      placeholder="All available countries"
      noOptionsMessage={() => noOptionsMessage}
      filterOption={createFilter({ignoreAccents: false})}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      blurInputOnSelect
      loadOptions={getOptions}
      components={{ Option }}
      isClearable
      maxMenuHeight={250}
    />
  );
}
