import React, { useState, useEffect, useContext } from 'react';
import ReactModal from 'react-modal';
import Header from '../../common/header.jsx';
import CountrySelect from './country-select/index.jsx';
import SectorSearch from './sector-search/index.jsx';
import NaicsSelector from  './naics-select/index.jsx';
import Select from 'react-select';
import URLSearchParams from '@ungap/url-search-params';
import styled, {css} from 'styled-components';
import { csrftoken } from '../../utils/csrf.jsx';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';
import moment from 'moment';

export const DynamicBenchmarkContext = React.createContext();

const StyledReactModal = styled(ReactModal)`
    max-width: 500px;
`;

const ModalContent = styled.div`
    margin: 16px auto;
`;

const StyledHeader = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgba(199, 210, 219, .7);
  border-bottom: none;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #FFF;
  box-shadow: 0 1px 3px 0 rgba(199, 210, 219, .4);

  @media screen and (min-width: 64em) {
    padding: 24px;
  }

  @media screen and (min-width: 75em) {
    padding: 32px;
  }
`;

const StyledFooter = styled.div`
  margin: 32px -16px -16px;
  padding: 16px;
  background-color: ${props => props.isDanger ? '#FDD8D8' : '#FFF'};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid rgba(199, 210, 219, .7);

  @media screen and (min-width: 64em) {
    margin: 32px -24px -24px;
    padding: 24px;
  }

  @media screen and (min-width: 75em) {
    margin: 32px -32px -32px;
    padding: 32px;
  }
`;

const TextContainer = styled.div`
  text-align: ${props => props.textAlign ? props.textAlign: 'right'};
  padding-right: 16px;
  color: ${props => props.textColor};

  p {
    color: ${props => props.textColor};
    line-height: initial;
  }
`;

const StyledButton = styled.button`
  min-width: 220px;
`;

const HelpText = styled.p`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: 400;
  color: #828c94;
  text-transform: none;
  max-width: initial;
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: ${props => props.flexWrap ? props.flexWrap : 'nowrap'};
  justify-content: space-between;
  margin: 0 ${props => props.spacing ? css`calc(-${props.spacing})` : '-8px'};

  @media screen and (min-width: 75em) {
    flex-wrap: nowrap;
  }

  > div {
    padding: 0 ${props => props.spacing ? props.spacing : '8px'};
    flex: ${props => props.flex ? props.flex : '0 1 auto'};

    &.dropdownContainer {
      flex: 1 1 auto;
    }
  }

  &.bordered {
    > div:not(:last-of-type) {
      position: relative;

      &:after {
        content: '';
        border-right: 1px solid #C7D2DB;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin-top: -24px;
        margin-bottom: -24px;
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  min-width: 155px;
`;

const ClearFilters = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const selectStyles = {
  control: provided => ({
    ...provided,
    minHeight: '40px',
  })
};

export default function DynamicBenchmark(props) {
  const [isPublic, setIsPublic] = useState('all');
  const [sales, setSales] = useState('all');
  const [salesLT, setSalesLT] = useState({ label: 'No Max', value: 'all' });
  const [salesGTE, setSalesGTE] = useState({ label: 'No Min', value: 'all' });
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [companies, setCompanies] = useState('all');
  const [countries, setCountries] = useState([]);
  const [countrySelected, setCountrySelected] = useState(null);
  const [sectorSelected, setSectorSelected] = useState(null);
  const [sectors, setSectors] = useState(null);
  const [sectorDigitSelected, setSectorDigitSelected] = useState(6);

  const companySizes = [
    {
      value: 50000000,
      label: '50M',
      salesGTE: '0',
      salesLT: '50000000'
    },
    {
      value: 100000000,
      label: '100M',
      salesGTE: '50000000',
      salesLT: '100000000'
    },
    {
      value: 250000000,
      label: '250M',
      salesGTE: '100000000',
      salesLT: '250000000'
    },
    {
      value: 500000000,
      label: '500M',
      salesGTE: '250000000',
      salesLT: '500000000'
    },
    {
      value: 1000000000,
      label: '1B',
      salesGTE: '500000000',
      salesLT: '1000000000'
    },
    {
      value: 2000000000,
      label: '2B',
      salesGTE: '1000000000',
      salesLT: '2000000000'
    }
  ];

  const companyTypes = [
    { type: 'all', description: 'All company types' },
    { type: 'public', description: 'Only public companies' },
    { type: 'private', description: 'Only private companies' }
  ];


  useEffect(() => {
    fetch(props.countries, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrftoken()
      },
      credentials: 'same-origin'
    })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      setCountries(data);
    });
  }, [props.countries]);

  useEffect(() => {
    setIsLoading(true);
    const csrftoken_value = csrftoken();

    const data = {};
    isPublic === 'all'
      ? (delete data.isPublic, delete data.isPrivate)
      : isPublic === 'public'
        ? data.isPublic = true
        : data.isPrivate = true;

    salesLT && salesLT.value !== 'all'
      ? data.salesLT = salesLT.value
      : delete data.salesLT;

    salesGTE && salesGTE.value !== 'all'
      ? data.salesGTE = salesGTE.value
      : delete data.salesGTE;

    countrySelected && countrySelected.value !== 'all'
      ? data.countryCode = countrySelected.value
      : delete data.countryCode;

    if (sectorSelected && sectorSelected.value !== 'all') {
      if (sectorDigitSelected === 6) {
        data.naicsCode = sectorSelected.value;
      } else if (sectorDigitSelected === 4) {
        data.naicsCode = sectorSelected.value.slice(0,4);
      } else {
        data.naicsCode = sectorSelected.value.slice(0,2);
      }
    } else {
       delete data.naicsCode;
    }

    const searchParams = new URLSearchParams(data);

    fetch(
      `${window.location.origin}/dynamic-benchmark/company-count?${searchParams}`,
      {
        method: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrftoken_value
        },
        credentials: 'same-origin'
      }
    )
    .then(response => {
      return response.json();
    })
    .then((response)=>{
      setIsLoading(false);
      setCompanies(response.count);
    });
  }, [isPublic, sales, salesLT, salesGTE, sectorSelected, sectorDigitSelected, countrySelected]);

  function resetAll () {
    setSalesLT({ label: 'No Max', value: 'all' });
    setSalesGTE({ label: 'No Min', value: 'all' });
    setIsPublic('all');
    setCountrySelected(null);
    setSectorSelected(null);
  }

  function noFiltersApplied() {
    if (isPublic === 'all'
    && salesLT.value === 'all'
    && salesGTE.value === 'all'
    && sectorSelected == null
    && countrySelected == null) {
      return true;
    } else {
      return false;
    }
  }

  function handleSubmit() {
    setIsGeneratingReport(true);
    const self = this;
    const csrftoken_value = csrftoken();
    const formData = new FormData();

    isPublic === 'all'
      ? formData.delete('isPublic', 'isPrivate')
      : isPublic === 'public'
        ? formData.append('isPublic', true)
        : formData.append('isPrivate', true);

    salesLT && salesLT.value !== 'all'
      ? formData.append('salesLT', salesLT.value)
      : formData.delete('salesLT');

    salesGTE && salesGTE.value !== 'all'
      ? formData.append('salesGTE', salesGTE.value)
      : formData.delete('salesGTE');

    countrySelected !== null
      ? formData.append('countryCode', countrySelected.value)
      : formData.delete('countryCode');

    if (sectorSelected && sectorSelected.value !== 'all') {
      if (sectorDigitSelected === 6) {
        formData.append('naicsCode', sectorSelected.value);
      } else if (sectorDigitSelected === 4) {
        formData.append('naicsCode', sectorSelected.value.slice(0,4));
      } else {
        formData.append('naicsCode', sectorSelected.value.slice(0,2));
      }
    } else {
      formData.delete('naicsCode');
    }

    fetch(
      `${window.location.origin}/dynamic-benchmark/create_dynamic_benchmark_report`,
      {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrftoken_value
        },
        credentials: 'same-origin',
        body: formData
      }
    )
    .then(response => {
      return response.json();
    })
    .then((response)=>{
      if (response.status === 'success') {
        downloadFile(response.downloadUrl);
      } else {
        console.log(response);
      }
    });
  }

  function downloadFile(url) {
    fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrftoken()
      },
      credentials: 'same-origin'
      })
      .then(resp => resp.blob())
      .then(blob => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          window.navigator.msSaveOrOpenBlob(
            blob,
            'benchmark_' + moment().format('M_D_YYYY') + '.pdf'
          );
        } else {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'benchmark_' + moment().format('M_D_YYYY') + '.pdf';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setIsGeneratingReport(false);
        }
      })
      .catch(() => console.log('An error occurred generating the report.'));
  }

  function NumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function renderSubmitMessage () {
    return (
      <StyledFooter
        isDanger = {companies < 5}
      >
        <TextContainer
          textColor = {companies < 5 ? '#ED4C4A' : '#45AF3C' }
        >
          {
            noFiltersApplied()
              ? <p className="caption--secondary">
                  {LOCALIZED_STRS.get().benchmarking} <span className="bold">{props.companyName}</span> {LOCALIZED_STRS.get().against} <span className="bold">{LOCALIZED_STRS.get().all} {companies !== 1 ? 'companies' : 'company'}</span>
                </p>
              :
                <>
                  <p className="caption--secondary">{LOCALIZED_STRS.get().benchmarking} <span className="bold">{props.companyName}</span> {LOCALIZED_STRS.get().against} <span className="bold">{NumberWithCommas(companies)} {companies !== 1 ? 'companies' : 'company'}</span></p>
                  {companies < 5 &&
                    <p className="caption--secondary">
                      <span className="bold">
                        {LOCALIZED_STRS.get().dynamic_report_minimum}
                      </span>
                    </p>
                  }
                </>
          }
        </TextContainer>
        <StyledButton
          type="button"
          className="rbtn rbtn--primary rbtn--md text__casing--uppercase"
          disabled={isLoading || companies < 5}
          onClick={() => {
            handleSubmit();
          }}
        >
          {isLoading ? LOCALIZED_STRS.get().loading : LOCALIZED_STRS.get().dynamic_report_run}
        </StyledButton>
      </StyledFooter>
    );
  }

  const minCompanySizes = [
    ...[{value: 'all', label: 'No Min', salesGTE: 'all' }],
    ...companySizes
  ];

  const maxCompanySizes = [
    ...[{value: 'all', label: 'No Max', salesLT: 'all' }],
    ...companySizes
  ];

  return (
    <>
      <Header
        mainheader={LOCALIZED_STRS.get().dynamic_report_header}
        subheader={LOCALIZED_STRS.get().dynamic_report_subheader}
      >
      </Header>
      <DynamicBenchmarkContext.Provider
        value={{
          countrySelected, setCountrySelected,
          sectorSelected, setSectorSelected,
          sectorDigitSelected, setSectorDigitSelected
        }}
      >
      <main>
        <StyledHeader>
          <div className="row">
            <div
              className="float-left align__vertical--center margin__horizontal--tight--right companyIcon companyIcon--firmographic"
            >
            </div>
            <span>
                <p className="bold">{LOCALIZED_STRS.get().dynamic_report_create}</p>
                <p>{props.companyname}</p>
            </span>
          </div>
        </StyledHeader>
        <div className="accordion accordion--card accordion--locked">
          <div className="margin__vertical--bottom">
            <p className="bold">
              {LOCALIZED_STRS.get().dynamic_report_description_1}
            </p>
          </div>
          <div className="dropdownContainer dropdownContainer--sector">
            <div
              className="margin__vertical--tight--bottom"
            >
              {LOCALIZED_STRS.get().sectorOrNaics}
            </div>
            <SectorSearch
              id="dynamicBenchmarkForm__naicsSelector"
              placeholder="All available sectors"
              name="sector"
              sector={sectorSelected}
              sectorsuggestionurl={props.naicscode}
            />
            <HelpText>
              {LOCALIZED_STRS.get().dynamic_report_sector_helper}
            </HelpText>
            {sectorSelected &&
              <NaicsSelector
                sectorSelected={sectorSelected}
              />
            }
          </div>
          <hr className="margin__vertical--loose" />
          <div id="dynamicBenchmarkForm">
            <div className="margin__vertical--bottom">
              <p className="bold">
                {LOCALIZED_STRS.get().dynamic_report_description_2}
              </p>
            </div>
            <FlexRow
              flexWrap="wrap"
            >
              <div className="dropdownContainer">
                <div
                  className="margin__vertical--tight--bottom text__casing--capitalize"
                >
                  {LOCALIZED_STRS.get().location}
                </div>
                <CountrySelect
                  id="dynamicBenchmarkForm__countrySelector"
                  name="country"
                  options={countries}
                  maxDisplayedOptions={10}
                />
              </div>
              <div>
                <div className="margin__vertical--tight--bottom">
                  {LOCALIZED_STRS.get().companySizeSales}
                </div>
                <FlexRow
                  flexWrap="nowrap"
                  spacing={'4px'}
                >
                  <StyledSelect
                    value={salesGTE}
                    styles={selectStyles}
                    options={minCompanySizes}
                    onChange={(selectedOption) =>
                      selectedOption === null
                      ? setSalesGTE({value: 'all', label: 'No Min'})
                      : setSalesGTE(selectedOption)
                    }
                    isOptionDisabled={
                      (option) => option.value != 'all' && option.value >= salesLT.value
                    }
                    isSearchable={false}
                  />
                  <StyledSelect
                    value={salesLT}
                    styles={selectStyles}
                    options={maxCompanySizes}
                    onChange={(selectedOption) =>
                      selectedOption === null
                      ? setSalesLT({value: 'all', label: 'No Max' })
                      : setSalesLT(selectedOption)
                    }
                    isOptionDisabled={
                      (option) => option.value != 'all' && option.value <= salesGTE.value
                    }
                    isSearchable={false}
                  />
                </FlexRow>
              </div>
              <div>
                <div
                  className="margin__vertical--tight--bottom text__casing--capitalize"
                >
                  {LOCALIZED_STRS.get().companyType}
                </div>
                <div
                  className="rbtn--group rbtn--group--tab--noStyles"
                  role="tablist"
                  aria-label="Company Type"
                >
                  {companyTypes.map((item, index) => {
                    return (
                      <button
                        key={index}
                        aria-label={item.description}
                        role="tab"
                        aria-selected={isPublic === item.type ? 'true' : 'false'}
                        className={`
                          rbtn rbtn--md rbtn--geyser
                          ${isPublic === item.type && 'rbtn--geyser--active'}
                        `}
                        onClick={() => {
                          setIsPublic(item.type);
                        }}
                      >
                        <span className="text__casing--capitalize">
                          {item.type}
                        </span>
                      </button>
                    );
                  })}
                </div>
              </div>
            </FlexRow>
            {renderSubmitMessage()}
          </div>
        </div>
      </main>
      </DynamicBenchmarkContext.Provider>
      <StyledReactModal
          isOpen={isGeneratingReport}
          contentLabel="Dynamic Benchmark Modal"
          className="Modal"
          overlayClassName="Overlay"
          shouldCloseOnOverlayClick={false}
      >
          <div className="ReactModal__Header">
              <h2>
                  {LOCALIZED_STRS.get().dynamic_benchmark_header}
              </h2>
          </div>
          <p>
              {LOCALIZED_STRS.get().dynamic_benchmark_helper}
          </p>
          <ModalContent>
              <div className="rr_logo_animation"></div>
          </ModalContent>
      </StyledReactModal>
    </>
  );
}
