import React from 'react';
import styled from 'styled-components';

const Form = styled.form`
  position: relative;
  display: flex;
  width: 100%;
  background: #fff;
  border: 1px solid #c7d2db;
  border-radius: 3px;
  height: 54px;

  &::before {
    content: '\\e915';
    font-size: 22px;
    color: ${props => (props.isCompanyInputFocused ? '#00afd7' : '#51585d')};
    padding-left: 12px;
    line-height: 49px;
    z-index: 1;
    font-family: RapidRatings;
  }

  & .location-select__control {
    padding-left: 32px;
    height: calc(100% - 16px);
    border: none;
    border-left: 1px solid #c7d2db;
    border-radius: 0;
    margin: 8px 0;
    box-shadow: none;

    &:hover {
      box-shadow: none !important;
      border-color: transparent;
      border-left: 1px solid #c7d2db;
    }

    &::before {
      content: '\\e90c';
      display: block;
      font-size: 22px;
      color: ${props => (props.isLocationInputFocused ? '#00afd7' : '#51585d')};
      position: absolute;
      left: 12px;
      top: 3px;
      z-index: 1;
      font-family: RapidRatings;
    }
  }

  & .location-select__indicators {
    display: none;
  }

  & .location-select__menu {
    width: calc(100% + 49px);
    margin-top: 1px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #c7d2db;
    border-top: none;
    padding: 0;
  }

  & .location-select__menu-notice {
    text-align: left;
  }
`;

export default function SearchForm({
  children,
  onSubmit,
  isCompanyInputFocused = false,
  isLocationInputFocused = false
}) {
  return (
    <Form
      onSubmit={onSubmit}
      isCompanyInputFocused={isCompanyInputFocused}
      isLocationInputFocused={isLocationInputFocused}
    >
      {children}
    </Form>
  );
}
