import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import List from './list.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';

class Strengths extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: JSON.parse(props.list || '[]')
        };
    }
    render() {
        return (
            <table className="table table--summary">
                <thead>
                    <tr>
                        <th scope="col" colSpan="2">
                            {LOCALIZED_STRS.get().ratioHeader}
                        </th>
                        <th scope="col" className="trend">{LOCALIZED_STRS.get().trend}</th>
                    </tr>
                </thead>
                <tbody>
                  {this.state.list.map((item, index) => {
                      return (
                          <tr key={index} className="">
                              <th scope="row">{item.name}</th>
                              <td className="numeric">{item.current}%</td>
                              <td className="trend">
                                  <i className={ parseInt(item.delta) > 0 ? 'icon icon--up' : parseInt(item.delta) === 0 ? '' : 'icon icon--down' } aria-hidden="true"></i>
                              </td>
                          </tr>
                      );
                  })}
                </tbody>
            </table>
        );
    }
}

export default Strengths;
