import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import DownChevron from './downChevron.jsx';
import {
  WHITE,
  BLACK,
  TURBO,
  MOBILE_BREAKPOINT,
  GREY_PRIMARY,
  ABBEY,
} from './constants';

const AccountNav = styled.nav`
    height: 24px;
    padding-right: 8px;
    float: right;

    .rbtn {
        line-height: 22px;
        font-size: 11px;
    }
`;
const AccountToggle = styled.div``;
AccountToggle.displayName = 'AccountToggle';
const AccountButton = styled.button`
    background-color: ${ABBEY};
    border-color: ${ABBEY};
    color: ${WHITE};
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    font-family: Source Sans Pro,sans-serif;
    line-height: 22px;
    display: flex;
    align-items: center;
    min-width: 95px;
    cursor: pointer;
    padding: 0 8px;
    transition: background-color .2s linear;
    border: none;
`;
const AccountList = styled.ul`
    right: 0;
    border: none;
    background-color: #3b4043;
    display: ${props => (props.active === true ? 'block' : 'none')};
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,.15);
    border-radius: 2px;
    width: 215px;
    position: absolute;

    li {
        padding: 4px;
    }

    a {
        cursor: pointer;
        text-decoration: none;
        text-align: left;
        font-family: Source Sans Pro,sans-serif;
        font-size: 14px;
        line-height: 32px;
        padding: 0 8px;
        color: ${WHITE};
        display: block;
        transition: background-color .3s cubic-bezier(.54,.09,.3,.82);

        &:hover {
            background-color: ${TURBO};
            transition: background-color .3s cubic-bezier(.54,.09,.3,.82);
            color: ${BLACK};
        }
    }
`;
AccountList.displayName = 'AccountList';
const ChevronContainer = styled.span`
    margin: 0 0 0 4px;
    float: right;
    transform: scale(0.7);
`;

class AccountMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false
        };
        this.keyFunction = this.keyFunction.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleEventOutside = this.handleEventOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleEventOutside, false);
        document.addEventListener('keydown', this.keyFunction, false);
        document.addEventListener('focus', this.handleEventOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleEventOutside, false);
        document.removeEventListener('keydown', this.keyFunction, false);
        document.removeEventListener('focus', this.handleEventOutside, true);
    }

    toggleMenu() {
        this.setState({
            isActive: !this.state.isActive
        });
    }

    navigateVertical = (offsetParent) => {
        this.focusOnLink(offsetParent, 'button');
    }

    focusOnLink = (reference, link) => {
        reference.querySelector(link).focus();
    }

    keyFunction(event){
        if (this.node.contains(event.target)) {
            const focusedElement = document.activeElement;
            if (event.keyCode === 27) { // esc is pressed
                this.toggleMenu();
            }

            if (event.keyCode === 38) { // up is pressed
                event.preventDefault();
                if (event.srcElement.parentNode.previousSibling){ // focusedElement is a link
                    this.focusOnLink(event.srcElement.parentNode.previousSibling, 'a');
                } else { // beginning of the overall category has been reached
                    const offsetParent = event.srcElement.closest('nav');
                    this.navigateVertical(offsetParent, 'button');
                    // close menu
                    this.toggleMenu();
                }
            }

            if (event.keyCode === 40) { // down is pressed
                event.preventDefault();
                if (event.srcElement.attributes['aria-haspopup']) { // focusedElement is last element
                    // go to first link in mainmenu
                    this.focusOnLink(event.srcElement.parentNode.closest('nav'), 'a');
                    // close the menu
                    this.toggleMenu();
                }

                 // focusedElement is a link
                if (event.srcElement.parentNode.nextSibling) { // link has an immediate link sibling
                    this.focusOnLink(event.srcElement.parentNode.nextSibling, 'a');
                } else { // link has an no immediate link sibling, go to next category
                    const nextParentCategory = event.srcElement.closest('div').nextSibling;
                    if (nextParentCategory) {
                        this.focusOnLink(nextParentCategory, 'a');
                    } else { // end of the overall category has been reached
                        // close menu
                        this.toggleMenu();
                        // navigate to first link in mainmenu
                        this.focusOnLink(document.getElementById('mainmenu'), 'a');
                    }
                }

            }
        }
    }

    handleEventOutside(event) {
        if ( this.node.contains(event.target)) {
            return;
        }
        this.setState({
            isActive: false
        });
    }

    render() {
        return (
            <AccountNav
                ref={node => this.node = node}
                id="accountmenu"
                aria-label="account menu"
            >
                <AccountToggle onClick={this.toggleMenu} role="menubar">
                    <div role="presentation">
                        <AccountButton
                            role="menuitem"
                            aria-haspopup="true"
                        >{this.props.account.label}
                            <ChevronContainer>
                                <DownChevron />
                            </ChevronContainer>
                        </AccountButton>
                    </div>
                    <AccountList
                        active={(this.state.isActive) ? true : false}
                        role="presentation"
                    >
                        {this.props.account.list.map((item, index) => {
                            return (
                                <li key={index} role="presentation">
                                    <a href={item.action} role="menuitem">{item.label}</a>
                                </li>
                            );
                        })}
                    </AccountList>
                </AccountToggle>
            </AccountNav>
        );
    }
}
export default AccountMenu;
