import React, {Component} from 'react';
import CustomError from '../../utils/error.jsx';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';

class BaseInput extends Component {
    constructor(props) {
        super(props);
        this.isCapsLockOn = false;
        this.state = {
            value: this.props.value,
            errors: this.props.errors || [],
            label: this.props.label,
            isTouched: false,
            placeholder: this.props.placeholder,
            attributes: {
                type: this.props.type,
                name: this.props.name,
                autoComplete: 'off',
                id: this.props.id,
                placeholder: this.props.placeholder
            },
            validations: {
                'max-length': this.props.maxLength,
                'min-length': this.props.minLength,
                'min': this.props.min,
                'mix': this.props.max,
                'required': this.props.required
            }
        };
    }
    checkCapsLock(evt) {
        this.isCapsLockOn = UTILS.isInputNotValid.capslock(evt.nativeEvent);
        if (this.isCapsLockOn) {
            const errs = [new CustomError({ error: 'capslock', message: LOCALIZED_STRS.get().formErrors.capslock })];
            this.setState({errors: errs});
        }
    }
    handleKeyUp(evt) {
        if (this.state.hasCapsLockCheck) {
            this.checkCapsLock(evt);
        }
    }
    handleBlur(evt) {
      // PLACEHOLDER
    }
    handleFocus(evt) {
      // PLACEHOLDER this.setState({isTouched: true});
    }
    handleChange(evt, callback) {
        const type = this.state.attributes.type;
        const val = evt.target.value;
        const validations = this.state.validations;
        /*
          THE UTILS.getInputErrors() returns all errors,
          but for UX, we will only show one at a time.
        */
        let errs = [];
        if (!this.isCapsLockOn) {
            errs = [UTILS.getInputErrors(type, val, validations)[0] || {}];
        }
        this.setState({value: val, errors: errs}, callback);
    }
}

export default BaseInput;
