import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

Modal.setAppElement('body');

class LoadingScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            hasError: props.hasError || false,
            hasFocus: props.hasFocus || false,
            showModal: props.showModal || false
        });

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal () {
      this.setState({ showModal: true });
    }

    handleCloseModal () {
      this.setState({ showModal: false });
    }

    render() {
      return (
          <Modal
              isOpen={this.state.showModal}
              contentLabel="onRequestClose Example"
              className="Modal"
              overlayClassName="Overlay">
              <div className="ReactModal__Header">
                  <h2 className="text__align--center">{LOCALIZED_STRS.get().search.loading}</h2>
              </div>
              <div className="please_wait_animation"></div>
          </Modal>
      );
    }
}

export default LoadingScreen;
