if (document.getElementById('nspActionsBtn')) {
    document.getElementById('nspActionsBtn').onclick = function() {
        toggleFunction();
    };
}

// add the show class
function toggleFunction() {
    document.getElementById('nspActionsDropdown').classList.toggle('show');
}

// close dropdown if a click occurs outside of it
window.onclick = function(event) {

    if (!event.target.matches('.dropdown *')) {

        const dropdowns = document.getElementsByClassName('dropdown-content');

        for (let i = 0; i < dropdowns.length; i++) {
            const openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
    }
};
