import React from 'react';
import ReduxStore from './redux_store.jsx';

class ReduxModelLabel extends ReduxStore(React.Component) {
    constructor(props){
        super(props);
        this.state = {
            text: this.store.getState()[props.store_model],
            store_model_raw: props.store_model_raw
        };
        this.store.subscribe(() => {
            this.setState({'text': this.store.getState()[this.store_model]});
        });
    }

    render() {
        if (this.state.store_model_raw) {
            return <label { ...this.state } dangerouslySetInnerHTML={{__html:this.state.text}} />;
        } else {
            return <label { ...this.state }>{this.state.text}</label>;
        }
    }
}

export default ReduxModelLabel;
