function isOverflown(element) {
    // check if element has overflown
    if (element.scrollHeight > element.clientHeight) {
        element.classList.add('overflown');
    } else {
        element.classList.remove('overflown');
    }
}

export default isOverflown;
