import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

class Quandrant extends React.Component {
    constructor(props) {
        super(props);
        const width = (300 - 4);
        const delta = width/100;
        const style = {
            top: (props.fhr * delta) + 'px',
            left: ((100 * delta) - (props.chs * delta)) + 'px'
        };
        const grid = LOCALIZED_STRS.get().indicators[UTILS.getRiskName(props.chs)][UTILS.getRiskName(props.fhr)];
        this.state = {
            fhr: props.fhr,
            chs: props.chs,
            grid: grid,
            style: style,
        };
    }
    render() {
        return (
          <div className="quadrant">
              <div className="quadrant__plane" aria-hidden="true">
                  <div className={ `plane plane--${this.state.grid.label}` }>
                      <div className="dot" style={this.state.style}>
                          <div className="dot__marker">
                              <svg width="60px" height="206px" viewBox="0 0 60 206">
                              <title>Dot Marker</title>
                              <defs></defs>
                              <g transform="translate(-459.000000, -159.000000)" fill="#3B4043">
                                  <g transform="translate(459.000000, 159.000000)">
                                      <path d="M6.39164746,104.302481 C2.38775339,93.3399986 0,79.5195157 0,64.5 C0,28.8776336 13.4314575,0 30,0 C46.5685425,0 60,28.8776336 60,64.5 C60,79.5195157 57.6122466,93.3399986 53.6083525,104.302481 L30,206 L6.39164746,104.302481 Z"></path>
                                  </g>
                              </g>
                              <g stroke="none" transform="translate(-742, -130)" fill="#FFFFFF" fontFamily="SourceSansPro-Light, Source Sans Pro" fontSize="40" fontWeight="300">
                                  <text>
                                      <tspan x="760.48" y="198">{this.state.grid.label.toUpperCase()}</tspan>
                                  </text>
                              </g>
                        </svg>
                          </div>
                      </div>
                      <div className="section section--a">
                          <span>A</span>
                      </div>
                      <div className="section section--b">
                          <span>B</span>
                      </div>
                      <div className="section section--c">
                          <span>C</span>
                      </div>
                      <div className="section section--d">
                          <span>D</span>
                      </div>
                      <div className="range range--left">
                          <div className="range__section range__section--veryhigh"></div>
                          <div className="range__section range__section--high"></div>
                          <div className="range__section range__section--medium"></div>
                          <div className="range__section range__section--low"></div>
                          <div className="range__section range__section--verylow"></div>
                      </div>
                      <div className="range range--right">
                          <div className="range__section range__section--veryhigh"></div>
                          <div className="range__section range__section--high"></div>
                          <div className="range__section range__section--medium"></div>
                          <div className="range__section range__section--low"></div>
                          <div className="range__section range__section--verylow"></div>
                      </div>
                  </div>
                  <div className="quadrant__numbers">
                      <div className="fhr__0">
                          0
                      </div>
                      <div className="fhr__level">
                          {this.state.fhr}
                      </div>
                      <div className="both__100">
                          100
                      </div>
                      <div className="chs__level">
                          {this.state.chs}
                      </div>
                      <div className="chs__0">
                          0
                      </div>
                  </div>
              </div>
          </div>
        );
    }
}

export default Quandrant;
