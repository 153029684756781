import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import BaseInput from '../forms/common/baseinput.jsx';
import {getRequest} from '../utils/fetch.jsx';
import ReactModal from 'react-modal';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import RequestPublicAddition from './request_public_addition.jsx';

class RequestPublicAdditionCallToAction extends React.Component {
    render () {
        return (
            <div className="row landingPublic">
                <div className="col-xs-12 col-sm-12">
                    <i className="icon icon--building icon--graychateau icon--2x iconLanding float-left padding__horizontal--right"></i>
                    <div>
                        <span className="search__company">
                          {LOCALIZED_STRS.get().search.public_comp_question}
                        </span>
                        <p className="margin__none">
                            <button className="link link--lowerSize rbtn--transparent padding__none request"
                                    onClick={this.props.handleOpenModal}>
                                <span className="search__details">
                                  {LOCALIZED_STRS.get().search.public_comp_action}
                                </span>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

class RequestPublicAdditionModal extends React.Component {

    render() {
        return (
        <ReactModal
            isOpen={this.props.showModal}
            contentLabel="onRequestClose Example"
            onRequestClose={this.props.handleCloseModal}
            className="Modal"
            overlayClassName="Overlay">
            <div className="ReactModal__Header">
                <h2>{LOCALIZED_STRS.get().search.action}</h2>
                <button
                    onClick={this.props.handleCloseModal}
                    className="rbtn rbtn--transparent"
                >
                <i className="icon icon--close close-menu" aria-hidden="true"></i>
                </button>
            </div>
            <RequestPublicAddition url={this.props.requestPublicAdditionURL} handler={this.props.handleCloseModal}/>
        </ReactModal>
        );
    }
}


class RequestLanding extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            hasError: false,
            hasFocus: false,
            showModal: false,
            requestPublicAdditionUrl: props['request-public-addition-url']
        });

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal () {
      this.setState({ showModal: true });
    }

    handleCloseModal () {
      this.setState({ showModal: false });
    }


    render() {
        return (
            <div>
                <RequestPublicAdditionCallToAction
                    handleOpenModal={this.handleOpenModal}
                    hasFocus={this.state.hasFocus}
                    isLoading={this.state.isLoading}
                />
                <RequestPublicAdditionModal
                    requestPublicAdditionURL={this.state.requestPublicAdditionUrl}
                    handleOpenModal={this.handleOpenModal}
                    handleCloseModal={this.handleCloseModal}
                    showModal={this.state.showModal}
                />
            </div>
        );
    }
}

export default RequestLanding;
