import React from 'react';

function MenuToggleIcon() {
    return(
        <svg width="40px" height="34px" viewBox="0 0 40 34">
            <title>MenuToggleIcon</title>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="menu" fill="#ffffff" fillRule="nonzero">
                    <path d="M0,13.733 L26.207,13.733 L26.207,20.285 L0,20.285 L0,13.733 Z M0,0.629 L39.311,0.629 L39.311,7.181 L0,7.181 L0,0.629 L0,0.629 Z M0,26.837 L32.759,26.837 L32.759,33.389 L0,33.389 L0,26.837 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
    );
}

export default MenuToggleIcon;
