import {csrftoken} from '../../../utils/csrf.jsx';

/**
 * Returns a promise that resolves with the location suggestions for the query.
 * @param {string} url The endpoint for the location suggestion.
 * @returns {Promise} The promise that resolves with the suggestions.
 */
export function getLocationSuggestions(url) {
    return fetch(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrftoken()
        },
        credentials: 'same-origin'
    })
    .then(res => res.json())
    .then(json => Promise.resolve(json.results));
}

/**
 * Returns an array of options based on the provided suggestions..
 * @param {string} suggestions The suggestions array.
 * @returns {Array} The array of options from the suggestions.
 */
export function transformSuggestionsToOptions(suggestions) {
  return suggestions.map(({ query, data: { code, description } }, index) => {
    return {
      value: makeLocationLabel({ code, description }),
      label: makeLocationLabel({ code, description })
    };
  });
}

/**
 * Returns a label from the selected location params.
 * @param {Object} location The location object.
 * @returns {string} The location label.
 */
export function makeLocationLabel({ code, description }) {
  return `${code ? `${code}, ` : ''}${description ? `${description}` : ''}`;
}
