import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

class NewsHeader extends React.Component {
    render () {
        return (
            <React.Fragment>
                <img src={this.props.logoUrl} className="news__logo--expanded" alt="News provider logo" />
                <span>
                    <h1 className="rr-header-1 !leading-none">{LOCALIZED_STRS.get().news.heading}</h1>
                    <p className="news__subheader">{LOCALIZED_STRS.get().news.subHeading}</p>
                </span>
            </React.Fragment>
        );
    }
}

class NewsCategories extends React.Component {
    render() {
        return (
            <ul className="news__category__list">
                {Object.keys(this.props.categories).map((category, index) => {
                    return (
                        <NewsCategory key={index} currentCategory={this.props.currentCategory} categoryName={category} categoryCount={this.props.categories[category]} clickHandler={this.props.clickHandler}/>
                    );
                })
                }
            </ul>
        );
    }
}

class NewsCategory extends React.Component {
    constructor(props) {
        super(props);
        this.clickable = true;
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        if(this.clickable) {
            this.props.clickHandler(this.props.categoryName);
        }
    }
    render() {
        let className = 'news__category news__category--' + this.props.categoryName;
        let titleClassName = 'news__category__title';

        if(this.props.categoryName === this.props.currentCategory){
            className += ' news__category--active';
            titleClassName += ' news__category__title--active';
        }
        if(parseInt(this.props.categoryCount) === 0){
            className += ' news__category--empty';
            this.clickable = false;
        }
       return (
            <li>
                <div onClick={this.handleClick} className={className}  data-count={this.props.categoryCount}>
                </div>
                <div className={titleClassName}>{LOCALIZED_STRS.get().news[this.props.categoryName]}</div>
            </li>
        );
    }
}

class NewsItems extends React.Component {

    renderEmpty(){
        return (
            <ul className="content__list content__list--news-expanded">
                <li>
                    <div>
                        {LOCALIZED_STRS.get().news[this.props.category]} {LOCALIZED_STRS.get().news.singularEmpty}
                    </div>
                </li>
            </ul>
        );
    }

    componentDidUpdate() {
        // Scrolls to the top of the element
        const element = window.document.getElementById('newsList');
        element.scrollTop = 0;
    }

    render() {
        const category = this.props.category;
        let  count = 0;
        this.props.articles.forEach((article) => count += article.category === category ? 1 : 0);
        if (count > 0) {
            return (
                <ul id="newsList" className="content__list content__list--news-expanded">
                    {this.props.articles.map((article, index) => {
                        return (
                            <NewsItem key={index} article={article}
                                      category={this.props.category}/>
                        );
                    })
                    }
                </ul>
            );
        }
        return this.renderEmpty();
    }
}

class NewsItem extends React.Component {
    render() {
        if (this.props.article.category !== this.props.category) {
            return null;
        }
        return (
            <li>
                <h2 className="news__headline news__headline--clickable">
                    <a href={this.props.article.url} target="_blank" rel="noopener noreferrer" className="link news__headline__extended">{this.props.article.head}</a>
                </h2>
                <div className="news__date">
                    {this.props.article.formatted_date}
                </div>
                <p className="news__snippet">
                    {this.props.article.real}
                </p>
                <div className="news__readmore news__readmore--clickable">
                    <a href={this.props.article.url} target="_blank" rel="noopener noreferrer" className="link">{LOCALIZED_STRS.get().news.readmore}<span className="sr-only"> about {this.props.article.head} </span></a>
                </div>
            </li>
        );
    }
}

class ExpandedNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            providerLogoUrl: props['providerLogoUrl'] || '',
            articles: props['articles'],
            category: 'news',
            navClass: ''
        };
        this.updateCategoryState = this.updateCategoryState.bind(this);
    }
    updateCategoryState (category) {
        this.setState({
            category: category
        });
    }
    render(){
        return (
            <React.Fragment>
                <div className="news__header">
                <NewsHeader logoUrl={this.state.providerLogoUrl}/>
                <NewsCategories categories={this.props.categories} clickHandler={this.updateCategoryState} currentCategory={this.state.category}/>
                </div>
                <NewsItems category={this.state.category} articles={this.state.articles}/>
            </React.Fragment>
        );
    }
}

export {
    ExpandedNews as default,
    ExpandedNews,
    NewsHeader,
    NewsCategories,
    NewsCategory,
    NewsItems,
    NewsItem
};
