import FastClick from './fastclick.jsx';


{
    const renderAccordions = () => {
        const accordions = [...document.getElementsByClassName('accordion')];
        accordions.forEach(accordion => {
            const header = accordion.getElementsByClassName('accordion__header');
            if (header.length > 0) {
                FastClick.apply(header[0], () => {
                    const event_type = accordion.classList.contains('accordion--active') ? 'collapse': 'expand';
                    const event_name = 'click-reveal-' + accordion.getAttribute('tracking-name') + '-' + event_type;
                    accordion.classList.toggle('accordion--active');
                });
            }
        }, this);
    };
    document.addEventListener('DOMContentLoaded', () => {
        renderAccordions();
    });

}
