import React from 'react';

import LOCALIZED_STRS from './localize.jsx';

const DEFAULT_LONG_MONTH_FORMAT = 'default-date';

class LongMonthSpan extends React.Component {
    constructor(props) {
        super(props);
        let date_format = props['date-format'] || DEFAULT_LONG_MONTH_FORMAT;
        if (!(date_format in LOCALIZED_STRS.get()['date-formats'])) {
            date_format = DEFAULT_LONG_MONTH_FORMAT;
        }
        let date_object = props['data-datetime'];
        if (date_object  && (typeof date_object === 'string' || date_object instanceof String)) {
            date_object = new Date(date_object);
        }
        this.state = {
            date_object: date_object,
            date_format: date_format
        };
    }

    render() {
        if (!this.state.date_object) {
            return null;
        }
        const date = this.state.date_object.toLocaleDateString(
            LOCALIZED_STRS.lang(), LOCALIZED_STRS.get()['date-formats'][this.state.date_format]);
        return <span className="datetime" data-datetime={this.state.date_object.toISOString()}>
              {date}
            </span>;
    }
}

export { LongMonthSpan as default, DEFAULT_LONG_MONTH_FORMAT };
