import React, {useMemo} from 'react';
import Toast from '../utils/toast.jsx';

export default function DataEntryToast() {

    const reviewValue = useMemo(() => {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('review');
    }, []);

    const resetValue = useMemo(() => {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('reset_datajob');
    }, []);

    const ToastMessage = () => {
        if (reviewValue) {
            switch(reviewValue){
                case 'submitted_for_qa':
                    return <span>The job was successfully submitted for QA.</span>;
                case 'pass':
                    return <span>The rating was released.</span>;
                case 'fail':
                    return <span>The data entry was successfully rejected.</span>;
                default:
                    return null;
            }
        }
        if (resetValue) {
            switch(resetValue){
                case 'success':
                    return <span>Data Entry was successfully reset.</span>;
                case 'fail':
                    return <span>Unable to reset data entry. Please try again later.</span>;
                default:
                    return null;
            }
        }
    };
    
    const toastClassName = resetValue === 'fail' ? 'toast--failed' : 'toast--success';
 
  return ToastMessage ? <Toast ToastMessage={ToastMessage()} toastId={'data-entry-toast'} className={toastClassName}/> : null;
}
