import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import isIe11 from '../../utils/isIe11.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';
import {getRequest} from '../../utils/fetch.jsx';
import Raphael from 'raphael';
import track from 'react-tracking';


class Trend extends React.Component {
    constructor(props) {
        super(props);
        this.scoreFHR = null;
        this.scoreCHS = null;
        this.scoreSIM = null;
        this.lookup = {
          Q1: 1,
          Q2: 2,
          Q3: 3,
          YE: 4
        };
        this.ele = null;
        this.trend = null;
        this.state = {
            data: JSON.parse(props.data || '{}'),
            period: {},
            isSelectedfhr: true,
            isSelectedchs: false,
            isSelectedsim: false,
            columns: [],
            periods: JSON.parse(props.periods || '[]'),
            isLoading: true,
            hasTrend: false,
            showCHS: JSON.parse(props['show-chs'] || 'true'),
            showFHR: JSON.parse(props['show-fhr'] || 'true'),
            showSIM: JSON.parse(props['show-simulated-fhr'] || 'true')
        };
    }
    componentDidMount() {
        if(this.state.periods.length > 0){
            this.setState({
                isLoading: false,
                hasTrend: this.state.periods.length > 1
            }, () => {
                this.initBuild();
            });
        } else {
            this.getPeriodData();
        }
    }
    getPeriodData() {
        getRequest(this.props['data-url']).then(results => {
            this.setState({
                periods: results.periods,
                isLoading: false,
                hasTrend: results.periods.length > 1
            }, () => {
                this.initBuild();
            });
        }).catch(err => {});
    }
    initBuild() {
        if(this.state.hasTrend){
            setTimeout(()=>{
                this.buildChart();
                this.selectPeriod(this.state.periods.length - 1);
            }, 100);
        } else {
            this.selectPeriod(this.state.periods.length - 1);
        }
    }
    selectPeriod(index) {
        const period = this.state.periods[index];
        this.setState({
            period: period

        });
        this.state.columns.forEach((column, i) => {
            if ((index - 1) === i) {
                column.node.setAttribute('class', 'period period--active');
            } else {
                column.node.setAttribute('class', 'period');
            }
        });
    }
    buildChart() {
        // display attribute fix for trend's width
        const revealerParent = document.querySelector('#risk-analysis-tab');
        if (revealerParent) {
            revealerParent.style.display = 'block';
        }

        const xPos = 32;
        const eleWidth = (this.ele.offsetWidth - xPos);
        const rowHeight = 1;
        const eleHeight = (100*rowHeight);
        const yPos = 5;
        const periodWidth = (eleWidth-xPos)/(this.state.periods.length-1);
        const quarterWidth = (periodWidth/4);
        const actualLength = (periodWidth*(this.state.periods.length - 2)) + (quarterWidth*this.lookup[this.state.periods[this.state.periods.length-1].period]);
        const lineHeight = (eleHeight/5);
        const lineHeightBarLong = (1);

        this.trend = Raphael('trendChart', eleWidth, (eleHeight + 50));
        this.trend.setViewBox(0, 0, eleWidth, (eleHeight + 50), true);
        this.trend.canvas.setAttribute('preserveAspectRatio', 'none');
        this.trend.canvas.setAttribute('meetOrSlice', 'meet');
        this.trend.setSize('100%', '100%');

        for (let i = 0; i <= 100; i++) {
            const lineY = yPos + (eleHeight - (rowHeight * i));
            if(i === 0 || i === 19 || i === 39 || i === 59 || i === 79){
              const bar = this.trend.rect(xPos - 5, (lineY - lineHeight), 5, lineHeight);
              const barLong = this.trend.rect(xPos, (lineY - lineHeightBarLong), actualLength, lineHeightBarLong);
              if (i === 0) {
                  bar.node.setAttribute('class', 'trend trend--veryhigh');
                  barLong.node.setAttribute('class', 'trend trend--veryhighbar');
              } else if (i === 19) {
                  bar.node.setAttribute('class', 'trend trend--high');
                  barLong.node.setAttribute('class', 'trend trend--highbar');
              } else if (i === 39) {
                  bar.node.setAttribute('class', 'trend trend--medium');
                  barLong.node.setAttribute('class', 'trend trend--mediumbar');
              } else if (i === 59) {
                  bar.node.setAttribute('class', 'trend trend--low');
                  barLong.node.setAttribute('class', 'trend trend--lowbar');
              } else if (i === 79) {
                  bar.node.setAttribute('class', 'trend trend--verylow');
                  barLong.node.setAttribute('class', 'trend trend--verylowbar');
              }
            }
            if (i % 20 === 0) {
                const score = this.trend.text((xPos - 10), lineY, i);
                score.node.setAttribute('class', 'period__text');
            }
        }

        let FHRString = '';
        let CHSString = '';
        let SIMString = '';
        this.state.periods.forEach((period, index) => {
            const FHR = parseFloat(period.fhr);
            const CHS = parseFloat(period.chs);
            const SIM = parseFloat(period.simulatedFhr);
            const lineY = yPos + eleHeight;

            const FHR_Y = lineY - (FHR * rowHeight);
            const CHS_Y = lineY - (CHS * rowHeight);
            const SIM_Y = lineY - (SIM * rowHeight);
            let X_POS = (xPos + (periodWidth * index));
            const X_ORG = X_POS;
            if(period.period !== 'YE'){
              X_POS -= (periodWidth - (quarterWidth * this.lookup[period.period]));
            }
            const Y_POS = eleHeight;

            if (index === 0) {
                FHRString += 'M ' + xPos + ',' + FHR_Y + ' ';
                CHSString += 'M ' + xPos + ',' + CHS_Y + ' ';
                SIMString += 'M ' + xPos + ',' + SIM_Y + ' ';
            } else {
                FHRString += 'L ' + X_POS + ', ' + FHR_Y + ' ';
                CHSString += 'L ' + X_POS + ', ' + CHS_Y + ' ';
                SIMString += 'L ' + X_POS + ', ' + SIM_Y + ' ';
            }

            if (index < this.state.periods.length - 1) {
                const vbar = this.trend.rect(X_POS, yPos, periodWidth, Y_POS);
                vbar.node.setAttribute('class', 'period');
                vbar.node.setAttribute('data-period', index);
                vbar.hover(evt => {
                    this.selectPeriod(parseFloat(evt.target.getAttribute('data-period')) + 1);
                });
                const arr = this.state.columns;
                arr.push(vbar);
                this.setState({columns: arr});
            }
            if (index > 0) {
                if (index < this.state.periods.length - 1) {
                    const vbar = this.trend.rect(X_POS, yPos, 1, Y_POS + 25);
                    vbar.node.setAttribute('class', 'period__divider');
                    const periodDate = this.trend.text(X_ORG - 2, yPos + eleHeight + 20, `${period.period} ${period.equityYear}`);
                    periodDate.node.setAttribute('class', 'period__date');
                } else {
                    const vbar = this.trend.rect((xPos - 1) + (periodWidth * (this.state.periods.length - 1)), yPos, 1, Y_POS + 25);
                    vbar.node.setAttribute('class', 'period__divider');
                    const periodDate = this.trend.text(X_ORG - 2, yPos + eleHeight + 20, `${period.period} ${period.equityYear}`);
                    periodDate.node.setAttribute('class', 'period__date');
                }
            }

        }, this);

        if(this.state.showCHS){
            this.scoreCHS = this.trend.path(CHSString);
            this.scoreCHS.node.setAttribute('class', 'trend__bar trend__bar--chs');
            this.scoreCHS.node.style.display = (this.state.isSelectedchs) ? '' : 'none';
        }
        if(this.state.showFHR){
            this.scoreFHR = this.trend.path(FHRString);
            this.scoreFHR.node.setAttribute('class', 'trend__bar trend__bar--fhr');
            this.scoreFHR.node.style.display = (this.state.isSelectedfhr) ? '' : 'none';
        }
        if(this.state.showSIM){
            this.scoreSIM = this.trend.path(SIMString);
            this.scoreSIM.node.setAttribute('class', 'trend__bar trend__bar--simulated');
            this.scoreSIM.node.style.display = (this.state.isSelectedsim) ? '' : 'none';
        }

        // remove display attribute fix for trend's width
        if (revealerParent) {
            revealerParent.style.display = '';
        }
    }
    renderSimLabel() {
        if (this.state.period.fhr === this.state.period.simulatedFhr) {
            return LOCALIZED_STRS.get().simulatedFhr.nochange;
        } else if (this.state.period.fhr > this.state.period.simulatedFhr) {
            return LOCALIZED_STRS.get().simulatedFhr.worse;
        } else if (this.state.period.fhr < this.state.period.simulatedFhr) {
            return LOCALIZED_STRS.get().simulatedFhr.better;
        }
    }
    renderSimScore() {
        if (this.state.period.fhr === this.state.period.simulatedFhr) {
            return '';
        } else {
            return this.state.period.simulatedFhr;
        }
    }
    @track((props, state, args) => {
        const scoreType = args[0];
        const tick_to = state[scoreType] ? 'uncheck' : 'check';
        return {
            event: 'checkbox-tick-' + scoreType + '-' + tick_to,
            name: scoreType,
            value: state[scoreType]
        };
    })
    toggleCheckboxes(scoreType) {
        this.setState({
            [scoreType]: !this.state[scoreType]
        }, () => {
            if(this.state.showCHS){
                this.scoreCHS.node.style.display = (this.state.isSelectedchs) ? '' : 'none';
            }
            if(this.state.showFHR){
                this.scoreFHR.node.style.display = (this.state.isSelectedfhr) ? '' : 'none';
            }
            if(this.state.showSIM){
                this.scoreSIM.node.style.display = (this.state.isSelectedsim) ? '' : 'none';
            }
        });
    }
    render() {
        return (
            <div className="trends" ref={el => this.ele = el}>
                { this.state.hasTrend &&
                    <div className="trendChart" id="trendChart"></div>
                }
                <div className="trend__figures">
                  <table className="table table--summary table--trend">
                      <tbody>
                          { this.state.showFHR &&
                            <tr onClick={this.toggleCheckboxes.bind(this, 'isSelectedfhr')} data-heap-id="trend-fhr">
                                { this.state.hasTrend &&
                                    <th className="th--score-legand">
                                        <span className="sr-only">FHR Checkbox</span>
                                        { !isIe11() && <input type="checkbox" id="fhr-trend-checkbox" aria-label="FHR Checkbox"/> }
                                        <i className={(this.state.isSelectedfhr) ? 'icon icon--checked' : 'icon icon--check'} aria-hidden="true"></i>
                                        <hr className="hr--fhr"/>
                                    </th>
                                }
                                <td className="td--score-name">{LOCALIZED_STRS.get().fhr}</td>
                                <td className="td--score">{this.state.period.fhr}
                                    { this.state.hasTrend &&
                                        <sup>
                                            {Math.abs(this.state.period.fhrAnnualDelta)}
                                            { this.state.period.fhrAnnualDelta > 0 &&
                                                <i className="icon icon--up" aria-hidden="true"></i>
                                            }
                                            { this.state.period.fhrAnnualDelta < 0 &&
                                                <i className="icon icon--down" aria-hidden="true"></i>
                                            }
                                        </sup>
                                    }
                                </td>
                            </tr>
                          }
                          { this.state.showSIM &&
                            <tr onClick={this.toggleCheckboxes.bind(this, 'isSelectedsim')} data-heap-id="trend-simulated">
                                { this.state.hasTrend &&
                                    <th className="th--score-legand">
                                        <span className="sr-only">Simulated FHR Checkbox</span>
                                        { !isIe11() && <input type="checkbox" id="sim-fhr-checkbox" aria-label="Simulated FHR Checkbox"/> }
                                        <i className={(this.state.isSelectedsim) ? 'icon icon--checked' : 'icon icon--check'} aria-hidden="true"></i>
                                        <hr className="hr--sim"/>
                                    </th>
                                }
                                <td className="td--score-name">{LOCALIZED_STRS.get().sim} <span>{this.renderSimLabel()}</span></td>
                                <td className="td--score">{this.renderSimScore()}
                                    <sup></sup>
                                </td>
                            </tr>
                          }
                          { this.state.showCHS &&
                            <tr onClick={this.toggleCheckboxes.bind(this, 'isSelectedchs')} data-heap-id="trend-chs">
                                { this.state.hasTrend &&
                                    <th className="th--score-legand">
                                        <span className="sr-only">CHS Checkbox</span>
                                        { !isIe11() && <input type="checkbox" id="chs-checkbox" aria-label="CHS Checkbox"/> }
                                        <i className={(this.state.isSelectedchs) ? 'icon icon--checked' : 'icon icon--check'} aria-hidden="true"></i>
                                        <hr className="hr--chs"/>
                                    </th>
                                }
                                <td className="td--score-name">{LOCALIZED_STRS.get().chs}</td>
                                <td className="td--score">{this.state.period.chs}
                                    { this.state.hasTrend &&
                                        <sup>
                                            {Math.abs(this.state.period.chsAnnualDelta)}
                                            { this.state.period.chsAnnualDelta > 0 &&
                                                <i className="icon icon--up" aria-hidden="true"></i>
                                            }
                                            { this.state.period.chsAnnualDelta < 0 &&
                                                <i className="icon icon--down" aria-hidden="true"></i>
                                            }
                                        </sup>
                                    }
                                </td>
                            </tr>
                          }
                      </tbody>
                  </table>
                </div>
            </div>
        );
    }
}

export default Trend;
