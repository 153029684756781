import React from 'react';

export function RemoveBookmarkIcon() {
  return (
      <svg width="9px" height="12px" viewBox="0 0 9 12" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-636.000000, -67.000000)" fill="#3B4043">
                  <path d="M645,78.9889064 L642.611548,77.2343596 L640.300404,75.5366021 L636,78.9002358 L636,68 C636,67.4477153 636.447715,67 637,67 L644,67 C644.552285,67 645,67.4477153 645,68 L645,78.9889064 Z" id="Combined-Shape-Copy-2"></path>
              </g>
          </g>
      </svg>
  );
}

export function AddBookmarkIcon() {
  return (
      <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-664.000000, -67.000000)" fill="#3B4043" fillRule="nonzero">
                  <g id="Group-3-Copy-9" transform="translate(664.000000, 67.000000)">
                      <path d="M9,7 L8,7 L8,8 L9,8 L9,11.9889064 L6.61154839,10.2343596 L4.30040359,8.53660205 L-3.04201109e-14,11.9002358 L-3.04201109e-14,1 C-3.04201109e-14,0.44771525 0.44771525,1.82964754e-13 1,1.82964754e-13 L8,1.82964754e-13 C8.55228475,1.82964754e-13 9,0.44771525 9,1 L8,1 L8,2 L9,2 L9,4 L11.032959,4 L11.032959,5 L9,5 L9,7 Z M8,4 L8,1 L1,1 L1,9.84850778 L4.28145384,7.28186289 L8,10.0134917 L8,5 L6,5 L6,4 L8,4 Z" id="Combined-Shape"></path>
                  </g>
              </g>
          </g>
      </svg>
  );
}

export function TableChildIcon() {
    return(
        <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <circle id="Oval-Copy-4" fill="#E3E8ED" cx="24" cy="24" r="24"></circle>
                        <rect id="Rectangle-Copy-43" fill="#FFFFFF" x="14" y="19" width="20" height="14"></rect>
                        <rect id="Rectangle-Copy-44" fill="#FFFFFF" x="14" y="17" width="20" height="3"></rect>
                        <rect id="Rectangle-Copy-50" fill="#FFFFFF" x="18" y="27" width="12" height="6"></rect>
                        <rect id="Rectangle-Copy-63" fill="#FEE800" x="17" y="15" width="14" height="3"></rect>
                        <polygon id="Path" fill="#98A4AE" fillRule="nonzero" points="18 22 30 22 30 25 18 25"></polygon>
                        <polygon id="Path" fill="#FFFFFF" fillRule="nonzero" points="19 23 19 24 29 24 29 23"></polygon>
                        <path d="M31,15 L30.999729,17 L34,17 L33.999729,32.005 L36.000271,32.0065041 L35.999729,33.006504 L24.922729,33 L14,33 L13.999729,32.994 L11.999729,32.993496 L12.000271,31.993496 L13.999729,31.994 L14,17 L16.999729,17 L17,15 L31,15 Z M19,28 L18.999729,31.997 L20.999729,31.998 L21,28 L19,28 Z M33,20 L15,20 L14.999729,31.995 L17.999729,31.997 L18,27 L30,27 L29.999729,32 L33,32 L33,20 Z M29,28 L26.999729,28 L26.999729,32 L29,32 L29,28 Z M33,18 L15,18 L15,19 L33,19 L33,18 Z M30,16 L18,16 L18,17 L30,17 L30,16 Z M21.999729,31.999 L24.922729,32 L25.999729,32 L25.999729,28 L22,28 L21.999729,31.999 Z" id="Combined-Shape" fill="#98A4AE" fillRule="nonzero"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export function TableParentIcon() {
    return(
        <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <circle fill="#D6DEE4" cx="24" cy="24" r="24"></circle>
                        <g transform="translate(12.000000, 12.000000)">
                            <rect id="Rectangle-Copy-35" fill="#FFFFFF" x="3" y="0" width="18" height="24"></rect>
                            <rect id="Rectangle-Copy-38" fill="#FFFFFF" transform="translate(21.500000, 16.500000) scale(-1, 1) translate(-21.500000, -16.500000) " x="20" y="9" width="3" height="15"></rect>
                            <rect id="Rectangle-Copy-39" fill="#FFFFFF" transform="translate(2.500000, 18.500000) scale(-1, 1) translate(-2.500000, -18.500000) " x="1" y="13" width="3" height="11"></rect>
                            <rect id="Rectangle-Copy-40" fill0pacity="0.303321678" fill="#00AFD7" x="6" y="3" width="12" height="3"></rect>
                            <rect id="Rectangle-Copy-41" fill0pacity="0.303321678" fill="#00AFD7" x="6" y="8" width="12" height="3"></rect>
                            <rect id="Rectangle-Copy-53" fill0pacity="0.303321678" fill="#00AFD7" x="6" y="13" width="12" height="3"></rect>
                            <path d="M0,24 L0,23 L1,23 L1,13 L3,13 L3,0 L21,0 L21,9 L23,9 L23,23 L24,23 L24,24 L15,24 L9,24 L0,24 Z M20,1 L4,1 L4,23 L6,23 L6,18 L18,18 L18,23 L20,23 L20,1 Z M22,10 L21,10 L21,23 L22,23 L22,10 Z M3,14 L2,14 L2,23 L3,23 L3,14 Z M9,19 L7,19 L7,23 L9,23 L9,19 Z M14,19 L10,19 L10,23 L14,23 L14,19 Z M17,19 L15,19 L15,23 L17,23 L17,19 Z M18,13 L18,16 L6,16 L6,13 L18,13 Z M17,14 L7,14 L7,15 L17,15 L17,14 Z M18,8 L18,11 L6,11 L6,8 L18,8 Z M17,9 L7,9 L7,10 L17,10 L17,9 Z M18,3 L18,6 L6,6 L6,3 L18,3 Z M17,4 L7,4 L7,5 L17,5 L17,4 Z" id="Combined-Shape" fill="#98A4AE" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}


export function NewLinkIcon() {
    return (
        <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-421.000000, -379.000000)">
                    <g transform="translate(171.000000, 326.000000)">
                        <path d="M256,53 L256,54 L258.2375,54 L254.533,57.5825 L255.268,58.2285 L259,54.463 L259,57 L260,57 L260,53 L256,53 Z M259,59 L260,59 L260,63 L250,63 L250,53 L254,53 L254,54 L251,54 L251,62 L259,62 L259,59 Z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}


export function PlusIcon() {
    return(
        <svg width="11px" height="11px" viewBox="0 0 11 11" version="1.1" className="pointer-events-none">
            <title>Add to lists</title>
            <g id="UI-Handover" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Landing-Page-Two-lists-1024px" transform="translate(-260.000000, -323.000000)" fill="#3B4043">
                    <g id="Card" transform="translate(8.000000, 296.000000)">
                        <g id="Group-20" transform="translate(252.000000, 27.000000)">
                            <g id="Group-19">
                                <rect id="Rectangle-Copy-22" x="0" y="5" width="11" height="1"></rect>
                                <rect id="Rectangle-Copy-22" transform="translate(5.500000, 5.500000) rotate(-90.000000) translate(-5.500000, -5.500000) " x="0" y="5" width="11" height="1"></rect>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export function TickIcon() {
    return(
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g id="UI-Handover" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="ToastSucess-1200px" transform="translate(-479.000000, -22.000000)">
                    <g id="SuccessMessage" transform="translate(479.000000, 22.000000)">
                        <g id="Icon">
                            <circle id="Oval-Copy-10" fill="#46A840" cx="12" cy="12" r="11.6363636"></circle>
                            <path d="M12,24 C18.627417,24 24,18.627417 24,12 C24,5.372583 18.627417,0 12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 Z M12,23.2727273 C5.77424464,23.2727273 0.727272727,18.2257554 0.727272727,12 C0.727272727,5.77424464 5.77424464,0.727272727 12,0.727272727 C18.2257554,0.727272727 23.2727273,5.77424464 23.2727273,12 C23.2727273,18.2257554 18.2257554,23.2727273 12,23.2727273 Z" id="Oval-Copy-23" fill="#368C2F" fillRule="nonzero"></path>
                            <path d="M8.15469874,11.7681063 C7.8580554,11.4818378 7.38551192,11.4902482 7.09924335,11.7868915 C6.81297478,12.0835349 6.82138519,12.5560783 7.11802853,12.8423469 L10.1930755,15.8098476 C10.4822802,16.0889377 10.940541,16.0889377 11.2297457,15.8098476 L16.8819715,10.3553021 C17.1786148,10.0690335 17.1870252,9.59649006 16.9007566,9.29984672 C16.6144881,9.00320337 16.1419446,8.99479296 15.8453013,9.28106153 L10.7114106,14.2354001 L8.15469874,11.7681063 Z" id="Path-4-Copy" fill="#FFFFFF" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export function ExclamationIcon() {
    return(
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g id="UI-Handover" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="ToastFailure-1200px" transform="translate(-474.000000, -22.000000)">
                    <g id="SuccessMessage" transform="translate(474.000000, 22.000000)">
                        <g id="ErrorIcon">
                            <circle id="Oval-Copy-10" fill="#F40B0B" cx="12" cy="12" r="11.6363636"></circle>
                            <path d="M12,24 C18.627417,24 24,18.627417 24,12 C24,5.372583 18.627417,0 12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 Z M12,23.2727273 C5.77424464,23.2727273 0.727272727,18.2257554 0.727272727,12 C0.727272727,5.77424464 5.77424464,0.727272727 12,0.727272727 C18.2257554,0.727272727 23.2727273,5.77424464 23.2727273,12 C23.2727273,18.2257554 18.2257554,23.2727273 12,23.2727273 Z" id="Oval-Copy-23" fill="#C10808" fillRule="nonzero"></path>
                            <g id="Group" transform="translate(12.250000, 12.000000) scale(1, -1) translate(-12.250000, -12.000000) translate(11.250000, 6.000000)" fill="#FFFFFF">
                                <circle id="Oval" cx="0.75" cy="0.75" r="1"></circle>
                                <path d="M0,3.5 L0,10.5 C0,10.9142136 0.335786438,11.25 0.75,11.25 C1.16421356,11.25 1.5,10.9142136 1.5,10.5 L1.5,3.5 C1.5,3.08578644 1.16421356,2.75 0.75,2.75 C0.335786438,2.75 0,3.08578644 0,3.5 Z" id="Line" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export function ErrorIcon() {
  return(
    <svg
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
        <title>ErrorIcon</title>
        <desc>Error</desc>
          <g
            id="Actions"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Cancel-Request-Modal-Default"
              transform="translate(-28.000000, -77.000000)"
              fillRule="nonzero"
            >
              <g id="Modal">
                <g id="ErrorIcon" transform="translate(28.000000, 77.000000)">
                  <circle
                    id="Oval-Copy-10"
                    fill="#D30547"
                    cx="24.5"
                    cy="24.5"
                    r="23.5"
                  />
                  <path
                    d="M24,48 C37.254834,48 48,37.254834 48,24 C48,10.745166 37.254834,0 24,0 C10.745166,0 0,10.745166 0,24 C0,37.254834 10.745166,48 24,48 Z M24,46.5454546 C11.5484893,46.5454546 1.45454545,36.4515108 1.45454545,24 C1.45454545,11.5484893 11.5484893,1.45454545 24,1.45454545 C36.4515108,1.45454545 46.5454546,11.5484893 46.5454546,24 C46.5454546,36.4515108 36.4515108,46.5454546 24,46.5454546 Z"
                    id="Oval-Copy-23"
                    fill="#930332"
                  />
                  <g
                    id="Group"
                    transform="translate(24.500000, 25.000000) scale(-1, 1) rotate(-180.000000) translate(-24.500000, -25.000000) translate(22.000000, 13.000000)"
                    fill="#FFFFFF"
                  >
                    <circle id="Oval" cx="2.5" cy="2.5" r="2" />
                    <path
                      d="M1,8 L1,22 C1,22.8284272 1.67157288,23.5 2.5,23.5 C3.32842712,23.5 4,22.8284272 4,22 L4,8 C4,7.17157288 3.32842712,6.5 2.5,6.5 C1.67157288,6.5 1,7.17157288 1,8 Z"
                      id="Line"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
  );
}

export function LoadingIcon() {
  return (
      <svg width="12px" height="12px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="rotate(0 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(30 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(60 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(90 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(120 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(150 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(180 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(210 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(240 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(270 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(300 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(330 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#3b4043">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
              </rect>
          </g>
      </svg>
  );
}

export function DownloadIcon() {
  return (
      <svg width="12px" height="12px" viewBox="0 0 12 12">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="company-structure-2-copy-3" transform="translate(-950.000000, -280.000000)" fill="#3b4043">
                  <path d="M960.8,290.2066 C960.8,290.5342 960.5342,290.8 960.2066,290.8 L951.7934,290.8 C951.4658,290.8 951.2,290.5342 951.2,290.2066 L951.2,281.7934 C951.2,281.4658 951.4658,281.2 951.7934,281.2 L954.2,281.2 L954.2,287.3206 C954.2,287.5846 953.8832,287.7202 953.6924,287.5366 L953.117,286.9852 C952.8782,286.7554 952.4978,286.7662 952.2722,287.0086 C952.0496,287.2474 952.0616,287.6206 952.2992,287.845 L954.0128,289.4614 C954.4772,289.8994 955.2032,289.897 955.6646,289.456 L957.3524,287.845 C957.5876,287.6206 957.5984,287.2486 957.377,287.011 L957.3686,287.002 C957.1454,286.762 956.7698,286.7488 956.5298,286.972 L955.9046,287.5546 C955.7126,287.7334 955.4,287.5972 955.4,287.335 L955.4,281.2 L957.2,281.2 L957.2,283.6 C957.2,284.263 957.737,284.8 958.4,284.8 L960.8,284.8 L960.8,290.2066 Z M961.826,283.426 L958.574,280.174 C958.4624,280.0624 958.3118,280 958.154,280 L951.2,280 C950.537,280 950,280.5376 950,281.2 L950,290.8 C950,291.463 950.537,292 951.2,292 L960.8,292 C961.463,292 962,291.463 962,290.8 L962,283.846 C962,283.6882 961.9376,283.5376 961.826,283.426 L961.826,283.426 Z" id="file_arrow_down-[#1717]"></path>
              </g>
          </g>
      </svg>
  );
}
