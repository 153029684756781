import React, {Component} from 'react';
import ReactDOM from 'react-dom';

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: props.list,
            header: props.header,
            type: props.type
        };
    }
    render() {
        return (
          <div className={ `findialog__list findialog__list--${this.state.type}` }>
              <h4>{this.state.header}</h4>
                <ul className="content__list">
                    {this.state.list.map((item, index) => {
                        return (
                            <li key={index}>{item}</li>
                        );
                    })}
                </ul>
          </div>
        );
    }
}

export default List;
