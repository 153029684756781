import React from 'react';
import BaseInput from './baseinput.jsx';
import Input from './input.jsx';
import Error from './error.jsx';
import UTILS from '../../utils/utils.jsx';

class Password extends BaseInput {
    constructor(props) {
        super(props);
        this.options = ['password', 'text'];
        this.state = Object.assign({
          type: 'password',
          isRequired: UTILS.isEmptyOrUndefined(props.required),
          hasForgotUrl: UTILS.isEmptyOrUndefined(props.url),
          hasCapsLockCheck: true,
          options: ['password', 'text']
        }, this.state);
    }
    toggleType() {
        this.setState({ attributes: { ...this.state.attributes, type: this.options.find(a => a != this.state.attributes.type)} });
    }
    render() {
        return (
          <div className="input__group">
              <label htmlFor={this.props.id} className={this.state.isRequired ? '' : 'required'}>{this.state.label}</label>
                <div className="passwordContainer">              
                  <input
                    autoComplete="off"
                    { ...this.state.attributes }
                    { ...this.state.validations }
                    value={this.state.value}
                    onChange={this.handleChange.bind(this)}
                    onKeyUp={this.handleKeyUp.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    onFocus={this.handleFocus.bind(this)}
                    placeholder={this.props.placeholder}
                  />
                  <button
                    id="login:show:password"
                    type="button"
                    className="link--showPassword"
                    onClick={this.toggleType.bind(this)}
                  >
                    { this.state.attributes.type === 'password' &&
                      <>                      
                        <p className="sr-only">Show Password</p>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            style={{verticalAlign: 'middle'}}
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <defs>
                              <path
                                d="M.244.244a.833.833 0 0 1 1.179 0L5.63 4.451a.84.84 0 0 1 .082.093l-.068-.078 9.887 9.886a.832.832 0 0 1 .007.007l.007.008 4.21 4.21a.834.834 0 0 1 .076 1.094l-.075.085a.833.833 0 0 1-1.179 0l-3.722-3.722A9.24 9.24 0 0 1 10 17.5c-2.817 0-5.282-1.36-7.379-3.597a17.077 17.077 0 0 1-1.876-2.41c-.32-.495-.54-.886-.657-1.12a.833.833 0 0 1 .011-.767 16.208 16.208 0 0 1 3.709-4.62L.244 1.422a.833.833 0 0 1 0-1.179ZM4.99 6.167a14.542 14.542 0 0 0-3.205 3.836 15.427 15.427 0 0 0 2.052 2.76c1.81 1.93 3.875 3.07 6.15 3.07a7.558 7.558 0 0 0 3.656-1.012l-1.934-1.935a3.334 3.334 0 0 1-4.595-4.595ZM10 2.5c2.817 0 5.282 1.36 7.379 3.597.72.768 1.346 1.588 1.876 2.41.32.495.54.886.657 1.12a.833.833 0 0 1-.01.766 16.25 16.25 0 0 1-1.898 2.802.833.833 0 0 1-1.275-1.073 14.583 14.583 0 0 0 1.486-2.124 15.427 15.427 0 0 0-2.052-2.762c-1.81-1.93-3.875-3.07-6.165-3.07a6.767 6.767 0 0 0-1.558.179.833.833 0 1 1-.38-1.623A8.43 8.43 0 0 1 10 2.5ZM8.357 9.535a1.666 1.666 0 0 0 2.108 2.108Z"
                                id="a"
                              />
                            </defs>
                            <g fill="none" fillRule="evenodd">
                              <mask id="b" fill="#fff">
                                <use xlinkHref="#a" />
                              </mask>
                              <use fill="#1B1F29" xlinkHref="#a" />
                              <g mask="url(#b)" fill="#1B1F29">
                                <path d="M0 0h20v20H0z" />
                              </g>
                              <path fill="#1B1F29" mask="url(#b)" d="M0 0h20v20H0z" />
                            </g>
                          </svg>
                        </span>
                      </>
                    }
                    { this.state.attributes.type === 'text' &&
                      <>
                        <p className="sr-only">Hide Password</p>
                        <span>
                          <svg
                            width="20"
                            height="20"
                            style={{verticalAlign: 'middle'}}
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <defs>
                              <path
                                d="M.244.244a.833.833 0 0 1 1.179 0L5.63 4.451a.84.84 0 0 1 .082.093l-.068-.078 9.887 9.886a.832.832 0 0 1 .007.007l.007.008 4.21 4.21a.834.834 0 0 1 .076 1.094l-.075.085a.833.833 0 0 1-1.179 0l-3.722-3.722A9.24 9.24 0 0 1 10 17.5c-2.817 0-5.282-1.36-7.379-3.597a17.077 17.077 0 0 1-1.876-2.41c-.32-.495-.54-.886-.657-1.12a.833.833 0 0 1 .011-.767 16.208 16.208 0 0 1 3.709-4.62L.244 1.422a.833.833 0 0 1 0-1.179ZM4.99 6.167a14.542 14.542 0 0 0-3.205 3.836 15.427 15.427 0 0 0 2.052 2.76c1.81 1.93 3.875 3.07 6.15 3.07a7.558 7.558 0 0 0 3.656-1.012l-1.934-1.935a3.334 3.334 0 0 1-4.595-4.595ZM10 2.5c2.817 0 5.282 1.36 7.379 3.597.72.768 1.346 1.588 1.876 2.41.32.495.54.886.657 1.12a.833.833 0 0 1-.01.766 16.25 16.25 0 0 1-1.898 2.802.833.833 0 0 1-1.275-1.073 14.583 14.583 0 0 0 1.486-2.124 15.427 15.427 0 0 0-2.052-2.762c-1.81-1.93-3.875-3.07-6.165-3.07a6.767 6.767 0 0 0-1.558.179.833.833 0 1 1-.38-1.623A8.43 8.43 0 0 1 10 2.5ZM8.357 9.535a1.666 1.666 0 0 0 2.108 2.108Z"
                                id="a"
                              />
                            </defs>
                            <g fill="none" fillRule="evenodd">
                              <mask id="b" fill="#fff">
                                <use xlinkHref="#a" />
                              </mask>
                              <use fill="#017BC1" xlinkHref="#a" />
                              <g mask="url(#b)" fill="#017BC1">
                                <path d="M0 0h20v20H0z" />
                              </g>
                              <path fill="#017BC1" mask="url(#b)" d="M0 0h20v20H0z" />
                            </g>
                          </svg>
                        </span>
                      </>
                    }
                  </button>
              </div>
              <Error errors={this.state.errors}/>
              { !this.state.hasForgotUrl &&
                <a href={this.props.url} className="link link--extraSmall">Forgot Password?</a>
              }
          </div>

        );
    }
}

export default Password;
