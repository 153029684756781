import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import BaseInput from '../forms/common/baseinput.jsx';
import {getRequest} from '../utils/fetch.jsx';
import ReactModal from 'react-modal';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import RequestPublicAddition from './request_public_addition.jsx';

class Search2 extends BaseInput {
    constructor(props) {
        super(props);
        this.charAt = 1;
        this.state = Object.assign({
            placeholder: props.placeholder,
            url: props['data-url'],
            requestPublicAdditionUrl: props['request-public-addition-url'],
            options: JSON.parse(props['search-by'] || '[]'),
            isLoading: false,
            count: 0,
            results: [],
            hasError: false,
            hasFocus: false,
            showModal: false,
            maxResults: props['max-results'] || 100
        }, this.state);
        this.closeMenu = this.closeMenu.bind(this);
        this.searchBy = this.searchBy.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.arrowsPressed = this.arrowsPressed.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onSetFocus = this.onSetFocus.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    handleOpenModal () {
      this.setState({ showModal: true });
    }

    handleCloseModal () {
      this.setState({ showModal: false });
    }

    closeMenu() {
        this.setState({hasFocus: false, openOptions: false});
    }

    handleClickOutside(evt) {
        if ( (this.node.contains(evt.target)) || (this.state.showModal) ) {
            return;
        }
        this.closeMenu();
    }

    onSetFocus(evt) {
        this.handleFocus(evt);
        if (this.isValidSituation()) {
            this.setState({hasFocus: true});
        }
    }

    onMouseOver(count) {
        this.setState({
            count: count
        });
    }

    redirectTo(href) {
        window.location = href;
    }

    isValidSituation() {
        return this.state.value.trim().length >= this.charAt;
    }

    handleSearch(evt) {
        this.handleChange(evt, this.initSearch);
    }

    initSearch() {
        clearTimeout(this.state.currentTimeout);
        const currentTimeout =  setTimeout(() => {
            if (this.isValidSituation()) {
              this.setState({ results: [], isLoading: true, hasFocus: true, hasError: false }, () => {
                  this.getResults();
              });
            } else {
                this.setState({results: [], isLoading: false, hasFocus: false, hasError: false});
            }
        }, 500);
        this.setState({
            currentTimeout: currentTimeout
        });
    }

    searchBy(option) {
        const opts = this.state.options;
        opts.forEach(opt => {
            opt.isSelected = (opt.key === option.key);
        });
        this.setState({
            options: opts
        }, () => {
            this.initSearch();
        });
    }

    getResults() {
        const url = `${this.state.url}?search=${encodeURIComponent(this.state.value)}&by=${this.state.options.find(option => option.isSelected).key}&version=2`;
        this.setState({
            currentSearchUrl: url
        });
        getRequest(url, 0, true).then(data => {
            if(this.state.currentSearchUrl === data.url) {
                this.setState({
                    results: data.results,
                    isLoading: false,
                    hasError: false,
                    count: 0
                });
            }
        }).catch(err => {
            this.setState({isLoading: false, hasError: false});
        });
    }

     arrowsPressed(evt) {
        this.handleKeyUp(evt);
        if (!this.state.isLoading && this.state.results.length > 0) {
            if (evt.keyCode === 40 || evt.keyCode === 38 || evt.keyCode === 13) {
                evt.preventDefault();
                if (evt.keyCode === 40) {
                    if (this.state.count < this.state.results.length - 1) {
                        this.setState({
                            count: this.state.count += 1
                        });
                    }
                } else if (evt.keyCode === 38) {
                    if (this.state.count > 0) {
                        this.setState({
                            count: this.state.count -= 1
                        });
                    }
                } else if (evt.keyCode === 13) {
                    this.redirectTo(this.state.results[this.state.count].href);
                    this.closeMenu();
                }
            }
        }
    }

    render() {
        return (
            <div className="search" ref={node => this.node = node}>
                <label className="sr-only" htmlFor={this.props.id}>{this.state.label}</label>
                <SearchInput
                    id={this.state.id}
                    value={this.state.value}
                    placeholder={this.state.placeholder}
                    options={this.state.options}
                    openOptions={this.state.openOptions}
                    attributes={this.state.attributes}
                    onChange={this.handleSearch}
                    onKeyUp={this.arrowsPressed}
                    onBlur={this.handleBlur}
                    onFocus={this.onSetFocus}
                    searchBy={this.searchBy}
                />
                <div className="search__results__v2 animated animated--fadeIn">
                    <SearchResults count={this.state.count} searchterm={this.state.value}
                                   options={this.state.options} onclick={this.closeMenu}
                                   results={this.state.results} hasError={this.state.hasError}
                                   hasFocus={this.state.hasFocus} isLoading={this.state.isLoading}
                                   onMouseOver={this.onMouseOver} maxResults={this.state.maxResults}
                    />
                    <RequestPublicAdditionCallToAction
                        handleOpenModal={this.handleOpenModal}
                        hasFocus={this.state.hasFocus}
                        isLoading={this.state.isLoading}
                    />
                </div>
                <RequestPublicAdditionModal
                    requestPublicAdditionURL={this.state.requestPublicAdditionUrl}
                    handleOpenModal={this.handleOpenModal}
                    handleCloseModal={this.handleCloseModal}
                    showModal={this.state.showModal}
                />
            </div>
        );
    }
}

class RequestPublicAdditionCallToAction extends React.Component {
    render () {
        if(this.props.hasFocus && !this.props.isLoading) {
                return (
                    <div className="search__request">
                        <button className="request"
                                onClick={this.props.handleOpenModal}>
                            <span className="search__company">
                              {LOCALIZED_STRS.get().search.question}
                            </span>
                            <span className="search__details">
                              {LOCALIZED_STRS.get().search.action}
                            </span>
                        </button>
                    </div>
                );
            }
            return null;
    }
}

class SearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openOptions: false
        };
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({
            openOptions: !this.state.openOptions
        });
    }
    searchBy(option) {
        this.props.searchBy(option);
    }

    renderNoOptions () {
        return (
            <div className="search__input">
                    <div className="input__group">
                        <label className="sr-only" htmlFor={this.props.id}>{this.props.label}</label>
                        <input
                            autoComplete="off"
                            id={this.props.id}
                            placeholder={this.props.placeholder}
                            { ...this.props.attributes }
                            { ...this.props.validations }
                            value={this.props.value}
                            onChange={this.props.onchange}
                            onKeyUp={this.props.onkeyup}
                            onBlur={this.props.handleblur}
                            onFocus={this.props.onfocus}/>
                    </div>
                </div>
        );

    }

    renderWithOptions () {
        return (
            <div className="search__input">
                <div className="input__group">
                    <input autoComplete="off" placeholder={this.props.placeholder} { ...this.props.attributes } { ...this.props.validations } value={this.props.value} onChange={this.props.onChange} onKeyUp={this.props.onKeyUp} onBlur={this.props.handleBlur} onFocus={this.props.onFocus}/>
                </div>
                <div className={`rbtn__group rbtn__group--onclick ${this.state.openOptions ? ' rbtn__group--onclicked' : ''}`} >
                    <button type="button" className="rbtn" onClick={this.toggleMenu}>{LOCALIZED_STRS.get().search.by} {this.props.options.find( option => option.isSelected).display}</button>
                    <ul>
                        {this.props.options.map((option, index) => {
                            return (
                                <li key={index}>
                                    <button onClick={this.searchBy.bind(this, option)} aria-label={option.display}>{option.display}</button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );

    }

    render () {
       if(this.props.options.length > 0){
           return this.renderWithOptions();
       }
       return this.renderNoOptions();
    }
}

class SearchResults extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            results: props['results'],
            maxResults: props['maxResults'] || 100
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.onclick();
    }

    onMouseOver(count) {
        this.props.onMouseOver(count);
    }

    renderLoading () {
        return (
            <div className="search__status">
                {LOCALIZED_STRS.get().search.loading}
            </div>
        );
    }

    renderResults () {
        return (
            <React.Fragment>
              <SearchStatus maxResults={this.state.maxResults} results={this.props.results} hasError={this.props.hasError}/>
              <ul>
                  {this.props.results.map((result, index) => {
                    return (
                        <SearchResult
                            key={index}
                            options={this.props.options}
                            index={index}
                            result={result}
                            onMouseOver={this.onMouseOver.bind(this, index)}
                            count={this.props.count}
                            onClick={this.onClick}
                            searchterm={this.props.searchterm}
                        />
                    );
                  })}
              </ul>
            </React.Fragment>
        );
    }

    render () {
        if(this.props.hasFocus){
            if(this.props.isLoading){
                return this.renderLoading();
            }
            return this.renderResults();
        }
        return null;
    }
}

class SearchResult extends React.Component {

    highlight(str, key) {
        if(this.props.options.find(option => option.isSelected).key === key){
            const searchterm = this.props.searchterm.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            return (
                <span dangerouslySetInnerHTML={{
                    __html: str.replace(new RegExp(searchterm, 'gi'), '<strong class="search__highlighted">$&</strong>')
                }}></span>
            );
        } else {
            return(
              <span>{str}</span>
            );
        }

    }

    render () {
        return (
            <li key={this.props.index} onClick={this.props.onClick} onMouseOver={this.props.onMouseOver} className={ this.props.count === this.props.index ? 'li--active' : ''}>
                <a href={`${this.props.result.href}`} target="_self">
                    <div className="search__company">{this.highlight(this.props.result.longName, 'longName')}</div>
                    <div className="search__details">
                        {LOCALIZED_STRS.get().ticker}: {this.highlight(this.props.result.ticker, 'ticker')} {LOCALIZED_STRS.get().exchange}: <strong>{this.props.result.exchange}</strong>
                    </div>
                </a>
            </li>
        );
    }
}

class RequestPublicAdditionModal extends React.Component {

    render() {
        return (
        <ReactModal
            isOpen={this.props.showModal}
            contentLabel="onRequestClose Example"
            onRequestClose={this.props.handleCloseModal}
            className="Modal"
            overlayClassName="Overlay">
            <div className="ReactModal__Header">
                <h2>{LOCALIZED_STRS.get().search.action}</h2>
                <button
                    onClick={this.props.handleCloseModal}
                    className="rbtn rbtn--transparent"
                >
                <i className="icon icon--close close-menu" aria-hidden="true"></i>
                </button>
            </div>
            <RequestPublicAddition url={this.props.requestPublicAdditionURL} handler={this.props.handleCloseModal}/>
        </ReactModal>
        );
    }
}

class SearchStatus extends React.Component {

    renderErrorState() {
        return (
          <div className="search__status">
                {LOCALIZED_STRS.get().search.failed}
          </div>
        );
    }

    renderEmptyState() {
        return (
            <div className="search__status">
                {LOCALIZED_STRS.get().search.empty}
            </div>
        );
    }

    renderState () {
        return (
            <div className="search__status">
                <div className="search__total">
                  <strong>{ this.props.results.length === this.props.maxResults ? this.props.maxResults.toString() + '+' : this.props.results.length}</strong> {LOCALIZED_STRS.get().found}
                </div>
            </div>
        );
    }

    render() {
        if (this.props.hasError){
            return this.renderErrorState();
        } else if (this.props.results.length === 0){
            return this.renderEmptyState();
        }
        return this.renderState();
    }
}

// necessary for screenreaders to focus on modal
ReactModal.setAppElement('body');

export {
    Search2 as default,
    Search2,
    SearchInput,
    SearchResult,
    SearchResults,
    SearchStatus,
    RequestPublicAdditionCallToAction,
    RequestPublicAdditionModal
};
