import React from 'react';

function DownChevron({className = 'inlineBlock', arrowColor = '#ffffff',  ...props}) {
  
    return(
        <svg width="14" height="10" className={className}>
          <title>Down Arrow</title>
          <path fill="none" d="M-1-1h16v12H-1z"/>
          <g>
            <path fill={arrowColor} fillRule="nonzero" stroke="null" d="M7 6.855l5.83-5.83 1.06 1.06L7 8.975.11 2.085l1.06-1.06z"/>
          </g>
        </svg>
    );
}

export default DownChevron;
