import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Error from './error.jsx';

class RadioGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            items: JSON.parse(this.props.items || '[]')
        };
    }

    onChange(e) {
        const value = e.target.value;
        this.setState({
            value: value
        });
    }

    get value() {
        return this.state.value;
    }

    render() {
        return (
            <React.Fragment>
            { this.state.items &&
                <fieldset className="radio-group" {...this.props}>
                    {
                        this.state.items.map(item => {
                            return (<label key={item.value}>
                                <input
                                    type="radio"
                                    checked={this.state.value === item.value}
                                    disabled={item.disabled}
                                    value={item.value}
                                    name={this.props.name}
                                    onChange={this.onChange.bind(this)}
                                />
                                <span>{item.label}</span>
                            </label>);
                        })
                    }
                </fieldset>
            }
            </React.Fragment>
        );
    }
}

export default RadioGroup;
