import React, { Component } from 'react';
import LocationSelect from './location-select.jsx';
import SearchContainer from './search-container.jsx';
import CancelIcon from './cancel-icon.jsx';
import {
  LOCATION_QUERY_FOCUS_MESSAGE,
  LOCATION_QUERY_NO_RESULTS_MESSAGE,
  LOCATION_QUERY_TOO_SHORT_MESSAGE,
  LOCATION_QUERY_ERROR_MESSAGE
} from './constants';
import {
  getLocationSuggestions,
  transformSuggestionsToOptions,
  search,
  getSearchTerms
} from './utils';

export default class HealthMarkSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: {value: props.location, label: props.location},
      locationSearchQuery: '',
      noOptionsMessage: LOCATION_QUERY_TOO_SHORT_MESSAGE,
      isLocationInputFocused: false
    };
  }

  getOptions = query => {
    return new Promise(resolve => {
      this.setState({
        locationSearchQuery: query
      });

      if (query.length < 2) {
        resolve([]);
        this.setState({
          noOptionsMessage: LOCATION_QUERY_TOO_SHORT_MESSAGE
        });
      } else {
        getLocationSuggestions(
          `${this.props.locationsuggestionurl}?city-only=true&location-term=${query}`
        )
          .then(suggestions => {
            const options = transformSuggestionsToOptions(suggestions);

            resolve(options);

            if (this.state.locationSearchQuery === query) {
              this.setState({
                noOptionsMessage:
                  options.length === 0
                    ? LOCATION_QUERY_NO_RESULTS_MESSAGE
                    : LOCATION_QUERY_FOCUS_MESSAGE
              });
            }
          })
          .catch(e => {
            resolve([]);

            if (this.state.locationSearchQuery === query) {
              this.setState({
                noOptionsMessage: LOCATION_QUERY_ERROR_MESSAGE
              });
            }
          });
      }
    });
  };

  handleSelectedLocation = selectedLocation => {
    this.props.parentCallback(selectedLocation.value);
    this.setState({
      noOptionsMessage: LOCATION_QUERY_FOCUS_MESSAGE,
      selectedLocation
    });
  };

  handleLocationInputBlur = () =>
    this.setState({
      isLocationInputFocused: false
    });

  handleLocationInputFocus = () =>
    this.setState({
      noOptionsMessage: LOCATION_QUERY_FOCUS_MESSAGE,
      isLocationInputFocused: true
    });

  clearSelectedLocation = () => {
    this.setState({
      selectedLocation: '',
      locationSearchQuery: '',
      noOptionsMessage: LOCATION_QUERY_TOO_SHORT_MESSAGE
    }, () => {
        this.props.parentCallback(this.state.selectedLocation);
    });
  }

  render() {
    const {
      noOptionsMessage,
      locationSearchQuery,
      selectedLocation,
      isLocationInputFocused
    } = this.state;

    return (
      <SearchContainer
        isHiglighted={this.props.isHiglighted}
        className={this.props.className}
      >
        <LocationSelect
          id={this.props.id}
          value={selectedLocation}
          name={this.props.name}
          getOptions={this.getOptions}
          noOptionsMessage={noOptionsMessage}
          onChange={this.handleSelectedLocation}
          onFocus={this.handleLocationInputFocus}
          onBlur={this.handleLocationInputBlur}
        />
        <CancelIcon
          isHidden={selectedLocation === null && locationSearchQuery.length === 0}
          onClick={this.clearSelectedLocation}
        />
      </SearchContainer>
    );
  }
}
