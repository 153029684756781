import LOCALIZED_STRS from './localize.jsx';
import CustomError from './error.jsx';

const UTILS = {
    getRiskName: val => {
        if (val >= 0 && val <= 19) {
            return 'veryhigh';
        } else if (val >= 20 && val <= 39) {
            return 'high';
        } else if (val >= 40 && val <= 59) {
            return 'medium';
        } else if (val >= 60 && val <= 79) {
            return 'low';
        } else if (val >= 80 && val <= 100) {
            return 'verylow';
        }
    },
    getRiskLevel: val => {
        if (val >= 0 && val <= 19) {
            return 'veryhigh';
        } else if (val >= 20 && val <= 39) {
            return 'high';
        } else if (val >= 40 && val <= 49) {
            return 'medium';
        } else if (val >= 50 && val <= 59) {
            return 'mediumupper';
        }else if (val >= 60 && val <= 79) {
            return 'low';
        } else if (val >= 80 && val <= 100) {
            return 'verylow';
        }
    },
    getAttrs: child => {
      const attrs = {};
      [...child.attributes].forEach(attribute => {
        attrs[attribute.name] = attribute.value;
      });
      return attrs;
    },
    stripAttrs: child => {
      [...child.attributes].forEach(attribute => {
        child.removeAttribute(attribute.name);
      });
    },
    stripAttrsExceptRole: child => {
        [...child.attributes].forEach(attribute => {
          attribute.name !== 'role' && child.removeAttribute(attribute.name);
        });
    },
    getDateObject: (utc) => {
        const date = new Date(utc);
        return {month: date.getUTCMonth(), day: date.getDate(), year: date.getFullYear()};
    },
    getReadableDate: dateObj => {
        return LOCALIZED_STRS.get().months[dateObj.month] + ' ' + dateObj.day + ', ' + dateObj.year;
    },
    getDisplayDate: utc => {
        return UTILS.getReadableDate(UTILS.getDateObject(utc));
    },
    isEmptyOrUndefined: val => {
        return val === '' || typeof val === 'undefined' || val === null;
    },
    getMonthsPassed: utc => {
        const to = new Date();
        const from = new Date(utc);
        let months = to.getMonth() - from.getMonth() + (12 * (to.getFullYear() - from.getFullYear()));
        if (to.getDate() < from.getDate()) {
            months--;
        }
        return months;
    },
    isInputNotValid: {
        'max-length': (val, param) => val.length > param,
        'min-length': (val, param) => val.length < param,
        'email': (val, param) => !param.test(val),
        'capslock': (evt, param) => evt.getModifierState('CapsLock'),
        'required': (val, param) => val === '',
    },
    getInputErrors: (type, val, validations = {}) => {
        const errors = [];
        if (type === 'email') {
          validations.email = /^[\w-']+(\.[\w-']+)*@([a-zA-Z0-9]+[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*?\.[a-zA-Z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
        }
        for (const [key, param] of Object.entries(validations)) {
            if (!UTILS.isEmptyOrUndefined(param) && !UTILS.isEmptyOrUndefined(val) && UTILS.isInputNotValid[key](val, param)) {
              errors.push(new CustomError({error: key, message: LOCALIZED_STRS.get().formErrors[key]}));
            }
        }
        return errors;
    }
};
export default UTILS;
