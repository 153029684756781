import {csrftoken} from '../../utils/csrf.jsx';
import URLSearchParams from '@ungap/url-search-params';

/**
 * Returns a promise that resolves with the location suggestions for the query.
 * @param {string} url The endpoint for the location suggestion.
 * @returns {Promise} The promise that resolves with the suggestions.
 */
export function getLocationSuggestions(url) {
    return fetch(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrftoken()
        },
        credentials: 'same-origin'
    })
    .then(res => res.json())
    .then(json => Promise.resolve(json.results));
}

/**
 * Returns an array of options based on the provided suggestions..
 * @param {string} suggestions The suggestions array.
 * @returns {Array} The array of options from the suggestions.
 */
export function transformSuggestionsToOptions(suggestions) {
  return suggestions.map(({ query, data: { city, state, country } }, index) => {
    return {
      value: query,
      label: makeLocationLabel({ city, state, country })
    };
  });
}

/**
 * Returns a label from the selected location params.
 * @param {Object} location The location object.
 * @returns {string} The location label.
 */
export function makeLocationLabel({ city, state, country }) {
  return `${city ? `${city}, ` : ''}${state ? `${state}, ` : ''}${
    country ? country : ''
  }`;
}

/**
 * Makes the search.
 * @param {string} searchUrl The search url.
 * @param {string} companySearchQuery The company search string.
 * @param {Object} selectedLocation The selected location object.
 * @returns {undefined}
 */
export function search(searchUrl, companySearchQuery, selectedLocation) {
  let queryString = `?search-term=${companySearchQuery}`;

  if (selectedLocation !== null) {
    queryString = `${queryString}&${selectedLocation.value}&label=${
      selectedLocation.label
    }`;
  }

  const { origin } = window.location;

  const url = `${origin}${searchUrl}${queryString}`;

  window.location.assign(url);
}

/**
 * Gets the search terms from the url query string.
 * @param {string}  queryString The company dcoded query search string.
 * @returns {undefined}
 */
export function getSearchTerms(queryString) {
  const params = new URLSearchParams(queryString);

  const companySearchQuery = params.get('search-term') || '';
  const label = params.get('label');
  const city = params.get('city');
  const state = params.get('state');
  const country = params.get('country');

  const selectedLocation =
    label === null || label === ''
      ? null
      : {
          label,
          value: `${city ? `city=${city}&` : ''}${
            state ? `state=${state}&` : ''
          }${country ? `country=${country}` : ''}`
        };

  return {
    selectedLocation,
    companySearchQuery
  };
}
