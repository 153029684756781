import React, { Component } from 'react';
import debounce from 'debounce-promise';
import Select from './select.jsx';
import CancelIcon from './cancel-icon.jsx';
import {
  QUERY_FOCUS_MESSAGE,
  QUERY_TOO_SHORT_MESSAGE,
} from './constants';
import {
  getSuggestions,
  transformSuggestionsToOptions,
  getNoResultsMessage,
  getQueryErrorMessage
} from './utils';
import {csrftoken} from '../../utils/csrf.jsx';
import styled from 'styled-components';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';
import { throws } from 'assert';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: #fff;
  border-radius: 3px;

  & .select__control {
    height: calc(100% - 16px);
    border: none;
    border-radius: 0;
    font-family: Source Sans Pro,sans-serif;
    padding: ${props => (props.isFormElement ? '4px 0 8px 16px' : '8px 0 8px 16px')};
    padding-left: ${props => (props.hasSearchIcon ? '16px' : '8px')};
    height: ${props => (props.isFormElement ? '40px' : '52px')};
    box-shadow: none;

    &::before {
      content: '';
      ${({ hasSearchIcon }) => hasSearchIcon && `
        content: '\\e915';
      `}
      font-size: 22px;
      color:'#51585d';
      margin-top: -8px;
      z-index: 1;
      font-family: RapidRatings;
    }
  }

  .select__placeholder,
  .select__single-value,
  .select__input,
  input {
    font-size: ${props => (props.isFormElement ? '16px' : '18px')};
    font-family: Source Sans Pro,sans-serif;
  }

  .select__value-container {
    padding: 2px 0;
  }

  .select__option {
    padding-left: 8px;
  }

  & .select__indicators {
    display: none;
  }

  & .select__menu {
    width: calc(100% + 49px);
    margin-top: 1px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #c7d2db;
    border-top: none;
    padding: 0;
    left: -1px;
    box-sizing: content-box;
    z-index: 3;
  }

  & .select__menu-notice {
    text-align: left;
  }
`;
export default class SearchGeneric extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: '',
      searchQuery: '',
      isFetching: false,
      action: '',
      message: '',
      searchobject: props.searchobject || 'term',
      searchobjectplural: props.searchobjectplural || 'terms',
      placeholder: props.placeholder,
      hasSearchIcon: props.hassearchicon !== undefined ? true : false,
      noOptionsMessage: QUERY_TOO_SHORT_MESSAGE,
      isFormElement: props.isformelement !== undefined ? true : false,
      isDanger: props.isdanger !== undefined ? true : false,
      hasCallback: props.callback !== undefined ? true : false
    };

  }

  componentDidUpdate(prevProps) {
    if (this.props.clearValue !== prevProps.clearValue) {
      this.props.clearValue === true
        && this.setState({selectedItem: ''});
    }
  }

  getOptions = query => {
    return new Promise(resolve => {
      this.setState({
        searchQuery: query
      });

      if (query.length < 2) {
        resolve([]);
        this.setState({
          noOptionsMessage: QUERY_TOO_SHORT_MESSAGE
        });
      } else {
        getSuggestions(
          `${this.props.searchurl}?search-term=${query}`
        )
          .then(suggestions => {
            const options = transformSuggestionsToOptions(suggestions, this.state.searchobject);

            resolve(options);

            if (this.state.searchQuery === query) {
              this.setState({
                noOptionsMessage:
                  options.length === 0
                    ? getNoResultsMessage(this.state.searchobject)
                    : QUERY_FOCUS_MESSAGE
              });
            }
          })
          .catch(e => {
            resolve([]);

            if (this.state.searchQuery === query) {
              this.setState({
                noOptionsMessage: getQueryErrorMessage(this.state.searchobject)
              });
            }
          });
      }
    });
  };

  handleSearchSubmit = e => {
    e.preventDefault();

    const { selectedItem } = this.state;

    this.search(this.props.permissionsurl, selectedItem);
  };

  handleSelected = selectedItem => {
    this.setState({
      noOptionsMessage: QUERY_FOCUS_MESSAGE,
      isButtonDisabled: false,
      selectedItem
    }, () => {
        if (this.state.hasCallback) {
            this.callbackFunction(selectedItem.value, this.props.callback);
        }
    });
  };

  callbackFunction = (selectedItem, callback) => {
    eval(callback + '(' + selectedItem + ')');
  }

  clearselectedItem = () =>
    this.setState({
      selectedItem: '',
      searchQuery: '',
      isButtonDisabled: true,
      noOptionsMessage: QUERY_TOO_SHORT_MESSAGE
    });

   search = (permissionsurl, selectedItem) => {
      const self = this;
      self.setState({
          modalIsOpen: true,
          isFetching: true
      });
      const { origin } = window.location;
      const csrftoken_value = csrftoken();
      const formData = new FormData();
      // add ID and CSRF token to FormData
      formData.append(this.props.name, selectedItem.value);
      formData.append('csrfmiddlewaretoken', csrftoken_value);
      fetch(`${origin}${permissionsurl}`, {
          method: 'POST',
          body: formData
      })
      .then((res) => {
          res.json().then((data) => {
              self.setState({
                  isFetching: false,
                  action: data.results.action,
                  message: data.results.message
              });
          });
      });
    }

  render() {
    const {
      noOptionsMessage,
      searchQuery,
      selectedItem,
      isDanger,
      isFormElement,
      hasSearchIcon
    } = this.state;

    return (
        <Container
          isFormElement={isFormElement}
          hasSearchIcon={hasSearchIcon}
        >
          <Select
            value={selectedItem}
            name={this.props.name || 'id'}
            getOptions={debounce(this.getOptions, 250)}
            noOptionsMessage={noOptionsMessage}
            onChange={this.handleSelected}
            onFocus={this.handleLocationInputFocus}
            onBlur={this.handleLocationInputBlur}
            placeHolder={this.props.placeholder}
          />
          <CancelIcon
            isHidden={searchQuery.length === 0}
            onClick={this.clearselectedItem}
          />
        </Container>
    );
  }
}
