import React from 'react';

function MenuCloseIcon() {
    return(
        <svg width="16px" height="16px" viewBox="0 0 31 32" >
            <title>Menu Close</title>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="close" fill="#ffffff" fillRule="nonzero">
                    <polygon id="Path" points=" 15.38 11.41 26.11 0.68 30.709 5.279 19.979 16.009 30.709 26.739 26.11 31.338 15.38 20.608 4.65 31.338 0.051 26.739 10.781 16.009 0.051 5.279 4.65 0.68"></polygon>
                </g>
            </g>
        </svg>
    );
}

export default MenuCloseIcon;
