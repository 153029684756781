import React, {Component} from 'react';
import styled from 'styled-components';
import {
  ABBEY,
} from './constants';
import MainMenu from './mainMenu.jsx';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';
import AccountMenu from './accountMenu.jsx';
import {getRequest} from '../utils/fetch.jsx';

const Nav = styled.nav`
    font-family: Source Sans Pro,sans-serif;
`;
const Button = styled.button`
    left: -999px;
    position: fixed;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
    color: #006d86;
    text-decoration: underline;

    :focus {
        background-color: #c7d2db;
        top: 0;
        left: auto;
        right: auto;
        width: 100%;
        height: 84px;
        line-height: 84px;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        z-index: 999;
    }
`;
const AccountNavContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: 24px;
    background-color: ${ABBEY};
    z-index: 6;
`;
class Navigation2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasURL: !UTILS.isEmptyOrUndefined(props['url']),
            url: props['url'],
            hasError: false,
            isLoading: true,
            hasAccount: !UTILS.isEmptyOrUndefined(props['account'])
        };
    }
    getNavigationData() {
        if (this.state.hasURL) {
            getRequest(this.state.url).then(results => {
                this.extractAndParseData(results);
            }).catch(err => {
                this.setState({hasError: true, menu:[], isLoading: false});
            });
        } else {
            this.extractAndParseData(this.props);
        }
    }

    extractAndParseData(data){
        const menu = Object.assign({}, data);
        delete menu.account;
        delete menu.logourl;
        delete menu.logout;
        delete menu.version;
        Object.keys(menu).map(key => {
            if(typeof menu[key] === 'string'){
                return JSON.parse(menu[key]);
            } else {
                return menu[key];
            }
        });

        const account =  (data.items)[0];
        // Remove account User information from navigation api
        const filterByGroup = menuItem => menuItem.hasOwnProperty('groups');
        const removedAccountMenu = menu.items.filter(filterByGroup);
        
        this.setState({account, menu: removedAccountMenu, isLoading: false});

    }
    componentDidMount() {
        this.getNavigationData();
    }

    render() {
        return (
            <Nav className="navigation__2" aria-label="navigation">
                <Button as="a" href="#main_content">{LOCALIZED_STRS.get().skip}</Button>
                {(this.state.hasError && !this.state.isLoading) &&
                    <React.Fragment>
                        <AccountNavContainer />
                        <MainMenu menu={this.state.menu} logourl={this.props.logourl}/>
                    </React.Fragment>
                }
                {(!this.state.isLoading && !this.state.hasError) &&
                    <React.Fragment>
                        <AccountNavContainer>
                        {(this.state.hasAccount) &&
                            <AccountMenu lang={this.props.lang} account={this.state.account}/>
                        }
                        </AccountNavContainer>
                        <MainMenu menu={this.state.menu} logourl={this.props.logourl}/>
                    </React.Fragment>
                }
            </Nav>
        );
    }
}

export default Navigation2;
