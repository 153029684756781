import React from 'react';
import HealthmarkRecents from './carousel_recents_v2.jsx';
import ListSummary from './list-summary/index.jsx';
import {csrftoken} from '../utils/csrf.jsx';

export default class HealthMarkLanding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          userList: '',
          masterList: '',
        };
    }

    recentCompaniesCallback = (value) => {
      this.fetchListInfo();
    };

    fetchData = (url, stateName) => {
      fetch(url, {
          headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': csrftoken()
          },
          credentials: 'same-origin'
      })
      .then((res) => {
          return res.json();
      })
      .then((data) => {
          stateName === 'userList'
          ? this.setState({userList: data})
          : this.setState({masterList: data});
      });
    }

    fetchListInfo = () => {
      this.fetchData(this.props.user_info_url, 'userList');
      this.fetchData(this.props.master_info_url, 'masterList');
    }

    componentDidMount () {
      this.fetchListInfo();
    }

    componentDidUpdate (prevProps) {
      if (this.props.userList !== prevProps.userList || this.props.masterList !== prevProps.masterList) {
        this.fetchListInfo();
      }
    }

    render() {
      return(
        <>
          <HealthmarkRecents
            data_url={this.props.data_url}
            title={this.props.recent_title}
            parentCallback={this.recentCompaniesCallback}
          />
          <div className="row">
              <div className="col-xs-12">
                  <hr style={{height: '1px'}} className="margin__vertical--loose" />
              </div>
          </div>
          <div className="row">
              <div className="col-xs-12">
                  <h3 className="bold">HealthMarks Lists</h3>
              </div>
          </div>
          <div className="row margin__vertical--top">
          { (this.state.userList.companies > 0 || this.state.masterList.companies > 0) &&
              <>
                <div className="col-xs-6">
                    <ListSummary
                        url={this.props.user_list_url}
                        info_data={this.state.userList}
                        title="My HealthMarks List"
                        description="Your personal HealthMark list of Prospects, Clients or Suppliers to better make business decisions"
                        date={this.props.user_date}
                    />
                </div>
                <div className="col-xs-6">
                    <ListSummary
                        isOrgList
                        url={this.props.master_list_url}
                        info_data={this.state.masterList}
                        title="Master HealthMarks List"
                        description="Master List of all existing suppliers with instant access to their financial health."
                        date={this.props.master_date}
                    />
                </div>
              </>
            }
            { this.state.userList.companies === 0 && this.state.masterList.companies === 0 &&
                <div className="col-xs-12 col-md-12">
                    <div className="accordion accordion--card accordion--locked">
                        <div className="row margin__vertical--loose--bottom">
                            <div className="col-xs-12 text__align--center">
                                <h2>No lists have been created</h2>
                            </div>
                            <div className="col-xs-12 text__align--center">
                                <p className="text--grey">
                                    To get started, you can add any company where you see the ‘+’ button.
                                </p>
                            </div>
                        </div>
                        <div className="row margin__vertical--bottom bordered">
                            <div className="col-xs-12 col-sm-4 details__block">
                                <div className="row">
                                    <div className="col-xs-12 center-xs">
                                        <div className="margin__vertical--loose--bottom list_image personal_list_image">
                                        </div>
                                    </div>
                                </div>
                                <p className="text__align--center margin__vertical--loose--bottom">
                                    Create a personal HealthMarks List of Prospects, Clients or Suppliers to make better decisions.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-4 details__block">
                                <div className="row">
                                    <div className="col-xs-12 center-xs">
                                        <div className="margin__vertical--loose--bottom list_image org_list_image">
                                        </div>
                                    </div>
                                </div>
                                <p className="text__align--center margin__vertical--loose--bottom">
                                    Build a Master List of all your existing suppliers to gain instant access to their financial health.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-4 details__block">
                                <div className="row">
                                    <div className="col-xs-12 center-xs">
                                        <div className="margin__vertical--loose--bottom list_image edit_list_image">
                                        </div>
                                    </div>
                                </div>
                                <p className="text__align--center margin__horizontal--tight--right margin__vertical--loose--bottom">
                                    Add individual companies to either list using the {'+'} button.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 center-xs">
                                <a
                                    href="https://help.rapidratings.com/hc/en-us/articles/360046221911-My-HealthMarks-List"
                                    className="rbtn rbtn--primary rbtn--md"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            }
          </div>
        </>
      );
    }

}
