import React from 'react';
import ReactTooltip from 'react-tooltip';

function Tooltip(props) {
  return (
      <div>
        <a data-tip="custom show" data-event="click" className="icon--selectable">
            <i className="icon icon--question icon--graychateau"></i>
        </a>
        <ReactTooltip
            globalEventOff="click"
            clickable={true}
            className="tooltip"
        >
            {props.text &&
                <p className="margin__vertical--tight--bottom">{props.text}</p>
            }
            {props.link && props.linktext &&
                <a
                    href={props.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link link--sentence">{props.linktext}</a>
            }
        </ReactTooltip>
      </div>
  );
}


export default Tooltip;
