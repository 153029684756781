import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import LOCALIZED_STRS from '../../utils/localize.jsx';
import UTILS from '../../utils/utils.jsx';

class CurrencyPicker extends React.Component {
    constructor(props) {
        super(props);
        const settings = this.initializeCurrencies(props);
        const defaults = LOCALIZED_STRS.settings.currency.defaults.map( (code, index) => {
            const cur = settings.currencies.find(currency => currency.code === code);
            if(cur){
                cur.isDefault = true;
                return cur;
            }
        });
        this.state = {
            base: props.base,
            currencies: settings.currencies,
            selected: props.base,
            showOptions: JSON.parse(props.show || 'false'),
            defaults: defaults
        };
    }
    initializeCurrencies(props) {
        const currencies = props.currencies.map(currency => {
            currency.date = UTILS.getReadableDate(UTILS.getDateObject(currency.updated));
            currency.isDefault = false;
            return currency;
        });
        return {
            currencies: currencies
        };
    }
    handleConversionChange(evt) {
      this.setState({
          selected: { ...this.state.selected, value: evt.target.value }
      }, () => {
          this.props.onChange(this.state.selected);
      });
    }
    handleCheck(evt) {
      this.setState({
          showOptions: evt.target.checked,
          selected: (!evt.target.checked) ? this.state.base : this.state.selected
      }, () => {
          this.props.onChange(this.state.selected);
      });
    }
    handleSelectChange(evt) {
        this.setState({
            selected: this.state.currencies.find(currency => currency.code === evt.target.value)
        }, () => {
            this.props.onChange(this.state.selected);
        });
    }
    decodeHtml(html) {
        // create a <textarea> element and injecting encoded HTML into it, browser automatically decodes it.
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }
    render() {
        return (
            <div>
                <div>
                    {LOCALIZED_STRS.get().currency.header} <strong>{this.state.base.code}</strong>
                </div>
                <div className="checkbox__group">
                <label htmlFor="change" className="labelchange" >
                    <span className="label-text">{LOCALIZED_STRS.get().currency.change}</span>
                    <input
                    id="change"
                    name="exchangeRate__checkbox"
                    type="checkbox"
                    checked={this.state.showOptions} 
                    onChange={this.handleCheck.bind(this)}
                    tabIndex="0"
                    />
                    <span className="square" />
                </label>
             </div>
                { this.state.showOptions &&
                    <div className="input__group input__group--inline currencyPicker">
                        <div className="currencyPicker__base">
                            1 <strong>{this.state.base.code}</strong> equals
                        </div>
                        <div className="currencyPicker__unit">
                            <input autoComplete="off" className="input--bordered" type="number" name="exchangeRate" value={this.state.selected.value} onChange={this.handleConversionChange.bind(this)} />
                        </div>
                        <div className="currencyPicker__code">
                            <select className="input--bordered" onChange={this.handleSelectChange.bind(this)} defaultValue={this.state.selected.code}>
                            <optgroup label={LOCALIZED_STRS.get().currency.commongroup}>
                              {this.state.defaults.map((currency, index) => {
                                  return (
                                      <option key={index} value={currency.code}>{currency.code} - {this.decodeHtml(currency.name)}</option>
                                  );
                                })}
                            </optgroup>
                            <optgroup label={LOCALIZED_STRS.get().currency.othergroup}>
                                {this.state.currencies.map((currency, index) => {
                                    if(!currency.isDefault){
                                        return (
                                            <option key={index} value={currency.code}>{currency.code} - {this.decodeHtml(currency.name)}</option>
                                        );
                                    }
                                })}
                            </optgroup>
                            </select>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default CurrencyPicker;
