import React from 'react';
import UTILS from '../../utils/utils.jsx';

class SummaryBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header: props.header || '',
            isActive: UTILS.isEmptyOrUndefined(props.active) ? false : props.active,
            hasHeader: !UTILS.isEmptyOrUndefined(props.header),
        };
        this.supported = {
            strengths: {
                icon: 'icon icon--dialog',
                required: ['header', 'strengths', 'concerns'],
            },
            ratio: {
                icon: 'icon icon--ratio',
                required: ['header', 'list'],
            },
            indicator: {
                icon: 'icon icon--action-indicator',
                required: ['fhr', 'chs', 'financial-date'],
            },
            fins: {
                icon: 'icon icon--fins',
                required: ['header', 'list'],
            },
            trend: {
                icon: 'icon icon--trend',
                required: ['periods'],
            },
            news: {
                icon: 'icon icon--news',
                required: ['articles'],
            },
            ondemand: {
                icon: 'icon icon--reports',
                required: ['reports'],
            }
        };
        this.classNames = {
            header: this.getHeaderClass(),
            body: this.getBodyClass(),
            main: this.getMainClass()
        };
    }
    isValid (){
        const params = this.supported[this.props.type];
        if(typeof params === 'undefined'){
            return false;
        } else {
            const valid = params.required.map( requirement => !UTILS.isEmptyOrUndefined(this.props[requirement])).indexOf(false) === -1;
            return valid;
        }
    }
    getMainClass() {
        if (this.props.wrappertype === 'panel') {
            return 'panel panel-default';
        } else {
            return (this.state.isActive) ? 'accordion accordion--active' : (this.state.hasHeader) ? 'accordion' : 'accordion accordion--locked';
        }
    }
    getHeaderClass(){
        if (this.props.wrappertype === 'panel') {
            return 'panel-heading';
        } else {
            return 'accordion__header';
        }

    }
    getBodyClass(){
        if (this.props.wrappertype === 'panel') {
            return 'panel-body';
        } else {
            return 'accordion__details';
        }

    }
    renderIcon() {
        if (this.props.hideicon) {
            return null;
        } else {
            return <i className={this.supported[this.props.type].icon} aria-hidden="true" />;
        }
    }
    renderHeader(){
        if (!this.state.hasHeader) {
            return null;
        }
        return <button className={`${this.classNames.header} full-width-inherit`}>{this.renderIcon()}{this.props.header}</button>;
    }
    renderMore(){
        if (this.props.reveal) {
            return (<button className="accordion__more" revealer={this.props.reveal} tracking-name={this.props.type} reveal-active={this.props['reveal-active']}>
                MORE
            </button>);
        } else {
            return null;
        }
    }
    renderBody(){
        return (
            <div className={this.classNames.body}>
                {this.props.children}
                {this.renderMore()}
            </div>
        );
    }
    render() {
        if (this.isValid()) {
            return (
                <span>
                  <div className={this.classNames.main} id={this.props.id} tracking-name={this.props.type}>
                      {this.renderHeader()}
                      {this.renderBody()}
                  </div>
                </span>
            );
        } else {
            return <span />;
        }
    }
}

SummaryBase.defaultProps = {
    wrappertype: 'accordion',
    hideicon: false
};


export default SummaryBase;
