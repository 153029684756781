import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { isNil } from 'lodash';
import track from 'react-tracking';
import html5shiv from 'html5shiv';
import Snapshot, {Snapshot2, Snapshot3} from './company/snapshot.jsx';
import Trend from './company/summaries/trend.jsx';
import News from './company/summaries/news.jsx';
import News2 from './company/summaries/news2.jsx';
import Navigation2 from './navigation2/navigation.jsx';
import Password from './forms/common/password.jsx';
import Financials, {Financials2} from './company/financials.jsx';
import Search from './company/search.jsx';
import Search2 from './company/search2.jsx';
import Search3 from './company/search3.jsx';
import Error from './forms/common/error.jsx';
import Summary, {Summary2, Summary3} from './company/summary.jsx';
import Quadrant from './company/quadrant.jsx';
import Comparison from './company/comparison.jsx';
import Comparison2 from './company/comparison2.jsx';
import CustomError from './utils/error.jsx';
import Input from './forms/common/input.jsx';
import RadioGroup from './forms/common/radioGroup.jsx';
import Pill from './utils/pills.jsx';
import LOCALIZED_STRS from './utils/localize.jsx';
import { Line } from 'rc-progress';
import Cookies from 'js-cookie';
import './utils/accordion.jsx';
import './utils/revealer.jsx';
import './utils/polyfills/remove.jsx';
import './utils/polyfills/closest.jsx';
import './utils/tabs.jsx';
import './utils/scrollTo.jsx';
import '../scss/main.scss';
import {getRequest} from './utils/fetch.jsx';
import SingleDatePicker from './utils/datepicker.jsx';
import DateTimePicker from './utils/datetimepicker.jsx';
import DateSpan from './utils/dates.jsx';
import Modal from './utils/modal.jsx';
import UploadComponent from './utils/fine_uploader.jsx';
import SortableTable from './utils/table_sortable.jsx';
import SortableFilterableTable from './supplierPay/sortable_filterable_table.jsx';
import SortableFilterableTable2 from './supplierPay/sortable_filterable_table2.jsx';
import CampaignSolicitationsTable from './supplierPay/campaignSolicitationsTable.jsx';
import UTILS from './utils/utils.jsx';
import CurrencyPicker from './utils/currencyPicker.jsx';
import AutoSuggest from './forms/common/autosuggest.jsx';
import SelectDropdown from './forms/common/selectDropdown.jsx';
import GetInitials from './utils/initials.jsx';
import './supplierPay/dropdown.jsx';
import './supplierPay/activityLog.jsx';
import RequestLanding from './company/requestLanding.jsx';
import SupplierPayContact, {SupplierPayContact2} from './supplierPay/supplierPayContactForm.jsx';
import RelationshipManager from './supplierPay/relationshipManager.jsx';
import LoadingScreen from './utils/loading.jsx';
import AddSolicitationRequestModal, {AddSolicitationRequestModal2} from './supplierPay/addSolicitationRequest.jsx';
import BasicFileInput from './utils/basic_file_input.jsx';
import NspStripeModal from './supplierPay/stripe.jsx';
import OnDemandSummary from './company/summaries/onDemandSummary.jsx';
import ReportType from './supplierPay/reportType.jsx';
import ReportType2 from './supplierPay/reportType2.jsx';
import RequestType from './supplierPay/requestType.jsx';
import Criticality from './supplierPay/criticality.jsx';
import Header from './common/header.jsx';
import { createStore } from 'redux';
import { attach_redux_revealers } from './utils/reveal_redux_store.jsx';
import { attach_ajax_forms } from './utils/ajax_form.jsx';
import ReduxModelLabel from './forms/common/redux_label.jsx';
import Tooltip from './utils/tooltip.jsx';
import HealthMarkSearch from './forms/healthmark-search/index.jsx';
import HealthMarkPermissions from './forms/healthmark-permission/index.jsx';
import SearchGeneric from './forms/search-generic/index.jsx';
import SuggestUpdate from './company/suggestUpdate.jsx';
import TopAnchor from './utils/topAnchor/topAnchor.jsx';
import BreadCrumbs from  './common/breadcrumbs.jsx';
import TableHealthMarkFollow from './healthMark/table_healthmark_follow.jsx';
import TableHealthMarkFollowV2 from './healthMark/table_healthmark_follow_v2.jsx';
import HealthMarkChildrenDownload from './forms/healthmark-download/index.jsx';
import HealthmarkRecents from './healthMark/carousel_recents.jsx';
import HealthmarkRecentsV2 from './healthMark/carousel_recents_v2.jsx';
import HealthMarkEdit from './forms/healthmark-company-edit/index.jsx';
import HealthMarkBulk from './forms/healthmark-bulk/index.jsx';
import HealthMarkLanding from './healthMark/healthMarkLanding.jsx';
import ListSummary from './healthMark/list-summary/index.jsx';
import DynamicBenchmark from './forms/dynamic-benchmark/index.jsx';
import TableHealthMarkSearchResults from './healthMark/tableHealthMarkSearchResults.jsx';
import SourceFinancials from './company/sourcefinancials.js';
import DataEntryToast from './workflow/DataEntryToast.jsx';
 
const allSupportedComponents = {
    tablehealthmarksearchresults: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <TableHealthMarkSearchResults { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    dynamicbenchmark: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <DynamicBenchmark { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    healthmarklanding: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <HealthMarkLanding { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    healthmarkbulk: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <HealthMarkBulk { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    listsummary: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <ListSummary { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    healthmarkedit: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <HealthMarkEdit { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    healthmarkchildrendownload: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <HealthMarkChildrenDownload { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    healthmarkrecents: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <HealthmarkRecents { ...attrs }/>, child);
                },
                2: () => {
                    ReactDOM.render(
                        <HealthmarkRecentsV2 { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    tablehealthmarkfollow: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <TableHealthMarkFollow { ...attrs }/>, child);
                },
                2: () => {
                    ReactDOM.render(
                        <TableHealthMarkFollowV2 { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    breadcrumbs: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <BreadCrumbs { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    topanchor: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <TopAnchor { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    suggestupdate: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <SuggestUpdate { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    campaignsolicitationstable: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <CampaignSolicitationsTable { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    healthmarksearch: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <HealthMarkSearch { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    healthmarkpermissions: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <HealthMarkPermissions { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    searchgeneric: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <SearchGeneric { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    tooltip: {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Tooltip { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    selectdropdown: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <SelectDropdown { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    header: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Header { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    requesttype: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <RequestType { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    reporttype: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <ReportType { ...attrs }/>, child);
                },
                2: () => {
                    ReactDOM.render(
                        <ReportType2 { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    criticality: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Criticality { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    nspstripemodal: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <NspStripeModal { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    basicfileinput: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <BasicFileInput { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    addsolicitationrequestmodal: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <AddSolicitationRequestModal { ...attrs }/>, child);
                },
                2: () => {
                    ReactDOM.render(
                        <AddSolicitationRequestModal2 { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    request: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <RequestLanding { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    loadingscreen: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <LoadingScreen { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    sortableTable: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <SortableTable { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    sortablefilterabletable: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <SortableFilterableTable { ...attrs }/>, child);
                },
                2: () => {
                    ReactDOM.render(
                        <SortableFilterableTable2 { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    snapshot: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Snapshot { ...attrs }/>, child);
                },
                2: () => {
                    ReactDOM.render(
                        <Snapshot2 { ...attrs }/>, child);
                },
                3: () => {
                    ReactDOM.render(
                        <Snapshot3 { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    modal: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Modal { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    currencypicker: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <CurrencyPicker { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    radiogroup: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <RadioGroup { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    pill: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Pill { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    getinitials: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <GetInitials { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    upload: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <UploadComponent { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    navigation: {
        render: (child) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 2;
            const navApiPath = '/api/navigation';
            const versions = {
                1: () => {
                    ReactDOM.render(
                        // Navigation is not ARIA, set version 2 to load
                        <Navigation2 {...attrs} url={navApiPath} />, child);
                },
                2: () => {
                    ReactDOM.render(
                        <Navigation2 {...attrs} url={navApiPath} />, child);
                }
            };
            versions[version]();
        }
    },
    password: {
        render: (child, lang, store) => {
            const ele = child;
            const version = child.getAttribute('version') || '1';
            const url = child.getAttribute('forgot-url') || '';
            const label = child.getAttribute('label') || '';
            const value = child.getAttribute('value') || '';
            const name = child.getAttribute('name') || '';
            const id = child.getAttribute('id') || '';
            const placeholder = child.getAttribute('placeholder') || '';
            const minLength = child.getAttribute('min-length') || '';
            const maxLength = child.getAttribute('max-length') || '';
            const required = child.getAttribute('required') || '';
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Password version={version} id={id} placeholder={placeholder} type="password" url={url} minLength={minLength} name={name} required={required} value={value} maxLength={maxLength} label={label}/>, child);
                }
            };
            versions[version]();
        }
    },
    error: {
        render: (child, lang, store) => {
            const ele = child;
            const version = child.getAttribute('version') || '1';
            const errors = (ele.getAttribute('errors')) ? JSON.parse(ele.getAttribute('errors')) : {};
            const customErrors = [];

            Object.keys(errors).forEach(error => {
                errors[error].forEach(msg => {
                    customErrors.push({error: error, message: msg});
                }, this);
            }, this);
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Error version={version} lang={lang} errors={customErrors}/>, child);
                }
            };
            versions[version]();
        }
    },
    'input-group': {
        render: (child, lang, store) => {
            const ele = child;
            const version = child.getAttribute('version') || '1';
            const type = child.getAttribute('type') || 'text';
            const name = child.getAttribute('name') || '';
            const value = child.getAttribute('value') || '';
            const label = child.getAttribute('label') || '';
            const id = child.getAttribute('id') || '';
            const placeholder = child.getAttribute('placeholder') || '';
            const minLength = child.getAttribute('min-length') || '';
            const maxLength = child.getAttribute('max-length') || '';
            const required = child.getAttribute('required') || '';
            const store_model = child.getAttribute('store_model') || '';
            const store_subscribe = child.getAttribute('store_subscribe') || '';
            const store_change_action = child.getAttribute('store_change_action') || '';
            UTILS.stripAttrs(child);
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Input version={version} id={id} placeholder={placeholder} lang={lang} type={type} value={value} name={name} minLength={minLength} required={required} maxLength={maxLength} label={label} store={store} store_model={store_model} store_subscribe={store_subscribe} store_change_action={store_change_action} />, child);
                }
            };
            versions[version]();
        }
    },
    financials: {
        render: child => {
            const ele = child;
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Financials { ...attrs }/>, child);
                },
                2:() => {
                    ReactDOM.render(
                        <Financials2 { ...attrs }/>, child);
                },
            };
            versions[version]();
        }
    },
    trend: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Trend { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    profilesummary: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Summary { ...attrs } />, child);
                },
                2: () => {
                    ReactDOM.render(
                        <Summary2 { ...attrs } />, child);
                }

            };
            versions[version]();
        }
    },
    sourcefinancials: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <SourceFinancials { ...attrs } />, child);
                }

            };
            versions[version]();
        }
    },
    'data-entry-toast': {
        render: (child, lang) => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <DataEntryToast { ...attrs }/>, child);
                }
            };
            versions[version]();
        }
    },
    ondemandsummary: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <OnDemandSummary { ...attrs } />, child);
                }

            };
            versions[version]();
        }
    },
    quadrant: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Quadrant { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    comparison: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Comparison { ...attrs } />, child);
                },
                2: () => {
                    ReactDOM.render(
                        <Comparison2 { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    singledatepicker: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <SingleDatePicker { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    datetimepicker: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <DateTimePicker { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    news: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <News { ...attrs } />, child);
                },
                2: () => {
                    ReactDOM.render(
                        <News2 { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    progressbar: {
        render: (child, lang, store) => {
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;

            // Translate the attributes, as they can be returned in
            // lowercase and the element will complain
            const ATTRIBUTES = new Set(['strokeWidth', 'strokeWidth', 'strokeColor',
                                        'trailWidth', 'trailColor', 'strokeLinecap',
                                        'prefixCls', 'className', 'style', 'percent',
                                        'gapDegree', 'gapPosition']);
            // Prepare a matrix to translate values
            const MATRIX = {};
            ATTRIBUTES.forEach(value => MATRIX[value.toLowerCase()] = value);

            const tattrs = {};
            for (const property in attrs) {
                if (attrs.hasOwnProperty(property)) {
                    if(property in MATRIX) {
                        tattrs[MATRIX[property]] = attrs[property];
                    } else {
                        tattrs[property] = attrs[property];
                    }
                }
            }

            const version = tattrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Line { ...tattrs } />, child);
                }
            };
            versions[version]();
        }
    },
    search: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <Search { ...attrs } />, child);
                },
                2: () => {
                    ReactDOM.render(
                        <Search2 { ...attrs } />, child);
                },
                3: () => {
                    ReactDOM.render(
                        <Search3 { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    datespan: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <DateSpan { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    autosuggest: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <AutoSuggest { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    supplierpaycontact: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <SupplierPayContact { ...attrs } />, child);
                },
                2: () => {
                    ReactDOM.render(
                        <SupplierPayContact2 { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    relationshipmanager: {
        render: child => {
            const attrs = UTILS.getAttrs(child);
            const version = attrs.version || 1;
            const versions = {
                1: () => {
                    ReactDOM.render(
                        <RelationshipManager { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    },
    reduxmodellabel: {
        render: (child, lang, store) => {
            const version = child.getAttribute('version') || '1';
            const attrs = UTILS.getAttrs(child);
            attrs.store = store;

            const versions = {
                1: () => {
                    ReactDOM.render(
                        <ReduxModelLabel { ...attrs } />, child);
                }
            };
            versions[version]();
        }
    }
};


function reducer(state = {
        selectedCountry: null,
        calculatedTax: null,
        showTaxForm: false,
        showTaxResults: false,
        showPaymentButton: false,
        order_cost: null,
        order_tax: null,
        order_total: null,
        order_total_raw: null,
        order_currency_symbol: null,
        order_should_enable_form: true
    },
    {type, payload}) {
    switch(type) {
        case 'REGISTRATION_SELECT_COUNTRY_ACTION': {
            const selectedCountry = payload.selectedCountry;
            return { ...state,
                selectedCountry: selectedCountry,
                showTaxForm: selectedCountry === 'us',
                showPaymentButton: selectedCountry !== 'us' };
        }
        case 'REGISTRATION_SET_ADDRESS_FIELD': {
            const newstate = { ...state, ...payload };
            return newstate;
        }
        case 'REGISTRATION_SET_CALCULATED_TAX_ACTION': {
            return { ...state,
                calculatedTax: payload,
                showTaxResults: true,
                showTaxForm: false,
                showPaymentButton: true,
                order_cost: payload.order_cost.toFixed(2),
                order_tax: payload.order_tax.toFixed(2),
                order_total: payload.order_total.toFixed(2),
                order_total_raw: payload.order_total_raw.toFixed(2),
                order_currency_symbol: payload.order_currency_symbol,
                order_should_enable_form: false
                };
        }
        default: {
            return { ...state };
        }
    }
}


class RapidRatings {
    constructor(lang = 'en') {
        this.lang = lang;
        this.redux_store = createStore(reducer);
    }
    renderSupportedComponents() {
        Object.keys(allSupportedComponents).forEach((component) => {
            const children = [...document.getElementsByTagName(component)];
            children.forEach((child) => {
                allSupportedComponents[component].render(child, this.lang, this.redux_store);
                UTILS.stripAttrsExceptRole(child);
            }, this);
        });
    }
    formValidation() {
        const forms = [
          ...document.querySelectorAll('form:not(.preventLoadingState)'),
        ];
        forms.forEach(form => {
          const submitButtons = form.querySelectorAll('[type="submit"]'); // Query all submit buttons inside of the form
          Array.from(submitButtons).map(button => { // Account for multiple inside of one form and add submit event to each element
            form.addEventListener('submit', () => {
              button.classList.add('btn--loading');
              if (!window.location.pathname.startsWith('/services')) {
                button.setAttribute('disabled', true);
              }
              button.innerHTML = `${LOCALIZED_STRS.get().loading}`;
            });
          });
        });
    }
    reduxRevealers() {
        attach_redux_revealers(this.redux_store);
    }
    ajaxForms() {
        attach_ajax_forms(this.redux_store);
    }
}

const RR = new RapidRatings(document.documentElement.lang);
RR.renderSupportedComponents();
RR.formValidation();
RR.reduxRevealers();
RR.ajaxForms();
