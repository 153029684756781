import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import List from './list.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';

class Strengths extends React.Component {
    constructor(props) {
        super(props);
        const strengths = JSON.parse(props.strengths || '[]');
        const concerns = JSON.parse(props.concerns || '[]');
        if (strengths.length === 0) {
            strengths.push(LOCALIZED_STRS.get().emptyStrengths);
        }
        if (concerns.length === 0) {
            concerns.push(LOCALIZED_STRS.get().emptyConcerns);
        }
        this.state = {
            strengths: strengths,
            concerns: concerns
        };
    }
    render() {
        return (
            <div className="findialog__lists">
                <List list={this.state.strengths} header={LOCALIZED_STRS.get().strengths} type="strengths"/>
                <List list={this.state.concerns} header={LOCALIZED_STRS.get().concerns} type="concerns"/>
            </div>
        );
    }
}

export default Strengths;
