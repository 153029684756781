import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {getRequest} from '../../utils/fetch.jsx';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';

class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: props['data-url'] || '',
            providerLogoUrl: props['logourl'] || '',
            isLoading: true,
            hasNews: false,
            articles: [],
            isSummaryMode: JSON.parse(props.summary || 'false')
        };
    }
    getNews() {
        getRequest(this.state.url).then(results => {
            this.refactorResults(results);
        }).catch(err => {});
    }
    refactorResults(results){
        let articles = [];
        Object.keys(results.articles).forEach(key => {
            if(results.counts[key] > 0){
                articles = [...articles, ...results.articles[key].map( article => article)];
            }
        });
        articles = articles.slice(0, (this.props.limit || articles.length));
        const others = Object.keys(results.counts).filter( key => parseFloat(results.counts[key]) === 0).map( key => LOCALIZED_STRS.get().news[key]);
        let noOthers = '';
        if(others.length >= 2){
            noOthers = others.join(', ').replace(/,([^,]*)$/, ` ${LOCALIZED_STRS.get().preposition}` + '$1') + ` ${LOCALIZED_STRS.get().news.othersEmpty}`;
        } else if(others.length === 1){
            noOthers = others.join('') + ` ${LOCALIZED_STRS.get().news.singularEmpty}`;
        }
        this.setState({
            hasNews: articles.length > 0,
            isLoading: false,
            articles: articles,
            noOthers: noOthers
        });
    }
    componentDidMount() {
        if (!UTILS.isEmptyOrUndefined(this.state.url)) {
            this.getNews();
        } else {
            this.refactorResults({ articles: JSON.parse(this.props.articles), counts: JSON.parse(this.props.counts)});
        }
    }
    render() {
        return (
            <div>
                {this.state.providerLogoUrl && <img src={this.state.providerLogoUrl} className="news__logo" alt="News provider logo"/>}
                {!this.state.isLoading &&
                    <span>
                      {this.state.hasNews &&
                          <div>
                            {!this.state.isSummaryMode &&
                                <span>
                                    <h2>{this.props.header}</h2>
                                    <div className="news__othersempty">
                                        {this.state.noOthers}
                                    </div>
                                </span>
                            }
                            <ul className="content__list content__list--news">
                                {this.state.articles.map((article, index) => {
                                    return (
                                        <li key={index}>
                                            { (index === 0 || article.category !== this.state.articles[0].category) &&
                                                <div id={article.category} className="news__topic">{LOCALIZED_STRS.get().news[article.category]}</div>
                                            }
                                            { (index === 0 || article.formatted_date !== this.state.articles[0].formatted_date) &&
                                              <p className="news__date">
                                                  {article.formatted_date}
                                              </p>
                                            }
                                            <div className="news__headline news__headline--clickable">
                                                <a href={article.url} target="_blank" rel="noopener noreferrer" className="link">{article.head}</a>
                                            </div>
                                            {!this.state.isSummaryMode &&
                                                <div className="news__snippet">
                                                    {article.real}
                                                </div>
                                            }
                                        </li>
                                      );
                                  })}
                            </ul>
                          </div>
                      }
                    {!this.state.hasNews &&
                        <p className="news__empty">{LOCALIZED_STRS.get().news.empty}</p>
                    }
                  </span>
                }
            </div>
        );
    }
}

export default News;
