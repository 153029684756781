import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';
import ReactModal from 'react-modal';
import { isEqual } from 'lodash';
import UTILS from '../../utils/utils.jsx';
import LOCALIZED_STRS from '../../utils/localize.jsx';
import {csrftoken} from '../../utils/csrf.jsx';
import styled from 'styled-components';
import 'formdata-polyfill';
import Checkbox from '../../forms/common/checkbox.jsx';
import { PlusIcon, TickIcon, ExclamationIcon } from './icons.jsx';
import { toast, ToastContainer, cssTransition } from 'react-toastify';

const StyledToastContainer = styled(ToastContainer)`
    width: 95vw;
    padding: 0;
    top: 88px;
    left: 0;
    right: 0;
    position: fixed;
    margin: 0 auto;

    .Toastify__toast {
        padding: 24px;

        &-body {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-top: -4px;
            }

            span {
                padding-left: 12px;
            }
        }

        &.toast {
            &--success {
                background-color: #D8EED6;
                color: #41A939;
                .icon {
                    color: #4A4A4A;
                    margin: auto 0;
                    padding-bottom: 6px;
                }
            }

            &--failed {
                background-color: #FDCECE;
                color: #ED4C4A;
                .icon {
                    color: #4A4A4A;
                    margin: auto 0;
                    padding-bottom: 6px;
                }
            }
        }
    }
`;

const StyledButton = styled.button`
    min-width: auto;

    svg {
        display: inline-block;
        vertical-align: middle;
    }
`;

const StyledReactModal = styled(ReactModal)`
    max-width: 500px;
`;

const ModalContent = styled.div`
    margin: 16px auto;
`;

const ModalFooter = styled.div`
    text-align: right;
    :before {
        content: "";
        border: 1px solid #f4f6f9;
        display: block;
        margin: 0 -34px 24px;
    }
`;

const Label = styled.label`
    font-size: 16px !important;
`;

export default class WatchList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItemId: props.companyid,
            originalSelectedItem: JSON.parse(props.watchlist || '[]'),
            selectedItem: JSON.parse(props.watchlist || '[]'),
            status: ''
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleBookmarkSubmit = this.handleBookmarkSubmit.bind(this);
    }

    componentDidMount () {
      this.fetchBookmarkedData();
    }

    fetchBookmarkedData = () => {
        fetch(this.props.watchlist_url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': csrftoken()
            },
            credentials: 'same-origin'
        })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            this.setState({
                selectedItem: data,
                originalSelectedItem: data
            });
        });
    }

    isSubmitDisabled = () => 
        this.state.isLoading || 
        isEqual(JSON.stringify(this.state.selectedItem), JSON.stringify(this.state.originalSelectedItem));

    openModal() {
        this.fetchBookmarkedData();
        this.setState({modalIsOpen: true});
    }

    closeModal() {
        this.setState({modalIsOpen: false, originalSelectedItem: [], selectedItem: []});
    }

    handleBookmarkSubmit() {
        const formData = new FormData();
        const csrftoken_value = csrftoken();
        formData.append('ids', JSON.stringify(this.state.selectedItemId));
        formData.append(
            'action', JSON.stringify(this.state.selectedItem)
        );
        formData.append('csrfmiddlewaretoken', csrftoken_value);
        fetch(window.location.href, {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': csrftoken_value
            },
            credentials: 'same-origin',
            body: formData
        })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            const status = data.bookmarked_status;
            this.setState({
              status: status,
              modalIsOpen: false
            }, () => {
              this.state.status !== '' &&
              toast(this.state.status === 'success'
                      ? LOCALIZED_STRS.get().listsUpdateSuccess
                      : LOCALIZED_STRS.get().listsUpdateFailed, {
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  className: `toast--${this.state.status}`
              });
            });
        });
    }

    handleCheckbox(e) {
        const editListName = (list, oldListName, name) => {
            // map over list
            return list.map(item => {
                const temp = Object.assign({}, item);
                // if there is a matching value
                if (temp.list == oldListName) {
                    // update the value
                    temp.bookmarked = (name === true ? true : false);
                }
                return temp;
            });
        };

        const updatedList = editListName(this.state.selectedItem, e.target.name, e.target.checked);

        this.setState({
            selectedItem: updatedList
        });
    }


    render() {
        const CloseButton = ({ closeToast }) => (
            <i
                className="icon icon--close close-menu"
                aria-hidden="true"
                onClick={closeToast}
            ></i>
        );

        const customTransition = cssTransition({
            enter: 'bounceInDown',
            exit: 'bounceOutUp',
            duration: 1500,
            appendPosition: false
        });

        return (
            <>
                <StyledToastContainer
                    status={this.state.status}
                    position="top-center"
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    transition={customTransition}
                    pauseOnVisibilityChange
                    closeButton={<CloseButton />}
                />
                <StyledButton
                    onClick={this.openModal}
                    id="healthmark:list:assign"
                    className="rbtn rbtn--md rbtn--white print__hide"
                    title="Bookmark"
                > <PlusIcon /> {LOCALIZED_STRS.get().assignList}
                </StyledButton>
            </>
        );
    }
}
