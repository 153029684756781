import UTILS from './utils.jsx';

class ReduxStoreReveal {
    constructor(ele, redux_store, props) {
        this.ele = ele;
        this.reveal_from_store = props.reveal_from_store;
        this.reveal_from_store_value = props.reveal_from_store_value;
        this.reveal_from_store_invert = props.reveal_from_store_invert;
        this.store = redux_store;
        this.store.subscribe(() => {
            this.revealOrHide();
        });
    }

    shouldDisplay() {
        let should_display = false;
        if (this.reveal_from_store_value) {
            should_display = this.store.getState()[this.reveal_from_store] == this.reveal_from_store_value;
        } else {
            const store_value = this.store.getState()[this.reveal_from_store];
            should_display = store_value != null && store_value != undefined && store_value != 0 && store_value != false;
        }
        if (this.reveal_from_store_invert) {
            should_display = !(should_display);
        }
        return should_display;
    }

    revealOrHide() {
        const is_displayed = this.ele.classList.contains('revealer--show');
        if (this.shouldDisplay() != is_displayed) {
            this.toggleClasses();
        }
    }

    toggleClasses() {
        this.ele.classList.toggle('revealer--show');
    }
}

const attach_redux_revealers = (redux_store) => {
    document.redux_revealer = new Map();
    document.addEventListener('DOMContentLoaded', () => {
        [...document.querySelectorAll('[reveal_on_action]')].forEach(ele => {
          const attrs = UTILS.getAttrs(ele);
          document.redux_revealer.set(ele.getAttribute('reveal_on_action'), new ReduxStoreReveal(ele, redux_store, attrs));
        }, this);
    });
};

export {attach_redux_revealers};
