import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import LOCALIZED_STRS from './localize.jsx';
import UTILS from './utils.jsx';

class CurrencyPicker extends React.Component {
    constructor(props) {
        super(props);
        const settings = this.initializeCurrencies(props);
        const defaults = LOCALIZED_STRS.settings.currency.defaults.map( (code, index) => {
            const cur = settings.currencies.find(currency => currency.code === code);
            if(cur){
                cur.isDefault = true;
                return cur;
            }
        });
        this.state = {
            base: props.base,
            currencies: settings.currencies,
            selected: props.base,
            defaults: defaults,
            label: props.label || 'Currency',
            required:  this.props.required !== undefined ? true : false,
            name: props.name || 'currencyPicker'
        };
    }
    initializeCurrencies(props) {
        const currencyList = JSON.parse(props.currencies || '[]');
        const currencies = currencyList.map(currency => {
            currency.date = UTILS.getReadableDate(UTILS.getDateObject(currency.updated));
            currency.isDefault = false;
            return currency;
        });
        return {
            currencies: currencies
        };
    }
    render() {
        return (
            <div className="input__group currencyPicker">
                <label htmlFor={this.state.name}>{this.state.label}</label>
                <select id={this.state.name} name={this.state.name} required={this.state.required}>
                <option value="">{LOCALIZED_STRS.get().inputPlaceholder}</option>
                    <optgroup label={LOCALIZED_STRS.get().currency.commongroup}>
                      {this.state.defaults.map((currency, index) => {
                          return (
                              <option key={index} value={currency.code}>{currency.code} - {currency.name}</option>
                          );
                        })}
                    </optgroup>
                    <optgroup label={LOCALIZED_STRS.get().currency.othergroup}>
                        {this.state.currencies.map((currency, index) => {
                            if(!currency.isDefault){
                                return (
                                    <option key={index} value={currency.code}>{currency.code} - {currency.name}</option>
                                );
                            }
                        })}
                    </optgroup>
                </select>
            </div>
        );
    }
}

export default CurrencyPicker;
