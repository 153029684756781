import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import ReactTable from 'react-table';
import Select from 'react-select';
import moment from 'moment';
import _ from 'lodash';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

class SortableFilterableTable extends Component {
    constructor(props) {
        super(props);
        const columns = JSON.parse(this.props.columns || '[]');
        Object.keys(columns).map((key) => {
            const column = columns[key];
            if (column.renderer !== undefined) {
                column.Cell = this.cellRenderers[column.renderer];
            }
        });
        this.state = {
            url: this.props.url,
            pageSize: parseFloat(this.props.pagesize || '25'),
            data: [],
            pages: 0,
            columns: columns,
            filtered: [],
            filters: [],
            total: 0,
            searchParam: props.searchparam || null,
            searchTerm: props.searchterm || '',
            selectStatus: undefined,
            selectAssignee: undefined,
            id: this.props.id || '[]',
            loading: undefined,
            sorted: [],
            page: -1
        };
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onFilteredChangeCustom = this.onFilteredChangeCustom.bind(this);
    }

    cellRenderers = {
        Link: (cellInfo) => {
            return <a href={cellInfo.original.href} className="link link--sentence"> {cellInfo.original.counterparty} </a>;
        },
        Date: (cellInfo) => {
            return <span>{moment(cellInfo.original.updated, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY h:mm:ss A').toString()}</span>;
        }
    }

    onFilterClick = o => {
        this.setState({ filtered: [o] });
    }

    onClearFilters = () => {
      this.setState({
          filtered: [],
          selectStatus: null,
          selectAssignee: null
      });
    };

    onFilteredChangeCustom = (value, accessor) => {
      const filtered = this.state.filtered;
      let insertNewFilter = 1;

      if (filtered.length) {
        filtered.forEach((filter, i) => {
          if (filter['id'] === accessor) {
            if (value === '' || !value.length) {
                filtered.splice(i, 1);
            } else {
                filter['value'] = value;
            }

            insertNewFilter = 0;
          }
        });
      }

      if (insertNewFilter) {
        filtered.push({ id: accessor, value: value });
      }

      this.setState({ filtered: filtered });

      this.fetchData({page:-1});
    };

    get_URL = (state) => {
        const searchParam = this.props.searchparam;
        const sorted = this.state.sorted.map(k => (k.desc ? '-' : '') + k.id);
        const filtered = this.state.filtered.map(k => k.id + '=' + k.value).join(',');
        const params = {
            page: state.page + 1,
            pageSize: this.state.pageSize,
            sorted: sorted,
            filtered: filtered
        };
        if (!UTILS.isEmptyOrUndefined(this.props.searchparam)) {
            Object.assign(params, {
                [searchParam]: this.state.searchTerm
            });
        }
        const esc = encodeURIComponent;
        const query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
        return this.state.url + '?' + query;
    };

    fetchData = (state, instance) => {
        this.setState({loading: true});
        // fetch the data
        setTimeout(() => {
            fetch(this.get_URL(state), {
                credentials: 'same-origin',
            })
              .then((res) => {
                  return res.json();
              })
              .then((data) => {
                  this.setState({
                    data: data.rows,
                    pages: data.pages,
                    total: data.total,
                    loading: false
                });
              });
        }, 1);
    };

    onPageSizeChange = (pageSize, pageIndex) => {
        this.setState({
            pageSize: pageSize,
            pageIndex: pageIndex
        });
    };

    onSortedChange = (newSorted, column, shiftKey) => {
        this.setState({
            sorted: newSorted
        });
    };

    onSearchTermChange = (searchTerm) => {
        this.setState({
            searchTerm: searchTerm
        });

        this.fetchData({page:-1});
    };

    render() {
        const {data, pages, pageSize} = this.state;
        const statuses = JSON.parse(this.props.statuses || '[]');
        const assignees = JSON.parse(this.props.assignees || '[]');

        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              padding: 5,
          }),
          singleValue: (provided, state) => ({
            ...provided,
            paddingTop: 2,
            paddingBottom: 2
          })
        };

          return (
              <React.Fragment>
                  <div className="row">
                      <div className="table_sortable">
                          <div className="filter--container">
                              <span>Filter by: </span>
                              <div className="select--container">
                                  <Select
                                    styles={customStyles}
                                    onChange={entry => {
                                      this.setState({ selectStatus: entry });
                                      this.onFilteredChangeCustom(entry.id, 'status');
                                    }}
                                    value={this.state.selectStatus}
                                    options={statuses.map(([k, v]) => {
                                      return {id: k, value: v, label: v};
                                    })}
                                    placeholder="Status"
                                  />
                              </div>
                          </div>
                          <div className="filter--container">
                              <div className="select--container">
                                  <Select
                                    styles={customStyles}
                                    onChange={entry => {
                                      this.setState({ selectAssignee: entry });
                                      this.onFilteredChangeCustom(entry.id, 'assignee');
                                    }}
                                    value={this.state.selectAssignee}
                                    options={assignees.map(([k, v]) => {
                                      return {id: k, value: v, label: v};
                                    })}
                                    placeholder="Assignee"
                                  />
                              </div>
                          </div>
                          <div className="filter--container">
                            <span>
                              <a className="link link--sentence" onClick={this.onClearFilters}>
                                {LOCALIZED_STRS.get().clearFilters}
                              </a>
                            </span>
                          </div>
                          { this.state.searchParam &&
                              <div className="filter--container filter--container--search">
                                  <input
                                      autoComplete="off"
                                      placeholder="Search"
                                      value={this.state.searchTerm}
                                      onChange={e => {
                                          this.onSearchTermChange(e.target.value);
                                      }}
                                  />
                              </div>
                          }
                          <ReactTable
                            key={pageSize}
                            data={data}
                            pages={pages}
                            url={this.state.url}
                            filtered={this.state.filtered}
                            defaultPageSize={this.state.pageSize}
                            onPageSizeChange={this.onPageSizeChange}
                            pageSize={this.state.pageSize}
                            loading={this.state.loading}
                            onSortedChange={this.onSortedChange}
                            minRows={0}
                            pageSizeOptions= {[5, 10, 20, 25, 50, 100]}
                            onFilteredChange={(filtered, column, value) => {
                              this.onFilteredChangeCustom(
                                value,
                                column.id || column.accessor
                              );
                            }}
                            sorted = {this.state.sorted}
                            onFetchData={ this.fetchData }
                            manual
                            columns={this.state.columns}
                        >
                          {(state, makeTable, instance) => {
                            let recordsInfoText = '';

                            const { filtered, pageRows, pageSize, sortedData, page } = state;

                            if (sortedData && sortedData.length > 0) {
                              const isFiltered = filtered.length > 0;

                              const total = this.state.total;

                              const recordsCountFrom = (page * pageSize) + 1;

                              const recordsCountTo = (recordsCountFrom + pageRows.length) - 1;

                              if (isFiltered) {
                                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${total} ` + LOCALIZED_STRS.get().filteredRequests;
                              } else {
                                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${total} ` + LOCALIZED_STRS.get().requests;
                              }

                            } else {
                                recordsInfoText = LOCALIZED_STRS.get().noRequests;
                            }
                            return (
                                <div className="main-grid">
                                  <div className="above-table margin__vertical">
                                      <span className="records-info">{recordsInfoText}</span>
                                  </div>
                                  {makeTable()}
                                </div>
                            );
                          }}
                        </ReactTable>
                      </div>
                  </div>
              </React.Fragment>
          );
    }
}

export default SortableFilterableTable;
