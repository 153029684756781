import React from 'react';
import styled, {css} from 'styled-components';
import { darken } from 'polished';

const background = {
  primary:'#00afd7',
  danger: '#d30547',
  primaryDarken: darken(0.10, '#00afd7'),
  dangerDarken: darken(0.10, '#d30547'),
};

const Button = styled.button`
  min-width: 150px !important;
  border-radius: 2px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background: ${props => (props.isDanger ? `${background.danger}` : `${background.primary}`)};
  border: ${props => (props.isDanger ? css`1px solid #d30547` : css`1px solid #00afd7`)};
`;

export default function SubmitButon({ onClick, children, ...props }) {
  return (
    <Button
      disabled={props.disabled}
      isDanger={props.isDanger}
      type="submit"
      className="rbtn rbtn--primary"
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
