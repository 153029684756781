import React from 'react';

export function LoadingIcon() {
  return (
      <svg width="12px" height="12px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="rotate(0 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(30 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(60 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(90 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(120 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(150 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(180 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(210 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(240 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(270 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(300 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
              </rect>
          </g>
          <g transform="rotate(330 50 50)">
              <rect x="46" y="-0.5" rx="0" ry="0" width="8" height="25" fill="#00afd7">
                  <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
              </rect>
          </g>
      </svg>
  );
}

export function DownloadIcon() {
  return (
      <svg width="12px" height="12px" viewBox="0 0 12 12">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="company-structure-2-copy-3" transform="translate(-950.000000, -280.000000)" fill="#00afd7">
                  <path d="M960.8,290.2066 C960.8,290.5342 960.5342,290.8 960.2066,290.8 L951.7934,290.8 C951.4658,290.8 951.2,290.5342 951.2,290.2066 L951.2,281.7934 C951.2,281.4658 951.4658,281.2 951.7934,281.2 L954.2,281.2 L954.2,287.3206 C954.2,287.5846 953.8832,287.7202 953.6924,287.5366 L953.117,286.9852 C952.8782,286.7554 952.4978,286.7662 952.2722,287.0086 C952.0496,287.2474 952.0616,287.6206 952.2992,287.845 L954.0128,289.4614 C954.4772,289.8994 955.2032,289.897 955.6646,289.456 L957.3524,287.845 C957.5876,287.6206 957.5984,287.2486 957.377,287.011 L957.3686,287.002 C957.1454,286.762 956.7698,286.7488 956.5298,286.972 L955.9046,287.5546 C955.7126,287.7334 955.4,287.5972 955.4,287.335 L955.4,281.2 L957.2,281.2 L957.2,283.6 C957.2,284.263 957.737,284.8 958.4,284.8 L960.8,284.8 L960.8,290.2066 Z M961.826,283.426 L958.574,280.174 C958.4624,280.0624 958.3118,280 958.154,280 L951.2,280 C950.537,280 950,280.5376 950,281.2 L950,290.8 C950,291.463 950.537,292 951.2,292 L960.8,292 C961.463,292 962,291.463 962,290.8 L962,283.846 C962,283.6882 961.9376,283.5376 961.826,283.426 L961.826,283.426 Z" id="file_arrow_down-[#1717]"></path>
              </g>
          </g>
      </svg>
  );
}
