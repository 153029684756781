import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactTable from 'react-table';
import Select from 'react-select';
import moment from 'moment';
import UTILS from '../utils/utils.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

class SortableFilterableTable2 extends Component {
    constructor(props) {
        super(props);
        const columns = JSON.parse(this.props.columns);
        Object.keys(columns).map((key) => {
            const column = columns[key];
            if (column.renderer !== undefined) {
                column.Cell = this.cellRenderers[column.renderer];
            }
        });
        this.state = {
            data: [],
            pages: 0,
            columns: columns,
            filtered: [],
            total: 0,
            filter_select_values: {},
            loading: undefined,
            sorted: [],
            searchPlaceholder: props.searchplaceholder,
            searchParam: props.searchparam,
            searchTerm: props.searchterm,
            page: -1,
            id: this.props.id,
            filters: JSON.parse(this.props.filters),
            url: this.props.url,
            pageSize: this.props.pagesize,
            totalRequestsLabel: this.props.total_requests_label,
            totalFilteredRequestsLabel: this.props.total_filtered_requests_label,
            totalNoRequests: this.props.total_no_requests
        };
        // apply debounce only once per component
        this.refreshDebounced = _.debounce(this.refreshDebounced, this.props.searchdebounce);
    }

    static defaultProps = {
        pagesize: 25,
        filters: '[]',
        id: 'sortable-table',
        columns: '[]',
        searchplaceholder: 'Search...',
        searchparam: null,
        searchterm: '',
        searchdebounce: 500,
        searchmin: 2,
        total_requests_label: LOCALIZED_STRS.get().requests,
        total_filtered_requests_label: LOCALIZED_STRS.get().filteredRequests,
        total_no_requests: LOCALIZED_STRS.get().noRequests
    };

    cellRenderers = {
        Link: (cellInfo) => {
            return <a href={cellInfo.original.href} className="link link--sentence"> {cellInfo.original[cellInfo.column.id]} </a>;
        },
        CampaignLink: (cellInfo) => {
            return <a href={cellInfo.original.campaign_link} className="link link--sentence"> {cellInfo.original.campaign_name} </a>;
        },
        HealthMarkLink: (cellInfo) => {
            return <a href={`/health-mark/profile/${cellInfo.original.company_id}?search-term=general&country-code=`} className="link link--sentence"> {cellInfo.original.name} </a>;
        },
        Date: (cellInfo) => {
            return <span>{moment(cellInfo.original[cellInfo.column.id], 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY h:mm:ss A').toString()}</span>;
        },
        HealthMarkIndicator: (cellInfo) => {
            return <span className={`riskLevel riskLevel__${cellInfo.original.risk_mark_code}`}><span className="riskLevel__text">{cellInfo.original.risk_mark_type}</span></span>;
        }
    };

    onClearFilters = () => {
        const filter_select_values = this.state.filter_select_values;
        Object.keys(filter_select_values).map((key) => {
            filter_select_values[key] = null;
        });
        this.setState({
            filtered: [],
            filter_select_values: filter_select_values
        });
    };

    onFilteredChangeCustom = (value, accessor) => {
        const filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter['id'] === accessor) {
                    if (value === '' || !value.length) {
                        filtered.splice(i, 1);
                    } else {
                        filter['value'] = value;
                    }

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });

        this.fetchData({page:-1});
    };

    get_URL = (state) => {
        const searchParam = this.props.searchparam;
        const sorted = this.state.sorted.map(k => (k.desc ? '-' : '') + k.id);
        const filtered = this.state.filtered.map(k => k.id + '=' + k.value).join(',');
        const params = {
            page: state.page + 1,
            pageSize: this.state.pageSize,
            sorted: sorted,
            filtered: filtered
        };
        if (!UTILS.isEmptyOrUndefined(this.props.searchparam)) {
            Object.assign(params, {
                [searchParam]: this.state.searchTerm
            });
        }
        const esc = encodeURIComponent;
        const query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
        return this.state.url + '?' + query;
    };

    fetchData = (state, instance) => {
        this.setState({loading: true});
        // fetch the data
        setTimeout(() => {
            fetch(this.get_URL(state), {
                credentials: 'same-origin',
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    this.setState({
                        data: data.rows,
                        pages: data.pages,
                        total: data.total,
                        loading: false
                    });
                });
        }, 1);
    };

    onPageSizeChange = (pageSize, pageIndex) => {
        this.setState({
            pageSize: pageSize,
            pageIndex: pageIndex
        });
    };

    onSortedChange = (newSorted, column, shiftKey) => {
        // sort only by one column
        // override previous sorted state
        this.setState({
            sorted: newSorted
        });
    };

    clearSearch = () => {
        this.setState({
            searchTerm: ''
        });
        this.fetchData({page:-1});
    }

    renderFilter = (filter, i) => {
        const filterName = filter.name;
        const options = filter.options;
        const placeholder = filter.placeholder;
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                padding: 5,
            }),
            menu:(provided, state) => ({
                ...provided,
                zIndex: 3
            }),
            singleValue: (provided, state) => ({
                ...provided,
                paddingTop: 2,
                paddingBottom: 2
            })
        };
        return <div className="filter--container" key={i}>
                <div className="select--container">
                    <Select
                        className={'table-filter-' + filterName}
                        styles={customStyles}
                        onChange={entry => {
                            const filter_select_values = this.state.filter_select_values;
                            filter_select_values[filterName] = entry;
                            this.setState({ filter_select_values: filter_select_values});
                            this.onFilteredChangeCustom(entry.id, filterName);
                        }}
                        value={this.state.filter_select_values[filterName]}
                        options={options.map(([value, label]) => {
                            return {id: value, value: label, label: label};
                        })}
                        placeholder={placeholder}
                    />
                </div>
            </div>;
    };

    renderTotals = (state, makeTable, instance) => {
        let recordsInfoText = '';

        const { filtered, pageRows, pageSize, sortedData, page } = state;

        if (sortedData && sortedData.length > 0) {
            const isFiltered = filtered.length > 0;

            const total = this.state.total;

            const recordsCountFrom = (page * pageSize) + 1;

            const recordsCountTo = (recordsCountFrom + pageRows.length) - 1;

            if (isFiltered) {
                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${total} ` + this.state.totalFilteredRequestsLabel;
            } else {
                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} ` + LOCALIZED_STRS.get().of + ` ${total} ` + this.state.totalRequestsLabel;
            }

        } else {
            recordsInfoText = this.state.totalNoRequests;
        }
        return <div className="main-grid">
            <div className="above-table margin__vertical">
                <span className="records-info">{recordsInfoText}</span>
            </div>
            {makeTable()}
        </div>;
    };

    onSearchTermChange = (searchTerm) => {
        this.setState({
            searchTerm: searchTerm
        });
        if (searchTerm.length < this.props.searchmin) {
            return;
        }
        this.refreshDebounced();
    };

    refreshDebounced = () => {
        this.fetchData({page:-1});
    };

    render() {
        const {data, pages} = this.state;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="table_sortable">
                        {this.state.filters.length > 0 &&
                            <div>
                                <span>Filter by: </span>
                                {
                                    this.state.filters.map(
                                        (filter, i) => this.renderFilter(filter, i)
                                    )
                                }
                                <div className="filter--container">
                                      <span>
                                        <a className="link link--sentence" onClick={this.onClearFilters}>
                                          {LOCALIZED_STRS.get().clearFilters}
                                        </a>
                                      </span>
                                </div>
                            </div>
                        }
                        { this.state.searchParam &&
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="filter--container filter--container--search">
                                        <input
                                            autoComplete="off"
                                            placeholder={this.state.searchPlaceholder}
                                            value={this.state.searchTerm}
                                            onChange={e => {
                                                this.onSearchTermChange(e.target.value);
                                            }}
                                        />
                                        { !UTILS.isEmptyOrUndefined(this.state.searchTerm) &&
                                            <i
                                                onClick={this.clearSearch}
                                                className="icon icon--selectable icon--failed-circle"
                                            >
                                            </i>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        <ReactTable

                            data={data}
                            pages={pages}
                            url={this.state.url}
                            filtered={this.state.filtered}
                            defaultPageSize={this.state.pageSize}
                            onPageSizeChange={this.onPageSizeChange}
                            pageSize={this.state.pageSize}
                            loading={this.state.loading}
                            onSortedChange={this.onSortedChange}
                            minRows={0}
                            pageSizeOptions= {[5, 10, 20, 25, 50, 100]}
                            onFilteredChange={(filtered, column, value) => {
                                this.onFilteredChangeCustom(
                                    value,
                                    column.id || column.accessor
                                );
                            }}
                            sorted = {this.state.sorted}
                            onFetchData={ this.fetchData }
                            manual
                            columns={this.state.columns}
                        >
                            {this.renderTotals}
                        </ReactTable>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

SortableFilterableTable2.propTypes = {
    pagesize: PropTypes.number,
    filters: PropTypes.string,
    id: PropTypes.string,
    columns: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired

};

export default SortableFilterableTable2;
