import React from 'react';
import Input from '../../forms/common/input.jsx';
import DateSpan from '../../utils/dates.jsx';

class Statement extends React.Component {
    constructor(props) {
        super(props);
        this.typeClassMapping = {
            header: 'tr--header',
            total: 'tr--total'
        };
        this.state = {
            lineItems: Object.keys(this.props.keyNameMapping).map(key => {
                const values = this.props.periods.map(period => period[key]);
                const mapping = this.props.keyNameMapping[key];
                return {
                    name: mapping.name,
                    className: this.typeClassMapping[mapping.emphasis] || '',
                    type: mapping.type,
                    values: values
                };
            })
        };
    }
    td_span(type, index, editable, value, name){
        if (type === 'heading') {
            return <td key={index}></td>;
        }
        return (<td key={index} className="numeric" aria-hidden={value?.length === 0 && true}>  
                  { editable &&
                    <Input name={name} value={value} type="number"/>
                  }
                  { !editable &&
                    <span>{(type === 'number') ? (value === null) ? '-' : value.toLocaleString() : value }</span>
                  }
                  </td>
        );
    }
    render() {
        return (
            <div className="financial__statement">
                <h2 className="rr-header-2">{this.props.name}</h2>
                <table className="table table--summary table--financials">
                    <tbody>
                        {this.state.lineItems.map((item, index) => {
                              return (
                                  <tr key={index} className={item.className}>
                                      <th scope="row" className="th--lineitem">{item.name}</th>
                                      {item.values.map((value, index) => {
                                        return this.td_span(item.type, index, this.props.isElement, value, item.name);
                                      })}
                                  </tr>
                              );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

class Statement2 extends Statement{
    td_not_editable(name, value, data_type) {
        let render_value = value;
        if (data_type === 'date') {
            return <DateSpan data-datetime={new Date(value)}/>;
        } else if (data_type === 'number') {
            render_value = (value === null) ? '-' : value.toLocaleString();
        }
        return <>{render_value}</>;
    }
    td_span(data_type, index, editable, value, name){
        if (data_type === 'heading') {
            if(value) { // lint would not allow one line return
                return (<td key={index}>{value}</td>);
            }
            return (<th scope="col" key={index} aria-hidden="true"></th>);
        }
        let field;
        if (editable) {
            field = <Input name={name} value={value} type="number"/>;
        } else {
            field = this.td_not_editable(name, value, data_type);
        }
        return <td key={index} className="numeric"> { field } </td>;
    }
}

export {Statement as default, Statement2};
