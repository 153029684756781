
import React, {Component} from 'react';
import ReactDom from 'react-dom';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import Gallery from 'react-fine-uploader';
import {csrftoken} from './csrf.jsx';
import LOCALIZED_STRS from '../utils/localize.jsx';

// ...or load this specific CSS file using a <link> tag in your document
import 'react-fine-uploader/gallery/gallery.css';


class UploadComponent extends React.Component {

    constructor(props) {
        super(props);

        this.options = {
            chunking: {
                enabled: false
            },
            // if prop 'enable_auto_upload' is absent, set auto uploading to false
            autoUpload:  this.props.enable_auto_upload !== undefined ? true : false,
            validation: {
                allowedExtensions: ['bmp', 'csv', 'doc', 'docx', 'et', 'jpeg', 'jpg', 'ods', 'odt', 'pdf', 'png', 'rtf', 'tif', 'txt', 'xls', 'xlsm', 'xlsx', 'xps', 'zip'],
                sizeLimit: 50000000 // 50mb
            },
            messages: {
                typeError: '{file} is not a supported file type. Valid file types are: {extensions}.',
                sizeErrorL: '{file} is too large, maximum file size is {sizeLimit}.'
            },
            deleteFile: {
                enabled: true,
                endpoint: this.props.delete_url,
            },
            request: {
                endpoint: this.props.upload_url,
            },
            retry: {
                enableAuto: true
            }
        };

        if (this.props.get_existing_files !== undefined) {
            this.options.session = {
                endpoint: this.props.upload_url
            };
        }

        this.uploader = new FineUploaderTraditional({
            options: this.options
        });

        this.uploader.on('onUpload', () => {
            this.uploader.methods.setCustomHeaders({'X-CSRFToken': csrftoken()});
        });

        this.uploader.on('onDelete', (id) => {
            this.uploader.methods.setDeleteFileCustomHeaders({'X-CSRFToken': csrftoken()});
        });

        this.uploader.on('onError', (id, name, errorReason, xhrOrXdr) => {
            document.getElementById('uploadErrors').innerHTML = '';
            document.getElementById('uploadErrors').append(errorReason);
        });

        this.uploader.on('onStatusChange', (id, oldStatus, newStatus) => {
            if (newStatus == 'submitted') {
                // remove error message as uploader has resumed
                document.getElementById('uploadErrors').innerHTML = '';
            }
        });

        this.fileInputChildren = <span>{LOCALIZED_STRS.get().browse_for_files}</span>;

        // This is required for a bug in fine-uploader, where it cannot delete files
        // which were previously uploaded (and read through options.session above)
        this.uploader.on('sessionRequestComplete', (res, success) => {
            res.map( ({ uuid }) => {
                const file = this.uploader.methods.getUploads({uuid});
                this.uploader.methods._uploadData.setStatus(
                    file.id,
                    this.uploader.qq.status.UPLOAD_SUCCESSFUL
                );
            });
        });

        window.uploadComponent = this;
        this.csrftoken = csrftoken;
    }

    render() {
        return (
            <React.Fragment>
                <Gallery fileInput-children={ this.fileInputChildren } uploader={ this.uploader } />
                <div className="errors">
                    <div id="uploadErrors" className="error margin__vertical--tight--top"></div>
                </div>
            </React.Fragment>
        );
    }
}

export default UploadComponent;
