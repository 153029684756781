import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const BreadCrumbListItem = styled.li`
    display: inline-block;

    &:first-of-type:before {
        content: '\\e92f';
        font-family: RapidRatings;
        font-size: 11px;
        color: #A3B0BB;
        margin-right: 8px;
    }

    :not(:first-of-type) {
        display: ${props => props.contracted ? 'none' : 'inline-block'}

        &:before {
            content: '\\e930';
            font-family: RapidRatings;
            font-size: 11px;
            color: #A3B0BB;
            margin-right: 8px;
        }
    }

    &:not(:last-of-type):after {
        content: '\\e912';
        font-family: RapidRatings;
        font-size: 8px;
        color: #A3B0BB;
        margin-left: 14px;
        margin-right: 14px;
        display: ${props => props.contracted ? 'none' : 'inline-block'};
        vertical-align: middle;
    }
`;

const BreadCrumbLink = styled.a`
    font-weight: 400;
    font-size: 14px;
    color: ${props => props.isLastItem ? '#333' : '#005E8C'};
    text-transform: initial;
    text-decoration: none;
    line-height: 24px;
    outline: none;
`;

const Pill = styled.button`
    background-color: #DFE6EB;
    border-radius: 10px;
    min-width: 40px;
    border: none;
    font-size: 14px;
    color: #A3B0BB;
    display: ${props => props.contracted ? 'inline-block' : 'none'};
    height: 20px;
    margin-left: 14px;

    &:hover {
        cursor: pointer;
    }

    span:after {
        content: '\\B7\\B7\\B7';
    }
`;

class BreadCrumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: JSON.parse(props.data),
            contracted: false,
            childrenLength: JSON.parse(props.data).length - 1
        };
        this.showAll = this.showAll.bind(this);
    }

    static defaultProps = {
        data: '[]'
    };

    componentDidMount(){
        if (this.state.childrenLength > 1) {
            this.setState({
                contracted: true
            });
        }
    }

    showAll() {
        this.setState({
            contracted: false
        });
    }

    render() {
        const {data, contracted, childrenLength} = this.state;
        return (
            <ul>
                {data.map((item, index) => {
                    return (
                        <BreadCrumbListItem
                            key={index}
                            contracted={contracted}
                        >
                            <BreadCrumbLink
                                 {...(index == data.length-1 ? {} : {href:item.link})}
                                 isLastItem={index == data.length-1  ? true : false }
                                 key={index}
                            >{item.name}
                            </BreadCrumbLink>
                        </BreadCrumbListItem>
                    );
                })}
                <Pill
                    onClick={this.showAll}
                    contracted={contracted}
                >
                    <span>{childrenLength}</span>
                </Pill>
            </ul>
        );
    }
}

export default BreadCrumbs;
